var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('formcontent', {
    attrs: {
      "ok-text": "Speichern",
      "cancel-text": "Abbrechen",
      "button-one-text": _vm.detailButtonCaption
    },
    on: {
      "onOk": _vm.save,
      "onCancel": _vm.cancel,
      "onButtonOne": _vm.changeToFile
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-template-columns": "1fr 1fr"
    }
  }, [_c('searchcard', {
    attrs: {
      "caption": "Frau"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nachname"
    },
    model: {
      value: _vm.registration.surname,
      callback: function ($$v) {
        _vm.$set(_vm.registration, "surname", $$v);
      },
      expression: "registration.surname"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Vorname"
    },
    model: {
      value: _vm.registration.prename,
      callback: function ($$v) {
        _vm.$set(_vm.registration, "prename", $$v);
      },
      expression: "registration.prename"
    }
  })], 1), _c('searchcard', {
    attrs: {
      "caption": "Erreichbarkeit"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-column-gap": "20px",
      "grid-template-rows": "1fr 1fr",
      "grid-template-columns": "1fr 1fr"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Telefon"
    },
    model: {
      value: _vm.registration.phone1,
      callback: function ($$v) {
        _vm.$set(_vm.registration, "phone1", $$v);
      },
      expression: "registration.phone1"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Weiteres Telefon"
    },
    model: {
      value: _vm.registration.phone2,
      callback: function ($$v) {
        _vm.$set(_vm.registration, "phone2", $$v);
      },
      expression: "registration.phone2"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "E-Mail"
    },
    model: {
      value: _vm.registration.mail,
      callback: function ($$v) {
        _vm.$set(_vm.registration, "mail", $$v);
      },
      expression: "registration.mail"
    }
  })], 1)]), _c('searchcard', {
    attrs: {
      "caption": "Anschrift"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-column-gap": "20px",
      "grid-template-rows": "1fr 1fr",
      "grid-template-columns": "3fr 1fr 8fr 1fr 3fr"
    }
  }, [_c('v-text-field', {
    staticStyle: {
      "grid-column": "1 / 4",
      "grid-row": "1"
    },
    attrs: {
      "label": "Straße"
    },
    model: {
      value: _vm.registration.street,
      callback: function ($$v) {
        _vm.$set(_vm.registration, "street", $$v);
      },
      expression: "registration.street"
    }
  }), _c('v-text-field', {
    staticStyle: {
      "grid-column": "5",
      "grid-row": "1"
    },
    attrs: {
      "label": "Hausnummer"
    },
    model: {
      value: _vm.registration.streetNumber,
      callback: function ($$v) {
        _vm.$set(_vm.registration, "streetNumber", $$v);
      },
      expression: "registration.streetNumber"
    }
  }), _c('v-text-field', {
    staticStyle: {
      "grid-column": "1",
      "grid-row": "2"
    },
    attrs: {
      "label": "PLZ"
    },
    model: {
      value: _vm.registration.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.registration, "postcode", $$v);
      },
      expression: "registration.postcode"
    }
  }), _c('v-text-field', {
    staticStyle: {
      "grid-column": "3 / 6",
      "grid-row": "2"
    },
    attrs: {
      "label": "Stadt"
    },
    model: {
      value: _vm.registration.city,
      callback: function ($$v) {
        _vm.$set(_vm.registration, "city", $$v);
      },
      expression: "registration.city"
    }
  })], 1)]), _c('searchcard', {
    attrs: {
      "caption": "Sonstiges"
    }
  }, [_c('v-textarea', {
    staticStyle: {
      "width": "95%"
    },
    attrs: {
      "clearable": "",
      "clear-icon": "mdi-close-circle",
      "label": "Info"
    },
    model: {
      value: _vm.registration.info,
      callback: function ($$v) {
        _vm.$set(_vm.registration, "info", $$v);
      },
      expression: "registration.info"
    }
  })], 1), _c('searchcard', {
    attrs: {
      "caption": "Suche nach"
    }
  }, [_c('caseparameter', {
    attrs: {
      "layout": "block",
      "set": _vm.origin = this.flexibleData,
      "location": _vm.origin.region,
      "bdate": _vm.origin.bdate,
      "edate": _vm.origin.edate,
      "activitySearchParamList": _vm.origin.currentSearchActivityList
    }
  })], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }