import HelpService from './help.service'
import router from '#enna/common/router'

export default {
  async openHelp () {
    const helpRoute = router.resolve({ name: 'help' })
    window.open(helpRoute.href)
  },

  async helpData () {
    const response = await HelpService.queryHelp()
    return response
  }
}
