import ApiService from '../../../../common/service/api/api.service'

const LocationActivityService = {

  async delete (pid) {
    await ApiService.doDelete('/enna/midwife/activity/location/' + pid)
  },

  async load (pid) {
    var result = await ApiService.doGet('/enna/midwife/activity/location/view/dto/' + pid)
    return result
  },

  async save (dtoMidwifeLocationActivity) {
    var result = await ApiService.doPost('/enna/midwife/activity/location', dtoMidwifeLocationActivity)
    return result
  },

  async generateActivity (data) {
    var result = await ApiService.doPost('/enna/midwife/activity/location/generate', data)
    return result
  }
}

export default LocationActivityService
