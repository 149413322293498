var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "nudge-right": 40,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": _vm.title,
            "prepend-icon": "mdi-calendar",
            "clear-icon": "mdi-delete",
            "clearable": "",
            "readonly": ""
          },
          on: {
            "click:clear": _vm.clear
          },
          model: {
            value: _vm.formatteddate,
            callback: function ($$v) {
              _vm.formatteddate = $$v;
            },
            expression: "formatteddate"
          }
        }, on))];
      }
    }]),
    model: {
      value: _vm.menu,
      callback: function ($$v) {
        _vm.menu = $$v;
      },
      expression: "menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "change": _vm.onInput,
      "input": function ($event) {
        _vm.menu = false;
      }
    },
    model: {
      value: _vm.datadate,
      callback: function ($$v) {
        _vm.datadate = $$v;
      },
      expression: "datadate"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }