import router from '#enna/common/router'
import MenuController from '../../app/menu/menu.controller'
import MidwifeInquiryService from './midwife.inquiry.service'

export default {
  async openMidwifeInquiryOverview (midwifeId) {
    const response = await MidwifeInquiryService.queryMidwifeInquiry(midwifeId)
    const midwifeName = response.midwifeName
    const involvedCaseList = response.involvedCaseList
    const showAccumulativeInquiry = response.showAccumulativeInquiry
    const selectedTabIndex = await MenuController.getMidwifeFileStartTab('midwifeinquiryoverview')
    router.push({ name: 'midwifeinquiryoverview', params: { midwifeId: midwifeId, midwifeName: midwifeName, preselectedTab: selectedTabIndex, historyData: involvedCaseList, showAccumulativeInquiry: showAccumulativeInquiry } })
  },

  async queryOpenTodo (midwifeId) {
    const response = await MidwifeInquiryService.queryOpenTodo(midwifeId)
    return response.openTodo
  }
}
