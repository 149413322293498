var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('overviewtablecontent', {
    attrs: {
      "tablecontentKeyId": "midwifeId",
      "anchorKey": "midwifeId",
      "data-list": _vm.midwifeList,
      "headers": _vm.headerList,
      "propFilterKey": _vm.propFilterKey,
      "propFilterParam": _vm.propFilterParam
    },
    on: {
      "open": _vm.openMidwife
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }