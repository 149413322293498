var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('formcontent', [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-rows": "580px 1fr",
      "grid-template-columns": "1fr"
    }
  }, [_c('v-stepper', {
    model: {
      value: _vm.processStep,
      callback: function ($$v) {
        _vm.processStep = $$v;
      },
      expression: "processStep"
    }
  }, [_c('v-stepper-header', [_c('v-stepper-step', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showMidwife,
      expression: "showMidwife"
    }],
    attrs: {
      "complete": _vm.processStep > _vm.stepMidwife,
      "step": _vm.stepMidwife
    }
  }, [_vm._v("Hebammen")]), _c('v-divider'), _c('v-stepper-step', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showCase,
      expression: "showCase"
    }],
    attrs: {
      "complete": _vm.processStep > _vm.stepCase,
      "step": _vm.stepCase
    }
  }, [_vm._v("Anfragen")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.processStep > _vm.stepTemplate,
      "step": _vm.stepTemplate
    }
  }, [_vm._v("Mailvorlagen")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.processStep > _vm.stepSend,
      "step": _vm.stepSend
    }
  }, [_vm._v("Versenden")])], 1), _c('v-stepper-items', [_c('v-stepper-content', {
    attrs: {
      "step": _vm.stepMidwife
    }
  }, [_vm._v(" Hebamme ")]), _c('v-stepper-content', {
    attrs: {
      "step": _vm.stepCase
    }
  }, [_c('div', {
    staticClass: "cards"
  }, [_c('searchcard', {
    attrs: {
      "caption": "Suchoptionen",
      "card-height": _vm.contentHeight - 140
    }
  }, [_c('v-select', {
    staticStyle: {
      "width": "500px"
    },
    attrs: {
      "items": _vm.activityGroupList,
      "item-text": "name",
      "item-value": "datagroupId",
      "label": "Leistung"
    },
    model: {
      value: _vm.caseOption.datagroupId,
      callback: function ($$v) {
        _vm.$set(_vm.caseOption, "datagroupId", $$v);
      },
      expression: "caseOption.datagroupId"
    }
  }), _c('div', {
    staticClass: "containerx"
  }, [_c('datepicker', {
    staticStyle: {
      "width": "150px"
    },
    attrs: {
      "title": "Termin",
      "propdate": _vm.caseOption.searchDate
    },
    on: {
      "update:propdate": function ($event) {
        return _vm.$set(_vm.caseOption, "searchDate", $event);
      }
    }
  }), _c('span', {
    staticClass: "subtitle-1",
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_vm._v("Tage...")]), _c('v-text-field', {
    staticStyle: {
      "width": "80px"
    },
    attrs: {
      "type": "number",
      "label": "vor",
      "clearable": ""
    },
    model: {
      value: _vm.caseOption.daysBeforeDate,
      callback: function ($$v) {
        _vm.$set(_vm.caseOption, "daysBeforeDate", $$v);
      },
      expression: "caseOption.daysBeforeDate"
    }
  }), _c('v-text-field', {
    staticStyle: {
      "width": "80px"
    },
    attrs: {
      "type": "number",
      "label": "nach",
      "clearable": ""
    },
    model: {
      value: _vm.caseOption.daysAfterDate,
      callback: function ($$v) {
        _vm.$set(_vm.caseOption, "daysAfterDate", $$v);
      },
      expression: "caseOption.daysAfterDate"
    }
  }), _c('span', {
    staticClass: "subtitle-1",
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_vm._v("...Geburt")])], 1), _c('v-select', {
    staticStyle: {
      "width": "500px"
    },
    attrs: {
      "items": _vm.caseOptionList,
      "item-text": "caption",
      "item-value": "id",
      "multiple": "",
      "chips": "",
      "label": "Anfragetyp"
    },
    model: {
      value: _vm.caseOption.selectedCaseOptionList,
      callback: function ($$v) {
        _vm.$set(_vm.caseOption, "selectedCaseOptionList", $$v);
      },
      expression: "caseOption.selectedCaseOptionList"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Nur Anfragen auf der Warteliste berücksichtigen"
    },
    model: {
      value: _vm.caseOption.onlyOnWaitingList,
      callback: function ($$v) {
        _vm.$set(_vm.caseOption, "onlyOnWaitingList", $$v);
      },
      expression: "caseOption.onlyOnWaitingList"
    }
  }), _c('cmdbutton', {
    attrs: {
      "caption": "Anfragen suchen"
    },
    on: {
      "cmdclick": _vm.searchCase
    }
  })], 1), _c('div', [_c('searchcard', {
    attrs: {
      "caption": 'Suchergebnis  ' + _vm.displayCaseCount,
      "card-height": _vm.contentHeight - 140
    }
  }, _vm._l(_vm.foundCaseList, function (item) {
    return _c('div', {
      key: item.concernId
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedCaseList,
        expression: "selectedCaseList"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "value": item,
        "checked": Array.isArray(_vm.selectedCaseList) ? _vm._i(_vm.selectedCaseList, item) > -1 : _vm.selectedCaseList
      },
      on: {
        "change": function ($event) {
          var $$a = _vm.selectedCaseList,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = item,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_vm.selectedCaseList = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.selectedCaseList = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.selectedCaseList = $$c;
          }
        }
      }
    }), _c('div', [_vm._v(_vm._s(item.woman.name))]), _c('div', {
      staticStyle: {
        "padding-left": "20px"
      }
    }, [item.woman.region ? _c('div', [_vm._v(_vm._s(item.woman.region))]) : _vm._e(), item.woman.edbd ? _c('div', [_vm._v(" " + _vm._s(item.woman.edbdMode) + ": "), _c('dateitem', {
      staticStyle: {
        "display": "inline"
      },
      attrs: {
        "date-item": item.woman.edbd
      }
    }), _vm._l(item.circularMailCaseCandidateList, function (activity) {
      return _c('div', {
        key: activity.name,
        staticStyle: {
          "padding-left": "40px"
        }
      }, [_vm._v(" " + _vm._s(activity.name) + " - " + _vm._s(activity.state) + " "), _vm._l(item.circularMailCaseCandidateList, function (activity) {
        return _c('div', {
          key: activity.name,
          staticStyle: {
            "padding-left": "60px"
          }
        }, [activity.acute ? _c('div', [_vm._v("Akut")]) : _vm._e(), activity.onWaitingList ? _c('div', [_vm._v("Auf der Warteliste")]) : _vm._e(), activity.useDeadline ? _c('div', [_vm._v("ET / GT verwenden")]) : _c('div', [activity.start ? _c('div', [_vm._v(" Ab "), _c('dateitem', {
          staticStyle: {
            "display": "inline"
          },
          attrs: {
            "date-item": activity.start
          }
        })], 1) : _vm._e(), activity.latest ? _c('div', [_vm._v(" Spätestens "), _c('dateitem', {
          staticStyle: {
            "display": "inline"
          },
          attrs: {
            "date-item": activity.latest
          }
        })], 1) : _vm._e()])]);
      })], 2);
    })], 2) : _vm._e()])]);
  }), 0)], 1)], 1)]), _c('v-stepper-content', {
    attrs: {
      "step": _vm.stepTemplate
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('div', {
    staticStyle: {
      "padding-top": "0px",
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-columns": "5fr 2fr"
    }
  }, [_c('div', {
    style: {
      'height': _vm.contentHeight - 180 + 'px',
      'overflow-y': 'auto'
    }
  }, [_c('div', {
    staticClass: "font-weight-light text--primary",
    staticStyle: {
      "padding-bottom": "10px"
    }
  }, [_vm._v("Aktuelle Mailvorlage")]), _c('div', [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "flat": "",
      "light": "",
      "outlined": ""
    }
  }, [_c('v-card-text', {
    staticStyle: {
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-columns": "1fr 1fr"
    }
  }, [_c('div', [_c('span', {
    staticClass: "font-weight-black text--primary"
  }, [_vm._v(" " + _vm._s(_vm.circularMailData.mailContent.viewMailTemplate.name) + " ")]), _vm.circularMailData.mailContent.viewMailTemplate.priorityTemplate ? _c('div', [_vm._v("Hauptvorlage")]) : _vm._e()]), _c('div', {
    staticClass: "text--primary",
    staticStyle: {
      "padding-top": "10px"
    }
  }, [_vm._v(" " + _vm._s(_vm.circularMailData.mailContent.viewMailTemplate.description) + " ")])])], 1)], 1), _c('div'), _c('div', {
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_c('div', {
    staticStyle: {
      "background-color": "#EDEDED"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Betreff",
      "hint": "Betreffzeile der Mail"
    },
    model: {
      value: _vm.changedMailContent.mailSubject,
      callback: function ($$v) {
        _vm.$set(_vm.changedMailContent, "mailSubject", $$v);
      },
      expression: "changedMailContent.mailSubject"
    }
  }), _c('v-textarea', {
    staticStyle: {
      "width": "95%"
    },
    attrs: {
      "clearable": "",
      "clear-icon": "mdi-close-circle",
      "label": "Mailinhalt",
      "hint": "Inhalt der Mailvorlage"
    },
    model: {
      value: _vm.changedMailContent.mailContent,
      callback: function ($$v) {
        _vm.$set(_vm.changedMailContent, "mailContent", $$v);
      },
      expression: "changedMailContent.mailContent"
    }
  })], 1), _c('cmdbutton', {
    attrs: {
      "caption": "Zurücksetzen"
    },
    on: {
      "cmdclick": function ($event) {
        return _vm.setChangedMailContentToOrg();
      }
    }
  })], 1)]), _c('div', [_c('div', {
    staticClass: "font-weight-light text--primary",
    staticStyle: {
      "padding-bottom": "10px"
    }
  }, [_vm._v("Alternative Mailvorlagen")]), _c('div', {
    style: {
      'overflow-y': 'auto',
      'height': _vm.contentHeight + 'px'
    }
  }, _vm._l(_vm.circularMailData.alternativeMailTemplateList, function (mailtemplate) {
    return _c('div', {
      key: mailtemplate.pid
    }, [_c('v-card', {
      staticClass: "mx-auto",
      attrs: {
        "flat": "",
        "light": "",
        "outlined": ""
      }
    }, [_c('v-card-text', [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "color": "blue"
      },
      on: {
        "click": function ($event) {
          return _vm.changeCaseTemplate(mailtemplate);
        }
      }
    }, [_vm._v("mdi-play-circle-outline")]), _c('span', {
      staticClass: "font-weight-black text--primary"
    }, [_vm._v(" " + _vm._s(mailtemplate.name) + " ")]), mailtemplate.priorityTemplate ? _c('div', [_vm._v("Hauptvorlage")]) : _vm._e(), _c('div', {
      staticClass: "text--primary",
      staticStyle: {
        "padding-top": "10px"
      }
    }, [_vm._v(" " + _vm._s(mailtemplate.description) + " ")])], 1)], 1)], 1);
  }), 0)])])])], 1)], 1), _c('v-stepper-content', {
    attrs: {
      "step": _vm.stepSend
    }
  }, [_c('cmdbutton', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showSendButton,
      expression: "showSendButton"
    }],
    attrs: {
      "caption": "Rundmail versenden"
    },
    on: {
      "cmdclick": _vm.generate
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.showSendButton,
      expression: "!showSendButton"
    }]
  }, [_vm._v("Rundmail wird versendet")])], 1)], 1)], 1), _c('div', {
    staticStyle: {
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-areas": "'nextbtn backbtn'",
      "grid-template-rows": "2fr 1fr",
      "grid-template-columns": "1fr 1fr 1fr"
    }
  }, [_c('cmdbutton', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showButtonNext,
      expression: "showButtonNext"
    }],
    staticStyle: {
      "grid-area": "nextbtn"
    },
    attrs: {
      "caption": "Weiter"
    },
    on: {
      "cmdclick": function ($event) {
        return _vm.next();
      }
    }
  }), _c('cmdbutton', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showButtonBack,
      expression: "showButtonBack"
    }],
    staticStyle: {
      "grid-area": "backbtn"
    },
    attrs: {
      "caption": "Zurück"
    },
    on: {
      "cmdclick": function ($event) {
        return _vm.back();
      }
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }