<template>
  <formcontent
    ok-text="Speichern" @onOk="save"
    cancel-text="Abbrechen" @onCancel="cancel">
    <v-icon x-large style="padding-right: 30px;">{{ activityType.icon }}</v-icon>
    <label class="font-weight-medium title display-1">{{ activityName }}</label>
    <div>
      <slot />
    </div>
  </formcontent>
</template>

<script>
import MidwifeActivityController from '../overview/midwife.activity.controller'
import formcontent from '#enna/common/components/content/FormContent'

export default {
  components: {
    formcontent
  },

  methods: {
    save () {
      this.$emit('onOk')
    },
    cancel () {
      MidwifeActivityController.openMidwifeActivityOverview(this.midwifeId)
    }
  },

  props: {
    pid: { type: Number },
    activityType: { type: Object },
    midwifeName: String,
    midwifeId: String,
    activityName: String
  }
}
</script>
