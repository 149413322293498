import ApiService from '#enna/common/service/api/api.service'

const StatisticService = {
  async queryStatistic () {
    const result = await ApiService.doGet('/enna/office/statistic/overview')
    return result
  },

  async evaluate (statisticId, from, until) {
    const data = {
      pid: statisticId,
      from: from,
      until: until
    }
    const result = await ApiService.doPost('/enna/office/statistic/evaluate', data)
    return result
  },

  async download (statisticId, from, until) {
    const data = {
      pid: statisticId,
      from: from,
      until: until
    }
    const result = await ApiService.downloadFile('/enna/office/statistic/download', data)
    return result
  }
}

export default StatisticService
