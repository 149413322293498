export default {
  getConfig (response, key) {
    const configElement = response.config
    var index = 0
    for (index = 0; index < configElement.length; index++) {
      if (key === configElement[index].first) {
        return configElement[index].second
      }
    }
  },

  getBooleanConfig (response, key) {
    const configElement = response.config
    var index = 0
    for (index = 0; index < configElement.length; index++) {
      if (key === configElement[index].first) {
        return configElement[index].second === 'true'
      }
    }
  }
}
