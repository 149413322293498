<template>
  <div>
    <formcontent
      :button-one-text="registrateCaption()" @onButtonOne="registrate"
      :ok-text="backButtonCaption" @onOk="back">

      <div v-bind:style="{ 'height': contentHeightPage + 'px' }" class="searchpage">
        <div>
          <searchcard caption="Wohnort" :card-height="150">
            <singleselection style=" max-width: 400px;" :selectedItem.sync="region" caption="Wohnort" :prop-selected-item="region" idName="regionId" :propItemList="constantData.searchKonfig.regionGroupList"></singleselection>
          </searchcard>
          <searchcard caption="Leistungen" :card-height="contentHeight - 150">
            <div>
              <div v-for="activity in constantData.searchKonfig.fastActivityList" :key="activity.activityId">
                <div @click="addActivity(activity)"><v-icon small color="blue">mdi-playlist-plus</v-icon> {{ activity.name }}</div>
              </div>
              <div v-for="group in constantData.searchKonfig.activityGroupList" :key="group.groupId">
                <h2>{{ group.caption }}</h2>
                <div v-for="activity in group.itemList" :key="activity.activityId">
                  <div @click="addActivity(activity)"><v-icon x-small color="blue">mdi-playlist-plus</v-icon> {{ activity.name }}</div>
                </div>
              </div>
            </div>
          </searchcard>
        </div>
        <div>
          <searchcard caption="Termin" :card-height="150">
            <datepicker style="width:140px; display: inline-block;" title="Datum" :propdate.sync="edate"/>
            <v-radio-group row style="padding-left: 40px; display: inline-block;" v-model="dateType">
              <v-radio label="ET" value="Edate"></v-radio>
              <v-radio label="Geburt" value="Bdate"></v-radio>
            </v-radio-group>
          </searchcard>
          <div style="display:flex; justify-content: center; align-items: center; height: 100px; ">
            <v-btn @click="search" x-large color="green" fab>
              Suche
            </v-btn>
          </div>
          <searchcard caption="Leistungen, nach denen gesucht wird" :card-height="contentHeight - 250">
            <div v-for="searchActivity in currentSearchActivityList" :key="searchActivity.activity.activityId">
              <span @click="removeActivity(searchActivity)">
                <v-icon small class="mr-2" color="blue">mdi-playlist-remove</v-icon>
                <strong v-bind:style="{ 'color': activityColor }">{{ searchActivity.activity.name }}</strong>
              </span>
              <v-checkbox dense style="display: inline-block;padding-left: 20px;" v-model="searchActivity.acute" :value="searchActivity.acute" label="Akut"/>
              <v-checkbox dense style="display: inline-block;padding-left: 20px;" v-model="searchActivity.useDeadline" :value="searchActivity.useDeadline" label="ET / GT verwenden"/>
              <div style="padding-left: 30px;">
                <datepicker style="width:160px;display: inline-block;" title="Start am " :propdate.sync="searchActivity.from"/>
                <datepicker style="width:160px;display: inline-block; padding-left: 30px;" title="Start bis" :propdate.sync="searchActivity.until"/>
              </div>
              <v-divider></v-divider>
            </div>
            <div style="text-align: center;">
              <cmdbutton @cmdclick="clear" caption="Alle Angaben löschen"/>
            </div>
          </searchcard>
        </div>
        <div>
          <searchcard caption="Suchoptionen" :card-height="400">
            <div style="transform: scale(.75); margin-top: -40px; margin-bottom: -40px;">
              <div v-for="option in constantData.searchKonfig.searchOptionList" :key="option.id">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-switch dense v-model="selectedOptionList" multiple :value="option.id" :label="option.caption"/>
                    </div>
                  </template>
                  <span>{{ option.description }}</span>
                </v-tooltip>
              </div>
            </div>
          </searchcard>
          <searchcard v-if="this.constantData.originSearchData" caption="Original-Daten"  :card-height="contentHeight - 470">
            <caseparameter
              layout="block"
              :set="origin = this.constantData.originSearchData"
              :location="origin.region"
              :bdate="origin.bdate"
              :edate="origin.edate"
              :activitySearchParamList="origin.activityList"
            ></caseparameter>
            <cmdbutton v-if="this.constantData.originSearchData" @cmdclick="reset" caption="Anfragedaten zurücksetzen"/>
          </searchcard>
        </div>
      </div>
    </formcontent>
  </div>
</template>

<script>
import searchcard from './SearchCard'
import cmdbutton from '../../../common/components/element/CmdButton'
import singleselection from '../../../common/components/element/SingleSelectObject'
import datepicker from '../../../common/components/element/DatePicker'
import formcontent from '#enna/common/components/content/FormContent'
import caseparameter from './CaseParameter'
import SearchController, { DateType } from './case.search.controller'
import { ACTIVITY_COLOR } from '../causeactivity/causeactivity.utils'
import { transformToActivityIdList } from '../common/search.utils'
import InfoDialogController from '../../../common/components/element/infodialog/infodialog.controller'
import cloneDeep from 'lodash/cloneDeep'
import { transformGroupedData } from '@/common/utils/datagroup.utils'

export default {
  name: 'SearchMidwife',

  async created () {
    this.$pageInitController.init(this, this.$moduleInfoController.concern, 'Frau', 'Suche Hebamme', this.concernData?.womanName)
  },

  components: {
    searchcard,
    caseparameter,
    cmdbutton,
    formcontent,
    singleselection,
    datepicker
  },

  computed: {
    itemList () {
      const result = transformGroupedData(this.constantData.searchKonfig.regionGroupList)
      return result
    },

    activityColor () {
      return ACTIVITY_COLOR
    },

    contentHeight () {
      const height = this.$store.getters['size/formContent']
      return height
    },

    contentHeightPage () {
      const height = this.$store.getters['size/formContent']
      return height
    },

    backButtonCaption () {
      if (this.configSearchView.backTo === 'BackCase') {
        return 'Zurück'
      } else if (this.configSearchView.backTo === 'BackCauseActivity') {
        return 'Zurück'
      } else if (this.configSearchView.backTo === 'FileCauseActivity') {
        return 'Zur Anfrage'
      } else {
        return ''
      }
    }
  },

  methods: {
    remove (element) {
      this.region = null
    },

    registrateCaption () {
      if (this.configSearchView.showRegistration) {
        return 'Angaben zur Frau'
      } else {
        return ''
      }
    },

    addActivity (activity) {
      const found = this.currentSearchActivityList.filter(
        function (value, index, arr) {
          return value.activity.activityId === activity.activityId
        }
      )

      if (found.length === 0) {
        const newRecord = {
          activity: activity,
          useDeadline: true,
          from: null,
          until: null
        }
        this.currentSearchActivityList.push(newRecord)
      }
    },

    removeActivity (activity) {
      this.currentSearchActivityList = this.currentSearchActivityList.filter(
        function (value, index, arr) {
          return value.activity.activityId !== activity.activity.activityId
        }
      )
    },

    currentFlexibleData () {
      const result = {
        selectedOptionList: this.selectedOptionList,
        edate: this.edate,
        dateType: this.dateType,
        region: this.region,
        currentSearchActivityList: this.currentSearchActivityList
      }
      return result
    },

    async reset () {
      const origin = this.constantData.originSearchData
      if (origin.bdate) {
        this.edate = origin.bdate
        this.dateType = DateType.Bdate
      } else if (origin.edate) {
        this.edate = origin.edate
        this.dateType = DateType.Edate
      } else {
        this.edate = null
        this.dateType = DateType.Edate
      }

      this.region = origin.region
      this.currentSearchActivityList = cloneDeep(origin.activityList)
    },
    async clear () {
      this.selectedOptionList = []
      this.edate = null
      this.dateType = DateType.Edate
      this.region = null
      this.currentSearchActivityList = []
    },
    async registrate () {
      const currentFlexibleData = await this.currentFlexibleData()
      await SearchController.startRegistration(this.configSearchView, this.constantData, this.concernData, currentFlexibleData)
    },
    async search () {
      if (this.currentSearchActivityList.length === 0) {
        InfoDialogController.showInfo('Suche nach Hebamme', 'Keine Leistung ausgewählt')
      } else {
        const currentFlexibleData = this.currentFlexibleData()
        const hasFoundMidwife = await SearchController.startSearching(this.configSearchView, this.constantData, this.concernData, currentFlexibleData)
        if (!hasFoundMidwife) {
          InfoDialogController.showInfo('Suche nach Hebamme', 'Keine Hebamme gefunden')
        }
      }
    },

    initializeDateType (value) {
      if (value === null || value === undefined) {
        return DateType.Edate
      } else {
        return value
      }
    },

    async back () {
      const currentFlexibleData = await this.currentFlexibleData()
      let edate = null
      let bdate = null
      if (currentFlexibleData.dateType === 'Bdate') {
        bdate = currentFlexibleData.edate
      } else {
        edate = currentFlexibleData.edate
      }

      const activityList = transformToActivityIdList(this.currentSearchActivityList)
      const checkChange = await SearchController.checkChange(this.concernData.caseLocator, this.concernData.concernVersion, currentFlexibleData.region?.regionId, edate, bdate, activityList)
      if (checkChange.hasChanged) {
        InfoDialogController.showInfo('Suche nach Hebamme', checkChange.message)
      }
      await SearchController.backToCase(this.configSearchView.backTo, this.concernData.caseLocator)
    }
  },

  data () {
    return {
      selectedOptionList: this.flexibleData.selectedOptionList,
      edate: this.flexibleData.edate,
      dateType: this.initializeDateType(this.flexibleData.dateType),
      region: this.flexibleData.region,
      currentSearchActivityList: this.flexibleData.currentSearchActivityList
    }
  },

  props: {
    concernData: { type: Object },
    constantData: { type: Object },
    flexibleData: { type: Object },
    configSearchView: { type: Object }
  }
}
</script>

<style>
  .searchpage {
    display: grid;
    height: 100vh;
    grid-template-columns: 35% 40% 25%;
    grid-template-rows: 1fr;
  }
</style>
