<template>
  <div>
    <ul v-for="group in dynPropPropertyList" :key="group.caption">
      <div v-if="withHeader">
        <v-subheader style="display: grid;grid-template-columns: 3fr 3fr 10fr;">
          <div>{{ group.caption }}</div>
        </v-subheader>
        <v-divider ></v-divider>
     </div>
      <div style="display: grid; grid-template-columns: 8fr 3fr 2fr;"
           :id="item.id"
           v-for="item in group.itemList"
           :key="item.id">
        <span>{{ item.name }}</span>
        <span v-if="item.valueType === 'boolean'">
            <v-icon v-if="item.value == true" color="green">mdi-check-bold</v-icon>
            <v-icon v-else-if="item.value == false" color="red">mdi-close-thick</v-icon>
          </span>
        <span v-else-if="item.valueType === 'int'">
            {{ item.value }}
          </span>
        <span v-else-if="item.valueType === 'string'">
            {{ item.value }}
          </span>
        <span v-else-if="item.valueType === 'date'">
            <dateitem :date-item="item.value"></dateitem>
          </span>
        <span v-else-if="item.valueType === 'keylist'">
            <span v-if="item.value !== null">
              {{ item.value.name }}
            </span>
          </span>
        <span v-else-if="item.valueType === 'multiplekeylist'">
            <div v-for="keyItem in item.value" :key="keyItem.pid">
              {{ keyItem.name }}
            </div>
          </span>
        <span v-else-if="item.valueType === 'unknownboolean'">
            <v-icon v-if="item.value === true" color="green">mdi-check-bold</v-icon>
            <v-icon v-else-if="item.value === false" color="red">mdi-close-thick</v-icon>
            <v-icon v-else color="blue">mdi-help</v-icon>
          </span>
        <v-btn fab small text outlined class="mx-2" router >
          <v-icon small class="mr-2" @click="openDynProp(item)" color="blue">mdi-pencil</v-icon>
        </v-btn>
      </div>
    </ul>
  </div>
</template>

<script>
import dateitem from '#enna/common/components/element/DateItem'

export default {
  name: 'DynPropOverview',

  props: {
    withHeader: Boolean,
    writeRight: { type: String },
    dynPropPropertyList: { type: Array }
  },

  components: {
    dateitem
  },

  computed: {
    contentheight () {
      const height = this.$store.getters['size/overviewContent']
      return height
    }
  },

  methods: {
    openDynProp (property) {
      this.$emit('onEditDynPropData', property)
    }
  }
}
</script>
