var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('dynpropbasedetail', {
    attrs: {
      "dynPropParam": _vm.dynPropParam
    },
    on: {
      "onSave": _vm.save
    }
  }, [_c('v-radio-group', {
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "ja",
      "value": "true"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "nein",
      "value": "false"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "unbekannt",
      "value": "null"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }