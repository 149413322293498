var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    style: {
      'overflow-y': 'auto',
      'max-height': _vm.contentheight + 'px'
    }
  }, [_c('formcontent', {
    attrs: {
      "buttonOneText": _vm.accumulativeButtonCaption
    },
    on: {
      "onButtonOne": _vm.accumulativeInquiry
    }
  }, _vm._l(_vm.historyData, function (caseData) {
    return _c('v-card', {
      key: caseData.caseLocatorPid,
      staticClass: "mx-auto",
      attrs: {
        "outlined": ""
      }
    }, [_c('div', {
      staticStyle: {
        "display": "grid",
        "grid-template-columns": "3fr 3fr"
      }
    }, [_c('div', [_c('div', {
      staticClass: "font-weight-light",
      staticStyle: {
        "padding-top": "5px"
      }
    }, [_vm._v("Frau")]), _c('span', {
      staticClass: "text-h5 font-weight-bold",
      staticStyle: {
        "padding-left": "15px"
      },
      on: {
        "click": function ($event) {
          return _vm.openWoman(caseData.caseLocator);
        }
      }
    }, [_vm._v(_vm._s(caseData.name))]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: caseData.adress,
        expression: "caseData.adress"
      }]
    }, [_c('div', {
      staticClass: "font-weight-light",
      staticStyle: {
        "padding-top": "20px"
      }
    }, [_vm._v("Anschrift")]), _c('div', {
      staticClass: "font-weight-medium",
      staticStyle: {
        "padding-left": "15px"
      }
    }, [_vm._v(_vm._s(caseData.adress.locationName))]), _c('span', {
      staticClass: "font-weight-medium",
      staticStyle: {
        "padding-left": "15px"
      }
    }, [_vm._v(_vm._s(caseData.adress.street) + " " + _vm._s(caseData.adress.streetnumber))]), _c('span', {
      staticClass: "font-weight-medium",
      staticStyle: {
        "padding-left": "15px"
      }
    }, [_vm._v(_vm._s(caseData.adress.postcode) + " " + _vm._s(caseData.adress.city))])]), _c('div', {
      staticClass: "font-weight-light",
      staticStyle: {
        "padding-top": "20px"
      }
    }, [_vm._v("Kontaktdaten")]), _c('div', {
      staticClass: "font-weight-medium",
      staticStyle: {
        "padding-left": "15px"
      }
    }, [_vm._v(_vm._s(caseData.phone1))]), _c('div', {
      staticClass: "font-weight-medium",
      staticStyle: {
        "padding-left": "15px"
      }
    }, [_vm._v(_vm._s(caseData.phone2))]), _c('div', {
      staticClass: "font-weight-medium",
      staticStyle: {
        "padding-left": "15px"
      }
    }, [_vm._v(_vm._s(caseData.mail))]), _c('div', {
      staticClass: "font-weight-light",
      staticStyle: {
        "padding-top": "20px"
      }
    }, [_vm._v("Anfrage vom")]), _c('div', {
      staticClass: "font-weight-medium",
      staticStyle: {
        "padding-left": "15px"
      }
    }, [_vm._v(_vm._s(_vm.date(caseData.caseInfo.inquiryDate)))])]), _c('div', [_c('div', {
      staticClass: "font-weight-light",
      staticStyle: {
        "padding-top": "5px"
      }
    }, [_vm._v("Termin")]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: caseData.edate,
        expression: "caseData.edate"
      }],
      staticClass: "font-weight-medium",
      staticStyle: {
        "padding-left": "15px"
      }
    }, [_vm._v("ET: " + _vm._s(_vm.date(caseData.edate)))]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: caseData.bdate,
        expression: "caseData.bdate"
      }],
      staticClass: "font-weight-medium",
      staticStyle: {
        "padding-left": "15px"
      }
    }, [_vm._v("Geburt: " + _vm._s(_vm.date(caseData.bdate)))]), _c('div', {
      staticClass: "font-weight-light",
      staticStyle: {
        "padding-top": "20px"
      }
    }, [_vm._v("Status")]), _c('div', {
      staticClass: "font-weight-medium",
      staticStyle: {
        "padding-left": "15px"
      }
    }, [_vm._v(_vm._s(caseData.caseInfo.state))]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: caseData.caseInfo.currentMidwife,
        expression: "caseData.caseInfo.currentMidwife"
      }],
      staticClass: "font-weight-medium",
      staticStyle: {
        "padding-left": "15px"
      }
    }, [_vm._v("Hebamme: " + _vm._s(caseData.caseInfo.currentMidwife))]), _c('div', {
      staticClass: "font-weight-light",
      staticStyle: {
        "padding-top": "20px"
      }
    }, [_vm._v("Leistung(en)")]), _c('ul', _vm._l(caseData.causeActivityList, function (causeActivity) {
      return _c('li', {
        key: causeActivity.caption,
        staticClass: "font-weight-medium",
        staticStyle: {
          "padding-left": "15px"
        }
      }, [_vm._v(_vm._s(causeActivity.activityName))]);
    }), 0)])])]);
  }), 1), _c('opentodoselectordialog', {
    attrs: {
      "propShowDialog": _vm.showDialog,
      "prop-open-todo": _vm.openTodoData
    },
    on: {
      "onSave": _vm.onSaveDialog,
      "onCancel": _vm.onCancelDialog,
      "update:propShowDialog": function ($event) {
        _vm.showDialog = $event;
      },
      "update:prop-show-dialog": function ($event) {
        _vm.showDialog = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }