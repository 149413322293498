<template>
  <div v-bind:style="{ 'overflow-y': 'auto', 'max-height': contentheight + 'px' }">
    <formcontent
      :buttonOneText=accumulativeButtonCaption
      @onButtonOne="accumulativeInquiry">
      <v-card v-for="caseData in historyData" :key="caseData.caseLocatorPid" class="mx-auto" outlined >
        <div style="display: grid;
        grid-template-columns:3fr 3fr;">
          <div>
            <div style="padding-top: 5px;" class="font-weight-light">Frau</div>
            <span @click="openWoman(caseData.caseLocator)" class="text-h5 font-weight-bold " style="padding-left: 15px;">{{ caseData.name }}</span>

            <div v-show="caseData.adress">
              <div style="padding-top: 20px;" class="font-weight-light">Anschrift</div>
              <div class="font-weight-medium" style="padding-left: 15px;">{{ caseData.adress.locationName }}</div>
              <span class="font-weight-medium" style="padding-left: 15px;">{{ caseData.adress.street }} {{ caseData.adress.streetnumber }}</span>
              <span class="font-weight-medium" style="padding-left: 15px;">{{ caseData.adress.postcode }} {{ caseData.adress.city }}</span>
            </div>

            <div style="padding-top: 20px;" class="font-weight-light">Kontaktdaten</div>
            <div class="font-weight-medium" style="padding-left: 15px;">{{ caseData.phone1 }}</div>
            <div class="font-weight-medium" style="padding-left: 15px;">{{ caseData.phone2 }}</div>
            <div class="font-weight-medium" style="padding-left: 15px;">{{ caseData.mail }}</div>

            <div style="padding-top: 20px;" class="font-weight-light">Anfrage vom</div>
            <div class="font-weight-medium" style="padding-left: 15px;">{{ date(caseData.caseInfo.inquiryDate) }}</div>
          </div>
          <div>
            <div style="padding-top: 5px;" class="font-weight-light">Termin</div>
            <div v-show="caseData.edate" class="font-weight-medium" style="padding-left: 15px;">ET: {{ date(caseData.edate) }}</div>
            <div v-show="caseData.bdate" class="font-weight-medium" style="padding-left: 15px;">Geburt: {{ date(caseData.bdate) }}</div>

            <div style="padding-top: 20px;" class="font-weight-light">Status</div>
            <div class="font-weight-medium" style="padding-left: 15px;">{{ caseData.caseInfo.state }}</div>
            <div v-show="caseData.caseInfo.currentMidwife" class="font-weight-medium" style="padding-left: 15px;">Hebamme: {{ caseData.caseInfo.currentMidwife }}</div>

            <div style="padding-top: 20px;" class="font-weight-light">Leistung(en)</div>
            <ul>
              <li v-for="causeActivity in caseData.causeActivityList" :key="causeActivity.caption" class="font-weight-medium" style="padding-left: 15px;">{{ causeActivity.activityName }}</li>
            </ul>
          </div>
        </div>
      </v-card>
    </formcontent>
    <opentodoselectordialog
      @onSave="onSaveDialog"
      @onCancel="onCancelDialog"
      :propShowDialog.sync="showDialog"
      :prop-open-todo="openTodoData" />
  </div>
</template>

<script>
import { formatDate } from '../../../common/utils/date.utils'
import formcontent from '../../../common/components/content/FormContent'
import MidwifeOverviewController from '../overview/midwife.overview.controller'
import CaseOverviewController from '../../../module/concern/case/case.overview.controller'
import opentodoselectordialog from './OpenTodoSelectorDialog.vue'
import MidwifeInquiryController from './midwife.inquiry.controller'
import SendmailController from '../../superior/sendmail/sendmail.controller'
export default {
  name: 'MidwifeInquiryOverview',

  async created () {
    this.$pageInitController.initBack(this, this.$moduleInfoController.midwife, 'Hebamme', 'Stammdaten', this.midwifeName)
  },

  methods: {
    async openWoman (caseLocator) {
      CaseOverviewController.openCaseFile(caseLocator, this.midwifeId)
    },

    async back () {
      await MidwifeOverviewController.openMidwifeOverview(this.midwifeId)
    },

    onCancelDialog () {
      this.showDialog = false
    },

    async onSaveDialog (selectedCases) {
      this.showDialog = false
      await SendmailController.openAccumulativeMail(this.midwifeId, selectedCases)
    },

    async accumulativeInquiry () {
      this.openTodoData = await MidwifeInquiryController.queryOpenTodo(this.midwifeId)
      this.showDialog = true
    },

    date (value) {
      return formatDate(value)
    }
  },

  computed: {
    accumulativeButtonCaption () {
      if (this.showAccumulativeInquiry) {
        return 'Sammelanfrage'
      } else {
        return null
      }
    },

    contentheight () {
      const height = this.$store.getters['size/overviewContent']
      return height
    }
  },

  components: {
    opentodoselectordialog,
    formcontent
  },

  data () {
    return {
      showDialog: false,
      openTodoData: null
    }
  },

  props: {
    midwifeId: { type: String },
    midwifeName: { type: String },
    historyData: { type: Array },
    showAccumulativeInquiry: { type: Boolean }
  }
}
</script>
