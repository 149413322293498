var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('formcontent', {
    attrs: {
      "ok-text": "Speichern",
      "cancel-text": "Abbrechen"
    },
    on: {
      "onOk": _vm.save,
      "onCancel": _vm.cancel
    }
  }, [_c('div', {
    staticClass: "font-weight-light",
    staticStyle: {
      "padding-bottom": "20px"
    }
  }, [_vm._v(_vm._s(_vm.dynPropParam.name))]), _vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }