<template>
  <div class="networklayout" v-resize="onResize">
    <div class="appbar">
      <appbar padding-left="110" padding-right="110" height="64"></appbar>
    </div>
    <div class="moduleinfo">
      <modulinfo></modulinfo>
    </div>
    <div class="sidelinks">
      <div v-for="item in shortlinks" :key="item.id"  style="padding-bottom: 20px;">
        <v-btn elevation="1" fab small text outlined class="mx-2" @click="item.openRoute()">
          <v-tooltip right :key="item.text">
            <template v-slot:activator="{ on }">
          <v-icon v-on="on" color="blue">{{ item.icon }}</v-icon>
            </template>
            <span>{{ item.text }}</span>
          </v-tooltip>
        </v-btn>
      </div >
      <div style="padding-bottom: 20px;">
        <v-btn v-if="showReload()" fab small text outlined class="mx-2" @click="refresh" >
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="blue"> mdi-reload </v-icon>
            </template>
            <span>Erneut laden</span>
          </v-tooltip>
        </v-btn>
        <v-btn elevation="20" v-if="showBack()" fab small text outlined class="mx-2" @click="back" >
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="blue">mdi-backburger</v-icon>
            </template>
            <span>Zurück</span>
          </v-tooltip>
        </v-btn>
        <infodialog content="importContent" caption="Überschrift"></infodialog>
      </div>
    </div>
    <div class="content">
      <v-fade-transition mode="out-in">
        <v-main style="padding: 0px;">
          <errorpanel></errorpanel>
          <div>
            <slot></slot>
          </div>
        </v-main>
      </v-fade-transition>
    </div>
    <div class="footer">
      <v-divider inset></v-divider>
    </div>
  </div>
</template>

<script>
import infodialog from '../../element/infodialog/InfoDialog'
import NavigationController from './navigation.controller'
import appbar from '../../../../module/app/appbar/AppBar'
import modulinfo from '../../../../module/app/moduleinfo/Moduleinfo'
import errorpanel from '../../../../module/app/error/ErrorPanel'

export default {
  name: 'networklayout',

  components: {
    infodialog,
    appbar,
    modulinfo,
    errorpanel
  },

  computed: {
    shortlinks: function () {
      return this.$store.state.menu.shortlinks
    }
  },

  data () {
    return {
      menu: [],
      drawer: false
    }
  },

  methods: {
    showReload () {
      return NavigationController.showReload()
    },

    refresh () {
      NavigationController.executeReload()
    },

    showBack () {
      return NavigationController.showBack()
    },

    back () {
      NavigationController.executeBack()
    },

    onResize () {
      this.$store.dispatch('size/setWindowY', window.innerHeight)
    }
  }
}
</script>

<style>
  .networklayout {
    display: grid;
    grid-template-columns: 1fr minmax(1200px, 2fr) 1fr;
    grid-template-rows: 64px 100px 5fr 20px;
    width: 100vw;
    height: 100vh;
  }
  .appbar {
    grid-column-start: 2;
  }
  .moduleinfo {
    padding-top: 5px;
    grid-column-start: 2;
  }
  .content {
    /*background-color: blueviolet;*/
    overflow: hidden;
  }
  .footer {
    /*background-color: darkorange;*/
    grid-column-start: 2;
  }

  .sidelinks {
    grid-column-start: 1;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
  }
</style>
