import { formatDate } from '../../../common/utils/date.utils'

export const ACTIVITY_COLOR = 'green'

export function startDate (start, latest) {
  if (start != null && latest != null) {
    return 'Start zwischen ' + formatDate(start) + ' und ' + formatDate(latest)
  } else if (start != null) {
    return 'Beginn ab ' + formatDate(start)
  } else if (latest != null) {
    return 'Beginn bis spätestens ' + formatDate(latest)
  } else {
    return null
  }
}
