<template>
  <div >
    <h1>Hebammennetzwerke in Deutschland</h1>
    <div>Datenserver: {{ serviceUrl }}</div>
  </div>
</template>

<script>
import getEnv from '@/utils/env'
export default {
  name: 'Homepage',

  async created () {
    this.$layoutController.setEnnaLayout(this)
  },

  data () {
    return {
      serviceUrl: getEnv('VUE_APP_SERVICE_URL')
    }
  }
}
</script>
