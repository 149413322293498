var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "background-color": "lightskyblue"
    }
  }, [_c('v-toolbar-title', [_c('span', {
    staticClass: "font-weight-thin"
  }, [_vm._v("e n ")]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("n a")])]), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }