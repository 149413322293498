<template>
  <formcontent
    cancel-text="Schließen" @onCancel="cancel">
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:1fr;
        grid-template-columns:2fr 1fr;">
        <div style="
          display: grid;
          grid-gap: 20px;
          grid-template-rows:580px 1fr;
          grid-template-columns:1fr;">
            <v-stepper v-model="processStep">
              <v-stepper-header>
                <v-stepper-step :complete="processStep > 1" step="1">Leistungstyp</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="processStep > 2" step="2">Leistungen</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="processStep > 3" step="3">Vorlage <small>Optional</small></v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="processStep > 4" step="4">Anlegen</v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-list>
                    <v-subheader>Leistungsart</v-subheader>
                    <v-list-item-group color="primary">
                      <v-list-item
                        v-for="item in activityCreationOptionList"
                        :key="item.composedPid"
                        @click="activityTypeChanged(item.activityType.keylistValueId)">
                        <v-list-item-icon>
                          <v-icon v-text="item.activityType.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="item.activityType.name"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <label>Leistungstyp: {{ textSelectedActivityType }}</label>
                  <v-select
                    v-model="selectedActivityList"
                    :items="activityList"
                    item-text="name"
                    item-value="activityId"
                    :menu-props="{ maxHeight: '400' }"
                    label="Leistungen"
                    multiple
                    chips
                    deletable-chips
                    hint="Leistungen, die hinzugefügt werden sollen"
                    persistent-hint>
                  </v-select>
                </v-stepper-content>
                <v-stepper-content step="3">
                  <v-select
                    v-model="selectedTemplate"
                    :items="templateList"
                    item-text="activity.name"
                    item-value="pid"
                    :menu-props="{ maxHeight: '400' }"
                    label="Vorlage"
                    chips
                    deletable-chips
                    hint="Vorlage, die zum Erzeugen der neuen Leistungen verwendet wird"
                    persistent-hint>
                    <template v-slot:item='{ item }'>
                      {{ item.activity.name }}<span class="font-weight-thin" style="padding-left: 15px;">{{ item.info }}</span>
                    </template>
                  </v-select>
                </v-stepper-content>
                <v-stepper-content step="4">
                  <cmdbutton @cmdclick="generate" caption="Leistungen anlegen"/>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
            <div style="
              display: grid;
              grid-gap: 20px;
              grid-template-areas: 'nextbtn backbtn';
              grid-template-rows:2fr 1fr;
              grid-template-columns:1fr 1fr 1fr;">
              <cmdbutton style="grid-area:nextbtn" v-show="showButtonNext" @cmdclick="next()" caption="Weiter"/>
              <cmdbutton style="grid-area:backbtn" v-show="showButtonBack" @cmdclick="back()" caption="Zurück"/>
            </div>
        </div>
        <div style="
          display: grid;
          grid-template-rows:100px 100px 1fr;
          grid-template-columns:1fr;">
          <div style="height: 80px;">
              <div class="font-weight-light">Leistungstyp</div>
              <div class="font-weight-medium" style="padding-left: 15px;">{{ textSelectedActivityType }}</div>
          </div>
          <div style="height: 80px;">
            <div class="font-weight-light">Vorlage</div>
            <div class="font-weight-medium" style="padding-left: 15px;">{{ textSelectedTemplate }}</div>
          </div>
          <div style="height: 80px;">
            <div class="font-weight-light">Ausgewählte Leistungen</div>
            <div v-for="activity in textSelectedActivityList" :key="activity" class="font-weight-medium" style="padding-left: 15px;">{{ activity }}</div>
          </div>
        </div>
      </div>
  </formcontent>
</template>

<script>
import formcontent from '#enna/common/components/content/FormContent'
import MidwifeActivityController from '../overview/midwife.activity.controller'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'MidwifeNewActivity',

  async mounted () {
    this.initSelection()
  },

  methods: {
    async reload () {
      MidwifeActivityController.reloadNewActivity(this.midwifeId)
    },

    cancel () {
      MidwifeActivityController.openMidwifeActivityOverview(this.midwifeId)
    },

    async generate () {
      const response = await MidwifeActivityController.generateActivity(this.midwifeId, this.selectedActivityType, this.selectedActivityList, this.selectedTemplate, this.versionId)
      this.activityCreationOptionList = response.data.activityCreationOptionList
      this.versionId = response.data.versionId
      this.processStep = 1
      this.initSelection()
    },

    setLayoutAfterChanged () {
      if (this.processStep === 1) {
        this.showButtonBack = false
      } else {
        this.showButtonBack = true
      }

      if (this.processStep === 4) {
        this.showButtonNext = false
      } else {
        this.showButtonNext = true
      }

      let type = null
      let templateText = null
      const textActivityList = []
      for (let i = 0; i < this.activityCreationOptionList.length; i++) {
        const group = this.activityCreationOptionList[i]
        if (group.activityType.keylistValueId === this.selectedActivityType) {
          type = group.activityType.name
          for (let k = 0; k < group.templateActivityList.length; k++) {
            const template = group.templateActivityList[k]
            if (template.pid === this.selectedTemplate) {
              templateText = template.activity.name
              if (template.info != null) {
                templateText = templateText + ' / ' + template.info
              }
            }
          }

          for (let l = 0; l < this.selectedActivityList.length; l++) {
            const selectedActivity = this.selectedActivityList[l]
            for (let m = 0; m < group.activityList.length; m++) {
              const activity = group.activityList[m]
              if (activity.activityId === selectedActivity) {
                textActivityList.push(activity.name)
              }
            }
          }
        }
      }
      this.textSelectedActivityType = type
      this.textSelectedActivityList = textActivityList
      this.textSelectedTemplate = templateText
    },

    next () {
      this.processStep++
      if (this.processStep > 4) {
        this.processStep = 4
      }
      this.setLayoutAfterChanged()
    },

    back () {
      this.processStep--
      if (this.processStep < 1) {
        this.processStep = 1
      }
      this.setLayoutAfterChanged()
    },

    initSelection (value) {
      let activityTypeId = null
      if (this.activityCreationOptionList.length > 0) {
        activityTypeId = this.activityCreationOptionList[0].activityType.keylistValueId
      }
      this.activityTypeChanged(activityTypeId)
      this.setLayoutAfterChanged()
    },

    activityTypeChanged (value) {
      this.selectedActivityType = value
      this.textSelectedActivityType = null
      this.activityList = []
      this.selectedActivityList = []
      this.textSelectedActivityList = []
      this.templateList = []
      this.selectedTemplate = null
      this.textSelectedTemplate = null
      for (let index = 0; index < this.activityCreationOptionList.length; index++) {
        const group = this.activityCreationOptionList[index]
        if (group.activityType.keylistValueId === value) {
          this.activityList = group.activityList
          this.templateList = group.templateActivityList
        }
      }
    }
  },

  props: {
    propActivityCreationOptionList: { type: Array },
    midwifeId: String,
    midwifeName: String,
    propVersionId: Number
  },

  async created () {
    this.$pageInitController.initReload(this, this.$moduleInfoController.midwife, 'Hebamme', 'Neue Leistung(en)', this.midwifeName)
  },

  watch: {
    propActivityCreationOptionList (val) {
      this.activityCreationOptionList = cloneDeep(this.propActivityCreationOptionList)
    }
  },

  data () {
    return {
      showButtonNext: true,
      showButtonBack: false,
      processStep: 1,
      activityCreationOptionList: cloneDeep(this.propActivityCreationOptionList),
      versionId: this.propVersionId,
      selectedActivityType: null,
      activityList: [],
      selectedActivityList: [],
      templateList: [],
      selectedTemplate: null,
      textSelectedActivityType: null,
      textSelectedActivityList: [],
      textSelectedTemplate: null
    }
  },

  components: {
    formcontent
  }
}
</script>
