import router from '#enna/common/router'
import DynPropService from './dynprop.service'

export default {
  async openDynPropDetail (param, reloadId) {
    await callDynPropDetail(param, reloadId)
  },

  async cancel (param) {
    await param.callFunction(param.callParam)
  },

  async saveBoolean (param, value) {
    save(param, value, 'boolean')
  },

  async saveUnknownBoolean (param, value) {
    save(param, value, 'unknownboolean')
  },

  async saveInt (param, value) {
    save(param, value, 'int')
  },

  async saveString (param, value) {
    save(param, value, 'string')
  },

  async saveMultipleKeylist (param, value) {
    save(param, value, 'multiplekeylist')
  },

  async saveKeylist (param, value) {
    save(param, value, 'keylist')
  },

  async saveDate (param, value) {
    save(param, value, 'date')
  },

  async reload (param) {
    await param.reloadFunction(param.reloadParam, Date.now())
  }
}

function isDatabase (param) {
  return param.dtoDynPropData.dynPropOrigin === 'database'
}

function createDatabaseBody (param, value) {
  const dto = param.dtoDynPropData
  dto.value = value
  return {
    data: dto,
    keyVersion: param.keyVersion
  }
}

function createConcernBody (param, value) {
  const dto = param.dtoDynPropData
  dto.value = value
  return {
    concernVersion: param.dtoDynPropData.version,
    concernId: param.objectId,
    dto: dto
  }
}

async function callDynPropDetail (param, reloadId) {
  const dtoDynPropData = param.dtoDynPropData
  let path = ''
  if (dtoDynPropData.dataType === 'boolean') {
    path = 'dynpropbooleandetail'
  } else if (dtoDynPropData.dataType === 'unknownboolean') {
    path = 'dynpropunknownbooleandetail'
  } else if (dtoDynPropData.dataType === 'string') {
    path = 'dynpropstringdetail'
  } else if (dtoDynPropData.dataType === 'keylist') {
    path = 'dynpropkeylistdetail'
  } else if (dtoDynPropData.dataType === 'multiplekeylist') {
    path = 'dynpropmultiplekeylistdetail'
  } else if (dtoDynPropData.dataType === 'date') {
    path = 'dynpropdatedetail'
  } else if (dtoDynPropData.dataType === 'int') {
    path = 'dynpropintdetail'
  }
  internallyCallDynPropDetail(param, path, reloadId)
}

async function internallyCallDynPropDetail (param, path, reloadId) {
  router.push({ name: path, params: { dynPropParam: param }, query: { reload: reloadId } })
}

async function save (param, value, path) {
  const originDatabase = isDatabase(param)
  if (originDatabase) {
    const body = createDatabaseBody(param, value)
    await DynPropService.saveDatabaseProperty(path, body)
  } else {
    const body = createConcernBody(param, value)
    await DynPropService.saveConcernProperty(path, body)
  }
  await param.callFunction(param.callParam)
}
