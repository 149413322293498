<template>
  <midwifeactivitydetail
    @onOk="save"
    :pid="pid"
    :activityType="activityType"
    :activityName="activityName"
    :midwifeName="midwifeName"
    :midwifeId="midwifeId">
    <template v-slot>
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:100px;
        align-items: end;
        grid-template-columns:3fr 1fr;">
        <v-textarea
          v-model="dtoKnowledgeActivity.info"
          label="Info"
          clearable
          auto-grow
          rows="1"
        ></v-textarea>
      </div>
    </template>
  </midwifeactivitydetail>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import midwifeactivitydetail from '../common/MidwifeActivityDetail'
import KnowledgeActivityController from './knowledge.activity.controller'
import MidwifeActivityController from '../overview/midwife.activity.controller'

export default {
  name: 'MidwifeActivityDetail',

  async created () {
    this.$pageInitController.initReload(this, this.$moduleInfoController.midwife, 'Hebammenleistung', this.activityType.name, this.midwifeName)
  },

  watch: {
    propDtoKnowledgeActivity (val) {
      this.dtoKnowledgeActivity = cloneDeep(this.propDtoKnowledgeActivity)
    }
  },

  methods: {
    async reload () {
      await MidwifeActivityController.reloadActivityDetail(this.midwifeId, this.activityType.keylistValueId, this.pid, Date.now())
    },

    save () {
      KnowledgeActivityController.save(this.dtoKnowledgeActivity)
    }
  },

  props: {
    propDtoKnowledgeActivity: { type: Object },
    pid: { type: Number },
    activityName: { type: String },
    activityType: { type: Object },
    midwifeName: { type: String },
    midwifeId: { type: String }
  },

  data () {
    return {
      dtoKnowledgeActivity: cloneDeep(this.propDtoKnowledgeActivity)
    }
  },

  components: {
    midwifeactivitydetail
  }
}
</script>
