var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('formcontent', {
    attrs: {
      "ok-text": "Abschicken",
      "cancel-text": "Abbrechen"
    },
    on: {
      "onOk": _vm.save,
      "onCancel": _vm.cancel
    }
  }, [_c('v-tabs', [_vm._l(_vm.mailExecDataList, function (item) {
    return _c('v-tab', {
      key: item.pid,
      attrs: {
        "vertical": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v("mdi-email")]), _vm._v(_vm._s(item.mailReceiverCaption)), _c('br'), _vm._v(_vm._s(item.mailType.caption) + " ")], 1);
  }), _vm._l(_vm.mailExecDataList, function (item) {
    return _c('v-tab-item', {
      key: item.pid
    }, [_c('v-card', {
      attrs: {
        "flat": ""
      }
    }, [_c('v-card-text', [_c('div', {
      staticStyle: {
        "padding-top": "30px",
        "display": "grid",
        "grid-gap": "20px",
        "grid-template-columns": "5fr 2fr"
      }
    }, [_c('div', [_c('div', {
      staticClass: "font-weight-light text--primary",
      staticStyle: {
        "padding-bottom": "10px"
      }
    }, [_vm._v("Aktuelle Mailvorlage")]), _c('div', [_c('v-card', {
      staticClass: "mx-auto",
      attrs: {
        "flat": "",
        "light": "",
        "outlined": ""
      }
    }, [_c('v-card-text', {
      staticStyle: {
        "display": "grid",
        "grid-gap": "20px",
        "grid-template-columns": "1fr 1fr"
      }
    }, [_c('div', [_c('span', {
      staticClass: "font-weight-black text--primary"
    }, [_vm._v(" " + _vm._s(item.mailContent.viewMailTemplate.name) + " ")]), item.mailContent.viewMailTemplate.priorityTemplate ? _c('div', [_vm._v("Hauptvorlage")]) : _vm._e()]), _c('div', {
      staticClass: "text--primary",
      staticStyle: {
        "padding-top": "10px"
      }
    }, [_vm._v(" " + _vm._s(item.mailContent.viewMailTemplate.description) + " ")])])], 1)], 1), _c('div', [_c('div', {
      staticStyle: {
        "padding-top": "30px",
        "display": "grid",
        "grid-gap": "20px",
        "grid-template-columns": "1fr 1fr"
      }
    }, [_c('div', [_c('div', [_vm._v("An")]), item.mailSendTo.mailAdress ? _c('div', [_c('div', {
      staticClass: "font-weight-bold",
      staticStyle: {
        "padding-left": "20px"
      }
    }, [_c('div', [_vm._v(_vm._s(item.mailSendTo.mailAdress.name))]), _c('div', [_vm._v(_vm._s(item.mailSendTo.mailAdress.adress))]), item.mailSendTo.mailAdress.info ? _c('div', [_vm._v("Hinweis: " + _vm._s(item.mailSendTo.mailAdress.info))]) : _vm._e()])]) : _vm._e()]), _c('div', [_c('div', [_vm._v("BCC")]), item.mailSendTo.bccMailAdress ? _c('div', [_c('div', {
      staticClass: "font-weight-bold",
      staticStyle: {
        "padding-left": "20px"
      }
    }, [_c('div', [_vm._v(_vm._s(item.mailSendTo.bccMailAdress.name))]), _c('div', [_vm._v(_vm._s(item.mailSendTo.bccMailAdress.adress))]), item.mailSendTo.bccMailAdress.info ? _c('div', [_vm._v("Hinweis: " + _vm._s(item.mailSendTo.bccMailAdress.info))]) : _vm._e()])]) : _vm._e()])])]), _c('div', {
      staticStyle: {
        "padding-top": "20px"
      }
    }, [_c('div', {
      staticStyle: {
        "background-color": "#EDEDED"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": "Betreff",
        "hint": "Betreffzeile der Mail"
      },
      model: {
        value: item.mailContent.mailSubject,
        callback: function ($$v) {
          _vm.$set(item.mailContent, "mailSubject", $$v);
        },
        expression: "item.mailContent.mailSubject"
      }
    }), _c('v-textarea', {
      staticStyle: {
        "width": "95%"
      },
      attrs: {
        "clearable": "",
        "clear-icon": "mdi-close-circle",
        "label": "Mailinhalt",
        "hint": "Inhalt der Mailvorlage"
      },
      model: {
        value: item.mailContent.mailContent,
        callback: function ($$v) {
          _vm.$set(item.mailContent, "mailContent", $$v);
        },
        expression: "item.mailContent.mailContent"
      }
    })], 1), _c('cmdbutton', {
      attrs: {
        "caption": "Zurücksetzen"
      },
      on: {
        "cmdclick": function ($event) {
          return _vm.reset(item);
        }
      }
    })], 1)]), _c('div', [_c('div', {
      staticClass: "font-weight-light text--primary",
      staticStyle: {
        "padding-bottom": "10px"
      }
    }, [_vm._v("Alternative Mailvorlagen")]), _c('div', {
      style: {
        'overflow-y': 'auto',
        'height': _vm.contentheight + 'px'
      }
    }, _vm._l(item.alternativeMailTemplateList, function (mailtemplate) {
      return _c('div', {
        key: mailtemplate.pid
      }, [_c('v-card', {
        staticClass: "mx-auto",
        attrs: {
          "flat": "",
          "light": "",
          "outlined": ""
        }
      }, [_c('v-card-text', [_c('v-icon', {
        staticClass: "mr-2",
        attrs: {
          "color": "blue"
        },
        on: {
          "click": function ($event) {
            return _vm.changeTemplate(item, mailtemplate);
          }
        }
      }, [_vm._v("mdi-play-circle-outline")]), _c('span', {
        staticClass: "font-weight-black text--primary"
      }, [_vm._v(" " + _vm._s(mailtemplate.name) + " ")]), mailtemplate.priorityTemplate ? _c('div', [_vm._v("Hauptvorlage")]) : _vm._e(), _c('div', {
        staticClass: "text--primary",
        staticStyle: {
          "padding-top": "10px"
        }
      }, [_vm._v(" " + _vm._s(mailtemplate.description) + " ")])], 1)], 1)], 1);
    }), 0)])])])], 1)], 1);
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }