<template>
  <midwifeactivitydetail
    @onOk="save"
    :pid="pid"
    :activityType="activityType"
    :activityName="activityName"
    :midwifeName="midwifeName"
    :midwifeId="midwifeId">
    <template v-slot>
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:80px;
        align-items: end;
        grid-template-columns:2fr 3fr 2fr 1fr;">
        <v-text-field dense v-model="dtoLocationActivity.locationName" label="Name"></v-text-field>
        <v-text-field dense v-model="dtoLocationActivity.starttime" label="Zeitpunkt"></v-text-field>
        <v-text-field dense v-model="dtoLocationActivity.phone" label="Telefon"></v-text-field>
      </div>
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:80px;
        align-items: end;
        grid-template-columns:1fr 3fr 3fr 1fr;">
        <v-text-field dense v-model="dtoLocationActivity.postcode" label="Postleitzahl"></v-text-field>
        <v-text-field dense v-model="dtoLocationActivity.city" label="Stadt"></v-text-field>
        <v-text-field dense v-model="dtoLocationActivity.streetnumber" label="Straße / Hausnummer"></v-text-field>
      </div>
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:80px;
        align-items: end;
        grid-template-columns:4fr 3fr 1fr;">
        <singleselection style="margin-bottom: -11px;" :selectedId.sync="dtoLocationActivity.regionId" :prop-selected-item="dtoLocationActivity.regionId"  caption="Stadtteil / Ortschaft" idName="regionId" :propItemList="regionList"></singleselection>
        <v-text-field dense v-model="dtoLocationActivity.district" label="Stadtteil"></v-text-field>
      </div>
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:80px;
        align-items: end;
        grid-template-columns:7fr 1fr;">
        <v-textarea v-model="dtoLocationActivity.info" label="Info" clearable auto-grow rows="1"></v-textarea>
      </div>
    </template>
  </midwifeactivitydetail>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import midwifeactivitydetail from '../common/MidwifeActivityDetail'
import LocationActivityController from './location.activity.controller'
import MidwifeActivityController from '../overview/midwife.activity.controller'
import singleselection from '../../../../common/components/element/SingleSelect'

export default {
  name: 'MidwifeActivityDetail',

  async created () {
    this.$pageInitController.initReload(this, this.$moduleInfoController.midwife, 'Hebammenleistung', this.activityType.name, this.midwifeName)
  },

  watch: {
    propDtoLocationActivity (val) {
      this.dtoLocationActivity = cloneDeep(this.propDtoLocationActivity)
    }
  },

  methods: {
    async reload () {
      await MidwifeActivityController.reloadActivityDetail(this.midwifeId, this.activityType.keylistValueId, this.pid, Date.now())
    },

    save () {
      LocationActivityController.save(this.dtoLocationActivity)
    }
  },

  props: {
    propDtoLocationActivity: { type: Object },
    regionList: { type: Array },
    pid: { type: Number },
    activityName: { type: String },
    activityType: { type: Object },
    midwifeName: { type: String },
    midwifeId: { type: String }
  },

  data () {
    return {
      dtoLocationActivity: cloneDeep(this.propDtoLocationActivity)
    }
  },

  components: {
    singleselection,
    midwifeactivitydetail
  }
}
</script>
