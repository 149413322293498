<template>
  <div>
    <div v-bind:style="{ 'overflow-y': 'auto', 'max-height': contentheight + 'px' }">
      <div v-for="item in causeActivityList" :key="item.activityId">
        <v-card style="padding-bottom: 30px;">
          <v-list-item-title class="text-h5 mb-1">
            <v-icon>{{ getResultIcon(item) }}</v-icon>
            {{ item.name }}
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="mr-2" @click="onOpen(item)" color="blue">mdi-arrow-left-bold-outline</v-icon>
              </template>
              <span>{{ getExecutionCaption(item) }}</span>
            </v-tooltip>
            <v-tooltip v-if="true" right>
              <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="mr-2" @click="onEdit(item)" color="blue">mdi-pencil</v-icon>
              </template>
              <span>Bearbeiten</span>
            </v-tooltip>
          </v-list-item-title>
          <div style="padding-left: 30px;">
            <div v-if="item.acute">
              <v-icon color="red">mdi-exclamation</v-icon>
              Akut
              <v-icon color="red">mdi-exclamation</v-icon>
            </div>
            <div style="padding-left: 30px;padding-top: 10px;">
              <div>Status: {{ item.state.caption }}</div>
              <div>Todo: {{ item.todo.caption }}</div>
              <div v-if="item.midwife">Hebamme: {{ item.midwife.name }}</div>
              <div>Kontakt: {{ item.startState }}</div>
              <div v-if="item.start || item.latest">
                {{ showStart(item.start, item.latest) }}
              </div>
              <div v-else-if="item.useDeadline">
                Termin verwenden
                <span v-if="edbdInfo != null">: {{ getEdbdInfo() }}</span>
              </div>
              <div v-if="item.onWaitingList">
                Auf Warteliste
              </div>
              <div v-if="item.mailInfoList.length > 0">
                Per Rundmail nachgefragt am
                <div v-for="mail in item.mailInfoList" :key="mail.mailPid">
                  - {{ formatDate(mail.sendDate) }}: {{ mail.mailTemplateName }}
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <formbuttonbar
      @onButtonOne="onSearch" button-one-text="Suche"
      @onButtonTwo="onNewActivity" button-two-text="Neue Leistung"
      @onButtonThree="onWriteMailNoMidwifeFound" button-three-text="'Keine Hebamme'"
      @onButtonFour="onWriteMail" button-four-text="Mail">
    </formbuttonbar>
  </div>
</template>

<script>

import formbuttonbar from '@/common/components/element/FormButtonBar'
import CaseOverviewController from '@/module/concern/case/case.overview.controller'
import ConcernCauseActivityController from './concern.causeactivity.controller'
import { stateIconMap, caseLocator } from '../case/case.util'
import { startDate } from '../causeactivity/causeactivity.utils'
import { formatDate } from '../../../common/utils/date.utils'

export default {
  name: 'ConcernCauseActivityOverview',

  async created () {
    this.$pageInitController.initBack(this, this.$moduleInfoController.concern, 'Frau', 'Leistungen', this.womanName)
  },

  computed: {
    contentheight: function () {
      return this.$store.getters['size/formContent']
    }
  },

  components: {
    formbuttonbar
  },

  methods: {
    async back () {
      await CaseOverviewController.openCaseOverview(this.caseLocator)
    },

    formatDate (date) {
      return formatDate(date)
    },

    getEdbdInfo () {
      if (this.edbdInfo) {
        return formatDate(this.edbdInfo.date) + ' (' + this.edbdInfo.type + ')'
      } else {
        return null
      }
    },

    showStart (start, latest) {
      return startDate(start, latest)
    },

    getResultIcon (item) {
      const state = stateIconMap.get(item.state.iconId)
      return state
    },

    getExecutionCaption (item) {
      if (item.casePid) {
        return 'Anfrage öffnen'
      } else {
        return 'Suche starten'
      }
    },

    async onOpen (item) {
      if (item.casePid) {
        const newCaseLocator = caseLocator(this.caseLocator.concernId, item.casePid)
        await CaseOverviewController.openCaseFile(newCaseLocator)
      } else {
        const neutralCaseLocator = caseLocator(this.caseLocator.concernId, null)
        await ConcernCauseActivityController.openSearchMidwife(neutralCaseLocator)
      }
    },
    async onEdit (item) {
      await ConcernCauseActivityController.openConcernEditCauseActivity(this.caseLocator, item.activityId)
    },
    async onSearch () {
      const neutralCaseLocator = caseLocator(this.caseLocator.concernId, null)
      await ConcernCauseActivityController.openSearchMidwife(neutralCaseLocator)
    },
    async onNewActivity () {
      await ConcernCauseActivityController.openConcernNewCauseActivity(this.caseLocator)
    },
    async onWriteMailNoMidwifeFound () {
      await ConcernCauseActivityController.writeMailNoMidwifeFound(this.caseLocator)
    },
    async onWriteMail () {
      await ConcernCauseActivityController.writeMail(this.caseLocator)
    }
  },

  props: {
    edbdInfo: { type: Object },
    womanName: { type: String },
    caseLocator: { type: Object },
    causeActivityList: { type: Array }
  }
}
</script>
