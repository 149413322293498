var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "grid",
    style: {
      'background-color': '#f0f0f0',
      'display': 'grid',
      'grid-template-columns': _vm.columnstyle
    }
  }, [_c('span'), _c('span', [_vm._v("Aktuelle Anfrage")]), _vm._l(_vm.columns, function (column) {
    return _c('span', {
      key: column
    }, [_vm._v(" Daten aus " + _vm._s(column) + ". Webformular ")]);
  })], 2), _c('div', {
    style: {
      'overflow-y': 'auto',
      'max-height': _vm.contentheight + 'px'
    }
  }, _vm._l(_vm.itemList, function (item) {
    return _c('div', {
      key: item.caption,
      staticClass: "grid",
      style: {
        'display': 'grid',
        'grid-template-columns': _vm.columnstyle
      }
    }, [_c('span', {
      staticStyle: {
        "background-color": "#fcfcfc"
      }
    }, [_vm._v(_vm._s(item.caption))]), _c('span', {
      staticStyle: {
        "font-weight": "bold"
      }
    }, [_c('doublettype', {
      attrs: {
        "type": item.type,
        "value": {
          data: _vm.refConcern[item.index]
        }
      }
    })], 1), _vm._l(_vm.columns, function (column) {
      return _c('span', {
        key: column
      }, [_c('doublettype', {
        attrs: {
          "type": item.type,
          "value": {
            data: _vm.webformInquiryList[column - 1][item.index]
          }
        }
      })], 1);
    })], 2);
  }), 0), _c('div', {
    staticClass: "grid",
    style: {
      'background-color': '#f0f0f0',
      'display': 'grid',
      'grid-template-columns': _vm.columnstyle
    }
  }, [_c('span'), _c('span'), _vm._l(_vm.columns, function (column) {
    return _c('span', {
      key: column
    }, [_c('div', {
      staticClass: "grid",
      style: {
        'display': 'grid',
        'grid-template-columns': '100px 50px'
      }
    }, [_c('v-checkbox', {
      attrs: {
        "label": "Geprüft"
      },
      on: {
        "change": function ($event) {
          return _vm.onProofed($event, _vm.webformInquiryList[column - 1]);
        }
      }
    }), _c('cmdbutton', {
      attrs: {
        "caption": "Lösen"
      },
      on: {
        "cmdclick": function ($event) {
          return _vm.onRelease(_vm.webformInquiryList[column - 1]);
        }
      }
    })], 1)]);
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }