var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('formcontent', {
    attrs: {
      "ok-text": "Speichern",
      "cancel-text": "Abbrechen"
    },
    on: {
      "onOk": _vm.save,
      "onCancel": _vm.cancel
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-columns": "7fr 3fr"
    }
  }, [_c('div', [_c('v-text-field', {
    attrs: {
      "label": "Kurzbeschreibung",
      "hint": "Kurze Zusammenfassung des Inhalts der Mailvorlage"
    },
    model: {
      value: _vm.dtoMailTemplate.name,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMailTemplate, "name", $$v);
      },
      expression: "dtoMailTemplate.name"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Beschreibung",
      "hint": "Hinweise zur Mailvorlage"
    },
    model: {
      value: _vm.dtoMailTemplate.description,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMailTemplate, "description", $$v);
      },
      expression: "dtoMailTemplate.description"
    }
  }), _c('div', {
    staticStyle: {
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-columns": "7fr 3fr"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.mailTypeList,
      "item-text": "name",
      "item-value": "keylistValueId",
      "label": "Mailtype"
    },
    model: {
      value: _vm.dtoMailTemplate.mailtemplateKey,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMailTemplate, "mailtemplateKey", $$v);
      },
      expression: "dtoMailTemplate.mailtemplateKey"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Voreingestellter Standard"
    },
    model: {
      value: _vm.dtoMailTemplate.priorityTemplate,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMailTemplate, "priorityTemplate", $$v);
      },
      expression: "dtoMailTemplate.priorityTemplate"
    }
  })], 1), _c('v-divider'), _c('div', {
    staticStyle: {
      "padding-top": "10px",
      "background-color": "#EDEDED"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Betreff",
      "hint": "Betreffzeile der Mail"
    },
    model: {
      value: _vm.dtoMailTemplate.subject,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMailTemplate, "subject", $$v);
      },
      expression: "dtoMailTemplate.subject"
    }
  }), _c('v-textarea', {
    staticStyle: {
      "width": "95%"
    },
    attrs: {
      "clearable": "",
      "clear-icon": "mdi-close-circle",
      "label": "Mailinhalt",
      "hint": "Inhalt der Mailvorlage"
    },
    model: {
      value: _vm.dtoMailTemplate.content,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMailTemplate, "content", $$v);
      },
      expression: "dtoMailTemplate.content"
    }
  })], 1), _c('div', {
    staticStyle: {
      "padding-top": "150px"
    }
  }, [_c('label', {
    staticClass: "grey--text font-weight-medium"
  }, [_vm._v("Vorschau")]), _c('v-divider'), _c('v-select', {
    staticStyle: {
      "max-width": "50%"
    },
    attrs: {
      "items": _vm.caseMidwifeList,
      "item-value": "caseLocator",
      "label": "Anfragen / Hebamme"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.getCaseMidwifeName(item)) + " ")];
      }
    }, {
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.getCaseMidwifeName(item)) + " ")];
      }
    }]),
    model: {
      value: _vm.caseLocator,
      callback: function ($$v) {
        _vm.caseLocator = $$v;
      },
      expression: "caseLocator"
    }
  }), _c('v-select', {
    staticStyle: {
      "max-width": "50%"
    },
    attrs: {
      "clearable": "",
      "items": _vm.midwifeList,
      "item-text": "name",
      "item-value": "midwifeId",
      "label": "Alternative Hebamme"
    },
    model: {
      value: _vm.midwifeId,
      callback: function ($$v) {
        _vm.midwifeId = $$v;
      },
      expression: "midwifeId"
    }
  }), _c('cmdbutton', {
    attrs: {
      "caption": "Vorschau anzeigen"
    },
    on: {
      "cmdclick": _vm.showPreview
    }
  })], 1)], 1), _c('div', {
    style: {
      'overflow-y': 'hidden',
      'height': _vm.contentheight + 'px'
    }
  }, [_c('div', {
    staticStyle: {
      "height": "40%"
    }
  }, [_c('mailvariableoverview', {
    attrs: {
      "condition-list": _vm.conditionList,
      "placeholder-list": _vm.placeholderList,
      "text-module-list": _vm.textModuleList
    }
  })], 1)])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }