import networklayout from './network/NetworkLayout'
import loginlayout from './LoginLayout'
import helplayout from './HelpLayout'
import monitoringlayout from './MonitoringLayout'
import ennalayout from './EnnaLayout'

export default {
  setEnnaLayout (thisObject) {
    thisObject.$emit('update:layout', ennalayout)
  },
  setHelpLayout (thisObject) {
    thisObject.$emit('update:layout', helplayout)
  },
  setLoginLayout (thisObject) {
    thisObject.$emit('update:layout', loginlayout)
  },
  setNetworkLayout (thisObject) {
    thisObject.$emit('update:layout', networklayout)
  },
  setMonitoringLayout (thisObject) {
    thisObject.$emit('update:layout', monitoringlayout)
  }
}
