var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('overviewcontent', {
    attrs: {
      "cancelText": "Anfragen abrufen",
      "objectIdName": "pid",
      "data-list": _vm.webformInquiryList,
      "withSearchString": false,
      "withNew": true,
      "newCaption": "Übernehmen / Löschen"
    },
    on: {
      "onCancel": _vm.loadFromMailserver,
      "onOk": function ($event) {
        return _vm.execute();
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (webformInquiryItem) {
        return [_c('v-card', {
          staticClass: "mx-auto",
          attrs: {
            "outlined": ""
          }
        }, [_c('div', [_c('div', {
          staticStyle: {
            "display": "grid",
            "grid-template-columns": "3fr 3fr 3fr 3fr 1fr"
          }
        }, [_c('div', {
          staticStyle: {
            "padding-top": "20px",
            "font-weight": "bold"
          }
        }, [_vm._v(" " + _vm._s(webformInquiryItem.item.compactInquiry.secondname) + ", " + _vm._s(webformInquiryItem.item.compactInquiry.firstname) + " ")]), _c('div', {
          staticStyle: {
            "padding-top": "20px"
          }
        }, [_c('span', {
          staticStyle: {
            "font-weight": "bold"
          }
        }, [_vm._v(_vm._s(_vm.getEtBtCaption(webformInquiryItem.item.compactInquiry.edbd, webformInquiryItem.item.compactInquiry.edbdMode)))]), _c('dateitem', {
          staticStyle: {
            "display": "inline"
          },
          attrs: {
            "date-item": webformInquiryItem.item.compactInquiry.edbd
          }
        })], 1), _c('div', {
          staticStyle: {
            "padding-top": "20px"
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(_vm._s('mdi-email'))]), _vm._v(" "), _c('dateitem', {
          staticStyle: {
            "display": "inline"
          },
          attrs: {
            "date-item": webformInquiryItem.item.compactInquiry.callDate
          }
        })], 1), _c('div', [webformInquiryItem.item.possibleDoubletList.length === 0 ? _c('v-combobox', {
          staticStyle: {
            "width": "160px"
          },
          attrs: {
            "label": "Auswählen",
            "items": _vm.optionList,
            "item-text": "caption",
            "item-value": "id",
            "deletable-chips": "",
            "clearable": ""
          },
          on: {
            "change": function ($event) {
              return _vm.handleWebformInquiry(webformInquiryItem.item, $event);
            }
          }
        }) : _c('div', {
          staticStyle: {
            "padding-top": "10px"
          }
        }, [_c('v-btn', {
          on: {
            "click": function ($event) {
              return _vm.handleDublette(webformInquiryItem.item);
            }
          }
        }, [_vm._v("Dublette?")])], 1)], 1), _c('div', [_c('v-card-actions', [_c('v-btn', {
          attrs: {
            "color": "orange lighten-2",
            "text": ""
          }
        }, [_vm._v("Details ")]), _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.switchDetails(webformInquiryItem.item.pid);
            }
          }
        }, [_c('v-icon', [_vm._v(_vm._s(_vm.showDetails(webformInquiryItem.item.pid) ? 'mdi-chevron-up' : 'mdi-chevron-down'))])], 1)], 1)], 1)])]), _c('v-expand-transition', [_c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showDetails(webformInquiryItem.item.pid),
            expression: "showDetails(webformInquiryItem.item.pid)"
          }],
          staticStyle: {
            "padding-left": "60px"
          }
        }, [_c('webforminquirydetail', {
          attrs: {
            "inquiry": webformInquiryItem.item.compactInquiry
          }
        })], 1)])], 1)];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }