import ApiService from '#enna/common/service/api/api.service'

const MidwifeMailService = {
  async queryMails (midwifeId) {
    const result = await ApiService.doGet('/enna/sendedmail/query/midwife/' + midwifeId)
    return result
  }
}

export default MidwifeMailService
