import ApiService from '../../../common/service/api/api.service'

const ConcernCircularMailService = {
  async queryCaseParam () {
    const result = await ApiService.doGet('/enna/circularmail/param/case')
    return result
  },

  async changeCaseTemplate (selectedTemplateId, selectedCaseList) {
    const caseTemplateParam = createCaseTemplateParam(selectedTemplateId, selectedCaseList)
    const result = await ApiService.doPost('/enna/circularmail/case/changetemplate', caseTemplateParam)
    return result
  },

  async changeSelectedCases (selectedTemplateId, selectedCaseList) {
    const caseTemplateParam = createCaseTemplateParam(selectedTemplateId, selectedCaseList)
    const result = await ApiService.doPost('/enna/circularmail/case/replacetemplate', caseTemplateParam)
    return result
  },

  async sendCaseMail (selectedTemplateId, selectedCaseList, subject, content, hash) {
    const caseTemplateDataList = createCaseTemplateDataList(selectedCaseList)
    const sendMailParam = {
      mailTemplateId: selectedTemplateId,
      subject: subject,
      content: content,
      hash: hash,
      caseTemplateDataList: caseTemplateDataList
    }
    await ApiService.doPost('/enna/circularmail/case/sendmail', sendMailParam)
  },

  async searchCase (caseOption) {
    const result = await ApiService.doPost('/enna/circularmail/search/case', caseOption)
    return result
  }
}

function createCaseTemplateParam (selectedTemplateId, selectedCaseList) {
  const caseTemplateDataList = createCaseTemplateDataList(selectedCaseList)
  const caseTemplateParam = {
    mailTemplateId: selectedTemplateId,
    caseTemplateDataList: caseTemplateDataList
  }
  return caseTemplateParam
}

function createCaseTemplateDataList (selectedCaseList) {
  const caseTemplateDataList = []
  selectedCaseList.forEach(item => {
    const activityIdList = []
    item.circularMailCaseCandidateList.forEach(activity => {
      activityIdList.push(activity.activityId)
    })
    const caseVar = {
      concernId: item.woman.concernId,
      activityIdList: activityIdList
    }
    caseTemplateDataList.push(caseVar)
  })
  return caseTemplateDataList
}

export default ConcernCircularMailService
