<template>
  <formcontent
    ok-text="Anlegen" @onOk="save">
    <div style="
      display: grid;
      grid-gap: 20px;
      grid-template-rows:100px;
      align-items: end;
      grid-template-columns:2fr 2fr 1fr;">
        <v-text-field v-model="prename" label="Vorname"></v-text-field>
        <v-text-field v-model="surname" label="Nachname"></v-text-field>
    </div>
  </formcontent>
</template>

<script>
import formcontent from '#enna/common/components/content/FormContent'
import MidwifeOverviewController from './midwife.overview.controller'

export default {
  name: 'NewMidwife',

  async created () {
    this.$pageInitController.init(this, this.$moduleInfoController.midwife, 'Hebammen', 'Neue Hebamme')
  },

  components: {
    formcontent
  },

  methods: {
    async save () {
      await MidwifeOverviewController.saveNewMidwife(this.prename, this.surname)
    }
  },

  data () {
    return {
      prename: '',
      surname: ''
    }
  }
}
</script>
