import ApiService from '../../../common/service/api/api.service'

const MidwifeSignOffService = {
  async deleteSignOff (pid) {
    var result = await ApiService.doDelete('/enna/midwife/signoff/' + pid)
    return result
  },

  async newSignOff (midwifeId) {
    var result = await ApiService.doGet('/enna/midwife/signoff/new/view/dto/' + midwifeId)
    return result
  },

  async loadSignOff (pid) {
    var result = await ApiService.doGet('/enna/midwife/signoff/view/dto/' + pid)
    return result
  },

  async saveSignOff (dtoMidwifeSignOff) {
    var result = await ApiService.doPost('/enna/midwife/signoff', dtoMidwifeSignOff)
    return result
  },

  async queryViewSignOff (midwifeId) {
    var result = await ApiService.doGet('/enna/midwife/signoff/query/view/' + midwifeId)
    return result
  },

  async querySignOff (midwifeId) {
    var result = await ApiService.doGet('/enna/midwife/signoff/query/' + midwifeId)
    return result
  }
}

export default MidwifeSignOffService
