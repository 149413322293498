import Vue from 'vue'
import VueRouter from 'vue-router'

import Homepage from '../../module/homepage/Homepage.vue'
import Login from '../../module/app/auth/Login'
import Changelog from '../../module/network/changelog/ChangelogPage'
import Statistic from '../../module/network/statistic/StatisticOverviewPage'
import CircularMail from '../../module/concern/circularmail/CircularMail'
import CurrentHandover from '../../module/network/handover/CurrentHandover'
import HandoverOverview from '../../module/network/handover/HandoverOverview'
import MidwifeOverview from '../../module/midwife/overview/MidwifeOverview.vue'
import MidwifeMainFile from '../../module/midwife/mainfile/MidwifeMainFile'
import MidwifeActivityOverview from '../../module/midwife/activity/overview/MidwifeActivityOverview'
import MidwifeContactOverview from '../../module/midwife/contact/MidwifeContactOverview'
// import MidwifeHistoryOverview from '../../module/midwife/history/MidwifeHistoryOverview'
import MidwifeInfoOverview from '../../module/midwife/info/MidwifeInfoOverview'
import MidwifeMailOverview from '../../module/midwife/mail/MidwifeMailPage'
import MidwifeInquiryOverview from '../../module/midwife/inquiry/MidwifeInquiryOverview'
import MidwifeMasterdataOverview from '../../module/midwife/masterdata/MidwifeMasterdataOverview'
import MidwifePropertyOverview from '../../module/midwife/property/MidwifePropertyOverview'
import MidwifeSignOffOverview from '../../module/midwife/signoff/MidwifeSignoffOverview'
import MidwifeSubstitutionOverview from '../../module/midwife/substitution/MidwifeSubstitutionOverview'
import MidwifeMasterdataDetail from '../../module/midwife/masterdata/MidwifeMasterdataDetail'
import MidwifeInfoDetail from '../../module/midwife/info/MidwifeInfoDetail'
import MidwifeContactDetail from '../../module/midwife/contact/MidwifeContactDetail'
import MidwifeSignOffDetail from '../../module/midwife/signoff/MidwifeSignOffDetail'
import MidwifePropertyDetail from '../../module/midwife/property/MidwifePropertyDetail'
import MidwifeNewActivity from '../../module/midwife/activity/common/MidwifeNewActivity'
import KnowledgeActivityDetail from '../../module/midwife/activity/knowledge/KnowledgeActivityDetail'
import LocationActivityDetail from '../../module/midwife/activity/location/LocationActivityDetail'
import MobileActivityDetail from '../../module/midwife/activity/mobile/MobileActivityDetail'
import MobileActivityModify from '../../module/midwife/activity/mobile/MobileActivityModify'
import MobileActivityCompare from '../../module/midwife/activity/mobile/MobileActivityCompare'
import NewMidwife from '../../module/midwife/overview/NewMidwife'
import CancelMidwife from '../../module/midwife/overview/CancelMidwife'
import DynPropStringDetail from '../components/dynprop/DynPropStringDetail'
import DynPropIntDetail from '../components/dynprop/DynPropIntDetail'
import DynPropBooleanDetail from '../components/dynprop/DynPropBooleanDetail'
import DynPropUnknownBooleanDetail from '../components/dynprop/DynPropUnknownBooleanDetail'
import DynPropKeylistDetail from '../components/dynprop/DynPropKeylistDetail'
import DynPropMultipleKeylistDetail from '../components/dynprop/DynPropMultipleKeylistDetail'
import DynPropDateDetail from '../components/dynprop/DynPropDateDetail'

import CaseFile from '../../module/concern/case/CaseFile'
import CaseOverview from '../../module/concern/case/CaseOverview'
import SendMail from '../../module/superior/sendmail/SendMail'
import FinishedCaseOverview from '../../module/concern/case/FinishedCaseOverview'
import WebformOverview from '../../module/concern/webform/WebformPage'
import DoubletProcessing from '../../module/concern/webform/DoubletProcessingPage'
import ConcernMainfile from '../../module/concern/mainfile/ConcernMainFile'
import ConcernWomanOverview from '../../module/concern/woman/ConcernWomanOverview'
import ConcernDoubletOverview from '../../module/concern/doublet/ConcernDoubletOverview'
import ConcernWomanDetail from '../../module/concern/woman/ConcernWomanDetail'
import ConcernMailOverview from '../../module/concern/mail/ConcernMailPage'
import ConcernCauseActivityOverview from '../../module/concern/causeactivity/ConcernCauseActivityOverview'
import ConcernEditCauseActivity from '../../module/concern/causeactivity/EditCauseActivity'
import ConcernAdviceOverview from '../../module/concern/advice/ConcernAdviceOverview'
import ConcernEditAdvice from '../../module/concern/advice/EditAdvice'
import ConcernSummary from '../../module/concern/summary/ConcernSummary'
import SearchMidwife from '../../module/concern/search/SearchMidwife'
import SearchResult from '../../module/concern/search/SearchResult'
import Registration from '../../module/concern/search/Registration'
import AdminConfigOverview from '../../module/admin/config/AdminConfigOverview'
import ConcernAdminOverview from '../../module/concern/concernadmin/ConcernAdminOverview.vue'

import Help from '../../module/help/HelpPage'

import MailTemplateOverview from '../../module/superior/mailtemplate/MailTemplateOverview'
import MailTemplateDetail from '../../module/superior/mailtemplate/MailTemplateDetail'
import TextModuleOverview from '../../module/superior/textmodule/TextModuleOverview'
import TextModuleDetail from '../../module/superior/textmodule/TextModuleDetail'
import ChangePassword from '../../module/user/ChangePassword'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Homepage', component: Homepage },
  { path: '/help', name: 'help', component: Help },
  { path: '/login', name: 'login', component: Login },
  { path: '/changelog', name: 'changelog', component: Changelog, props: true },
  { path: '/statistic', name: 'statistic', component: Statistic, props: true },
  { path: '/currenthandover', name: 'currenthandover', component: CurrentHandover, props: true },
  { path: '/handoveroverview', name: 'handoveroverview', component: HandoverOverview, props: true },
  { path: '/midwifeoverview', name: 'midwifeoverview', component: MidwifeOverview, props: true },
  { path: '/mailtemplateoverview', name: 'mailtemplateoverview', component: MailTemplateOverview, props: true },
  { path: '/mailtemplatedetail', name: 'mailtemplatedetail', component: MailTemplateDetail, props: true },
  { path: '/adminconfigoverview', name: 'adminconfigoverview', component: AdminConfigOverview, props: true },
  { path: '/textmoduleoverview', name: 'textmoduleoverview', component: TextModuleOverview, props: true },
  { path: '/textmoduledetail', name: 'textmoduledetail', component: TextModuleDetail, props: true },
  { path: '/changepassword', name: 'changepassword', component: ChangePassword, props: true },

  {
    path: '/midwifemainfile/:midwifeId',
    name: 'midwifemainfile',
    component: MidwifeMainFile,
    props: true,
    children: [
      { path: '/midwifeactivityoverview', name: 'midwifeactivityoverview', component: MidwifeActivityOverview, props: true },
      { path: '/midwifecontactoverview', name: 'midwifecontactoverview', component: MidwifeContactOverview, props: true },
      // { path: '/midwifehistoryoverview', name: 'midwifehistoryoverview', component: MidwifeHistoryOverview, props: true },
      { path: '/midwifeinfooverview', name: 'midwifeinfooverview', component: MidwifeInfoOverview, props: true },
      { path: '/midwifemailoverview', name: 'midwifemailoverview', component: MidwifeMailOverview, props: true },
      { path: '/midwifeinquiryoverview', name: 'midwifeinquiryoverview', component: MidwifeInquiryOverview, props: true },
      { path: '/midwifemasterdataoverview', name: 'midwifemasterdataoverview', component: MidwifeMasterdataOverview, props: true },
      { path: '/midwifepropertyoverview', name: 'midwifepropertyoverview', component: MidwifePropertyOverview, props: true },
      { path: '/midwifesignoffoverview', name: 'midwifesignoffoverview', component: MidwifeSignOffOverview, props: true },
      { path: '/midwifesubstitutionoverview', name: 'midwifesubstitutionoverview', component: MidwifeSubstitutionOverview, props: true }
    ]
  },
  { path: '/midwifemasterdatadetail', name: 'midwifemasterdatadetail', component: MidwifeMasterdataDetail, props: true },
  { path: '/midwifeinfodetail', name: 'midwifeinfodetail', component: MidwifeInfoDetail, props: true },
  { path: '/midwifecontactdetail', name: 'midwifecontactdetail', component: MidwifeContactDetail, props: true },
  { path: '/midwifesignoffdetail', name: 'midwifesignoffdetail', component: MidwifeSignOffDetail, props: true },
  { path: '/midwifepropertydetail', name: 'midwifepropertydetail', component: MidwifePropertyDetail, props: true },
  { path: '/midwifenewactivity', name: 'midwifenewactivity', component: MidwifeNewActivity, props: true },
  { path: '/knowledgeactivitydetail', name: 'knowledgeactivitydetail', component: KnowledgeActivityDetail, props: true },
  { path: '/locationactivitydetail', name: 'locationactivitydetail', component: LocationActivityDetail, props: true },
  { path: '/mobileactivitydetail', name: 'mobileactivitydetail', component: MobileActivityDetail, props: true },
  { path: '/mobileactivitymodify', name: 'mobileactivitymodify', component: MobileActivityModify, props: true },
  { path: '/mobileactivitycompare', name: 'mobileactivitycompare', component: MobileActivityCompare, props: true },
  { path: '/newmidwife', name: 'newmidwife', component: NewMidwife, props: true },
  { path: '/cancelmidwife', name: 'cancelmidwife', component: CancelMidwife, props: true },
  { path: '/dynpropunknownbooleandetail', name: 'dynpropunknownbooleandetail', component: DynPropUnknownBooleanDetail, props: true },
  { path: '/dynpropbooleandetail', name: 'dynpropbooleandetail', component: DynPropBooleanDetail, props: true },
  { path: '/dynpropstringdetail', name: 'dynpropstringdetail', component: DynPropStringDetail, props: true },
  { path: '/dynpropintdetail', name: 'dynpropintdetail', component: DynPropIntDetail, props: true },
  { path: '/dynpropkeylistdetail', name: 'dynpropkeylistdetail', component: DynPropKeylistDetail, props: true },
  { path: '/dynpropmultiplekeylistdetail', name: 'dynpropmultiplekeylistdetail', component: DynPropMultipleKeylistDetail, props: true },
  { path: '/dynpropdatedetail', name: 'dynpropdatedetail', component: DynPropDateDetail, props: true },

  { path: '/doubletprocessing', name: 'doubletprocessing', component: DoubletProcessing, props: true },
  { path: '/webformoverview', name: 'webformoverview', component: WebformOverview, props: true },
  { path: '/searchmidwife', name: 'searchmidwife', component: SearchMidwife, props: true },
  { path: '/searchresult', name: 'searchresult', component: SearchResult, props: true },
  { path: '/registration', name: 'registration', component: Registration, props: true },
  { path: '/sendmail', name: 'sendmail', component: SendMail, props: true },
  { path: '/caseoverview', name: 'caseoverview', component: CaseOverview, props: true },
  { path: '/finishedcaseoverview', name: 'finishedcaseoverview', component: FinishedCaseOverview, props: true },
  { path: '/concernadminoverview', name: 'concernadminoverview', component: ConcernAdminOverview, props: true },
  { path: '/circularmail', name: 'circularmail', component: CircularMail, props: true },

  {
    path: '/concernmainfile/:concernId',
    name: 'concernmainfile',
    component: ConcernMainfile,
    props: true,
    children: [
      { path: '/concerndoubletoverview', name: 'concerndoubletoverview', component: ConcernDoubletOverview, props: true },
      { path: '/concernwomanoverview', name: 'concernwomanoverview', component: ConcernWomanOverview, props: true },
      { path: '/concerncasefile', name: 'concerncasefile', component: CaseFile, props: true },
      { path: '/concernmailoverview', name: 'concernmailoverview', component: ConcernMailOverview, props: true },
      { path: '/concerncauseactivityoverview', name: 'concerncauseactivityoverview', component: ConcernCauseActivityOverview, props: true },
      { path: '/concernadviceoverview', name: 'concernadviceoverview', component: ConcernAdviceOverview, props: true },
      { path: '/concernsummary', name: 'concernsummary', component: ConcernSummary, props: true }
    ]
  },
  { path: '/concernwomandetail', name: 'concernwomandetail', component: ConcernWomanDetail, props: true },
  { path: '/concerneditadvice', name: 'concerneditadvice', component: ConcernEditAdvice, props: true },
  { path: '/concerneditcauseactivity', name: 'concerneditcauseactivity', component: ConcernEditCauseActivity, props: true }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, from) {
    if (to.hash) {
      const element = document.getElementById(to.hash.substring(1))
      if (element) {
        element.scrollIntoView({ block: 'center' })
      }
    }
  }
})

export default router
