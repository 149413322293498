var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('div', [_c('v-card', [_c('v-card-title', [_c('v-text-field', {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Search",
      "single-line": "",
      "clearable": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filterParam.search,
      callback: function ($$v) {
        _vm.$set(_vm.filterParam, "search", $$v);
      },
      expression: "filterParam.search"
    }
  }), _c('v-spacer')], 1), _c('v-data-table', {
    attrs: {
      "hide-default-footer": true,
      "headers": _vm.headers,
      "height": _vm.contentheight,
      "fixed-header": "",
      "sort-by": _vm.filterParam.sortAttribute,
      "sort-desc": _vm.filterParam.sortDirection,
      "items-per-page": 5000,
      "items": _vm.dataList,
      "search": _vm.filterParam.search
    },
    on: {
      "update:sortBy": function ($event) {
        return _vm.$set(_vm.filterParam, "sortAttribute", $event);
      },
      "update:sort-by": function ($event) {
        return _vm.$set(_vm.filterParam, "sortAttribute", $event);
      },
      "update:sortDesc": function ($event) {
        return _vm.$set(_vm.filterParam, "sortDirection", $event);
      },
      "update:sort-desc": function ($event) {
        return _vm.$set(_vm.filterParam, "sortDirection", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var items = _ref.items;
        return [_c('tbody', _vm._l(items, function (item) {
          return _c('tr', {
            key: item[_vm.tablecontentKeyId],
            attrs: {
              "id": item[_vm.anchorKey]
            },
            on: {
              "click": function ($event) {
                return _vm.open(item);
              }
            }
          }, _vm._l(_vm.headers, function (header) {
            return _c('td', {
              key: header.name,
              style: _vm.columnStyle(header)
            }, [header.type === 'date' ? _c('div', {
              staticStyle: {
                "white-space": "pre"
              }
            }, [_vm._v(_vm._s(_vm.getDateValue(item, header.value)))]) : header.type === 'boolean' ? _c('div', {
              staticStyle: {
                "white-space": "pre"
              }
            }, [_vm._v(_vm._s(_vm.getBooleanValue(item, header.value)))]) : header.type === 'icon' ? _c('div', {
              staticStyle: {
                "white-space": "pre"
              },
              attrs: {
                "set": _vm.icon = _vm.getIcon(item, header.value, header.iconMapper)
              }
            }, [_c('v-icon', {
              attrs: {
                "small": "",
                "light": ""
              }
            }, [_vm._v(_vm._s(_vm.icon.icon))]), _vm._v(" " + _vm._s(_vm.icon.caption) + " ")], 1) : header.type === 'booleanicon' ? _c('div', {
              staticStyle: {
                "white-space": "pre"
              }
            }, [_c('checkitem', {
              attrs: {
                "flag-item": _vm.getBooleanValueForIcon(item, header.value)
              }
            })], 1) : _c('div', {
              staticStyle: {
                "white-space": "pre-wrap"
              }
            }, [_vm._v(_vm._s(_vm.getTextValue(item, header.value)))])]);
          }), 0);
        }), 0)];
      }
    }])
  })], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }