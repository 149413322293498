<template>
  <div>
    <overviewcontent :data-list="changelogList" :withSearchString=true>
      <template v-slot="changelogItem">
        <v-subheader style="display: grid;grid-template-columns: 3fr 3fr 10fr;">
          <div>{{ changelogItem.item.semver }}</div>
          <dateitem :id="changelogItem.item.publishdate" :date-item="changelogItem.item.publishdate"></dateitem>
        </v-subheader>
        <v-divider ></v-divider>
        <div v-for="changelog in changelogItem.item.changelogList" :key="changelog.changelogId">
          <div style="padding-left: 20px; padding-top: 10px; padding-bottom: 30px; display: grid;grid-template-columns: 3fr 3fr 4fr;">
            <div>ÄnderungsId</div>
            <div>{{ changelog.changelogId }}</div>
            <div></div>
            <div>Thema</div>
            <div style="color: darkblue; font-weight: bold;font-size: larger;">{{ changelog.theme }}</div>
            <div></div>
            <div>Ticketnummer</div>
            <div>{{ changelog.ticketId }}</div>
            <div></div>
            <div>Kategorie</div>
            <div>{{ changelog.category }}</div>
            <div></div>
            <div>Datenbankänderung</div>
            <div>{{ changelog.dbchange }}</div>
            <div></div>
            <div>Beschreibung</div>
            <div>{{ changelog.description }}</div>
            <div></div>
          </div>
        </div>
      </template>
    </overviewcontent>
  </div>
</template>

<script>
import overviewcontent from '#enna/common/components/content/OverviewContent'
import dateitem from '#enna/common/components/datatype/DateItem'

export default {
  name: 'ChangelogPage',

  async created () {
    this.$pageInitController.init(this, this.$moduleInfoController.admin, 'Programmänderungen')
  },

  components: {
    overviewcontent,
    dateitem
  },

  props: {
    changelogList: { type: Array }
  }
}
</script>
