var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('formcontent', {
    attrs: {
      "ok-text": "Speichern"
    },
    on: {
      "onOk": _vm.save
    }
  }, [_c('v-text-field', {
    staticStyle: {
      "width": "340px"
    },
    attrs: {
      "append-icon": _vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',
      "type": _vm.showPassword ? 'text' : 'password',
      "label": "Paßwort"
    },
    on: {
      "click:append": function ($event) {
        _vm.showPassword = !_vm.showPassword;
      }
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('v-text-field', {
    staticStyle: {
      "width": "340px"
    },
    attrs: {
      "append-icon": _vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',
      "type": _vm.showPassword ? 'text' : 'password',
      "label": "Wiederholung Paßwort"
    },
    on: {
      "click:append": function ($event) {
        _vm.showPassword = !_vm.showPassword;
      }
    },
    model: {
      value: _vm.repeatedPassword,
      callback: function ($$v) {
        _vm.repeatedPassword = $$v;
      },
      expression: "repeatedPassword"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }