const actions = {
  async setVersion ({ commit }, { versionId, data }) {
    commit('setVersion', { versionId, data })
  },

  async resetAllVersion ({ commit }) {
    commit('resetAllVersion')
  },

  async resetVersion ({ commit }, versionId) {
    commit('resetVersion', versionId)
  }
}

const state = {
  versioningMap: new Map()
}

const getters = {
  getVersion (state) {
    return versionId => state.versioningMap.get(versionId)
  }
}

const mutations = {
  setVersion (state, { versionId, data }) {
    if (data) {
      state.versioningMap.set(versionId, data)
    } else {
      state.versioningMap.delete(versionId)
    }
  },

  resetAllVersion (state) {
    state.versioningMap.clear()
  },

  resetVersion (state, { versionId }) {
    state.versioningMap.delete(versionId)
  }
}

export const versioning = {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}
