import ApiService from '../../../../common/service/api/api.service'

const MidwifeActivityService = {
  async queryViewActivity (midwifeId) {
    var result = await ApiService.doGet('/enna/midwife/activity/overview/query/view/' + midwifeId)
    return result
  },

  async queryActivity (midwifeId) {
    var result = await ApiService.doGet('/enna/midwife/activity/overview/query/' + midwifeId)
    return result
  },

  async loadViewActivityCreationOption (midwifeId) {
    var result = await ApiService.doGet('/enna/midwife/activity/overview/new/view/' + midwifeId)
    return result
  },

  async loadActivityCreationOption (midwifeId) {
    var result = await ApiService.doGet('/enna/midwife/activity/overview/new/' + midwifeId)
    return result
  }
}

export default MidwifeActivityService
