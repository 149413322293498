import ApiService from '../../../common/service/api/api.service'

const MidwifePropertyService = {
  async loadDynPropProperty (midwifeId, dynPropDefId, pid) {
    const result = await ApiService.doGet('/enna/midwife/property/dynprop/view/dto/' + midwifeId + '/' + dynPropDefId + '/' + pid)
    return result
  },

  async loadMidwifeProperty (midwifeId) {
    const result = await ApiService.doGet('/enna/midwife/property/midwifeproperty/view/dto/' + midwifeId)
    return result
  },

  async saveMidwifeProperty (midwifeProperty) {
    const result = await ApiService.doPost('/enna/midwife/property/midwifeproperty', midwifeProperty)
    return result
  },

  async queryViewProperty (midwifeId) {
    const result = await ApiService.doGet('/enna/midwife/property/query/view/' + midwifeId)
    return result
  }
}

export default MidwifePropertyService
