<template>
  <div>
    <formcontent
      ok-text="Speichern" @onOk="save">
      <v-text-field
        style="width:340px;"
        v-model="password"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        :type="showPassword ? 'text' : 'password'"
        label="Paßwort">
      </v-text-field>
      <v-text-field
        style="width:340px;"
        v-model="repeatedPassword"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        :type="showPassword ? 'text' : 'password'"
        label="Wiederholung Paßwort">
      </v-text-field>
    </formcontent>
  </div>
</template>

<script>
import formcontent from '#enna/common/components/content/FormContent'
import UserController from '@/module/user/user.controller'

export default {
  name: 'ChangePassword',

  async created () {
    this.$pageInitController.init(this, this.$moduleInfoController.user, 'Benutzer', 'Passwort ändern', this.username)
  },

  methods: {
    async save () {
      if (this.password === '') {
        alert('Kein Paßwort vorhanden')
      } else if (this.password !== this.repeatedPassword) {
        alert('Paßwörter stimmen nicht überein')
      } else {
        const result = await UserController.savePassword(this.userid, this.password)
        if (result === true) {
          alert('Paßwort ist geändert')
        }
      }
    }
  },

  data () {
    return {
      showPassword: false,
      password: '',
      repeatedPassword: ''
    }
  },

  props: {
    username: { type: String },
    userid: { type: String }
  },

  components: {
    formcontent
  }
}
</script>
