var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-data-table', {
    attrs: {
      "hide-default-footer": true,
      "headers": _vm.realHeaders,
      "height": _vm.contentheight,
      "items-per-page": 1000,
      "sort-by": _vm.searchParam.sortAttribute,
      "sort-desc": _vm.searchParam.sortDirection,
      "fixed-header": "",
      "items": _vm.dataList
    },
    on: {
      "update:sortBy": function ($event) {
        return _vm.$set(_vm.searchParam, "sortAttribute", $event);
      },
      "update:sort-by": function ($event) {
        return _vm.$set(_vm.searchParam, "sortAttribute", $event);
      },
      "update:sortDesc": function ($event) {
        return _vm.$set(_vm.searchParam, "sortDirection", $event);
      },
      "update:sort-desc": function ($event) {
        return _vm.$set(_vm.searchParam, "sortDirection", $event);
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers, function (column) {
      return {
        key: _vm.getColumnValue(column),
        fn: function (_ref) {
          var item = _ref.item;
          return [_vm._t(_vm.getNewSlotValue(column.value), function () {
            return [_c('div', {
              key: column.value
            }, [_vm._v(_vm._s(_vm.getTextValue(item, column.value)))])];
          }, {
            "item": item
          })];
        }
      };
    }), _vm.editMode ? {
      key: "item.edit",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-btn', {
          staticClass: "mx-2",
          attrs: {
            "fab": "",
            "small": "",
            "text": "",
            "outlined": "",
            "router": ""
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "blue"
          },
          on: {
            "click": function ($event) {
              return _vm.open(item);
            }
          }
        }, [_vm._v("mdi-pencil")])], 1)];
      }
    } : null, _vm.writeMode ? {
      key: "item.delete",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-btn', {
          staticClass: "mx-2",
          attrs: {
            "fab": "",
            "small": "",
            "text": "",
            "outlined": "",
            "router": ""
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "blue"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v("mdi-delete")])], 1)];
      }
    } : null], null, true)
  }), _c('formbuttonbar', {
    attrs: {
      "ok-text": "Neu",
      "button-one-text": _vm.buttonOneText,
      "button-two-text": _vm.buttonTwoText
    },
    on: {
      "onOk": _vm.newItem,
      "onButtonOne": _vm.onButtonOne,
      "onButtonTwo": _vm.onButtonTwo
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }