<template>
  <div>
    <v-card
      style=""
      class="mx-auto"
      max-width="344"
    >
      <v-card-title>
        Anmeldung
      </v-card-title>

      <v-card-text>
        <v-text-field v-model="user.userId" label="Benutzerkennung"></v-text-field>
        <v-text-field
          v-model="user.password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          :type="showPassword ? 'text' : 'password'"
          label="Paßwort">
        </v-text-field>
        <v-select
          v-show="mandatorList.length > 0"
          :items="mandatorList"
          item-text="name"
          item-value="suffix"
          v-model="selectedMandator"
          density="compact"
          label="Hebammenzentrale"
          clearable
        ></v-select>
        <cmdbutton @cmdclick="login" caption="OK"/>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import AuthController from './auth.controller'

export default {
  name: 'Login',

  async created () {
    this.$layoutController.setLoginLayout(this)
  },

  data () {
    return {
      showPassword: false,
      mandatorList: AuthController.getMandatorList(),
      selectedMandator: '',
      user: {
        userId: '',
        password: ''
      }
    }
  },

  methods: {
    login () {
      const tempUser = {
        userId: this.user.userId,
        password: this.user.password
      }
      if (this.selectedMandator != null && this.selectedMandator !== '') {
        tempUser.userId = tempUser.userId + '_' + this.selectedMandator
      }
      AuthController.login(tempUser)
    }
  }
}
</script>
