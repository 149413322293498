var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('midwifeactivitydetail', {
    attrs: {
      "pid": _vm.pid,
      "activityType": _vm.activityType,
      "activityName": _vm.activityName,
      "midwifeName": _vm.midwifeName,
      "midwifeId": _vm.midwifeId
    },
    on: {
      "onOk": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "display": "grid",
            "grid-gap": "20px",
            "grid-template-rows": "100px",
            "align-items": "end",
            "grid-template-columns": "3fr 1fr"
          }
        }, [_c('v-textarea', {
          attrs: {
            "label": "Info",
            "clearable": "",
            "auto-grow": "",
            "rows": "1"
          },
          model: {
            value: _vm.dtoMobileActivity.info,
            callback: function ($$v) {
              _vm.$set(_vm.dtoMobileActivity, "info", $$v);
            },
            expression: "dtoMobileActivity.info"
          }
        })], 1), _vm._l(_vm.regionList, function (group) {
          return _c('v-list', {
            key: group.name
          }, [_c('v-divider'), _c('label', {
            staticClass: "font-weight-medium title display-1"
          }, [_vm._v(_vm._s(group.name))]), _c('v-list', {
            staticStyle: {
              "display": "grid",
              "grid-auto-flow": "row",
              "grid-template-rows": "1fr",
              "grid-template-columns": "1fr 1fr 1fr 1fr"
            }
          }, _vm._l(group.itemList, function (item) {
            return _c('v-list-item', {
              key: item.id
            }, [_c('v-checkbox', {
              attrs: {
                "multiple": "",
                "value": item.id
              },
              model: {
                value: _vm.dtoMobileActivity.regionIdSet,
                callback: function ($$v) {
                  _vm.$set(_vm.dtoMobileActivity, "regionIdSet", $$v);
                },
                expression: "dtoMobileActivity.regionIdSet"
              }
            }), _c('v-checkbox', {
              attrs: {
                "multiple": "",
                "value": item.id
              },
              model: {
                value: _vm.dtoMobileActivity.expandedRegionIdSet,
                callback: function ($$v) {
                  _vm.$set(_vm.dtoMobileActivity, "expandedRegionIdSet", $$v);
                },
                expression: "dtoMobileActivity.expandedRegionIdSet"
              }
            }), _c('v-icon', {
              staticClass: "mr-2",
              attrs: {
                "small": ""
              }
            }, [_vm._v("mdi-arrow-all")]), _vm._v(_vm._s(item.name) + " ")], 1);
          }), 1)], 1);
        })];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }