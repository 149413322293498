import ApiService from '../../../common/service/api/api.service'

const CaseRegistrationService = {
  async load (caseLocator) {
    const result = await ApiService.doPost('/enna/cause/registration/load', caseLocator)
    return result
  },

  async save (registrationData) {
    const result = await ApiService.doPost('/enna/cause/registration/save', registrationData)
    return result
  },

  async new () {
    const result = await ApiService.doGet('/enna/cause/registration/new')
    return result
  }
}

export default CaseRegistrationService
