import router from '#enna/common/router'
import ConcernWomanService from './concern.woman.service'
import MenuController from '../../app/menu/menu.controller'
import DynPropController from '../../../common/components/dynprop/dynprop.controller'
import ModuleInfoController from '../../app/moduleinfo/moduleinfo.controller'

export default {
  async openDynPropDetail (caseLocator, property) {
    callDynPropDetail(caseLocator, property, this)
  },

  async reloadDynPropDetail (param, reloadId) {
    await callDynPropDetail(param.caseLocator, param.property, param.thisObject, reloadId)
  },

  async openConcernWomanOverviewFromDynProp (param) {
    callOpenConcernWomanOverview(param.caseLocator)
  },

  async openConcernWomanDetail (caseLocator, reloadId) {
    const response = await ConcernWomanService.getDtoWoman(caseLocator.concernId)
    const concernVersion = response.data.concernBaseData.concernVersion
    const womanName = response.data.concernBaseData.womanName
    const woman = response.data.concernData.woman
    const regionList = response.data.concernData.regionList
    const multipleBirthList = response.data.concernData.multipleBirthList
    router.push({
      name: 'concernwomandetail',
      params: {
        concernVersion: concernVersion,
        caseLocator: caseLocator,
        regionList: regionList,
        multipleBirthList: multipleBirthList,
        womanName: womanName,
        propDtoWoman: woman
      },
      query: { reload: reloadId }
    })
  },

  async openConcernWomanOverview (caseLocator) {
    callOpenConcernWomanOverview(caseLocator)
  },

  async saveLoadConcernWoman (caseLocator, concernVersion, dtoConcernWoman) {
    await ConcernWomanService.saveDtoWoman(concernVersion, dtoConcernWoman)
    callOpenConcernWomanOverview(caseLocator)
  }
}

async function callOpenConcernWomanOverview (caseLocator) {
  const response = await ConcernWomanService.getWoman(caseLocator.concernId)
  const concernBaseData = response.data.concernBaseData
  const dynPropList = response.data.concernData.dynPropList
  const woman = response.data.concernData.woman
  const selectedTabIndex = await MenuController.getConcernFileStartTab('concernwomanoverview')
  router.push({
    name: 'concernwomanoverview',
    params: {
      caseLocator: caseLocator,
      concernBaseData: concernBaseData,
      womanName: concernBaseData.womanName,
      dynPropList: dynPropList,
      preselectedTab: selectedTabIndex,
      woman: woman
    }
  })
}

async function callDynPropDetail (caseLocator, property, thisObject, reloadId) {
  const response = await ConcernWomanService.loadDynPropProperty(caseLocator, property.id, property.pid)
  const moduleInfo = {
    first: 'Frau',
    second: 'Zusatzdaten',
    name: response.data.concernBaseData.womanName
  }
  const dynPropLoadData = response.data.concernData.dynPropLoadData
  const param = {
    callFunction: thisObject.openConcernWomanOverviewFromDynProp,
    callParam: {
      caseLocator: caseLocator
    },
    reloadFunction: thisObject.reloadDynPropDetail,
    reloadParam: {
      caseLocator: caseLocator,
      property: property,
      thisObject: thisObject
    },
    objectType: 'woman',
    objectId: caseLocator.concernId,
    name: dynPropLoadData.name,
    dtoDynPropData: dynPropLoadData.dtoDynPropData,
    keylistList: dynPropLoadData.keylistList,
    versionId: dynPropLoadData.keyVersion,
    moduleInfo: moduleInfo,
    moduleController: ModuleInfoController.concern
  }
  DynPropController.openDynPropDetail(param, reloadId)
}
