var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.dynPropPropertyList, function (group) {
    return _c('ul', {
      key: group.caption
    }, [_vm.withHeader ? _c('div', [_c('v-subheader', {
      staticStyle: {
        "display": "grid",
        "grid-template-columns": "3fr 3fr 10fr"
      }
    }, [_c('div', [_vm._v(_vm._s(group.caption))])]), _c('v-divider')], 1) : _vm._e(), _vm._l(group.itemList, function (item) {
      return _c('div', {
        key: item.id,
        staticStyle: {
          "display": "grid",
          "grid-template-columns": "8fr 3fr 2fr"
        },
        attrs: {
          "id": item.id
        }
      }, [_c('span', [_vm._v(_vm._s(item.name))]), item.valueType === 'boolean' ? _c('span', [item.value == true ? _c('v-icon', {
        attrs: {
          "color": "green"
        }
      }, [_vm._v("mdi-check-bold")]) : item.value == false ? _c('v-icon', {
        attrs: {
          "color": "red"
        }
      }, [_vm._v("mdi-close-thick")]) : _vm._e()], 1) : item.valueType === 'int' ? _c('span', [_vm._v(" " + _vm._s(item.value) + " ")]) : item.valueType === 'string' ? _c('span', [_vm._v(" " + _vm._s(item.value) + " ")]) : item.valueType === 'date' ? _c('span', [_c('dateitem', {
        attrs: {
          "date-item": item.value
        }
      })], 1) : item.valueType === 'keylist' ? _c('span', [item.value !== null ? _c('span', [_vm._v(" " + _vm._s(item.value.name) + " ")]) : _vm._e()]) : item.valueType === 'multiplekeylist' ? _c('span', _vm._l(item.value, function (keyItem) {
        return _c('div', {
          key: keyItem.pid
        }, [_vm._v(" " + _vm._s(keyItem.name) + " ")]);
      }), 0) : item.valueType === 'unknownboolean' ? _c('span', [item.value === true ? _c('v-icon', {
        attrs: {
          "color": "green"
        }
      }, [_vm._v("mdi-check-bold")]) : item.value === false ? _c('v-icon', {
        attrs: {
          "color": "red"
        }
      }, [_vm._v("mdi-close-thick")]) : _c('v-icon', {
        attrs: {
          "color": "blue"
        }
      }, [_vm._v("mdi-help")])], 1) : _vm._e(), _c('v-btn', {
        staticClass: "mx-2",
        attrs: {
          "fab": "",
          "small": "",
          "text": "",
          "outlined": "",
          "router": ""
        }
      }, [_c('v-icon', {
        staticClass: "mr-2",
        attrs: {
          "small": "",
          "color": "blue"
        },
        on: {
          "click": function ($event) {
            return _vm.openDynProp(item);
          }
        }
      }, [_vm._v("mdi-pencil")])], 1)], 1);
    })], 2);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }