import ApiService from '../../../common/service/api/api.service'

const ConcernConcernadminService = {
  async queryAdminConcern () {
    const result = await ApiService.doGet('/enna/concern/queryallconcernwithcase')
    return result
  },

  async removeConcern (concernId) {
    const result = await ApiService.doPost('/enna/concern/removeconcern/' + concernId)
    return result
  }
}
export default ConcernConcernadminService
