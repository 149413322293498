<template>
  <div>
    <tabcontent :object-id="caseLocator" :tab-list="concernFileTabs" :preselectedTab="preselectedTab" contenttype="concern"></tabcontent>
  </div>
</template>

<script>
import tabcontent from '../../../common/components/content/TabContent'
import CaseOverviewController from '../case/case.overview.controller'

export default {
  name: 'ConcernMainFile',

  async created () {
    this.$pageInitController.initBack(this, this.$moduleInfoController.concern, 'Frau', 'Kartei', this.concernBaseData.womanName)
  },

  components: {
    tabcontent
  },

  methods: {
    async back () {
      await CaseOverviewController.openCaseOverview(this.caseLocator)
    }
  },

  computed: {
    concernFileTabs () {
      const tabList = this.$store.state.menu.concernFileTabs
      const result = []
      for (let index = 0; index < tabList.length; index++) {
        const tab = tabList[index]
        if (tab.tabid === 'concerndoubletoverview') {
          tab.count = this.concernBaseData.unproofedWebform
        } else {
          tab.count = 0
        }
        result.push(tab)
      }
      for (let index = 0; index < this.concernBaseData.caseIdList.length; index++) {
        const caseTab = { name: 'Anfrage', icon: 'mdi-account-search', tabid: 'concerncasefile', caseId: this.concernBaseData.caseIdList[index], openRoute (caseLocator) { CaseOverviewController.openCaseFile(caseLocator) } }
        result.push(caseTab)
      }
      return result
    }
  },

  props: {
    concernBaseData: { type: Object },
    preselectedTab: { type: Number },
    caseLocator: { type: Object }
  }
}
</script>
