<template>
    <div>
      <v-text-field v-show="withSearchString"
        v-model="searchString"
        label="Suchtext"
        clearable
      ></v-text-field>
      <ul v-bind:style="{ 'overflow-y': 'auto', 'max-height': contentheight + 'px' }">
        <li v-for="item in filteredList" :key="item[objectIdName]" style="overflow-y: auto;">
          <slot v-bind:item="item"></slot>
        </li>
      </ul>
      <formbuttonbar
        @onOk="onOk" :ok-text="newText"
        @onCancel="onCancel" :cancel-text="cancelText"
        @onButtonOne="onButtonOne" :button-one-text="buttonOneText"
        @onButtonTwo="onButtonTwo" :button-two-text="buttonTwoText"
      ></formbuttonbar>
    </div>
</template>

<script>
import formbuttonbar from '../element/FormButtonBar'

export default {
  name: 'OverviewContent',

  props: {
    objectIdName: { type: String },
    cancelText: { type: String },
    buttonOneText: { type: String },
    buttonTwoText: { type: String },
    dataList: { type: Array },
    withNew: { type: Boolean },
    newCaption: { type: String },
    withSearchString: { type: Boolean }
  },

  components: {
    formbuttonbar
  },

  computed: {
    newText () {
      if (this.newCaption) {
        return this.newCaption
      } else if (this.withNew) {
        return 'Neu'
      } else {
        return null
      }
    },

    contentheight () {
      let height = this.$store.getters['size/overviewContent']
      if (this.withSearchString) {
        height = height - 70
      }
      if (this.withNew) {
        height = height - 80
      }
      return height
    },

    filteredList () {
      const search = this.searchString.toLowerCase()
      if (search) {
        const filteredList = this.filterJson(this.dataList, search)
        return filteredList
      } else {
        return this.dataList
      }
    }
  },

  methods: {
    onOk () {
      this.$emit('onOk')
    },

    onCancel () {
      this.$emit('onCancel')
    },

    onButtonOne () {
      this.$emit('onButtonOne')
    },

    onButtonTwo () {
      this.$emit('onButtonTwo')
    },

    addNode (currentObject, search) {
      const result = {}
      let useNode = false
      for (const item in currentObject) {
        const currentValue = currentObject[item]
        if (typeof currentValue === 'object') {
          if (Array.isArray(currentValue)) {
            if (currentValue.length === 0) {
              result[item] = []
            } else {
              const tempReturn = []
              for (const index in currentValue) {
                const element = currentValue[index]
                const newResult = this.addNode(element, search)
                if (newResult.useNode) {
                  tempReturn.push(newResult.result)
                }
              }
              if (!useNode && tempReturn.length > 0) {
                useNode = true
              }
              result[item] = tempReturn
            }
          } else {
            const newResult = this.addNode(currentValue, search)
            result[item] = newResult.result
            if (!useNode && newResult.useNode) {
              useNode = true
            }
          }
        } else {
          result[item] = currentValue
          if (!useNode && search && currentValue.toLowerCase().includes(search)) {
            useNode = true
          }
        }
      }
      return { result, useNode }
    },

    filterJson (orgList, search) {
      const result = []
      for (const currentObject in orgList) {
        const node = this.addNode(orgList[currentObject], search)
        if (node.useNode && node.result) {
          result.push(node.result)
        }
      }
      return result
    }
  },

  data () {
    return {
      searchString: ''
    }
  }
}
</script>
