<template>
  <v-dialog
    v-model="showDialog"
    width="700"
    persistent
  >
    <v-card >
      <v-card-title class="text-h5 grey lighten-2">
        Auswahl für Sammelanfrage
      </v-card-title>
      <v-card-text v-if="propOpenTodo" style="white-space: pre-wrap;">
        <div class="text-h6">Benachrichtigen</div>
          <div v-for="item in informList" :key="item.todo.caseLocatorPid">
            <v-checkbox
              v-model="item.selected"
              :label="item.todo.name"
            ></v-checkbox>
          </div>
        <div>
        <div class="text-h6">Erinnern</div>
          <div v-for="item in remindList" :key="item.caseLocatorPid">
            <v-checkbox
              v-model="item.selected"
              :label="item.todo.name"
            ></v-checkbox>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="save"
        >
          Ok
        </v-btn>
        <v-btn
          color="primary"
          @click="cancel"
          text
        >
          Abbrechen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'OpenTodoSelectorDialog',

  methods: {
    cancel () {
      this.$emit('onCancel')
      this.informList = []
      this.remindList = []
      this.showDialog = false
    },

    initInformList () {
      if (this.propOpenTodo) {
        this.informList = []
        this.propOpenTodo.informTodoList.forEach(item => {
          this.informList.push(
            {
              selected: false,
              todo: item
            })
        })
      } else {
        this.informList = []
      }
    },

    initRemindList () {
      if (this.propOpenTodo) {
        this.remindList = []
        this.propOpenTodo.remindTodoList.forEach(item => {
          this.remindList.push(
            {
              selected: false,
              todo: item
            })
        })
      } else {
        this.remindList = []
      }
    },

    addToList (list, item) {
      if (item.selected) {
        const data = {
          caseLocator: item.todo.caseLocator,
          versionId: item.todo.versionId
        }
        list.push(data)
      }
    },

    save () {
      const informResult = []
      this.informList.forEach(item => {
        this.addToList(informResult, item)
      })
      const remindResult = []
      this.remindList.forEach(item => {
        this.addToList(remindResult, item)
      })
      const result = {
        informList: informResult,
        remindList: remindResult
      }
      this.$emit('onSave', result)
      this.showDialog = false
    }
  },

  watch: {
    propShowDialog (val) {
      if (val) {
        this.initInformList()
        this.initRemindList()
      }
      this.showDialog = val
    }
  },

  props: {
    propShowDialog: { type: Boolean },
    propOpenTodo: { type: Object }
  },

  data () {
    return {
      informList: [],
      remindList: [],
      showDialog: this.propShowDialog
    }
  }
}
</script>
