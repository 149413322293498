<template>
  <div>
    <overviewcontent :data-list="infoList" @onOk="newItem()" :withNew=true>
      <template v-slot="infoItem">
        <v-system-bar>
          {{ format(infoItem.item.infoDate) }} - {{ infoItem.item.userInfo.userName }}
          <v-spacer></v-spacer>
          <div v-show="infoItem.item.deleteDate">Automatisch gelöscht ab  {{ format(infoItem.item.deleteDate) }}</div>
          <v-spacer></v-spacer>
          <v-icon light @click="deleteItem(infoItem.item.pid)" color="blue">mdi-delete</v-icon>
          <v-icon light @click="updateItem(infoItem.item.pid)" color="blue">mdi-pencil</v-icon>
        </v-system-bar>
        <div style="display:grid; grid-template-columns: 1fr 3fr 1fr;">
          <div>
            <span>
              <v-tooltip right :key="infoItem.item.pid">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" v-show="infoItem.item.forAgency" light size="30" class="mr-2" @click="deleteItem(infoItem.item.pid)" color="blue">mdi-information-variant</v-icon>
                </template>
                <span>Relevant in der Übersicht</span>
              </v-tooltip>
            </span>
            <span>
              <v-tooltip right :key="infoItem.item.pid">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" v-show="infoItem.item.forMediation" light size="30" class="mr-2" @click="deleteItem(infoItem.item.pid)" color="blue">mdi-binoculars</v-icon>
                </template>
                <span>Relevant bei der Vermittlung</span>
              </v-tooltip>
            </span>
            <span>
              <v-tooltip right :key="infoItem.item.pid">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" v-show="infoItem.item.forTodo" light size="30" class="mr-2" @click="deleteItem(infoItem.item.pid)" color="blue">mdi-wrench</v-icon>
                </template>
                <span>Zu erledigen</span>
              </v-tooltip>
            </span>
          </div>
          <div style="padding-top: 10px; padding-bottom: 30px;">{{ infoItem.item.info }}</div>
        </div>
      </template>
    </overviewcontent>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import overviewcontent from '#enna/common/components/content/OverviewContent'
import MidwifeInfoController from './midwife.info.controller'
import MidwifeOverviewController from '../overview/midwife.overview.controller'
import { formatDate } from '../../../common/utils/date.utils'

export default {
  name: 'MidwifeInfoOverview',

  async created () {
    this.$pageInitController.initBack(this, this.$moduleInfoController.midwife, 'Hebamme', 'Info', this.midwifeName)
  },

  methods: {
    async back () {
      await MidwifeOverviewController.openMidwifeOverview(this.midwifeId)
    },

    newItem () {
      MidwifeInfoController.openNewInfo(this.midwifeId)
    },

    async deleteItem (value) {
      this.infoList = await MidwifeInfoController.deleteInfo(this.midwifeId, value)
    },

    updateItem (value) {
      MidwifeInfoController.openInfo(this.midwifeId, value)
    },

    format (value) {
      return formatDate(value)
    }
  },

  props: {
    propInfoList: { type: Array },
    midwifeName: { type: String },
    midwifeId: { type: String }
  },

  data () {
    return {
      infoList: cloneDeep(this.propInfoList)
    }
  },

  components: {
    overviewcontent
  }

}
</script>
