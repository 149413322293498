<template>
  <div>
    <formcontent
      ok-text="Speichern" @onOk="save"
      cancel-text="Abbrechen" @onCancel="cancel"
      write-right="midPropertyWrite">
      <v-checkbox v-model="dtoMidwifeProperty.participateMediation" label="Teilnahme an Vermittlung"></v-checkbox>
      <v-checkbox v-model="dtoMidwifeProperty.participateSubstitution" label="Teilnahme am Vertretungssystem"></v-checkbox>
      <v-checkbox v-model="dtoMidwifeProperty.infoPerMail" label="Benachrichtung per Mail"></v-checkbox>
      <v-checkbox v-model="dtoMidwifeProperty.infoPerCollectiveMail" label="Benachrichtung per Sammelmail"></v-checkbox>
      <v-checkbox v-model="dtoMidwifeProperty.accumulativeMailFullData" label="Sammelmail mit vollständigen Daten der Frau"></v-checkbox>
      <v-checkbox v-model="dtoMidwifeProperty.infoPerSms" label="Benachrichtigung per SMS"></v-checkbox>
      <v-checkbox v-model="dtoMidwifeProperty.prioritySmsMail" label="Priorität: Mail vor SMS"></v-checkbox>
      <v-checkbox v-model="dtoMidwifeProperty.salutation" label="Anrede 'Sie'"></v-checkbox>
      <v-select
        :items="contactProcedureOptionList"
        item-text="name"
        item-value="id"
        v-model="dtoMidwifeProperty.contactProcedure"
        label="Verfahren bei 'Anfrage'">
      </v-select>
      <v-text-field v-model="dtoMidwifeProperty.contactMailinfo" label="Zusatzinfo bei Anfrage'"></v-text-field>
      <v-select
        :items="promiseProcedureOptionList"
        item-text="name"
        item-value="id"
        v-model="dtoMidwifeProperty.promiseProcedure"
        label="Verfahren bei 'Zusage'">
      </v-select>
      <v-text-field v-model="dtoMidwifeProperty.promiseMailinfo" label="Zusatzinfo bei Zusage'"></v-text-field>
    </formcontent>
  </div>
</template>

<script>
import formcontent from '../../../common/components/content/FormContent'
import cloneDeep from 'lodash/cloneDeep'
import MidwifePropertyController from './midwife.property.controller'

export default {
  name: 'MidwifePropertyDetail',

  async created () {
    this.$pageInitController.initReload(this, this.$moduleInfoController.midwife, 'Hebamme', 'Zusatzdaten', this.midwifeName)
  },

  components: {
    formcontent
  },

  watch: {
    propDtoMidwifeProperty (val) {
      this.dtoMidwifeProperty = cloneDeep(this.propDtoMidwifeProperty)
    }
  },

  methods: {
    async reload () {
      await MidwifePropertyController.openMidwifePropertyDetail(this.dtoMidwifeProperty.midwifeId, Date.now())
    },
    async save () {
      await MidwifePropertyController.saveMidwifeProperty(this.dtoMidwifeProperty)
    },
    async cancel () {
      await MidwifePropertyController.openMidwifePropertyOverview(this.dtoMidwifeProperty.midwifeId)
    }
  },

  data () {
    return {
      dtoMidwifeProperty: cloneDeep(this.propDtoMidwifeProperty)
    }
  },

  props: {
    contactProcedureOptionList: { type: Array },
    promiseProcedureOptionList: { type: Array },
    midwifeName: { type: String },
    propDtoMidwifeProperty: { type: Object }
  }
}
</script>
