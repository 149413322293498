import ApiService from '../../../common/service/api/api.service'

const MidwifeInfoService = {
  async deleteInfo (pid) {
    var result = await ApiService.doDelete('/enna/midwife/info/' + pid)
    return result
  },

  async newInfo (midwifeId) {
    var result = await ApiService.doGet('/enna/midwife/info/new/view/dto/' + midwifeId)
    return result
  },

  async loadInfo (pid) {
    var result = await ApiService.doGet('/enna/midwife/info/view/dto/' + pid)
    return result
  },

  async saveInfo (dtoMidwifeInfo) {
    var result = await ApiService.doPost('/enna/midwife/info', dtoMidwifeInfo)
    return result
  },

  async queryViewInfo (midwifeId) {
    var result = await ApiService.doGet('/enna/midwife/info/query/view/' + midwifeId)
    return result
  },

  async queryInfo (midwifeId) {
    var result = await ApiService.doGet('/enna/midwife/info/query/' + midwifeId)
    return result
  }
}

export default MidwifeInfoService
