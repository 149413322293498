import router from '#enna/common/router'
import ConcernSummaryService from './concern.summary.service'
import MenuController from '../../app/menu/menu.controller'

export default {
  async changeConcernState (caseLocator, versionId, openState, reloadId) {
    await ConcernSummaryService.changeConcernState(caseLocator, versionId, openState)
    await internalOpenConcernSummary(caseLocator, reloadId)
  },

  async reloadConcernSummary (caseLocator, reloadId) {
    await internalOpenConcernSummary(caseLocator, reloadId)
  },

  async openConcernSummary (caseLocator) {
    await internalOpenConcernSummary(caseLocator)
  }
}

async function internalOpenConcernSummary (caseLocator, reloadId) {
  const response = await ConcernSummaryService.loadConcernSummary(caseLocator.concernId)
  const concernBaseData = response.concernBaseData
  const concernVersion = response.concernBaseData.concernVersion
  const womanName = response.concernBaseData.womanName
  const concernSummary = response.concernData
  const selectedTabIndex = await MenuController.getConcernFileStartTab('concernsummary')

  router.push({
    name: 'concernsummary',
    params: {
      concernBaseData: concernBaseData,
      caseLocator: caseLocator,
      womanName: womanName,
      preselectedTab: selectedTabIndex,
      concernVersion: concernVersion,
      concernSummary: concernSummary
    },
    query: { reload: reloadId }
  })
}
