var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('formcontent', {
    attrs: {
      "ok-text": "Speichern",
      "cancel-text": "Abbrechen"
    },
    on: {
      "onOk": _vm.save,
      "onCancel": _vm.cancel
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-rows": "100px",
      "align-items": "end",
      "grid-template-columns": "3fr 3fr 2fr"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.signOffTypeList,
      "item-text": "name",
      "item-value": "signofftypeId",
      "label": "Typ"
    },
    model: {
      value: _vm.dtoMidwifeSignOff.signOffTypeId,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeSignOff, "signOffTypeId", $$v);
      },
      expression: "dtoMidwifeSignOff.signOffTypeId"
    }
  })], 1), _c('div', {
    staticStyle: {
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-rows": "100px",
      "align-items": "end",
      "grid-template-columns": "3fr 3fr 2fr"
    }
  }, [_c('datepicker', {
    attrs: {
      "title": "von",
      "propdate": _vm.dtoMidwifeSignOff.from
    },
    on: {
      "update:propdate": function ($event) {
        return _vm.$set(_vm.dtoMidwifeSignOff, "from", $event);
      }
    }
  }), _c('datepicker', {
    attrs: {
      "title": "bis",
      "propdate": _vm.dtoMidwifeSignOff.until
    },
    on: {
      "update:propdate": function ($event) {
        return _vm.$set(_vm.dtoMidwifeSignOff, "until", $event);
      }
    }
  })], 1), _c('div', {
    staticStyle: {
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-rows": "100px",
      "align-items": "end",
      "grid-template-columns": "6fr 2fr"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Info"
    },
    model: {
      value: _vm.dtoMidwifeSignOff.info,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeSignOff, "info", $$v);
      },
      expression: "dtoMidwifeSignOff.info"
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }