var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('formcontent', {
    attrs: {
      "ok-text": "Speichern",
      "cancel-text": "Abbrechen"
    },
    on: {
      "onOk": _vm.save,
      "onCancel": _vm.cancel
    }
  }, [_c('v-icon', {
    staticStyle: {
      "padding-right": "30px"
    },
    attrs: {
      "x-large": ""
    }
  }, [_vm._v(_vm._s(_vm.activityType.icon))]), _c('label', {
    staticClass: "font-weight-medium title display-1"
  }, [_vm._v(_vm._s(_vm.activityName))]), _c('div', [_vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }