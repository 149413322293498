<template>
  <div>
    MidwifeSubstitutionOverview
    {{ midwifeId }}
  </div>
</template>

<script>

export default {
  name: 'MidwifeSubstitutionOverview',

  props: {
    midwifeId: { type: String }
  }
}
</script>
