<template>
  <div>
    <formcontent
      ok-text="Speichern" @onOk="save"
      cancel-text="Abbrechen" @onCancel="cancel"
      :button-one-text="detailButtonCaption" @onButtonOne="changeToFile">
      <div style="display: grid;grid-template-columns: 1fr 1fr;">
        <searchcard caption="Frau" >
          <v-text-field v-model="registration.surname" label="Nachname"></v-text-field>
          <v-text-field v-model="registration.prename" label="Vorname"></v-text-field>
        </searchcard>
        <searchcard caption="Erreichbarkeit" >
            <div style="
                  display: grid;
                  grid-column-gap: 20px;
                  grid-template-rows: 1fr 1fr;
                  grid-template-columns: 1fr 1fr;">
              <v-text-field v-model="registration.phone1" label="Telefon"></v-text-field>
              <v-text-field v-model="registration.phone2" label="Weiteres Telefon"></v-text-field>
              <v-text-field v-model="registration.mail" label="E-Mail"></v-text-field>
            </div>
        </searchcard>
        <searchcard caption="Anschrift">
            <div style="
                  display: grid;
                  grid-column-gap: 20px;
                  grid-template-rows: 1fr 1fr;
                  grid-template-columns: 3fr 1fr 8fr 1fr 3fr;">
              <v-text-field style="grid-column: 1 / 4; grid-row: 1;" v-model="registration.street" label="Straße"></v-text-field>
              <v-text-field style="grid-column: 5; grid-row: 1;" v-model="registration.streetNumber" label="Hausnummer"></v-text-field>
              <v-text-field style="grid-column: 1; grid-row: 2;" v-model="registration.postcode" label="PLZ"></v-text-field>
              <v-text-field style="grid-column: 3 / 6; grid-row: 2;" v-model="registration.city" label="Stadt"></v-text-field>
            </div>
        </searchcard>
        <searchcard caption="Sonstiges">
            <v-textarea
              style="width: 95%;"
              clearable
              clear-icon="mdi-close-circle"
              label="Info"
              v-model="registration.info"
            ></v-textarea>
        </searchcard>
        <searchcard caption="Suche nach">
          <caseparameter
            layout="block"
            :set="origin = this.flexibleData"
            :location="origin.region"
            :bdate="origin.bdate"
            :edate="origin.edate"
            :activitySearchParamList="origin.currentSearchActivityList">
          </caseparameter>
        </searchcard>
      </div>
    </formcontent>
  </div>
</template>

<script>
import formcontent from '#enna/common/components/content/FormContent'
import SearchController from './case.search.controller'
import cloneDeep from 'lodash/cloneDeep'
import searchcard from './SearchCard'
import caseparameter from './CaseParameter'
import InfoDialogController from '@/common/components/element/infodialog/infodialog.controller'
import { transformToActivityIdList } from '@/module/concern/common/search.utils'

export default {
  name: 'Registration',

  async created () {
    this.$pageInitController.init(this, this.$moduleInfoController.concern, 'Frau', 'Angaben zu Frau', this.womanName)
  },

  components: {
    formcontent,
    searchcard,
    caseparameter
  },

  computed: {
    detailButtonCaption () {
      if (this.concernData) {
        return 'Details'
      } else {
        return null
      }
    },

    contentHeight () {
      const height = this.$store.getters['size/overviewContent']
      return height
    }
  },

  methods: {
    async checkChange () {
      if (this.configSearchView.checkChange) {
        const activityList = transformToActivityIdList(this.flexibleData.currentSearchActivityList)
        const checkChange = await SearchController.checkChange(this.concernData.caseLocator, this.concernData.concernVersion, this.region?.regionId, this.edate, this.bdate, activityList)
        if (checkChange.hasChanged) {
          InfoDialogController.showInfo('Suche nach Hebamme', checkChange.message)
        }
      }
    },

    async changeToFile () {
      if (this.configSearchView.checkChange) {
        let edate = null
        let bdate = null
        if (this.flexibleData.dateType === 'Bdate') {
          bdate = this.flexibleData.edate
        } else {
          edate = this.flexibleData.edate
        }
        const activityList = transformToActivityIdList(this.flexibleData.currentSearchActivityList)
        const checkChange = await SearchController.checkChange(this.concernData.caseLocator, this.concernData.concernVersion, this.flexibleData.region?.regionId, edate, bdate, activityList)
        if (checkChange.hasChanged) {
          InfoDialogController.showInfo('Suche nach Hebamme', checkChange.message)
        }
      }
      await SearchController.changeToFile(this.configSearchView, this.constantData, this.concernData, this.flexibleData, this.registration)
    },

    async save () {
      if (this.configSearchView.checkChange) {
        let edate = null
        let bdate = null
        if (this.flexibleData.dateType === 'Bdate') {
          bdate = this.flexibleData.edate
        } else {
          edate = this.flexibleData.edate
        }
        const activityList = transformToActivityIdList(this.flexibleData.currentSearchActivityList)
        const checkChange = await SearchController.checkChange(this.concernData.caseLocator, this.concernData.concernVersion, this.flexibleData.region?.regionId, edate, bdate, activityList)
        if (checkChange.hasChanged) {
          InfoDialogController.showInfo('Suche nach Hebamme', checkChange.message)
        }
      }
      await SearchController.saveRegistration(this.configSearchView, this.constantData, this.concernData, this.flexibleData, this.registration)
    },
    async cancel () {
      await SearchController.openCancelledRegistrationToSearch9(this.configSearchView, this.constantData, this.concernData, this.flexibleData)
    }
  },

  data () {
    return {
      registration: cloneDeep(this.propRegistration)
    }
  },

  props: {
    configSearchView: { type: Object },
    concernData: { type: Object },
    constantData: { type: Object },
    flexibleData: { type: Object },
    propRegistration: { type: Object }
  }
}
</script>
