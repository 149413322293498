<template>
  <div style="">
    <div>
      <v-app-bar app color="grey lighten-1" dark style="padding-top: 50px; height: 170px;">
        <div style="display: grid; grid-template-columns: 2fr 2fr; ">
          <div></div>
          <v-toolbar-title>
            <span class="font-weight-thin" style="font-size:4em;">e n </span>
            <span class="font-weight-bold" style="font-size:4em;">n a</span>
            <span class="font-weight-bold" style="color:aquamarine; font-size:4em;padding-left: 20px;">.</span>
            <span style="padding-left: 20px;font-size:2em;">network</span>
          </v-toolbar-title>
        </div>
      </v-app-bar>
    </div>
    <div >
      <div style="padding-left: 150px; padding-top: 100px;">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'helplayout',

  components: {
  }
}
</script>
