var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('midwifeactivitydetail', {
    attrs: {
      "pid": _vm.pid,
      "activityType": _vm.activityType,
      "activityName": _vm.activityName,
      "midwifeName": _vm.midwifeName,
      "midwifeId": _vm.midwifeId
    },
    on: {
      "onOk": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "display": "grid",
            "grid-gap": "20px",
            "grid-template-rows": "100px",
            "align-items": "end",
            "grid-template-columns": "3fr 1fr"
          }
        }, [_c('v-textarea', {
          attrs: {
            "label": "Info",
            "clearable": "",
            "auto-grow": "",
            "rows": "1"
          },
          model: {
            value: _vm.dtoKnowledgeActivity.info,
            callback: function ($$v) {
              _vm.$set(_vm.dtoKnowledgeActivity, "info", $$v);
            },
            expression: "dtoKnowledgeActivity.info"
          }
        })], 1)];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }