import MidwifeActivityController from '../overview/midwife.activity.controller'
import KnowledgeActivityService from './knowledge.activity.service'
import router from '#enna/common/router'

export default {
  async delete (pid) {
    const value = await KnowledgeActivityService.delete(pid)
    return value
  },

  async load (midwifeId, pid, reloadId) {
    const response = await KnowledgeActivityService.load(pid)
    const propDtoKnowledgeActivity = response.data.dtoKnowledgeActivity
    const midwifeName = response.data.midwifeName
    const activityName = response.data.activityName
    const activityType = response.data.activityType
    router.push({
      name: 'knowledgeactivitydetail',
      params: {
        midwifeId: midwifeId,
        pid: pid,
        midwifeName: midwifeName,
        activityName: activityName,
        activityType: activityType,
        propDtoKnowledgeActivity: propDtoKnowledgeActivity
      },
      query: { reload: reloadId }
    })
  },

  async save (dtoKnowledgeActivity) {
    await KnowledgeActivityService.save(dtoKnowledgeActivity)
    MidwifeActivityController.openMidwifeActivityOverview(dtoKnowledgeActivity.midwifeId)
  },

  async generateActivity (data) {
    await KnowledgeActivityService.generateActivity(data)
  }
}
