var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('mastercontent', {
    attrs: {
      "data-list": _vm.adminConcernList,
      "headers": _vm.headers,
      "noButtonBar": true,
      "noEdit": true,
      "writeRightProp": "concernWrite",
      "componentId": "concernId"
    },
    on: {
      "remove": _vm.remove
    },
    scopedSlots: _vm._u([{
      key: "mdt.firstContact",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('dateitem', {
          attrs: {
            "date-item": item.firstContact
          }
        })];
      }
    }])
  }), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "auto"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v(" Anfrage vollständig löschen? ")]), _c('v-card-text', [_c('div', [_vm._v("Die Anfrage wird vollständig gelöscht. Auch alle Mails, die im Zusammenhang mit dieser Anfrage versendet wurden, werden gelöscht. Die Anfrage kann anschließend nicht wiederhergestellt werden.")]), _c('div', [_vm._v(" Soll die Anfrage vollständig gelöscht werden?")])]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "green-darken-1"
    },
    on: {
      "click": _vm.deleteConcern
    }
  }, [_vm._v(" Vollständig löschen ")]), _c('v-btn', {
    attrs: {
      "color": "green-darken-1"
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" Abbrechen ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }