<template>
    <div>
      <div>
        <div>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="filterParam.search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                clearable
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table
              :hide-default-footer="true"
              :headers="headers"
              :height="contentheight"
              fixed-header
              :sort-by.sync="filterParam.sortAttribute"
              :sort-desc.sync="filterParam.sortDirection"
              :items-per-page=5000
              :items="dataList"
              :search="filterParam.search"
            >
              <template v-slot:body="{ items }">
                <tbody>
                <tr :id="item[tablecontentKeyId]" v-for="item in items" :key="item[tablecontentKeyId]">
                  <td v-for="header in headers" :key="header.name">
                    {{ getTextValue(item, header.value) }}
                  </td>
                  <template v-if="withOpen" >
                    <td >
                      <v-btn fab small text outlined class="mx-2" router >
                        <v-icon small class="mr-2" @click="open(item)" color="blue">mdi-pencil</v-icon>
                      </v-btn>
                    </td>
                  </template>
                  <template v-if="withRemove" >
                    <td>
                      <v-btn fab small text outlined class="mx-2" router >
                        <v-icon small class="mr-2" @click="remove(item)" color="blue">mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </template>
                </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </div>
</template>

<script>
import PageinfoController from '../../../module/app/pageinfo/pageinfo.controller'
import cloneDeep from 'lodash/cloneDeep'
import Helper from '../../utils/json.utils'

export default {
  name: 'TableContent',

  computed: {
    contentheight: function () {
      return this.$store.getters['size/tableContent']
    }
  },

  methods: {
    getTextValue (item, value) {
      return Helper.getValue(item, value)
    },
    open (item) {
      this.$emit('open', item)
    },

    remove (item) {
      this.$emit('remove', item)
    }
  },

  watch: {
    filterParam: {
      deep: true,
      handler () {
        PageinfoController.setPageinfo(this.propFilterKey, this.filterParam)
      }
    }
  },

  data () {
    return {
      filterParam: cloneDeep(this.propFilterParam)
    }
  },

  props: {
    tablecontentKeyId: { type: String },
    objectIdName: { type: String },
    propFilterKey: { type: String },
    propFilterParam: { type: Object },
    withOpen: { type: Boolean },
    withRemove: { type: Boolean },
    dataList: { type: Array },
    headers: { type: Array }
  }
}
</script>
