var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-list-item', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-content', [_c('div', {
    staticClass: "text-h5 mb-1"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-list-item-subtitle', {
    staticStyle: {
      "padding-left": "20px"
    }
  }, [_vm._t("default")], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }