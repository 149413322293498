<template>
  <div>
    <formcontent
      ok-text="Bearbeiten" @onOk="edit"
      write-right="midMasterdataWrite">
      <div style="display: grid; grid-template-columns: 1fr 1fr;">
        <themecard title="Frau">
          {{ woman.prename }} {{ woman.surname }}
          <div v-if="showBirthyear">Geburtsjahr {{ woman.birthyear }}</div>
        </themecard>
        <themecard title="Schwangerschaft / Geburt">
          <div v-if="woman.edate">
            ET: <dateitem style="display: inline;" :date-item="woman.edate"></dateitem>
          </div>
          <div v-if="woman.bdate">
            Geburt: <dateitem style="display: inline;" :date-item="woman.bdate"></dateitem>
          </div>
          <div v-if="woman.gravida">
            Gravida: {{ woman.gravida }}
          </div>
          <div v-if="woman.para">
            Para: {{ woman.para }}
          </div>
          <div v-if="woman.multipleBirth">
            {{ woman.multipleBirth.name }}
          </div>
        </themecard>
        <themecard v-if="woman.mailInfo" title="Info aus dem Webformular">
          <div>{{ woman.mailInfo }}</div>
        </themecard>
        <themecard title="Kontaktdaten">
          <div>{{ woman.phone1 }}</div>
          <div>{{ woman.phone2 }}</div>
          <div>{{ woman.mail }}</div>
          <div>{{ woman.contactInfo }}</div>
        </themecard>
        <themecard v-if="showAdress" title="Anschrift">
          <div>{{ woman.adress.street }} {{ woman.adress.streetNumber }}</div>
          <div>{{ woman.adress.postcode }} {{ woman.adress.city }}</div>
          <div v-if="woman.adress.region">{{ woman.adress.region.name }}</div>
          <div>{{ woman.adresschangeinfo }}</div>
        </themecard>
        <themecard title="Zusatzdaten">
          <dynpropoverview :withHeader="false" :dynPropPropertyList="dynPropList" @onEditDynPropData="onEditDynPropData"></dynpropoverview>
        </themecard>
        <themecard v-if="showFutureAdress" title="Alternative Anschrift">
          <div>{{ woman.futureAdress.street }} {{ woman.futureAdress.streetNumber }}</div>
          <div>{{ woman.futureAdress.postcode }} {{ woman.futureAdress.city }}</div>
          <div v-if="woman.futureAdress.region">{{ woman.futureAdress.region.name }}</div>
          <div v-if="showFutureAdressDate">
            Neue Adresse gültig ab <dateitem style="display: inline;" :date-item="woman.adresschangedate"></dateitem>
          </div>
        </themecard>
        <themecard v-if="showInfo" title="Info">
          <div v-if="woman.info">
            <label>Original-Info:</label>
            <div>{{ woman.info }}</div>
          </div>
          <div v-if="woman.modifiedInfo">
            <label>Überarbeitete Info:</label>
            <div>{{ woman.modifiedInfo }}</div>
          </div>
        </themecard>
        <themecard v-if="showContacter" title="Kontakt über">
          <div>{{ woman.contacter.name }}</div>
          <div>{{ woman.contacter.phone }}</div>
          <div>{{ woman.contacter.mail }}</div>
        </themecard>
      </div>
    </formcontent>
  </div>
</template>

<script>
import dynpropoverview from '#enna/common/components/element/DynPropOverview'
import formcontent from '../../../common/components/content/FormContent'
import themecard from '../../../common/components/element/ThemeCard'
import dateitem from '../../../common/components/element/DateItem'
import ConcernWomanController from './concern.woman.controller'
import CaseOverviewController from '../case/case.overview.controller'

export default {
  name: 'ConcernWomanOverview',

  components: {
    formcontent,
    dateitem,
    themecard,
    dynpropoverview
  },

  async created () {
    this.$pageInitController.initBack(this, this.$moduleInfoController.concern, 'Frau', 'Stammdaten', this.womanName)
  },

  computed: {
    showBirthyear () {
      return this.woman.birthyear
    },
    showContacter () {
      return this.woman.contacter
    },
    showInfo () {
      return this.woman.info || this.woman.modifiedInfo
    },
    showAdress () {
      return this.woman.adress
    },
    showFutureAdress () {
      return this.woman.futureAdress
    },
    showFutureAdressDate () {
      return this.woman.adresschangedate
    }
  },

  methods: {
    async back () {
      await CaseOverviewController.openCaseOverview(this.caseLocator)
    },

    onEditDynPropData (property) {
      ConcernWomanController.openDynPropDetail(this.caseLocator, property)
    },

    async edit () {
      await ConcernWomanController.openConcernWomanDetail(this.caseLocator)
    }
  },

  props: {
    caseLocator: { type: Object },
    dynPropList: { type: Array },
    womanName: { type: String },
    woman: { type: Object }
  }
}
</script>
