<template>
  <div>
    <mastercontent
      :data-list="activityList"
      :headers="headers"
      :writeRightProp="writeRight"
      componentId="midwifeActivity"
      buttonOneText="Vergleich mobile Leistungen"
      @onButtonOne="mobileCompare"
      buttonTwoText="Bearbeite mobile Leistungen"
      @onButtonTwo="mobileGenerate"
      @openNew="openNew"
      @open="open"
      @remove="remove"
    >
      <template v-slot:mdt.activity.activityType="{ item }">
        <v-icon small light>{{ item.activityType.icon }}</v-icon>
      </template>
    </mastercontent>
    <mobileactivitycomparison :midwife-name="midwifeName" :midwife-id="midwifeId" :propshowdialog.sync="mobileActivityComparison"></mobileactivitycomparison>
  </div>
</template>

<script>
import mastercontent from '#enna/common/components/content/MasterContent'
import MidwifeActivityController from './midwife.activity.controller'
import MobileActivityController from '../mobile/mobile.activity.controller'
import cloneDeep from 'lodash/cloneDeep'
import MidwifeOverviewController from '../../overview/midwife.overview.controller'
import mobileactivitycomparison from '../mobile/MobileActivityCompare'

export default {
  name: 'MidwifeActivityOverview',

  async created () {
    this.$pageInitController.initBack(this, this.$moduleInfoController.midwife, 'Hebamme', 'Leistungen', this.midwifeName)
  },

  props: {
    writeRight: { type: String },
    midwifeId: { type: String },
    midwifeName: { type: String },
    propActivityList: { type: Array }
  },

  components: {
    mobileactivitycomparison,
    mastercontent
  },

  data () {
    return {
      mobileActivityComparison: false,
      activityList: cloneDeep(this.propActivityList),
      headers: [
        { text: 'Art', value: 'activity.activityType' },
        { text: 'Name', value: 'activity.name' },
        { text: 'Info', value: 'info', width: 220 }
      ]
    }
  },

  methods: {
    mobileGenerate () {
      MobileActivityController.openModifyActivity(this.midwifeId)
    },
    async mobileCompare () {
      this.mobileActivityComparison = true
    },
    async back () {
      await MidwifeOverviewController.openMidwifeOverview(this.midwifeId)
    },
    async openNew () {
      MidwifeActivityController.openNewActivity(this.midwifeId)
    },
    async open (activity) {
      MidwifeActivityController.openActivityDetail(this.midwifeId, activity)
    },
    async remove (activity) {
      this.activityList = await MidwifeActivityController.deleteReadActivity(this.midwifeId, activity)
    }
  }
}
</script>
