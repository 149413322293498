import ApiService from '../../../common/service/api/api.service'
import VersionController from '../../../module/app/versioning/versioning.controller'

const CaseOverviewService = {

  async setCaseAction (caseLocator, actionParam, concernVersion) {
    const data = {
      actionParam: actionParam,
      concernVersion: concernVersion
    }
    await ApiService.doPost('/enna/cause/case/action', data)
  },

  async midwifeMediationInfo (midwifeId) {
    const result = await ApiService.doGet('/enna/cause/case/midwifemediationinfo/' + midwifeId)
    return result
  },

  async loadCase (caseLocator) {
    const result = await ApiService.doPost('/enna/cause/case/load', caseLocator)
    return result
  },

  async queryCase () {
    const latestData = await VersionController.getLatestData('caseOverview')
    if (latestData === null) {
      const result = await ApiService.doGet('/enna/cause/case/overview')
      VersionController.setLatestData('caseOverview', result.data)
      return result.data.data
    } else {
      return latestData.data
    }
  },

  async queryFinishedCase () {
    const latestData = await VersionController.getLatestData('finishedCaseOverview')
    if (latestData === null) {
      const result = await ApiService.doGet('/enna/cause/case/finishedoverview')
      VersionController.setLatestData('finishedCaseOverview', result.data)
      return result.data.data
    } else {
      return latestData.data
    }
  }
}

export default CaseOverviewService
