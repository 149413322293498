import StatisticService from './statistic.service'
import router from '#enna/common/router'

export default {
  async openStatisticOverview () {
    const response = await StatisticService.queryStatistic()
    const statisticList = response.data
    router.push({ name: 'statistic', params: { statisticList: statisticList } })
  },

  async downloadStatistic (statisticId, from, until) {
    const response = await StatisticService.download(statisticId, from, until)
    return response
  },

  async showStatistic (statisticId, from, until) {
    const response = await StatisticService.evaluate(statisticId, from, until)
    return response.data
  }
}
