<template>
  <v-dialog
    v-model="showDialog"
    width="800"
    persistent
  >
    <v-card v-if="midwifeInfo">
      <div v-bind:style="{ 'overflow-y': 'auto', 'max-height': contentheight + 'px' }">
        <v-card v-if="midwifeInfo.midwifeContactList.length > 0">
          <v-card-title class="text-h5 grey lighten-2">Kontaktdaten</v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead class="font-weight-thin">
              <tr>
                <th class="text-left">Art</th>
                <th class="text-left">Typ</th>
                <th class="text-left">Nummer</th>
                <th class="text-left">Frauennummer</th>
                <th class="text-left">Hauptnummer</th>
                <th class="text-left">Info</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in midwifeInfo.midwifeContactList" :key="item.number">
                <td>{{ item.contactMedium }}</td>
                <td>{{ item.contactType }}</td>
                <td>{{ item.number }}</td>
                <td><checkitem :flag-item="item.forWoman"></checkitem></td>
                <td><checkitem :flag-item="item.mainnumber"></checkitem></td>
                <td>{{ item.info }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-card v-if="midwifeInfo.midwifeInfoList.length > 0">
          <v-card-title class="text-h5 grey lighten-2">Infos</v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead class="font-weight-thin">
              <tr>
                <th class="text-left">Datum</th>
                <th class="text-left">Benutzer</th>
                <th class="text-left">Info</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in midwifeInfo.midwifeInfoList" :key="item.number">
                <td><dateitem :date-item="item.infoDate"></dateitem></td>
                <td>{{ item.userInfo.shorthand }}</td>
                <td>{{ item.info }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-card v-if="midwifeInfo.midwifeSignOffList.length > 0">
          <v-card-title class="text-h5 grey lighten-2">Abmeldungen</v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">Typ</th>
                <th class="text-left">Von</th>
                <th class="text-left">Bis</th>
                <th class="text-left">Info</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in midwifeInfo.midwifeSignOffList" :key="item.number">
                <td>{{ item.signOffType }}</td>
                <td><dateitem :date-item="item.from"></dateitem></td>
                <td><dateitem :date-item="item.until"></dateitem></td>
                <td>{{ item.info }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2" :set="property=midwifeInfo.midwifeInquiryData">Präferenzen</v-card-title>
          <tbody>
            <tr>
              <td style="padding-left: 10px; padding-right: 10px;">Teilnahme an Vermittlung</td>
              <td><checkitem :flag-item="property.participateMediation"></checkitem></td>
            </tr>
            <tr>
              <td style="padding-left: 10px; padding-right: 10px;">Verfahren bei Benachrichtigung</td>
              <td>{{ property.contactProcedure }}</td>
            </tr>
            <tr>
              <td style="padding-left: 10px; padding-right: 10px;">Zusatzinfo bei Benachrichtigung</td>
              <td>{{ property.contactMailinfo }}</td>
            </tr>
            <tr>
              <td style="padding-left: 10px; padding-right: 10px;">Verfahren bei Zusage</td>
              <td>{{ property.promiseProcedure }}</td>
            </tr>
            <tr>
              <td style="padding-left: 10px; padding-right: 10px;">Zusatzinfo bei Zusage</td>
              <td>{{ property.promiseMailinfo }}</td>
            </tr>
          </tbody>

        </v-card>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="closeDialog"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import checkitem from '#enna/common/components/element/CheckItem'
import dateitem from '#enna/common/components/element/DateItem'

export default {
  name: 'MidwifeInfoDialog',

  components: {
    dateitem,
    checkitem
  },

  methods: {
    closeDialog () {
      this.$emit('onCancel')
      this.showDialog = false
    }
  },

  computed: {
    contentheight () {
      const height = this.$store.getters['size/currentHeight'] - 200
      return height
    }
  },

  watch: {
    propShowDialog (val) {
      this.showDialog = val
    }
  },

  props: {
    propShowDialog: { type: Boolean },
    midwifeInfo: { type: Object }
  },

  data () {
    return {
      showDialog: this.propShowDialog
    }
  }
}
</script>
