<template>
  <div>
    <div v-bind:style="{ 'overflow-y': 'auto', 'max-height': contentheight + 'px' }">
      <div v-for="item in adviceList" :key="item.activityId">
        <v-card style="padding-bottom: 30px;">
          <v-list-item-title class="text-h5 mb-1">
            <span class="font-weight-thin">Beratung am </span>
            <span class="font-weight-thick">{{ datetime(item.adviceTime) }}</span>
            <span v-if="item.duration" class="font-weight-thick"> ({{ item.duration }} Minuten) </span>
            <span class="font-weight-thin"> durch </span>
            <span class="font-weight-thick">{{ item.userName }}</span>
            <v-icon style="padding-left: 10px;" @click="onOpen(item)" color="blue">mdi-pencil</v-icon>
            <v-icon style="padding-left: 10px;" @click="onDelete(item)" color="blue">mdi-delete</v-icon>
            <br/>
            <div v-if="item.adviceThemeList.length > 0">
              <div class="font-weight-thin">Inhalt</div>
              <div v-for="theme in item.adviceThemeList" :key="theme" class="font-weight-thick" style="padding-left: 30px;">{{ theme }}</div>
            </div>
            <div v-if="item.info">
            <div class="font-weight-thin">Weitere Beratungsthemen</div>
              <span class="font-weight-thick" style="padding-left: 30px;">{{ item.info }}</span>
            </div>
          </v-list-item-title>
        </v-card>
      </div>
    </div>
    <formbuttonbar
      @onButtonOne="onNewAdvice" button-one-text="Neue Beratung">
    </formbuttonbar>
  </div>
</template>

<script>
import formbuttonbar from '@/common/components/element/FormButtonBar'
import CaseOverviewController from '@/module/concern/case/case.overview.controller'
import ConcernAdviceController from './concern.advice.controller'
import { formatDateTime } from '../../../common/utils/date.utils'

export default {
  name: 'ConcernAdviceOverview',

  components: {
    formbuttonbar
  },

  async created () {
    this.$pageInitController.initBack(this, this.$moduleInfoController.concern, 'Frau', 'Beratungen', this.womanName)
  },

  methods: {
    datetime (value) {
      return formatDateTime(value)
    },

    async back () {
      await CaseOverviewController.openCaseOverview(this.caseLocator)
    },

    async onOpen (item) {
      await ConcernAdviceController.loadConcernAdvice(this.caseLocator, item.pid)
    },

    async onDelete (item) {
      await ConcernAdviceController.deleteConcernAdvice(this.caseLocator, this.concernVersion, item.pid, Date.now())
    },

    async onNewAdvice () {
      await ConcernAdviceController.openConcernNewAdvice(this.caseLocator)
    }
  },

  computed: {
    contentheight: function () {
      return this.$store.getters['size/formContent']
    }
  },

  props: {
    caseLocator: { type: Object },
    womanName: { type: String },
    concernVersion: { type: Number },
    adviceList: { type: Array }
  }
}
</script>
