var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('formcontent', {
    attrs: {
      "ok-text": "Speichern",
      "cancel-text": "Abbrechen"
    },
    on: {
      "onOk": _vm.save,
      "onCancel": _vm.cancel
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-rows": "100px",
      "align-items": "end",
      "grid-template-columns": "2fr 2fr 1fr"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.contactMediumList,
      "item-text": "name",
      "item-value": "keylistValueId",
      "label": "Kontakttyp"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (data) {
        return [_c('v-chip', {
          key: JSON.stringify(data.item),
          staticClass: "chip--select-multi",
          attrs: {
            "input-value": data.selected
          },
          on: {
            "input": function ($event) {
              return data.parent.selectItem(data.item);
            }
          }
        }, [_c('v-avatar', {
          attrs: {
            "left": ""
          }
        }, [_c('v-icon', [_vm._v(_vm._s(data.item.icon))])], 1), _vm._v(" " + _vm._s(data.item.name) + " ")], 1)];
      }
    }, {
      key: "item",
      fn: function (data) {
        return [[_c('v-icon', [_vm._v(_vm._s(data.item.icon))]), _c('div', [_vm._v(_vm._s(data.item.name))])]];
      }
    }]),
    model: {
      value: _vm.dtoMidwifeContact.contactMedium,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeContact, "contactMedium", $$v);
      },
      expression: "dtoMidwifeContact.contactMedium"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.contactTypeList,
      "item-text": "name",
      "item-value": "id",
      "label": "Kontaktart"
    },
    model: {
      value: _vm.dtoMidwifeContact.contactType,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeContact, "contactType", $$v);
      },
      expression: "dtoMidwifeContact.contactType"
    }
  })], 1), _c('div', {
    staticStyle: {
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-rows": "100px",
      "align-items": "end",
      "grid-template-columns": "2fr 1fr 1fr 1fr"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nummer"
    },
    model: {
      value: _vm.dtoMidwifeContact.number,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeContact, "number", $$v);
      },
      expression: "dtoMidwifeContact.number"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Frauennummer"
    },
    model: {
      value: _vm.dtoMidwifeContact.forWoman,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeContact, "forWoman", $$v);
      },
      expression: "dtoMidwifeContact.forWoman"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Primärnummer"
    },
    model: {
      value: _vm.dtoMidwifeContact.mainnumber,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeContact, "mainnumber", $$v);
      },
      expression: "dtoMidwifeContact.mainnumber"
    }
  })], 1), _c('div', {
    staticStyle: {
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-rows": "100px",
      "align-items": "end",
      "grid-template-columns": "4fr 1fr"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Info"
    },
    model: {
      value: _vm.dtoMidwifeContact.info,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeContact, "info", $$v);
      },
      expression: "dtoMidwifeContact.info"
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }