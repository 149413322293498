import router from '#enna/common/router'
import MenuController from '../../app/menu/menu.controller'

export default {
  async openMidwifeSubstitutionOverview (midwifeId) {
    const midwifeName = 'response.data.midwifeName'
    const selectedTabIndex = await MenuController.getMidwifeFileStartTab('midwifesubstitutionoverview')
    router.push({ name: 'midwifesubstitutionoverview', params: { midwifeId: midwifeId, midwifeName: midwifeName, preselectedTab: selectedTabIndex } })
  }
}
