<template>
  <formcontent>
    <div style="
      display: grid;
      grid-gap: 20px;
      grid-template-rows:580px 1fr;
      grid-template-columns:1fr;">
        <v-stepper v-model="processStep">
          <v-stepper-header>
            <v-stepper-step v-show="showMidwife" :complete="processStep > stepMidwife" :step="stepMidwife">Hebammen</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step v-show="showCase" :complete="processStep > stepCase" :step="stepCase">Anfragen</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="processStep > stepTemplate" :step="stepTemplate">Mailvorlagen</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="processStep > stepSend" :step="stepSend">Versenden</v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content :step="stepMidwife">
              Hebamme
            </v-stepper-content>
            <v-stepper-content :step="stepCase">
              <div class="cards">
                <searchcard caption="Suchoptionen" :card-height="contentHeight - 140">
                  <v-select style="width: 500px;"
                            :items="activityGroupList"
                            item-text="name"
                            item-value="datagroupId"
                            v-model="caseOption.datagroupId"
                            label="Leistung">
                  </v-select>
                  <div class="containerx">
                    <datepicker style="width:150px;" title="Termin" :propdate.sync="caseOption.searchDate"/>
                    <span style="padding-top: 20px;" class="subtitle-1">Tage...</span>
                    <v-text-field style="width:80px;"
                                  v-model="caseOption.daysBeforeDate"
                                  type="number"
                                  label="vor"
                                  clearable
                    ></v-text-field>
                    <v-text-field style="width:80px;"
                                  v-model="caseOption.daysAfterDate"
                                  type="number"
                                  label="nach"
                                  clearable
                    ></v-text-field>
                    <span style="padding-top: 20px;" class="subtitle-1">...Geburt</span>
                  </div>
                  <v-select style="width: 500px;"
                            :items="caseOptionList"
                            item-text="caption"
                            item-value="id"
                            multiple
                            chips
                            v-model="caseOption.selectedCaseOptionList"
                            label="Anfragetyp">
                  </v-select>
                  <v-checkbox
                    v-model="caseOption.onlyOnWaitingList"
                    label="Nur Anfragen auf der Warteliste berücksichtigen"
                  ></v-checkbox>
                  <cmdbutton @cmdclick="searchCase" caption="Anfragen suchen"/>
                </searchcard>
                <div>
                  <searchcard :caption="'Suchergebnis  ' + displayCaseCount" :card-height="contentHeight - 140">
                    <div v-for="item in foundCaseList" :key="item.concernId">
                      <input type="checkbox" v-model="selectedCaseList" :value="item" />
                      <div>{{ item.woman.name }}</div>
                      <div style="padding-left: 20px;">
                        <div v-if="item.woman.region">{{ item.woman.region }}</div>
                        <div v-if="item.woman.edbd">
                          {{ item.woman.edbdMode }}: <dateitem style="display: inline;" :date-item="item.woman.edbd"></dateitem>
                          <div style="padding-left: 40px;" v-for="activity in item.circularMailCaseCandidateList" :key="activity.name">
                            {{ activity.name }} - {{ activity.state }}
                            <div style="padding-left: 60px;" v-for="activity in item.circularMailCaseCandidateList" :key="activity.name">
                              <div v-if="activity.acute">Akut</div>
                              <div v-if="activity.onWaitingList">Auf der Warteliste</div>
                              <div v-if="activity.useDeadline">ET / GT verwenden</div>
                              <div v-else>
                                <div v-if="activity.start">
                                  Ab <dateitem style="display: inline;" :date-item="activity.start"></dateitem>
                                </div>
                                <div v-if="activity.latest">
                                  Spätestens <dateitem style="display: inline;" :date-item="activity.latest"></dateitem>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </searchcard>
                </div>
              </div>
            </v-stepper-content>
            <v-stepper-content :step="stepTemplate">
              <v-card flat>
                <v-card-text>
                  <div style="
              padding-top: 0px;
              display: grid;
              grid-gap: 20px;
              grid-template-columns:5fr 2fr;">
                    <div v-bind:style="{ 'height': (contentHeight - 180) + 'px', 'overflow-y': 'auto' }">
                      <div style="padding-bottom: 10px;" class="font-weight-light text--primary">Aktuelle Mailvorlage</div>
                      <div>
                        <v-card flat light outlined class="mx-auto" >
                          <v-card-text style="
                        display: grid;
                        grid-gap: 20px;
                        grid-template-columns:1fr 1fr;">
                            <div>
                          <span class="font-weight-black text--primary">
                            {{ circularMailData.mailContent.viewMailTemplate.name }}
                          </span>
                              <div v-if="circularMailData.mailContent.viewMailTemplate.priorityTemplate">Hauptvorlage</div>
                            </div>
                            <div style="padding-top: 10px;" class="text--primary">
                              {{ circularMailData.mailContent.viewMailTemplate.description }}
                            </div>
                          </v-card-text>
                        </v-card>
                      </div>
                      <div>
                      </div>
                      <div style="padding-top: 20px;" >
                        <div style="background-color: #EDEDED">
                          <v-text-field v-model="changedMailContent.mailSubject" label="Betreff" hint="Betreffzeile der Mail"></v-text-field>
                          <v-textarea
                            style="width: 95%;"
                            clearable
                            clear-icon="mdi-close-circle"
                            label="Mailinhalt"
                            hint="Inhalt der Mailvorlage"
                            v-model="changedMailContent.mailContent"></v-textarea>
                        </div>
                        <cmdbutton @cmdclick="setChangedMailContentToOrg()" caption="Zurücksetzen"/>
                      </div>
                    </div>
                    <div>
                      <div style="padding-bottom: 10px;" class="font-weight-light text--primary">Alternative Mailvorlagen</div>
                      <div v-bind:style="{ 'overflow-y': 'auto', 'height': contentHeight + 'px' }" >
                        <div v-for="mailtemplate in circularMailData.alternativeMailTemplateList" :key="mailtemplate.pid">
                          <v-card flat light outlined class="mx-auto" >
                            <v-card-text>
                              <v-icon class="mr-2" @click="changeCaseTemplate(mailtemplate)" color="blue">mdi-play-circle-outline</v-icon>
                              <span class="font-weight-black text--primary">
                            {{ mailtemplate.name }}
                          </span>
                              <div v-if="mailtemplate.priorityTemplate">Hauptvorlage</div>
                              <div style="padding-top: 10px;" class="text--primary">
                                {{ mailtemplate.description }}
                              </div>
                            </v-card-text>
                          </v-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-stepper-content>
            <v-stepper-content :step="stepSend">
              <cmdbutton @cmdclick="generate" caption="Rundmail versenden" v-show="showSendButton"/>
              <div v-show="!showSendButton">Rundmail wird versendet</div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <div style="
          display: grid;
          grid-gap: 20px;
          grid-template-areas: 'nextbtn backbtn';
          grid-template-rows:2fr 1fr;
          grid-template-columns:1fr 1fr 1fr;">
          <cmdbutton style="grid-area:nextbtn" v-show="showButtonNext" @cmdclick="next()" caption="Weiter"/>
          <cmdbutton style="grid-area:backbtn" v-show="showButtonBack" @cmdclick="back()" caption="Zurück"/>
        </div>
    </div>
  </formcontent>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import CircularMailController from '../circularmail/concern.circularmail.controller'
import formcontent from '#enna/common/components/content/FormContent'
import datepicker from '../../../common/components/element/DatePicker'
import searchcard from '@/module/concern/search/SearchCard.vue'
import dateitem from '@/common/components/element/DateItem.vue'

export default {
  name: 'ConcernCircularMail',

  async created () {
    this.$pageInitController.init(this, this.$moduleInfoController.concern, 'Anfragen per Rundmail')
  },

  computed: {
    displayCaseCount () {
      if (this.foundCaseList) {
        return this.selectedCaseList.length + ' / ' + this.foundCaseList.length
      } else {
        return ''
      }
    },

    contentHeight () {
      const height = this.$store.getters['size/formContent']
      return height
    },

    stepMidwife () {
      if (this.showMidwife) {
        return 1
      } else {
        return 0
      }
    },

    stepTemplate () {
      return 2
    },

    stepSend () {
      return 3
    },

    stepCase () {
      if (this.showCase) {
        return 1
      } else {
        return 0
      }
    },

    maxStep () {
      return 3
    },

    showCase () {
      return this.mode === 'CASE'
    },

    showMidwife () {
      return this.mode === 'MIDWIFE'
    }
  },

  methods: {
    async searchCase () {
      this.foundCaseList = await CircularMailController.searchCase(this.caseOption)
      this.selectedCaseList = cloneDeep(this.foundCaseList)
    },

    async changeCaseTemplate (mailTemplate) {
      this.circularMailData = await CircularMailController.changeCaseTemplate(mailTemplate.mailtemplateId, this.selectedCaseList)
      this.orgMailContent = this.circularMailData.mailContent
      this.setChangedMailContent(this.orgMailContent)
    },

    async changeSelectedCases () {
      this.orgMailContent = await CircularMailController.changeSelectedCases(this.orgMailContent.viewMailTemplate.mailtemplateId, this.selectedCaseList)
      this.setChangedMailContent(this.orgMailContent)
    },

    async setChangedMailContent (orgMailContent) {
      if (orgMailContent) {
        const mailContent = {
          mailSubject: cloneDeep(orgMailContent.mailSubject),
          mailContent: cloneDeep(orgMailContent.mailContent)
        }
        this.changedMailContent = mailContent
      } else {
        this.changedMailContent = null
      }
    },

    async setChangedMailContentToOrg () {
      this.setChangedMailContent(this.orgMailContent)
    },

    async generate () {
      this.showSendButton = false
      await CircularMailController.sendCaseMail(this.orgMailContent.viewMailTemplate.mailtemplateId, this.selectedCaseList, this.changedMailContent.mailSubject, this.changedMailContent.mailContent, this.orgMailContent.hash)
    },

    selectedActivity (activity) {
      this.activity = activity
    },

    setLayoutAfterChanged () {
      if (this.processStep === 1) {
        this.showButtonBack = false
      } else {
        this.showButtonBack = true
      }

      if (this.processStep === this.maxStep) {
        this.showButtonNext = false
      } else {
        this.showButtonNext = true
      }
    },

    next () {
      this.processStep++
      if (this.processStep > this.maxStep) {
        this.processStep = this.maxStep
      }
      this.setLayoutAfterChanged()
    },

    back () {
      this.processStep--
      if (this.processStep < 1) {
        this.processStep = 1
      }
      this.setLayoutAfterChanged()
    }
  },

  props: {
    mode: String,
    activityGroupList: { type: Array },
    caseOptionList: { type: Array },
    circularMailData: { type: Object }
  },

  watch: {
    selectedCaseList (val) {
      this.changeSelectedCases()
    }
  },

  data () {
    return {
      orgMailContent: this.circularMailData.mailContent,
      changedMailContent: {
        mailSubject: cloneDeep(this.circularMailData.mailContent.mailSubject),
        mailContent: cloneDeep(this.circularMailData.mailContent.mailContent)
      },
      showSendButton: true,
      foundCaseList: [],
      selectedCaseList: [],
      caseOption: {
        selectedCaseOptionList: null,
        datagroupId: null,
        searchDate: null,
        onlyOnWaitingList: false,
        daysBeforeDate: null,
        daysAfterDate: null
      },
      showButtonNext: true,
      showButtonBack: false,
      processStep: 1
    }
  },

  components: {
    dateitem,
    searchcard,
    datepicker,
    formcontent
  }
}
</script>
<style>
.containerx {
  display: grid;
  grid-template-columns: 3fr 1fr 2fr 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". . . ."
    ". . . .";
  align-content: end;
}
.cards {
  display: grid;
  height: 100vh;
  grid-template-columns: 4fr 4fr;
  grid-template-rows: 1fr;
}</style>
