<template>
  <div>
    <formcontent
      cancel-text="Schließen" @onCancel="cancel"
      buttonOneText="Vergleich mobile Leistungen"
      @onButtonOne="mobileCompare"
    >
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:1fr;
        grid-template-columns:2fr 1fr;">
        <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:380px 1fr;
        grid-template-columns:1fr;">
          <v-stepper style="width:700px;" v-model="processStep">
            <v-stepper-header>
              <v-stepper-step :complete="processStep > 1" step="1">Leistung(en)<small>auswählen</small></v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="processStep > 2" step="2">Stadtteil(e)<small>auswählen</small></v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="processStep > 3" step="3">Umkreis</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="processStep > 4" step="4">Hinzüfgen/Entfernen</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-select
                  v-model="selectedActivityList"
                  :items="mobileActivityList"
                  item-text="activity.name"
                  return-object
                  :menu-props="{ maxHeight: '400' }"
                  label="Leistungen"
                  multiple
                  chips
                  deletable-chips
                  hint="Leistungen, zu denen Stadtteile hinzugefügt bzw. entfernt werden sollen"
                  persistent-hint>
                </v-select>
              </v-stepper-content>
              <v-stepper-content step="2">
                <div style="height: 300px; overflow: scroll;">
                  <v-list v-for="group in regionList" :key="group.name">
                    <v-divider></v-divider>
                    <label class="font-weight-medium title display-1">{{ group.name }}</label>
                    <v-list style="
                    display: grid;
                    grid-auto-flow: row;
                    grid-template-rows: 1fr;
                    grid-template-columns: 1fr 1fr 1fr;
                  ">
                      <v-list-item dense v-for="item in group.itemList" :key="item.id">
                        <v-checkbox dense v-model="selectedRegionList" multiple :value="item" :label="item.name"/>
                      </v-list-item>
                    </v-list>
                  </v-list>
                </div>
              </v-stepper-content>
              <v-stepper-content step="3">
                <div style="
                    margin-top: 120px;
                    display: grid;
                    grid-column-gap: 10px;
                    justify-items: center;
                    grid-template-rows: 1fr;
                    grid-template-columns: 1fr 1fr;
                  ">
                  <v-radio-group v-model="selectedSurrounding" column >
                    <v-radio
                      :label=closeSurrounding.caption
                      :value=closeSurrounding
                    ></v-radio>
                    <v-radio
                      :label=wideSurrounding.caption
                      :value=wideSurrounding
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-stepper-content>
              <v-stepper-content step="4">
                <div style="
                    margin-top: 120px;
                    display: grid;
                    grid-column-gap: 10px;
                    justify-items: center;
                    grid-template-rows: 1fr;
                    grid-template-columns: 1fr 1fr;
                  ">
                  <cmdbutton @cmdclick="addRegion" caption="Stadtteile hinzufügen"/>
                  <cmdbutton @cmdclick="removeRegion" caption="Stadtteile entfernen"/>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-areas: 'nextbtn backbtn';
        grid-template-rows:2fr 1fr;
        grid-template-columns:1fr 1fr 1fr;">
            <cmdbutton style="grid-area:nextbtn" v-show="showButtonNext" @cmdclick="next()" caption="Weiter"/>
            <cmdbutton style="grid-area:backbtn" v-show="showButtonBack" @cmdclick="back()" caption="Zurück"/>
          </div>
        </div>
        <div style="
        display: grid;
        grid-template-rows:150px 200px;
        grid-template-columns:1fr;">
          <div style="height: 140px;overflow-y:auto; ">
            <div class="font-weight-light">Leistung(en)</div>
            <div v-for="activity in selectedActivityList" :key="activity.activityId" class="font-weight-medium" style="padding-left: 15px;">{{ activity.activity.name }}</div>
          </div>
          <div style="height: 280px;overflow-y:auto;">
            <div class="font-weight-light">Stadtteil(e)</div>
            <div v-for="region in selectedRegionList" :key="region.regionId" class="font-weight-medium" style="padding-left: 15px;">{{ region.name }}</div>
          </div>
          <div style="height: 280px;overflow-y:auto;">
            <div class="font-weight-light">Umkreis</div>
            {{ selectedSurrounding.caption }}
          </div>
        </div>
      </div>
    </formcontent>
    <mobileactivitycomparison :midwife-name="midwifeName" :midwife-id="midwifeId" :propshowdialog.sync="mobileActivityComparison"></mobileactivitycomparison>
  </div>
</template>

<script>
import MobileActivityController from './mobile.activity.controller'
import formcontent from '#enna/common/components/content/FormContent'
import MidwifeActivityController from '../overview/midwife.activity.controller'
import mobileactivitycomparison from './MobileActivityCompare'

export default {
  name: 'MobileActivityModify',

  async created () {
    this.$pageInitController.initReload(this, this.$moduleInfoController.midwife, 'Hebammenleistung', 'Mobile Leistungen bearbeiten', this.midwifeName)
  },

  methods: {
    surroundingCaption () {

    },

    async mobileCompare () {
      this.mobileActivityComparison = true
    },

    cancel () {
      MidwifeActivityController.openMidwifeActivityOverview(this.midwifeId)
    },

    async addRegion () {
      MobileActivityController.addRegion(this.midwifeId, this.selectedActivityList, this.selectedRegionList, this.selectedSurrounding.close)
      this.resetLayout()
    },

    async removeRegion () {
      MobileActivityController.removeRegion(this.midwifeId, this.selectedActivityList, this.selectedRegionList, this.selectedSurrounding.close)
      this.resetLayout()
    },

    next () {
      this.processStep++
      if (this.processStep > 4) {
        this.processStep = 4
      }
      this.setLayoutAfterChanged()
    },

    back () {
      this.processStep--
      if (this.processStep < 1) {
        this.processStep = 1
      }
      this.setLayoutAfterChanged()
    },

    resetLayout () {
      this.selectedSurrounding = { }
      this.selectedActivityList = []
      this.selectedRegionList = []
      this.showButtonNext = true
      this.showButtonBack = false
      this.processStep = 1
    },

    setLayoutAfterChanged () {
      if (this.processStep === 1) {
        this.showButtonBack = false
      } else {
        this.showButtonBack = true
      }

      if (this.processStep === 4) {
        this.showButtonNext = false
      } else {
        this.showButtonNext = true
      }
    },

    async reload () {
      this.resetLayout()
      await MobileActivityController.openModifyActivity(this.midwifeId, Date.now())
    }
  },

  props: {
    regionList: { type: Array },
    mobileActivityList: { type: Array },
    midwifeName: { type: String },
    midwifeId: { type: String }
  },

  data () {
    return {
      closeSurrounding: { caption: 'Enger Umkreis', close: true },
      wideSurrounding: { caption: 'Weiterer Umkreis', close: false },
      mobileActivityComparison: false,
      selectedSurrounding: { },
      selectedActivityList: [],
      selectedRegionList: [],
      showButtonNext: true,
      showButtonBack: false,
      processStep: 1
    }
  },

  components: {
    mobileactivitycomparison,
    formcontent
  }
}
</script>
