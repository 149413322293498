import Vue from 'vue'
import App from './module/app/main/App.vue'
import router from './common/router'
import store from './common/store'
import vuetify from './plugins/vuetify'
import LayoutController from './common/components/layout/layout.controller'
import PageInitController from './common/components/layout/pageinit.controller'
import ModuleInfoController from './module/app/moduleinfo/moduleinfo.controller'
import { formatDate, formatDateTime, formatWeekdayDate } from './common/utils/date.utils'
import cmdbutton from './common/components/element/CmdButton'

Vue.config.productionTip = false
Vue.prototype.$layoutController = LayoutController
Vue.prototype.$pageInitController = PageInitController
Vue.prototype.$moduleInfoController = ModuleInfoController
Vue.component('cmdbutton', cmdbutton)
Vue.filter('formattedDate', (value) => {
  return formatDate(value)
})
Vue.filter('formattedDatetime', (value) => {
  return formatDateTime(value)
})
Vue.filter('formattedWeekdayDate', (value) => {
  return formatWeekdayDate(value)
})

const ennaApp = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

export default ennaApp
