var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "grid",
      "grid-template-columns": "4fr 3fr"
    }
  }, [_c('div', [_c('searchcard', {
    attrs: {
      "caption": "Aktueller Status",
      "set": _vm.state = _vm.caseFileData.caseFileState
    }
  }, [_c('div', {
    staticClass: "font-weight-thin overline"
  }, [_vm._v(_vm._s(_vm.state.state))]), _c('div', {
    staticClass: "font-weight-medium text-body-1",
    staticStyle: {
      "padding-bottom": "10px"
    }
  }, [_vm._v(_vm._s(_vm.state.stateAction))]), _c('v-icon', [_vm._v(_vm._s(_vm.getTodoIcon(_vm.state.todoIcon)))]), _c('span', {
    staticClass: "font-weight-bold ma-3 text-body-2"
  }, [_vm._v(_vm._s(_vm.state.todo))]), _vm.state.midwife != null ? _c('div', {
    staticStyle: {
      "padding-top": "10px",
      "padding-left": "50px"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "blue"
    },
    on: {
      "click": function ($event) {
        return _vm.showMidwifeInfo(_vm.state.midwife.midwifeId);
      }
    }
  }, [_vm._v("mdi-phone-plus")]), _c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(_vm._s(_vm.state.midwife.name))])], 1) : _vm._e()], 1), _c('searchcard', {
    attrs: {
      "caption": "Nächste Aktion"
    }
  }, [_c('div', {
    staticStyle: {
      "text-align": "center"
    }
  }, _vm._l(_vm.caseFileData.mainCaseList, function (mainCaseAction) {
    return _c('span', {
      key: mainCaseAction.mainCase
    }, [_c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on;
          return [_c('v-icon', _vm._g({
            staticClass: "mr-4",
            attrs: {
              "color": "blue",
              "large": ""
            },
            on: {
              "click": function ($event) {
                return _vm.executeAction(mainCaseAction.viewCaseActionOption);
              }
            }
          }, on), [_vm._v(_vm._s(_vm.getMainActionIcon(mainCaseAction)))])];
        }
      }], null, true)
    }, [_c('span', [_vm._v(_vm._s(mainCaseAction.viewCaseActionOption.caseActionCaption))])])], 1);
  }), 0), _c('div', {
    staticStyle: {
      "max-height": "300px",
      "overflow": "scroll"
    }
  }, _vm._l(_vm.caseFileData.possibleActionList, function (group) {
    return _c('div', {
      key: group.caseActionGroupType,
      staticStyle: {
        "padding-bottom": "20px"
      }
    }, [_c('div', {
      staticStyle: {
        "background-color": "#f0f0f0"
      }
    }, [_vm._v(" " + _vm._s(group.caseActionGroupTypeCaption) + " ")]), _vm._l(group.caseActionOptionList, function (option) {
      return _c('div', {
        key: option.tempId,
        staticStyle: {
          "display": "grid",
          "grid-template-columns": "1fr 7fr",
          "padding-left": "20px",
          "padding-bottom": "5px"
        }
      }, [_c('span', [_c('v-icon', {
        staticClass: "mr-2",
        attrs: {
          "color": "blue"
        },
        on: {
          "click": function ($event) {
            return _vm.executeAction(option);
          }
        }
      }, [_vm._v("mdi-play-circle-outline")])], 1), _c('span', [_vm._v(" " + _vm._s(option.caseActionCaption) + " "), option.refInfo ? _c('div', {
        staticStyle: {
          "padding-left": "50px"
        }
      }, [_c('div', [_vm._v(_vm._s(option.refInfo.caseActionCaption))]), _c('div', [_vm._v(_vm._s(option.refInfo.info))])]) : _vm._e()])]);
    })], 2);
  }), 0)]), _c('searchcard', {
    staticClass: "caseinfo",
    attrs: {
      "caption": "Info"
    }
  }, [_c('caseinfo', {
    attrs: {
      "concernId": _vm.caseLocator.concernId,
      "initialCaseId": _vm.caseLocator.caseId,
      "versionId": _vm.concernVersion,
      "info-list": _vm.caseFileData.infoList
    },
    on: {
      "reloadParent": _vm.reloadCaseFile
    }
  })], 1)], 1), _c('div', [_vm.caseFileData.originSearchData != null ? _c('searchcard', {
    attrs: {
      "caption": "Daten zur Anfrage"
    }
  }, [_c('caseparameter', {
    attrs: {
      "layout": "block",
      "set": _vm.origin = this.caseFileData.originSearchData,
      "location": _vm.origin.region,
      "bdate": _vm.origin.bdate,
      "edate": _vm.origin.edate,
      "activitySearchParamList": _vm.origin.activityList
    }
  })], 1) : _vm._e(), _c('searchcard', {
    attrs: {
      "caption": "Verlauf"
    }
  }, [_c('casehistory', {
    style: {
      'transform': 'scale(1)',
      'overflow-y': 'auto',
      'max-height': _vm.contentheight + 'px'
    },
    attrs: {
      "historyList": _vm.caseFileData.historyList
    }
  })], 1)], 1), _c('tododialog', {
    attrs: {
      "prop-case-action-caption": _vm.textCaseActionCaption,
      "propShowDialog": _vm.showTextDialog
    },
    on: {
      "onCancelText": _vm.onCancelText,
      "onAcceptedText": _vm.onAcceptedText,
      "update:propShowDialog": function ($event) {
        _vm.showTextDialog = $event;
      },
      "update:prop-show-dialog": function ($event) {
        _vm.showTextDialog = $event;
      }
    }
  }), _c('midwifeInfoDialog', {
    attrs: {
      "midwife-info": _vm.midwifeInfo,
      "propShowDialog": _vm.showMidwifeInfoDialog
    },
    on: {
      "onCancel": _vm.onCancelMidwifeInfo,
      "update:propShowDialog": function ($event) {
        _vm.showMidwifeInfoDialog = $event;
      },
      "update:prop-show-dialog": function ($event) {
        _vm.showMidwifeInfoDialog = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }