<template>
  <div>
    <div style="display: grid; grid-template-columns:1fr 5fr 11fr 1fr;">
      <div :style="{'display': 'table-cell', 'width':'56px', 'height':'56px', 'background-color': moduleinfo.colour }"></div>
      <div :style="{'display': 'table-cell', 'padding-left': '10px', 'vertical-align': 'top' }">
        <div>
          <h3 class="font-weight-bold title">{{ moduleinfo.theme }}</h3>
          <h4 class="font-weight-light" >{{ moduleinfo.detail }}</h4>
        </div>
      </div>
      <h2 class="font-weight-black font-italic" :style="{ 'padding-left': '66px', 'padding-top': '10px' }">{{ moduleinfo.name }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Moduleinfo',

  computed: {
    moduleinfo: function () {
      return this.$store.state.moduleinfo.moduleinfo
    }
  }
}
</script>
