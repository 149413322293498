<template>
  <div>
    <tablecontent
      tablecontentKeyId="pid"
      objectIdName="midwifeId"
      :data-list="midwifeList"
      :headers="headers"
      :propFilterKey="propFilterKey"
      :propFilterParam="propFilterParam"
      :withRemove="true"
      @remove="deleteMidwife"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import tablecontent from '#enna/common/components/content/TableContent'
import MidwifeOverviewController from './midwife.overview.controller'

export default {
  name: 'CancelMidwife',

  async created () {
    this.$pageInitController.init(this, this.$moduleInfoController.midwife, 'Hebammen', 'Hebamme löschen')
  },

  components: {
    tablecontent
  },

  props: {
    propFilterKey: { type: String },
    propFilterParam: { type: Object },
    propMidwifeList: { type: Array }
  },

  data () {
    return {
      midwifeList: cloneDeep(this.propMidwifeList),
      headers: [
        { text: 'Id', value: 'pid' },
        { text: 'Nachname', value: 'firstname' },
        { text: 'Vorname', value: 'secondname' },
        { text: 'Wohnort', value: 'location' },
        { text: 'Id der Hebamme', value: 'midwifeId' }
      ]
    }
  },

  methods: {
    async deleteMidwife (midwife) {
      this.midwifeList = await MidwifeOverviewController.deleteReadMidwife(midwife.pid)
    }
  }
}
</script>
