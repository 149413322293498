var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    style: {
      'overflow-y': 'auto',
      'max-height': _vm.contentheight + 'px'
    }
  }, [_c('v-expansion-panels', _vm._l(_vm.sendedMailList, function (mail) {
    return _c('v-expansion-panel', {
      key: mail.pid,
      staticStyle: {
        "max-width": "100%"
      }
    }, [_c('v-expansion-panel-header', [_c('span', {
      staticStyle: {
        "display": "grid",
        "grid-template-columns": "1fr 5fr 4fr"
      }
    }, [_c('span', [_vm._v(_vm._s(_vm._f("formattedDate")(mail.sendDate)))]), _c('span', [_c('label', {
      staticClass: "mailstyle"
    }, [_vm._v("Vorlage: ")]), _vm._v(_vm._s(mail.caption)), mail.templateChanged ? _c('span', [_vm._v(" (Vorlage geändert)")]) : _vm._e()]), _vm.type === 'woman' ? _c('span', [mail.midwifeName ? _c('span', [_c('label', {
      staticClass: "mailstyle"
    }, [_vm._v("Hebamme: ")]), _vm._v(" " + _vm._s(mail.midwifeName))]) : _vm._e()]) : _vm.type === 'midwife' ? _c('span', [mail.womanName ? _c('span', [_c('label', {
      staticClass: "mailstyle"
    }, [_vm._v("Anfrage: ")]), _vm._v(" " + _vm._s(mail.womanName))]) : _vm._e()]) : _vm._e()])]), _c('v-expansion-panel-content', [_c('span', {
      staticStyle: {
        "display": "grid",
        "gap": "20px",
        "grid-template-columns": "2fr 1fr"
      }
    }, [_c('div', [_c('label', {
      staticClass: "mailstyle"
    }, [_vm._v("Mailtype: ")]), _c('div', {
      staticStyle: {
        "padding-bottom": "10px"
      }
    }, [_vm._v(_vm._s(mail.mailtemplateCaption))]), _c('div', {
      staticStyle: {
        "background-color": "#EDEDED"
      }
    }, [_c('label', {
      staticClass: "mailstyle"
    }, [_vm._v("Betreff: ")]), _c('div', {
      staticStyle: {
        "padding-bottom": "10px"
      }
    }, [_vm._v(_vm._s(mail.subject))]), _c('label', {
      staticClass: "mailstyle"
    }, [_vm._v("Inhalt: ")]), _c('div', {
      staticStyle: {
        "white-space": "pre-wrap"
      }
    }, [_vm._v(_vm._s(mail.content))])])]), _c('div', [_c('label', {
      staticClass: "mailstyle"
    }, [_vm._v("Verschickt von: ")]), _c('div', [_vm._v(_vm._s(mail.userName))]), _vm.type === 'woman' ? _c('div', [mail.midwifeName ? _c('div', {
      staticStyle: {
        "padding-top": "20px"
      }
    }, [_c('label', {
      staticClass: "mailstyle"
    }, [_vm._v("Verschickt an Hebamme: ")]), _c('div', [_vm._v(_vm._s(mail.midwifeName))]), _c('div', [_vm._v(_vm._s(mail.midwifeAdressat))])]) : _vm._e(), mail.concernAdressat ? _c('div', {
      staticStyle: {
        "padding-top": "20px"
      }
    }, [_c('label', {
      staticClass: "mailstyle"
    }, [_vm._v("Verschickt an Frau: ")]), _c('div', [_vm._v(_vm._s(mail.concernAdressat))])]) : _vm._e()]) : _vm.type === 'midwife' ? _c('div', [mail.concernAdressat ? _c('div', {
      staticStyle: {
        "padding-top": "20px"
      }
    }, [_c('label', {
      staticClass: "mailstyle"
    }, [_vm._v("Verschickt an Frau: ")]), _c('div', [_vm._v(_vm._s(mail.womanName))]), _c('div', [_vm._v(_vm._s(mail.concernAdressat))])]) : _vm._e(), mail.midwifeAdressat ? _c('div', {
      staticStyle: {
        "padding-top": "20px"
      }
    }, [_c('label', {
      staticClass: "mailstyle"
    }, [_vm._v("Verschickt an Hebamme: ")]), _c('div', [_vm._v(_vm._s(mail.midwifeAdressat))])]) : _vm._e()]) : _vm._e()])])])], 1);
  }), 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }