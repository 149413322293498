<template>
  <div>
    <dynpropbasedetail :dynPropParam="dynPropParam" @onSave="save" >
      <v-text-field style="width:140px;"
        v-model="intValue"
        type="number"
        label="Wert"
        clearable
      ></v-text-field>
    </dynpropbasedetail>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import DynPropController from './dynprop.controller'
import dynpropbasedetail from './DynPropBaseDetail'

export default {
  name: 'DynPropIntDetail',

  methods: {
    save () {
      DynPropController.saveInt(this.dynPropParam, this.intValue)
    }
  },

  watch: {
    dynPropParam (val) {
      this.intValue = cloneDeep(this.dynPropParam.dtoDynPropData.value)
    }
  },

  props: {
    dynPropParam: { type: Object }
  },

  data () {
    return {
      intValue: cloneDeep(this.dynPropParam.dtoDynPropData.value)
    }
  },

  components: {
    dynpropbasedetail
  }
}
</script>
