var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    style: {
      'height': _vm.cardHeight + 'px',
      'overflow': 'hidden'
    }
  }, [_c('v-card', {
    attrs: {
      "height": _vm.cardHeight,
      "outlined": ""
    }
  }, [_c('v-app-bar', {
    staticClass: "grey lighten-2",
    attrs: {
      "height": "30"
    }
  }, [_c('v-toolbar-title', {
    staticClass: "text-h8 white--text"
  }, [_vm._v(" " + _vm._s(_vm.caption) + " ")])], 1), _c('v-card-text', [_c('div', {
    style: {
      'overflow-y': 'auto',
      'max-height': this.cardHeight - 50 + 'px'
    }
  }, [_vm._t("default")], 2)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }