<template>
  <div>
    <v-navigation-drawer style="width: 500px;background-color: aliceblue;" v-model="drawer" @input="onChange" :clipped="$vuetify.breakpoint.lgAndUp" app >
    <v-list dense>
      <template v-for="item in menu">
        <v-list-group v-if="item.children"
                      :key="item.text" v-model="item.model" :prepend-icon="item.model ? item.icon : item.icon_alt"
                      append-icon="" no-action>
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-content><v-list-item-title class="font-weight-light">{{ item.text }}</v-list-item-title></v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item v-for="(child, i) in item.children" :key="i" link @click="onItemClicked(child)">
            <v-list-item-action v-if="child.icon"><v-icon>{{ child.icon }}</v-icon></v-list-item-action>
            <v-list-item-content><v-list-item-title>{{ child.text }}</v-list-item-title></v-list-item-content>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import menuController from './menu.controller'

export default {
  name: 'MenuPanel',

  async mounted () {
    this.menu = await menuController.getMenu()
  },

  methods: {
    onItemClicked (child) {
      this.drawer = false
      child.openRoute()
    },

    onChange () {
      this.$emit('update:propDrawer', this.drawer)
    }
  },

  props: {
    propDrawer: Boolean
  },

  watch: {
    propDrawer (val) {
      this.drawer = val
    }
  },

  data () {
    return {
      drawer: this.propDrawer,
      menu: []
    }
  }
}
</script>
