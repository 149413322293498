<template>
  <div>
    <sendmailoverview type="midwife" :sendedMailList="sendedMailList"></sendmailoverview>
  </div>
</template>

<script>
import sendmailoverview from '../../concern/mail/SendMailOverview'
import MidwifeOverviewController from '../overview/midwife.overview.controller'

export default {
  name: 'MidwifeMailPage',

  async created () {
    this.$pageInitController.initBack(this, this.$moduleInfoController.midwife, 'Hebamme', 'Mails', this.midwifeName)
  },

  methods: {
    async back () {
      await MidwifeOverviewController.openMidwifeOverview(this.midwifeId)
    }
  },

  components: {
    sendmailoverview
  },

  props: {
    midwifeName: { type: String },
    midwifeId: { type: String },
    sendedMailList: { type: Array }
  }
}
</script>
