<template>
  <div>
    <dynpropbasedetail :dynPropParam="dynPropParam" @onSave="save" >
      <v-radio-group v-model="selected">
        <v-radio label="ja" :value="true"> </v-radio>
        <v-radio label="nein" :value="false" ></v-radio>
      </v-radio-group>
    </dynpropbasedetail>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import DynPropController from './dynprop.controller'
import dynpropbasedetail from './DynPropBaseDetail'

export default {
  name: 'DynPropBooleanDetail',

  methods: {
    save () {
      DynPropController.saveBoolean(this.dynPropParam, this.selected)
    }
  },

  watch: {
    dynPropParam (val) {
      this.selected = cloneDeep(this.dynPropParam.dtoDynPropData.value)
    }
  },

  props: {
    dynPropParam: { type: Object }
  },

  data () {
    return {
      selected: cloneDeep(this.dynPropParam.dtoDynPropData.value)
    }
  },

  components: {
    dynpropbasedetail
  }
}
</script>
