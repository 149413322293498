<template>
  <div>
    <formcontent
      ok-text="Speichern" @onOk="save"
      cancel-text="Abbrechen" @onCancel="cancel"
      write-right="midMasterdataWrite">
      <div style="
      display: grid;
      align-items: end;
      grid-gap: 20px;
      grid-template-rows:100px;
      grid-template-columns:1fr 1fr 1fr;">
        <v-text-field v-model="dtoMidwifeMasterdata.prename" label="Vorname"></v-text-field>
        <v-text-field v-model="dtoMidwifeMasterdata.surname" label="Zuname"></v-text-field>
        <datepicker title="Geburtsdatum" :propdate.sync="dtoMidwifeMasterdata.birthday"/>
      </div>
      <div style="
      display: grid;
      align-items: end;
      grid-gap: 20px;
      grid-template-rows:100px;
      grid-template-columns:150px 1fr;">
        <v-checkbox v-model="dtoMidwifeMasterdata.practise" label="Praxis" style="width: 40px;"></v-checkbox>
        <v-text-field v-model="dtoMidwifeMasterdata.practiseName" label="Praxisname"></v-text-field>
      </div>
      <div style="
      display: grid;
      align-items: end;
      grid-gap: 20px;
      grid-template-rows:100px;
      grid-template-columns:100px 1fr 1fr;">
        <v-text-field v-model="dtoMidwifeMasterdata.postcode" label="Postleitzahl"></v-text-field>
        <v-text-field v-model="dtoMidwifeMasterdata.city" label="Stadt"></v-text-field>
        <v-text-field v-model="dtoMidwifeMasterdata.streetnumber" label="Straße / Hausnummer"></v-text-field>
      </div>
      <div style="
      display: grid;
      grid-gap: 20px;
      grid-template-rows:100px;
      align-items: end;
      grid-template-columns:1fr 1fr 1fr;">
        <v-text-field v-model="dtoMidwifeMasterdata.district" label="Stadtteil"></v-text-field>
        <singleselection style=" margin-bottom: -6px;" :selectedId.sync="dtoMidwifeMasterdata.locationId" :prop-selected-item="dtoMidwifeMasterdata.locationId" caption="Standort" idName="regionId" :propItemList="regionList"></singleselection>
      </div>
      <div style="
      display: grid;
      grid-template-rows:100px;
      align-items: end;
      grid-gap: 20px;
      grid-template-columns:1fr 3fr 1fr;">
        <v-text-field v-model="memberSince" :readonly="true"  label="Teilnahme seit"></v-text-field>
        <v-text-field v-model="dtoMidwifeMasterdata.homepage" label="Homepage"></v-text-field>
      </div>
    </formcontent>
  </div>
</template>

<script>
import formcontent from '../../../common/components/content/FormContent'
import cloneDeep from 'lodash/cloneDeep'
import singleselection from '../../../common/components/element/SingleSelect'
import datepicker from '../../../common/components/element/DatePicker'
import MidwifeMasterdataController from './midwife.masterdata.controller'
import { formatDate } from '../../../common/utils/date.utils'

export default {
  name: 'MidwifeMasterdataDetail',

  async created () {
    this.$pageInitController.initReload(this, this.$moduleInfoController.midwife, 'Hebamme', 'Stammdaten', this.midwifeName)
  },

  components: {
    datepicker,
    singleselection,
    formcontent
  },

  watch: {
    propDtoMidwifeMasterdata (val) {
      this.dtoMidwifeMasterdata = cloneDeep(this.propDtoMidwifeMasterdata)
    }
  },

  methods: {
    async reload () {
      await MidwifeMasterdataController.openDtoMidwifeMasterdataOverview(this.dtoMidwifeMasterdata.pid, Date.now())
    },
    async save () {
      await MidwifeMasterdataController.saveMidwifeMasterdataOverview(this.dtoMidwifeMasterdata)
    },
    async cancel () {
      await MidwifeMasterdataController.openMidwifeMasterdataOverview(this.dtoMidwifeMasterdata.midwifeId)
    }
  },

  computed: {
    memberSince () {
      return formatDate(this.dtoMidwifeMasterdata.memberSince)
    }
  },

  data () {
    return {
      dtoMidwifeMasterdata: cloneDeep(this.propDtoMidwifeMasterdata)
    }
  },

  props: {
    regionList: { type: Array },
    midwifeName: { type: String },
    propDtoMidwifeMasterdata: { type: Object }
  }
}
</script>
