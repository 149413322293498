import ApiService from '../../../common/service/api/api.service'
import VersionController from '../../../module/app/versioning/versioning.controller'

const MailTemplateService = {
  async load (pid) {
    const result = await ApiService.doGet('/enna/superior/mailtemplate/' + pid)
    return result
  },

  async preview (previewParam) {
    const result = await ApiService.doPost('/enna/superior/mailtemplate/preview', previewParam)
    return result
  },

  async new () {
    const result = await ApiService.doGet('/enna/superior/mailtemplate/new')
    return result
  },

  async delete (pid) {
    const result = await ApiService.doDelete('/enna/superior/mailtemplate/' + pid)
    return result
  },

  async save (dtoMailTemplate) {
    const result = await ApiService.doPost('/enna/superior/mailtemplate', dtoMailTemplate)
    return result
  },

  async query () {
    const latestData = await VersionController.getLatestData('mailTemplate')
    if (latestData === null) {
      const result = await ApiService.doGet('/enna/superior/mailtemplate/query')
      VersionController.setLatestData('mailTemplate', result.data)
      return result.data.data
    } else {
      return latestData.data
    }
  }
}

export default MailTemplateService
