import store from '../../../common/store'
import VersioningService from './versioning.service'

export default {
  async getLatestData (versionId) {
    const versionData = store.getters['versioning/getVersion'](versionId)
    if (versionData) {
      const curData = await VersioningService.loadVersion(versionId)
      if (curData === versionData.version) {
        return versionData
      }
    }
    store.dispatch('versioning/resetVersion', { versionId })
    return null
  },

  setLatestData (versionId, data) {
    store.dispatch('versioning/setVersion', { versionId, data })
  },

  async getLatestDataWithParam (realVersionId, param) {
    const versionId = realVersionId + '#' + param
    const versionData = store.getters['versioning/getVersion'](versionId)
    if (versionData) {
      const curData = await VersioningService.loadVersion(realVersionId, param)
      if (curData === versionData.version) {
        return versionData
      }
    }
    store.dispatch('versioning/resetVersion', { versionId })
    return null
  },

  setLatestDataWithParam (realVersionId, param, data) {
    this.setLatestData(realVersionId + '#' + param, data)
  }
}
