var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('formcontent', {
    attrs: {
      "ok-text": "Speichern",
      "cancel-text": "Abbrechen",
      "write-right": "concernWrite"
    },
    on: {
      "onOk": _vm.save,
      "onCancel": _vm.cancel
    }
  }, [_vm.newMode ? _c('div', [_c('singleselection', {
    staticStyle: {
      "margin-bottom": "-6px",
      "max-width": "400px"
    },
    attrs: {
      "selectedId": _vm.activityId,
      "caption": "Leistung",
      "idName": "activityId",
      "propItemList": _vm.activityList
    },
    on: {
      "update:selectedId": function ($event) {
        _vm.activityId = $event;
      },
      "update:selected-id": function ($event) {
        _vm.activityId = $event;
      }
    }
  })], 1) : _c('div', [_vm._v(" " + _vm._s(_vm.addInfo.activityName) + " ")]), _c('v-select', {
    staticStyle: {
      "max-width": "400px",
      "padding-top": "40px"
    },
    attrs: {
      "items": _vm.startStateList,
      "item-text": "caption",
      "item-value": "keylistValueId",
      "label": "Initiative"
    },
    model: {
      value: _vm.startStateId,
      callback: function ($$v) {
        _vm.startStateId = $$v;
      },
      expression: "startStateId"
    }
  }), _vm.addInfo.finishAble ? _c('div', [_c('v-select', {
    staticStyle: {
      "max-width": "400px"
    },
    attrs: {
      "items": _vm.finishStateList,
      "item-text": "caption",
      "item-value": "keylistValueId",
      "label": "Abschluß",
      "clearable": ""
    },
    model: {
      value: _vm.manualFinishState,
      callback: function ($$v) {
        _vm.manualFinishState = $$v;
      },
      expression: "manualFinishState"
    }
  })], 1) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "grid",
      "align-items": "start",
      "grid-template-columns": "2fr 3fr 1fr"
    }
  }, [_c('div', [_c('datepicker', {
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "title": "Start Leistungserbringung ab",
      "propdate": _vm.startDate
    },
    on: {
      "update:propdate": function ($event) {
        _vm.startDate = $event;
      }
    }
  }), _c('datepicker', {
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "title": "Start Leistungserbringung bis",
      "propdate": _vm.latestDate
    },
    on: {
      "update:propdate": function ($event) {
        _vm.latestDate = $event;
      }
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "ET/GT verwenden"
    },
    model: {
      value: _vm.useDeadline,
      callback: function ($$v) {
        _vm.useDeadline = $$v;
      },
      expression: "useDeadline"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Akut"
    },
    model: {
      value: _vm.acute,
      callback: function ($$v) {
        _vm.acute = $$v;
      },
      expression: "acute"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Auf Wartelistse"
    },
    model: {
      value: _vm.onWaitingList,
      callback: function ($$v) {
        _vm.onWaitingList = $$v;
      },
      expression: "onWaitingList"
    }
  })], 1), _c('div', [_vm._v(" Zur Info: "), _c('div', {
    staticStyle: {
      "display": "grid",
      "align-items": "end",
      "grid-template-columns": "1fr 1fr"
    }
  }, [_c('label', [_vm._v("ET:")]), _c('dateitem', {
    attrs: {
      "date-item": _vm.addInfo.edate
    }
  })], 1), _c('div', {
    staticStyle: {
      "display": "grid",
      "align-items": "baseline",
      "grid-template-columns": "1fr 1fr"
    }
  }, [_c('label', [_vm._v("Geburt:")]), _c('dateitem', {
    attrs: {
      "date-item": _vm.addInfo.bdate
    }
  })], 1), _vm.propDtoCauseActivity.mailInfoList.length > 0 ? _c('div', {
    staticStyle: {
      "display": "grid",
      "align-items": "baseline",
      "grid-template-columns": "1fr 1fr"
    }
  }, [_c('div', [_vm._v(" Per Rundmail nachgefragt am ")]), _c('div', _vm._l(_vm.propDtoCauseActivity.mailInfoList, function (mail) {
    return _c('div', {
      key: mail.mailPid
    }, [_vm._v(" - " + _vm._s(_vm.formatDate(mail.sendDate)) + ": " + _vm._s(mail.mailTemplateName) + " "), _c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "color": "blue"
      },
      on: {
        "click": function ($event) {
          return _vm.showMailContent(mail.mailPid);
        }
      }
    }, [_vm._v("mdi-email-open")])], 1);
  }), 0)]) : _vm._e()])])], 1), _c('mailcontentdialog', {
    attrs: {
      "sended-mail": _vm.sendedMail,
      "propShowDialog": _vm.showTextDialog
    },
    on: {
      "onCancel": _vm.onCancel,
      "update:propShowDialog": function ($event) {
        _vm.showTextDialog = $event;
      },
      "update:prop-show-dialog": function ($event) {
        _vm.showTextDialog = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }