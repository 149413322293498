<template>
    <div>
      <v-data-table
        :hide-default-footer="true"
        :headers="realHeaders"
        :height="contentheight"
        :items-per-page=1000
        :sort-by.sync="searchParam.sortAttribute"
        :sort-desc.sync="searchParam.sortDirection"
        fixed-header
        :items="dataList">
        <template v-for="column in headers" v-slot:[getColumnValue(column)]="{ item }">
          <slot :name="getNewSlotValue(column.value)" v-bind:item="item">
            <div :key="column.value">{{ getTextValue(item, column.value) }}</div>
          </slot>
        </template>
        <template v-if="editMode" v-slot:item.edit="{ item }">
          <v-btn fab small text outlined class="mx-2" router >
            <v-icon small class="mr-2" @click="open(item)" color="blue">mdi-pencil</v-icon>
          </v-btn>
        </template>
        <template v-if="writeMode" v-slot:item.delete="{ item }">
          <v-btn fab small text outlined class="mx-2" router >
            <v-icon small class="mr-2" @click="remove(item)" color="blue">mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <formbuttonbar
        @onOk="newItem" ok-text="Neu"
        @onButtonOne="onButtonOne" :button-one-text="buttonOneText"
        @onButtonTwo="onButtonTwo" :button-two-text="buttonTwoText"
      >
      </formbuttonbar>
    </div>
</template>

<script>
import formbuttonbar from '../element/FormButtonBar'
import Helper from '../../utils/json.utils'
import { containsRight } from '../../utils/right.utils'
import PageinfoController from '../../../module/app/pageinfo/pageinfo.controller'

export default {
  name: 'MasterContent',

  async created () {
    var result = null
    result = PageinfoController.getPageinfo(this.searchKey)
    if (result == null) {
      result = {
        sortAttribute: [],
        sortDirection: []
      }
    }
    this.searchParam = result
  },

  components: {
    formbuttonbar
  },

  methods: {
    handleClick (item) {
      this.$emit('open', item)
    },

    getTextValue (item, value) {
      return Helper.getValue(item, value)
    },
    getColumnValue (column) {
      return 'item.' + column.value
    },
    getNewSlotValue (column) {
      return 'mdt.' + column
    },
    newItem () {
      this.$emit('openNew')
    },
    open (item) {
      this.$emit('open', item)
    },
    remove (item) {
      this.$emit('remove', item)
    },
    onButtonOne () {
      this.$emit('onButtonOne')
    },
    onButtonTwo () {
      this.$emit('onButtonTwo')
    }
  },

  data () {
    return {
      searchParam: {}
    }
  },

  computed: {
    contentheight: function () {
      let height = this.$store.getters['size/masterdataContent']
      if (this.withButtonBar) {
        height = height - 45
      }
      return height
    },
    searchKey () {
      return 'masterdata.' + this.componentId
    },
    withButtonBar () {
      if (this.noEdit === true) {
        return false
      } else {
        return containsRight(this.writeRightProp)
      }
    },
    writeMode () {
      return containsRight(this.writeRightProp)
    },
    editMode () {
      return !(this.noEdit === true)
    },
    realHeaders () {
      const result = this.headers
      result.push({ text: '', value: 'edit' })
      result.push({ text: '', value: 'delete' })
      return result
    }
  },

  watch: {
    searchParam: {
      deep: true,
      handler () {
        PageinfoController.setPageinfo(this.searchKey, this.searchParam)
      }
    }
  },

  props: {
    noButtonBar: { type: Boolean },
    noEdit: { type: Boolean },
    buttonOneText: { type: String },
    buttonTwoText: { type: String },
    componentId: { type: String },
    writeRightProp: { type: String },
    dataList: { type: Array },
    headers: { type: Array }
  }
}
</script>
