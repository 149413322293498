<template>
  <div>
    <v-app-bar app color="grey lighten-1" dark v-bind:style="{ 'padding-left': paddingLeft + 'px', 'padding-right': paddingRight + 'px', 'height': height + 'px' }">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title >
        <span class="font-weight-thin">e n </span>
        <span class="font-weight-bold">n a</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div>
        <label class="font-weight-medium">{{userInfo.userName}}</label><br/>
        <label class="font-weight-thin">{{userInfo.mandatorName}}</label>
      </div>
      <v-btn x-small text v-on:click="onLogout" style="padding-left: 30px;">
        <v-icon right color="blue">mdi-exit-to-app</v-icon>
      </v-btn>
      <v-btn x-small text v-on:click="onHelp" style="padding-left: 30px;">
        <v-icon right color="blue">mdi-help</v-icon>
      </v-btn>
    </v-app-bar>
    <menupanel v-bind:propDrawer.sync="drawer" ></menupanel>
  </div>
</template>

<script>
import menupanel from '../menu/MenuPanel'
import authcontroller from '../auth/auth.controller'
import UserController from '../../user/user.controller'
import HelpController from '../../help/help.controller'

export default {
  name: 'AppBar',

  components: {
    menupanel
  },

  props: {
    paddingLeft: String,
    paddingRight: String,
    height: String
  },

  data () {
    return {
      drawer: false,
      userInfo: {
        userName: '',
        mandatorName: ''
      }
    }
  },

  methods: {
    onHelp () {
      HelpController.openHelp()
    },

    onLogout () {
      authcontroller.logout()
    }
  },

  async mounted () {
    this.userInfo = await UserController.userInfo()
  }
}
</script>
