<template>
  <div>
    <div v-bind:style="{ 'overflow-y': 'auto', 'max-height': 200 + 'px' }">
      <div v-for="info in infoList" :key="info.initialDate">
        <v-subheader>
          <div>
            {{ getOrigin(info) }}<br/>
            {{ info.user.userName }} - Letzte Änderung: <datetimeitem style="display: inline;" :datetime-item="info.lastModifiedDate"></datetimeitem>
            <v-icon class="mr-2" @click="editInfo(info)" color="blue">mdi-pencil</v-icon>
            <v-icon class="mr-2" @click="deleteInfo(info)" color="blue">mdi-delete</v-icon>
            <v-divider></v-divider>
          </div>
        </v-subheader>
        <div style="white-space: pre-wrap; word-wrap: break-word; color: black; font-family: inherit; padding-left: 60px; padding-top: 5px; padding-right: 25px; padding-bottom: 20px;}">
          {{ info.infoText }}
        </div>
      </div>
    </div>
    <cmdbutton @cmdclick="newInfo" caption="Neue Info"/>
    <infodialog
      :propInfoText.sync="currentInfoText"
      @onSave="onSaveInfoDialog"
      @onCancel="onCancelInfoDialog"
      :propShowDialog.sync="showDialog"
    />

  </div>
</template>

<script>
import infodialog from './InfoTextDialog.vue'
import CaseInfoController from './concern.caseinfo.controller'
import datetimeitem from '../../../common/components/datatype/DatetimeItem'

export default {
  name: 'CaseInfo',

  components: {
    datetimeitem,
    infodialog
  },

  data () {
    return {
      currentCasePid: null,
      currentInfoPid: null,
      currentInfoText: null,
      showDialog: false
    }
  },

  props: {
    concernId: { type: String },
    versionId: { type: Number },
    initialCaseId: { type: Number },
    infoList: { type: Array }
  },

  methods: {
    getOrigin (info) {
      if (info.refCasePid != null) {
        return 'Info zu Anfrage ' + info.refCasePid
      } else {
        return 'Allgemeine Info'
      }
    },

    onCancelInfoDialog () {
      this.showDialog = false
    },

    newInfo () {
      this.currentInfoText = null
      this.currentInfoPid = null
      this.currentCasePid = this.initialCaseId
      this.showDialog = true
    },

    editInfo (info) {
      this.currentCasePid = info.refCasePid
      this.currentInfoPid = info.infoPid
      this.currentInfoText = info.infoText
      this.showDialog = true
    },

    async onSaveInfoDialog (infoText) {
      this.showDialog = false
      const data = {
        concernId: this.concernId,
        versionId: this.versionId,
        infoPid: this.currentInfoPid,
        refCasePid: this.currentCasePid,
        infoText: infoText
      }

      await CaseInfoController.saveCaseInfo(data)
      this.currentInfoText = infoText
      this.$emit('reloadParent')
    },

    async deleteInfo (info) {
      const data = {
        concernId: this.concernId,
        versionId: this.versionId,
        infoPid: info.infoPid,
        refCasePid: info.refCasePid,
        infoText: 'dummy'
      }
      await CaseInfoController.deleteCaseInfo(data)
      this.$emit('reloadParent')
    }
  }
}
</script>
