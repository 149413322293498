<template>
  <div style="display:grid; grid-template-columns: 3fr 3fr 3fr 1fr;">
    <div>
      <v-subheader style="padding-top: 20px;">
        <div>Geburt / Kind</div>
      </v-subheader>
      <div v-if="hasEdate" style="padding-bottom: 5px;">
        ET: <dateitem style="display: inline;" :date-item="inquiry.edate"></dateitem>
      </div>
      <div v-if="hasBirthdate" style="padding-bottom: 5px;">
        Geburt: <dateitem style="display: inline;" :date-item="inquiry.birthdate"></dateitem>
      </div>
      <div v-if="hasGravida" style="padding-bottom: 5px;">
        Gravida: {{ inquiry.gravida }}
      </div>
      <div v-if="hasPara" style="padding-bottom: 5px;">
        Para: {{ inquiry.para }}
      </div>
      <div v-if="hasMultipleBirth" style="padding-bottom: 5px;">
        {{ inquiry.multipleBirth.name }}
      </div>
      <div v-if="hasBirthYear" style="padding-bottom: 5px;">
        Frau ist {{ womanAge }} Jahre alt
      </div>
      <div v-if="inquiry.activityList.length > 0" style="padding-bottom: 5px;">
        <v-subheader style="padding-top: 20px;">
          <div>Sucht nach:</div>
        </v-subheader>
        <li v-for="activity in inquiry.activityList" :key="activity.pid">
          - {{ activity.name }}
        </li>
      </div>
    </div>
    <div>
      <v-subheader v-if="hasContact" style="padding-top: 20px;">
        <div>Kontaktdaten</div>
      </v-subheader>
      <div v-if="hasMail" style="padding-bottom: 5px;">
        {{ inquiry.mail }}
      </div>
      <div v-if="hasContact1" style="padding-bottom: 5px;">
        {{ inquiry.contact1 }}
      </div>
      <div v-if="hasContact2" style="padding-bottom: 5px;">
        {{ inquiry.contact2 }}
      </div>
      <div v-if="hasContactInfo" style="padding-bottom: 5px;">
        {{ inquiry.contactInfo }}
      </div>
      <div v-if="hasAdress">
        <v-subheader style="padding-top: 20px;">
          <div>Anschrift</div>
        </v-subheader>
        <div style="white-space: pre-wrap;">
          {{ inquiry.currentAdressAsString }}
        </div>
      </div>
      <div v-if="hasFutureAdress">
        <v-subheader style="padding-top: 20px;">
          <div>Zukünfige Anschrift</div>
        </v-subheader>
        <div style="white-space: pre-wrap;">
          {{ inquiry.futureAdressAsString }}
        </div>
        <div v-if="hasFutureAdressFrom" style="padding-bottom: 5px;">
          Gültig ab <dateitem style="display: inline;" :date-item="inquiry.futureAdressFrom"></dateitem>
        </div>
      </div>
    </div>
    <div>
      <div v-if="hasInfo">
        <v-subheader style="padding-top: 20px;">
          <div>Zusätzliche Info</div>
        </v-subheader>
          {{ inquiry.info }}
      </div>
    </div>
  </div>
</template>

<script>
import dateitem from '#enna/common/components/datatype/DateItem'

export default {
  name: 'WebformInquiryPanel',

  components: {
    dateitem
  },

  computed: {
    hasAdress () { return this.inquiry.currentAdressAsString != null },
    hasFutureAdress () { return this.inquiry.futureAdressAsString != null },
    hasFutureAdressFrom () { return this.inquiry.futureAdressFrom != null },
    hasContact () { return this.inquiry.mail != null || this.inquiry.contact1 != null || this.inquiry.contact2 != null },
    hasMail () { return this.inquiry.mail != null },
    hasContactInfo () { return this.inquiry.contactInfo != null },
    hasContact1 () { return this.inquiry.contact1 != null },
    hasContact2 () { return this.inquiry.contact2 != null },
    hasInfo () { return this.inquiry.info != null && this.inquiry.info.length > 0 },
    hasEdate () { return this.inquiry.edate != null },
    hasBirthdate () { return this.inquiry.birthdate != null },
    hasMultipleBirth () { return this.inquiry.multipleBirth != null },
    hasPara () { return this.inquiry.para != null },
    hasGravida () { return this.inquiry.gravida != null },
    hasBirthYear () { return this.inquiry.birthYear != null },
    womanAge () {
      const today = new Date()
      return today.getFullYear() - this.inquiry.birthYear
    }
  },

  props: {
    inquiry: { type: Object }
  }
}
</script>
