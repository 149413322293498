<template>
  <midwifeactivitydetail
    @onOk="save"
    :pid="pid"
    :activityType="activityType"
    :activityName="activityName"
    :midwifeName="midwifeName"
    :midwifeId="midwifeId">
    <template v-slot>
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:100px;
        align-items: end;
        grid-template-columns:3fr 1fr;">
        <v-textarea
          v-model="dtoMobileActivity.info"
          label="Info"
          clearable
          auto-grow
          rows="1"
        ></v-textarea>
      </div>
      <v-list v-for="group in regionList" :key="group.name">
        <v-divider></v-divider>
        <label class="font-weight-medium title display-1">{{ group.name }}</label>
        <v-list style="
              display: grid;
              grid-auto-flow: row;
              grid-template-rows: 1fr;
              grid-template-columns: 1fr 1fr 1fr 1fr;
            ">
          <v-list-item v-for="item in group.itemList" :key="item.id">
            <v-checkbox v-model="dtoMobileActivity.regionIdSet" multiple :value="item.id"/>
            <v-checkbox v-model="dtoMobileActivity.expandedRegionIdSet" multiple :value="item.id"/><v-icon small class="mr-2">mdi-arrow-all</v-icon>{{ item.name }}
          </v-list-item>
        </v-list>
      </v-list>
    </template>
  </midwifeactivitydetail>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import midwifeactivitydetail from '../common/MidwifeActivityDetail'
import MobileActivityController from './mobile.activity.controller'
import MidwifeActivityController from '../overview/midwife.activity.controller'

export default {
  name: 'MidwifeActivityDetail',

  async created () {
    this.$pageInitController.initReload(this, this.$moduleInfoController.midwife, 'Hebammenleistung', this.activityType.name, this.midwifeName)
  },

  watch: {
    propDtoMobileActivity (val) {
      this.dtoMobileActivity = cloneDeep(this.propDtoMobileActivity)
    }
  },

  methods: {
    async reload () {
      await MidwifeActivityController.reloadActivityDetail(this.midwifeId, this.activityType.keylistValueId, this.pid, Date.now())
    },

    save () {
      MobileActivityController.save(this.dtoMobileActivity)
    }
  },

  props: {
    regionList: { type: Array },
    propDtoMobileActivity: { type: Object },
    pid: { type: Number },
    activityName: { type: String },
    activityType: { type: Object },
    midwifeName: { type: String },
    midwifeId: { type: String }
  },

  data () {
    return {
      dtoMobileActivity: cloneDeep(this.propDtoMobileActivity)
    }
  },

  components: {
    midwifeactivitydetail
  }
}
</script>
