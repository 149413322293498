import router from '#enna/common/router'
import MenuController from '../../../app/menu/menu.controller'
import MidwifeActivityService from './midwife.activity.service'
import KnowledgeActivityController from '../knowledge/knowledge.activity.controller'
import LocationActivityController from '../location/location.activity.controller'
import MobileActivityController from '../mobile/mobile.activity.controller'

export default {
  async openNewActivity (midwifeId) {
    return await callNewActivity(midwifeId)
  },

  async reloadNewActivity (midwifeId) {
    return await callNewActivity(midwifeId, Date.now())
  },

  async openActivityDetail (midwifeId, activity, reloadId) {
    const activityType = getActivityType(activity.activityType.id)
    await activityType.load(midwifeId, activity.pid, reloadId)
  },

  async reloadActivityDetail (midwifeId, activityTypeId, pid, reloadId) {
    const activityType = getActivityType(activityTypeId)
    await activityType.load(midwifeId, pid, reloadId)
  },

  async deleteReadActivity (midwifeId, activity) {
    const activityType = getActivityType(activity.activityType.keylistValueId)
    await activityType.delete(activity.pid)
    const value = await MidwifeActivityService.queryActivity(midwifeId)
    return value
  },

  async generateActivity (midwifeId, activityTypeId, activityIdList, templatePid, versionId) {
    const activityType = getActivityType(activityTypeId)
    const data = {
      midwifeId, activityIdList, templatePid, versionId
    }
    await activityType.generate(data)
    const response = await MidwifeActivityService.loadActivityCreationOption(midwifeId)
    return response
  },

  async openMidwifeActivityOverview (midwifeId) {
    const response = await MidwifeActivityService.queryViewActivity(midwifeId)
    const midwifeName = response.data.midwifeName
    const activityList = response.data.midwifeActivityList
    const selectedTabIndex = await MenuController.getMidwifeFileStartTab('midwifeactivityoverview')
    router.push({
      name: 'midwifeactivityoverview',
      params: {
        midwifeId: midwifeId,
        propActivityList: activityList,
        midwifeName: midwifeName,
        writeRight: 'midActivityWrite',
        preselectedTab: selectedTabIndex
      }
    })
  }
}

const activityTypeMapper = [
  {
    activityTypeId: 'mobile',
    async delete (pid) { await MobileActivityController.delete(pid) },
    async generate (data) { await MobileActivityController.generateActivity(data) },
    async load (midwifeId, pid, reloadId) { await MobileActivityController.load(midwifeId, pid, reloadId) }
  }, {
    activityTypeId: 'knowledge',
    async delete (pid) { await KnowledgeActivityController.delete(pid) },
    async generate (data) { await KnowledgeActivityController.generateActivity(data) },
    async load (midwifeId, pid, reloadId) { await KnowledgeActivityController.load(midwifeId, pid, reloadId) }
  }, {
    activityTypeId: 'course',
    delete: (pid) => { console.log('implement delete for ' + pid) },
    generate: (data) => { console.log('implement generate for ' + data.midwifeId) },
    load: (midwifeId, pid, reloadId) => { console.log('implement load for ' + pid, reloadId) }
  }, {
    activityTypeId: 'location',
    async delete (pid) { await LocationActivityController.delete(pid) },
    async generate (data) { await LocationActivityController.generateActivity(data) },
    async load (midwifeId, pid, reloadId) { await LocationActivityController.load(midwifeId, pid, reloadId) }
  }
]

function getActivityType (activityTypeId) {
  for (let index = 0; index < activityTypeMapper.length; index++) {
    const activityType = activityTypeMapper[index]
    if (activityType.activityTypeId === activityTypeId) {
      return activityType
    }
  }
}

async function callNewActivity (midwifeId, reloadId) {
  const response = await MidwifeActivityService.loadViewActivityCreationOption(midwifeId)
  const activityCreationOptionList = response.data.activityCreationOptionList
  const midwifeName = response.data.midwifeName
  const versionId = response.data.versionId
  const selectedTabIndex = await MenuController.getMidwifeFileStartTab('midwifeactivityoverview')
  router.push({
    name: 'midwifenewactivity',
    params: {
      midwifeId: midwifeId,
      midwifeName: midwifeName,
      propActivityCreationOptionList: activityCreationOptionList,
      preselectedTab: selectedTabIndex,
      propVersionId: versionId
    },
    query: { reload: reloadId }
  })
}
