var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Hebammennetzwerke in Deutschland")]), _c('div', [_vm._v("Datenserver: " + _vm._s(_vm.serviceUrl))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }