import ApiService from '../../../common/service/api/api.service'
import VersionController from '../../../module/app/versioning/versioning.controller'

const TextModuleService = {
  async load (pid) {
    const result = await ApiService.doGet('/enna/superior/textmodule/' + pid)
    return result
  },

  async preview (previewParam) {
    const result = await ApiService.doPost('/enna/superior/textmodule/preview', previewParam)
    return result
  },

  async new () {
    const result = await ApiService.doGet('/enna/superior/textmodule/new')
    return result
  },

  async delete (pid) {
    const result = await ApiService.doDelete('/enna/superior/textmodule/' + pid)
    return result
  },

  async save (dtoTextModule) {
    const result = await ApiService.doPost('/enna/superior/textmodule', dtoTextModule)
    return result
  },

  async query () {
    const latestData = await VersionController.getLatestData('textModule')
    if (latestData === null) {
      const result = await ApiService.doGet('/enna/superior/textmodule/query')
      VersionController.setLatestData('textModule', result.data)
      return result.data.data
    } else {
      return latestData.data
    }
  }
}

export default TextModuleService
