<template>
  <div>
    <mastercontent
      :data-list="adminConcernList"
      :headers="headers"
      :noButtonBar="true"
      :noEdit="true"
      writeRightProp="concernWrite"
      @remove="remove"
      componentId="concernId">
      <template v-slot:mdt.firstContact="{ item }">
        <dateitem :date-item="item.firstContact"></dateitem>
      </template>
    </mastercontent>
    <v-dialog
        v-model="dialog"
        persistent
        width="auto">
      <v-card>
        <v-card-title class="text-h5">
          Anfrage vollständig löschen?
        </v-card-title>
        <v-card-text>
          <div>Die Anfrage wird vollständig gelöscht. Auch alle Mails, die im Zusammenhang mit dieser Anfrage versendet wurden, werden gelöscht. Die Anfrage kann anschließend nicht wiederhergestellt werden.</div>
          <div>  Soll die Anfrage vollständig gelöscht werden?</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green-darken-1"
              @click="deleteConcern"
          >
            Vollständig löschen
          </v-btn>
          <v-btn
              color="green-darken-1"
              @click="dialog = false"
          >
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mastercontent from '#enna/common/components/content/MasterContent'
import ConcernConcernadminController from '@/module/concern/concernadmin/concern.concernadmin.controller'
import dateitem from '#enna/common/components/element/DateItem'

export default {
  name: 'ConcernAdminOverview',

  async created () {
    this.$pageInitController.init(this, this.$moduleInfoController.concern, 'Anfragen überarbeiten', 'Übersicht')
  },

  props: {
    propAdminConcernList: { type: Array }
  },

  components: {
    dateitem,
    mastercontent
  },

  data () {
    return {
      concernId: null,
      dialog: false,
      adminConcernList: this.propAdminConcernList,
      headers: [
        { text: 'Id', value: 'concernId' },
        { text: 'Nachname', value: 'surname' },
        { text: 'Vorname', value: 'prename' },
        { text: 'Anfrage vom', value: 'firstContact' }
      ]
    }
  },

  methods: {
    async remove (concern) {
      this.dialog = true
      this.concernId = concern.concernId
    },

    async deleteConcern () {
      this.dialog = false
      await ConcernConcernadminController.removeConcern(this.concernId)
      this.adminConcernList = await ConcernConcernadminController.queryAdminConcern()
    }
  }
}
</script>
