<template>
  <v-dialog v-model="showdialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ statisticResult.name }}</span>
        <v-icon class="mr-2" @click="downloadStatistic" color="blue">mdi-download</v-icon>
      </v-card-title>
      <v-card-text>
        <div>{{ statisticResult.description }}</div>
        <div style="padding-bottom: 20px;">Zeitraum: {{ timeSpan }}</div>
        <div v-bind:style="{ 'overflow-y': 'auto', 'max-height': contentheight + 'px' }">
          <div v-for="item in statisticResult.dataList" :key="item.name">
            <div class="statistic">
              <div class="item">{{ item.name }}</div><div class="count">{{ item.count }}</div>
              <div v-if="item.childList">
                <div v-for="subitem in item.childList" :key="subitem.name">
                  <div class="statistic">
                    <div style="padding-left:20px;" class="subitem">{{ subitem.name }}</div>
                    <div class="subcount">{{ subitem.count }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <cmdbutton @cmdclick="close" caption="Schließen"/>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StatisticController from '@/module/network/statistic/statistic.controller'
import { formatDate } from '@/common/utils/date.utils'

export default {
  name: 'StatisticResult',

  watch: {
    async propshowdialog (val) {
      this.showdialog = val
      if (val) {
        this.statisticResult = await StatisticController.showStatistic(this.statisticId, this.from, this.until)
        if (this.from != null && this.until != null) {
          this.timeSpan = formatDate(this.from) + ' - ' + formatDate(this.from)
        } else if (this.from == null && this.until == null) {
          this.timeSpan = 'gesamter Vermittlungszeitraum'
        } else if (this.from != null) {
          this.timeSpan = 'ab ' + formatDate(this.from)
        } else {
          this.timeSpan = 'bis ' + formatDate(this.until)
        }
      } else {
        this.statisticResult = {}
        this.timeSpan = ''
      }
    }
  },

  computed: {
    contentheight () {
      const height = this.$store.getters['size/overviewContent']
      return height - 200
    }
  },

  methods: {
    async downloadStatistic () {
      const result = await StatisticController.downloadStatistic(this.statisticId, this.from, this.until)
      var fileURL = window.URL.createObjectURL(new Blob([result.content]))
      var fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', result.fileName)
      document.body.appendChild(fileLink)
      fileLink.click()
    },

    close () {
      this.showdialog = false
      this.$emit('update:propshowdialog', this.showdialog)
    }
  },

  data () {
    return {
      showdialog: this.propshowdialog,
      timeSpan: '',
      statisticResult: {}
    }
  },

  props: {
    propshowdialog: Boolean,
    statisticId: { type: Number },
    from: { type: String },
    until: { type: String }
  }
}
</script>
<style>
  .statistic {
    display: grid;
    grid-template-columns: 2fr 50px;
  }
  .item {
    font-size: 18px;
    color: blue;
  }
  .count {
    font-size: 18px;
    color: blue;
    text-align: right;
  }
  .subitem {
    color: brown;
  }
  .subcount {
    color: brown;
    text-align: right;
  }
</style>
