var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('dynpropbasedetail', {
    attrs: {
      "dynPropParam": _vm.dynPropParam
    },
    on: {
      "onSave": _vm.save
    }
  }, [_c('v-text-field', {
    staticStyle: {
      "width": "540px"
    },
    attrs: {
      "label": "Wert",
      "clearable": ""
    },
    model: {
      value: _vm.stringValue,
      callback: function ($$v) {
        _vm.stringValue = $$v;
      },
      expression: "stringValue"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }