var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-text-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.withSearchString,
      expression: "withSearchString"
    }],
    attrs: {
      "label": "Suchtext",
      "clearable": ""
    },
    model: {
      value: _vm.searchString,
      callback: function ($$v) {
        _vm.searchString = $$v;
      },
      expression: "searchString"
    }
  }), _c('ul', {
    style: {
      'overflow-y': 'auto',
      'max-height': _vm.contentheight + 'px'
    }
  }, _vm._l(_vm.filteredList, function (item) {
    return _c('li', {
      key: item[_vm.objectIdName],
      staticStyle: {
        "overflow-y": "auto"
      }
    }, [_vm._t("default", null, {
      "item": item
    })], 2);
  }), 0), _c('formbuttonbar', {
    attrs: {
      "ok-text": _vm.newText,
      "cancel-text": _vm.cancelText,
      "button-one-text": _vm.buttonOneText,
      "button-two-text": _vm.buttonTwoText
    },
    on: {
      "onOk": _vm.onOk,
      "onCancel": _vm.onCancel,
      "onButtonOne": _vm.onButtonOne,
      "onButtonTwo": _vm.onButtonTwo
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }