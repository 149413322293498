const actions = {
}

const state = {
  rightSet: new Set(),
  menuSet: new Set()
}

const getters = {
  rightSet: (state) => {
    return state.rightSet
  },

  menuSet: (state) => {
    return state.menuSet
  }
}

const mutations = {
  setRightsMenu (state, data) {
    data.rightSet.forEach((value, index) => {
      state.rightSet.add(value)
    })
    data.menuSet.forEach((value, index) => {
      state.menuSet.add(value)
    })
  },

  resetRightsMenu (state) {
    state.rightSet = new Set()
    state.menuSet = new Set()
  }
}

export const right = {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}
