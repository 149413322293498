<template>
  <div>
    <dynpropbasedetail :dynPropParam="dynPropParam" @onSave="save" >
      <v-select style="width: 500px;"
        :items="dynPropParam.keylistList"
        item-text="name"
        item-value="id"
        multiple
        chips
        v-model="keylistValue"
        label="Typ">
      </v-select>
    </dynpropbasedetail>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import DynPropController from './dynprop.controller'
import dynpropbasedetail from './DynPropBaseDetail'

export default {
  name: 'DynPropMultipleKeylistDetail',

  methods: {
    save () {
      DynPropController.saveMultipleKeylist(this.dynPropParam, this.keylistValue)
    }
  },

  watch: {
    dynPropParam (val) {
      this.keylistValue = cloneDeep(this.dynPropParam.dtoDynPropData.value)
    }
  },

  props: {
    dynPropParam: { type: Object }
  },

  data () {
    return {
      keylistValue: cloneDeep(this.dynPropParam.dtoDynPropData.value)
    }
  },

  components: {
    dynpropbasedetail
  }
}
</script>
