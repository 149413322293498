<template>
  <v-dialog v-model="showdialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Vergleich der mobilen Leistungen</span>
      </v-card-title>
      <v-card-text>
        <v-carousel light height="400" >
          <v-carousel-item v-for="(mobileActivity, i) in mobileActivityList" :key="i">
            <div class="font-weight-black">{{ mobileActivity.name }} </div>
            <div  style="padding-top:20px; padding-left:80px; max-height:81%; overflow: auto;">
              <div v-for="region in mobileActivity.regionList" :key="region.id">
                <span v-show="region.second" class="font-weight-medium">{{ region.first }}</span><span class="red--text" v-show="!region.second"><del>{{ region.first }}</del></span>
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
      <v-card-actions>
        <cmdbutton @cmdclick="close" caption="Schließen"/>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MobileActivityController from './mobile.activity.controller'

export default {
  name: 'MobileActivityCompare',

  watch: {
    async propshowdialog (val) {
      this.showdialog = val
      if (val) {
        const mobileActivityComparison = await MobileActivityController.compareActivity(this.midwifeId)
        this.mobileActivityList = mobileActivityComparison.mobileActivityList
      } else {
        this.mobileActivityList = []
      }
    }
  },

  methods: {
    close () {
      this.showdialog = false
      this.$emit('update:propshowdialog', this.showdialog)
    }
  },

  props: {
    propshowdialog: Boolean,
    midwifeName: { type: String },
    midwifeId: { type: String }
  },

  data () {
    return {
      showdialog: this.propshowdialog,
      mobileActivityList: []
    }
  }
}
</script>
