<template>
  <div>
    <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:100px;
        align-items: end;
        grid-template-columns:3fr 3fr 2fr;">
      <datepicker title="Von" :propdate.sync="from"/>
      <datepicker title="Bis" :propdate.sync="until"/>
    </div>
      <div v-bind:style="{ 'overflow-y': 'auto', 'max-height': contentheight + 'px' }">
        <div v-for="statistic in statisticList" :key="statistic.id">
          <v-icon class="mr-2" @click="executeStatistic(statistic.pid)" color="blue">mdi-play-circle-outline</v-icon>
          <label style="font-weight: bold;">
            {{ statistic.name }}
          </label>
          <div style="padding: 2px 10px 5px 60px; ">
            {{ statistic.description }}
          </div>
        </div>
      </div>
    <statisticresult :statisticId="statisticId" :from="from" :until="until" :propshowdialog.sync="showStatisticResult"></statisticresult>
  </div>
</template>

<script>
import statisticresult from './StatisticResult.vue'
import datepicker from '#enna/common/components/element/DatePicker'
import { gettDateToIso } from '@/common/utils/date.utils'

export default {
  name: 'StatisticOverview',

  async created () {
    this.$pageInitController.init(this, this.$moduleInfoController.admin, 'Statistik')
  },

  computed: {
    contentheight () {
      const height = this.$store.getters['size/overviewContent']
      return height - 86
    }
  },

  methods: {
    async executeStatistic (statisticId) {
      this.showStatisticResult = true
      this.statisticId = statisticId
    },

    calculateFrom () {
      var date = new Date()
      date.setMonth(0)
      date.setDate(1)
      return gettDateToIso(date)
    },

    calculateUntil () {
      var date = new Date()
      date.setMonth(11)
      date.setDate(31)
      return gettDateToIso(date)
    }
  },

  components: {
    statisticresult,
    datepicker
  },

  data () {
    return {
      showStatisticResult: false,
      statisticId: null,
      from: this.calculateFrom(),
      until: this.calculateUntil()
    }
  },

  props: {
    statisticList: { type: Array }
  }
}
</script>
