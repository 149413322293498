import store from '../../../store'

export default {
  showInfo (title, text) {
    store.dispatch('infoDialog/setInfoDialog', {
      caption: title,
      text: text
    })
  },

  clear () {
    store.dispatch('infoDialog/clear')
  }
}
