<template>
    <div>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px">
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="formatteddate"
            :label="title"
            prepend-icon="mdi-calendar"
            clear-icon="mdi-delete"
            clearable
            @click:clear="clear"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="datadate"
          @change="onInput"
          no-title
          scrollable
          @input="menu = false">
        </v-date-picker>
      </v-menu>
    </div>
</template>

<script>
import { formatDate } from '../../utils/date.utils'
export default {
  methods: {
    clear () {
      this.datadate = null
      this.onInput()
    },
    onInput () {
      this.$emit('update:propdate', this.datadate)
    }
  },
  props: {
    propdate: String,
    title: String
  },
  watch: {
    propdate (val) {
      this.datadate = val
    },
    datadate (val) {
      this.formatteddate = formatDate(val)
    }
  },
  data () {
    return {
      formatteddate: formatDate(this.propdate),
      datadate: this.propdate,
      menu: false
    }
  }
}
</script>
