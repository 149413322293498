var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "resize",
      rawName: "v-resize",
      value: _vm.onResize,
      expression: "onResize"
    }],
    staticClass: "networklayout"
  }, [_c('div', {
    staticClass: "appbar"
  }, [_c('appbar', {
    attrs: {
      "padding-left": "110",
      "padding-right": "110",
      "height": "64"
    }
  })], 1), _c('div', {
    staticClass: "moduleinfo"
  }, [_c('modulinfo')], 1), _c('div', {
    staticClass: "sidelinks"
  }, [_vm._l(_vm.shortlinks, function (item) {
    return _c('div', {
      key: item.id,
      staticStyle: {
        "padding-bottom": "20px"
      }
    }, [_c('v-btn', {
      staticClass: "mx-2",
      attrs: {
        "elevation": "1",
        "fab": "",
        "small": "",
        "text": "",
        "outlined": ""
      },
      on: {
        "click": function ($event) {
          return item.openRoute();
        }
      }
    }, [_c('v-tooltip', {
      key: item.text,
      attrs: {
        "right": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on;
          return [_c('v-icon', _vm._g({
            attrs: {
              "color": "blue"
            }
          }, on), [_vm._v(_vm._s(item.icon))])];
        }
      }], null, true)
    }, [_c('span', [_vm._v(_vm._s(item.text))])])], 1)], 1);
  }), _c('div', {
    staticStyle: {
      "padding-bottom": "20px"
    }
  }, [_vm.showReload() ? _c('v-btn', {
    staticClass: "mx-2",
    attrs: {
      "fab": "",
      "small": "",
      "text": "",
      "outlined": ""
    },
    on: {
      "click": _vm.refresh
    }
  }, [_c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on;
        return [_c('v-icon', _vm._g({
          attrs: {
            "color": "blue"
          }
        }, on), [_vm._v(" mdi-reload ")])];
      }
    }], null, false, 1941304247)
  }, [_c('span', [_vm._v("Erneut laden")])])], 1) : _vm._e(), _vm.showBack() ? _c('v-btn', {
    staticClass: "mx-2",
    attrs: {
      "elevation": "20",
      "fab": "",
      "small": "",
      "text": "",
      "outlined": ""
    },
    on: {
      "click": _vm.back
    }
  }, [_c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on;
        return [_c('v-icon', _vm._g({
          attrs: {
            "color": "blue"
          }
        }, on), [_vm._v("mdi-backburger")])];
      }
    }], null, false, 1815469368)
  }, [_c('span', [_vm._v("Zurück")])])], 1) : _vm._e(), _c('infodialog', {
    attrs: {
      "content": "importContent",
      "caption": "Überschrift"
    }
  })], 1)], 2), _c('div', {
    staticClass: "content"
  }, [_c('v-fade-transition', {
    attrs: {
      "mode": "out-in"
    }
  }, [_c('v-main', {
    staticStyle: {
      "padding": "0px"
    }
  }, [_c('errorpanel'), _c('div', [_vm._t("default")], 2)], 1)], 1)], 1), _c('div', {
    staticClass: "footer"
  }, [_c('v-divider', {
    attrs: {
      "inset": ""
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }