<template>
  <div>
    <formcontent
      ok-text="Zurück" @onOk="back">
      <searchcard caption="Suchparameter" :card-height="parameterHeight">
        <caseparameter
          layout="line"
         :set="origin = this.flexibleData"
         :location="origin.region"
         :bdate="origin.bdate"
         :edate="origin.edate"
         :activitySearchParamList="origin.currentSearchActivityList"
        ></caseparameter>
      </searchcard>
      <searchcard v-if="true" :caption="'Gefundene Hebammen: ' + searchResult.dataList.length" :card-height="contentHeight - parameterHeight">
        <searchresulttable
          @proposeMidwife="proposeMidwife"
          tablecontentKeyId="midwifeId"
          anchorKey="midwifeId"
          :height="contentHeight - parameterHeight - 100"
          :data-list="searchResult.dataList"
          :propHeaders="searchResult.headerList"
        />
      </searchcard>
    </formcontent>
  </div>
</template>

<script>
import formcontent from '#enna/common/components/content/FormContent'
import SearchController from './case.search.controller'
import searchcard from './SearchCard'
import caseparameter from './CaseParameter'
import searchresulttable from './SearchResultTable'
import InfoDialogController from '@/common/components/element/infodialog/infodialog.controller'
import { transformToActivityIdList } from '../common/search.utils'

export default {
  name: 'SearchResult',

  async created () {
    this.$pageInitController.init(this, this.$moduleInfoController.concern, 'Frau', 'Gefundene Hebammen', this.concernData.womanName)
  },

  components: {
    searchresulttable,
    searchcard,
    caseparameter,
    formcontent
  },

  computed: {
    parameterHeight () {
      let lines = 0
      const origin = this.flexibleData
      if (origin.bdate || origin.edate) {
        lines += 1
      }
      if (origin.region) {
        lines += 1
      }
      if (origin.currentSearchActivityList.length > lines) {
        lines = origin.currentSearchActivityList.length
      }
      if (lines > 5) {
        lines = 5
      }
      return 50 + (lines * 30)
    },

    contentHeight () {
      const height = this.$store.getters['size/formContent']
      return height
    }
  },

  methods: {
    async proposeMidwife (item) {
      let errorText = null
      let searchMidwife = false
      if (this.concernData) {
        const validData = await SearchController.isValidForPropose(this.concernData.caseLocator, this.concernData.concernVersion, this.flexibleData)
        if (validData.valid) {
          searchMidwife = true
        } else {
          validData.messageList.forEach(item => {
            if (errorText) {
              errorText = errorText + item + '\n'
            } else {
              errorText = item + '\n'
            }
          })
        }
      } else {
        errorText = 'Zur Frau wurden noch keine Daten erfaßt'
      }

      if (searchMidwife) {
        if (this.configSearchView.checkChange) {
          const activityList = transformToActivityIdList(this.flexibleData.currentSearchActivityList)
          const checkChange = await SearchController.checkChange(this.concernData.caseLocator, this.concernData.concernVersion, this.region?.regionId, this.edate, this.edate, activityList)
          if (checkChange.hasChanged) {
            InfoDialogController.showInfo('Suche nach Hebamme', checkChange.message)
          }
        }

        await SearchController.proposeMidwife(item.midwifeId, this.configSearchView, this.constantData, this.concernData, this.flexibleData)
      } else {
        InfoDialogController.showInfo('Suche nach Hebamme', 'Die Hebamme kann nicht vorgeschlagen werden. \n\n' + errorText)
      }
    },

    async back () {
      await SearchController.openSearchResultToSearch8(this.configSearchView, this.constantData, this.concernData, this.flexibleData)
    }
  },

  props: {
    configSearchView: { type: Object },
    concernData: { type: Object },
    constantData: { type: Object },
    flexibleData: { type: Object },
    searchResult: { type: Object }
  }
}
</script>
