var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    style: {
      'overflow-y': 'auto',
      'max-height': _vm.contentheight + 'px'
    }
  }, [_c('ul', [_c('v-subheader', {
    staticStyle: {
      "display": "grid",
      "grid-template-columns": "6fr 3fr 4fr"
    }
  }, [_c('div', [_vm._v("Programminterne Informationen")])]), _c('v-divider'), _vm._l(_vm.midwifePropertyList, function (dynPropItem) {
    return _c('div', {
      key: dynPropItem.id,
      staticStyle: {
        "display": "grid",
        "grid-template-columns": "8fr 3fr 2fr"
      },
      attrs: {
        "id": dynPropItem.id
      }
    }, [_c('span', [_vm._v(_vm._s(dynPropItem.name))]), dynPropItem.dynPropDataType === 'boolean' ? _c('span', [dynPropItem.value == true ? _c('v-icon', {
      attrs: {
        "color": "green"
      }
    }, [_vm._v("mdi-check-bold")]) : dynPropItem.value == false ? _c('v-icon', {
      attrs: {
        "color": "red"
      }
    }, [_vm._v("mdi-close-thick")]) : _vm._e()], 1) : _vm._e(), dynPropItem.dynPropDataType === 'string' ? _c('span', [_vm._v(" " + _vm._s(dynPropItem.value) + " ")]) : _vm._e(), dynPropItem.dynPropDataType === 'keylist' ? _c('span', [dynPropItem.value ? _c('div', [_vm._v(_vm._s(dynPropItem.value.caption))]) : _vm._e()]) : _vm._e(), _c('v-btn', {
      staticClass: "mx-2",
      attrs: {
        "fab": "",
        "small": "",
        "text": "",
        "outlined": "",
        "router": ""
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": "",
        "color": "blue"
      },
      on: {
        "click": function ($event) {
          return _vm.openMidwifeProp();
        }
      }
    }, [_vm._v("mdi-pencil")])], 1)], 1);
  })], 2), _c('dynpropoverview', {
    attrs: {
      "withHeader": true,
      "dynPropPropertyList": _vm.dynPropPropertyList
    },
    on: {
      "onEditDynPropData": _vm.onEditDynPropData
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }