export function formatDate (value) {
  if (value) {
    var date = new Date(value)
    var month = date.getMonth() + 1
    var year = date.getFullYear()
    var day = date.getDate()

    if (day < 10) {
      day = '0' + day
    }
    if (month < 10) {
      month = '0' + month
    }
    return day + '.' + month + '.' + year
  }
  return ''
}

export function formatDateTime (value) {
  if (value) {
    var date = new Date(value)
    var month = date.getMonth() + 1
    var year = date.getFullYear()
    var day = date.getDate()
    var hour = date.getHours()
    var minute = date.getMinutes()

    if (day < 10) {
      day = '0' + day
    }
    if (month < 10) {
      month = '0' + month
    }
    if (hour < 10) {
      hour = '0' + hour
    }
    if (minute < 10) {
      minute = '0' + minute
    }
    return day + '.' + month + '.' + year + ' ' + hour + ':' + minute
  }
  return ''
}

export function formatWeekdayDate (value) {
  if (value) {
    var date = new Date(value)
    var weekday = date.toLocaleString('de-DE', { weekday: 'long' })
    var month = date.getMonth() + 1
    var year = date.getFullYear()
    var day = date.getDate()

    if (day < 10) {
      day = '0' + day
    }
    if (month < 10) {
      month = '0' + month
    }
    return weekday + ', ' + day + '.' + month + '.' + year
  }
  return ''
}

export function gettDateToIso (value) {
  if (value) {
    var month = value.getMonth() + 1
    var year = value.getFullYear()
    var day = value.getDate()

    if (day < 10) {
      day = '0' + day
    }
    if (month < 10) {
      month = '0' + month
    }
    return year + '-' + month + '-' + day
  }
  return ''
}

export function addDay (value, number) {
  if (value) {
    var date = new Date(value)
    date.setDate(date.getDate() + number)
    return date
  }
  return null
}
