var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    style: {
      'overflow-y': 'auto',
      'max-height': _vm.contentheight + 'px'
    }
  }, _vm._l(_vm.adviceList, function (item) {
    return _c('div', {
      key: item.activityId
    }, [_c('v-card', {
      staticStyle: {
        "padding-bottom": "30px"
      }
    }, [_c('v-list-item-title', {
      staticClass: "text-h5 mb-1"
    }, [_c('span', {
      staticClass: "font-weight-thin"
    }, [_vm._v("Beratung am ")]), _c('span', {
      staticClass: "font-weight-thick"
    }, [_vm._v(_vm._s(_vm.datetime(item.adviceTime)))]), item.duration ? _c('span', {
      staticClass: "font-weight-thick"
    }, [_vm._v(" (" + _vm._s(item.duration) + " Minuten) ")]) : _vm._e(), _c('span', {
      staticClass: "font-weight-thin"
    }, [_vm._v(" durch ")]), _c('span', {
      staticClass: "font-weight-thick"
    }, [_vm._v(_vm._s(item.userName))]), _c('v-icon', {
      staticStyle: {
        "padding-left": "10px"
      },
      attrs: {
        "color": "blue"
      },
      on: {
        "click": function ($event) {
          return _vm.onOpen(item);
        }
      }
    }, [_vm._v("mdi-pencil")]), _c('v-icon', {
      staticStyle: {
        "padding-left": "10px"
      },
      attrs: {
        "color": "blue"
      },
      on: {
        "click": function ($event) {
          return _vm.onDelete(item);
        }
      }
    }, [_vm._v("mdi-delete")]), _c('br'), item.adviceThemeList.length > 0 ? _c('div', [_c('div', {
      staticClass: "font-weight-thin"
    }, [_vm._v("Inhalt")]), _vm._l(item.adviceThemeList, function (theme) {
      return _c('div', {
        key: theme,
        staticClass: "font-weight-thick",
        staticStyle: {
          "padding-left": "30px"
        }
      }, [_vm._v(_vm._s(theme))]);
    })], 2) : _vm._e(), item.info ? _c('div', [_c('div', {
      staticClass: "font-weight-thin"
    }, [_vm._v("Weitere Beratungsthemen")]), _c('span', {
      staticClass: "font-weight-thick",
      staticStyle: {
        "padding-left": "30px"
      }
    }, [_vm._v(_vm._s(item.info))])]) : _vm._e()], 1)], 1)], 1);
  }), 0), _c('formbuttonbar', {
    attrs: {
      "button-one-text": "Neue Beratung"
    },
    on: {
      "onButtonOne": _vm.onNewAdvice
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }