var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('formcontent', {
    attrs: {
      "ok-text": "Zurück"
    },
    on: {
      "onOk": _vm.back
    }
  }, [_c('searchcard', {
    attrs: {
      "caption": "Suchparameter",
      "card-height": _vm.parameterHeight
    }
  }, [_c('caseparameter', {
    attrs: {
      "layout": "line",
      "set": _vm.origin = this.flexibleData,
      "location": _vm.origin.region,
      "bdate": _vm.origin.bdate,
      "edate": _vm.origin.edate,
      "activitySearchParamList": _vm.origin.currentSearchActivityList
    }
  })], 1), true ? _c('searchcard', {
    attrs: {
      "caption": 'Gefundene Hebammen: ' + _vm.searchResult.dataList.length,
      "card-height": _vm.contentHeight - _vm.parameterHeight
    }
  }, [_c('searchresulttable', {
    attrs: {
      "tablecontentKeyId": "midwifeId",
      "anchorKey": "midwifeId",
      "height": _vm.contentHeight - _vm.parameterHeight - 100,
      "data-list": _vm.searchResult.dataList,
      "propHeaders": _vm.searchResult.headerList
    },
    on: {
      "proposeMidwife": _vm.proposeMidwife
    }
  })], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }