const state = {
  height: 0
}

const mutations = {
  setHeight (state, windowY) {
    state.height = windowY
  }
}

const actions = {
  async setWindowY ({ commit }, windowY) {
    commit('setHeight', windowY)
  }
}

const getters = {
  currentHeight: (state) => {
    return state.height
  },

  overviewContent: (state) => {
    return state.height - 184
  },

  tableContent: (state) => {
    return state.height - 266
  },

  tabContent: (state) => {
    return state.height - 266
  },

  masterdataContent: (state) => {
    return state.height - 200
  },

  formContent: (state) => {
    return state.height - 240
  }
}

export const size = {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}
