var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tabcontent', {
    attrs: {
      "object-id": {
        midwifeId: _vm.midwifeId
      },
      "tab-list": _vm.midwifeFileTabs,
      "preselectedTab": _vm.preselectedTab,
      "contenttype": "midwife"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }