<template>
  <div v-bind:style="{ 'height': cardHeight + 'px', 'overflow': 'hidden' }">
    <v-card :height="cardHeight" outlined>
      <v-app-bar height="30" class="grey lighten-2">
        <v-toolbar-title class="text-h8 white--text">
          {{ caption }}
        </v-toolbar-title>
      </v-app-bar>
      <v-card-text>
        <div v-bind:style="{ 'overflow-y': 'auto', 'max-height': this.cardHeight - 50 + 'px'}">
          <slot></slot>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    cardHeight: { type: Number },
    caption: { type: String }
  }
}
</script>

<style scoped>

</style>
