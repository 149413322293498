var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "errorpanel"
  }, [_c('v-bottom-sheet', {
    attrs: {
      "persistent": ""
    },
    model: {
      value: _vm.showError,
      callback: function ($$v) {
        _vm.showError = $$v;
      },
      expression: "showError"
    }
  }, [_c('v-sheet', {
    staticClass: "text-center",
    attrs: {
      "height": "300px"
    }
  }, [_c('v-btn', {
    staticClass: "mt-6",
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": function ($event) {
        return _vm.resetError();
      }
    }
  }, [_vm._v(" Schliessen ")]), _c('div', {
    staticStyle: {
      "overflow-y": "auto",
      "height": "220px"
    }
  }, _vm._l(_vm.error, function (message) {
    return _c('div', {
      key: message.reason,
      staticClass: "py-5"
    }, [_c('h5', [_vm._v(_vm._s(message.reason))]), _vm._l(message.informationList, function (information) {
      return _c('li', {
        key: information
      }, [_vm._v(" " + _vm._s(information) + " ")]);
    })], 2);
  }), 0)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }