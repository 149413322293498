<template>
  <div>
    <mastercontent
      :data-list="contactList"
      :headers="headers"
      :writeRightProp="writeRight"
      componentId="midwifeContact"
      @openNew="openNew"
      @open="open"
      @remove="remove"
    >
      <template v-slot:mdt.contactMedium.name="{ item }">
        <v-icon small light>{{ item.contactMedium.icon }}</v-icon>
      </template>
      <template v-slot:mdt.forWoman="{ item }">
        <checkitem :flag-item="item.forWoman"></checkitem>
      </template>
      <template v-slot:mdt.mainnumber="{ item }">
        <checkitem :flag-item="item.mainnumber"></checkitem>
      </template>
    </mastercontent>
  </div>
</template>

<script>
import mastercontent from '#enna/common/components/content/MasterContent'
import checkitem from '#enna/common/components/element/CheckItem'
import MidwifeContactController from './midwife.contact.controller'
import cloneDeep from 'lodash/cloneDeep'
import MidwifeOverviewController from '../overview/midwife.overview.controller'

export default {
  name: 'MidwifeContactOverview',

  async created () {
    this.$pageInitController.initBack(this, this.$moduleInfoController.midwife, 'Hebamme', 'Kontaktdaten', this.midwifeName)
  },

  props: {
    writeRight: { type: String },
    midwifeId: { type: String },
    midwifeName: { type: String },
    propContactList: { type: Array }
  },

  components: {
    mastercontent,
    checkitem
  },

  data () {
    return {
      contactList: cloneDeep(this.propContactList),
      headers: [
        { text: 'Typ', value: 'contactMedium.name' },
        { text: 'Nummer', value: 'number' },
        { text: 'Art', value: 'contactType.name' },
        { text: 'Hauptnummer', value: 'mainnumber' },
        { text: 'Für Frau', value: 'forWoman' },
        { text: 'Info', value: 'info' }
      ]
    }
  },

  methods: {
    async back () {
      await MidwifeOverviewController.openMidwifeOverview(this.midwifeId)
    },
    openNew () {
      MidwifeContactController.openNewContact(this.midwifeId)
    },
    open (contact) {
      MidwifeContactController.openContactDetail(this.midwifeId, contact.pid)
    },
    async remove (contact) {
      this.contactList = await MidwifeContactController.deleteReadContact(this.midwifeId, contact.pid)
    }
  }
}
</script>
