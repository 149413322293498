export function transformToActivityIdList (searchActivityList) {
  const result = []
  for (let index = 0; index < searchActivityList.length; index++) {
    const activity = searchActivityList[index]
    const data = {
      activityId: activity.activity.activityId,
      from: activity.from,
      until: activity.until,
      useDeadline: activity.useDeadline,
      acute: activity.acute
    }
    result.push(data)
  }
  return result
}
