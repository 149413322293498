<template>
  <div>
    <overviewtablecontent
      tablecontentKeyId="midwifeId"
      anchorKey="midwifeId"
      :data-list="midwifeList"
      :headers="headerList"
      :propFilterKey="propFilterKey"
      :propFilterParam="propFilterParam"
      @open="openMidwife"
    />
  </div>
</template>

<script>
import overviewtablecontent from '#enna/common/components/content/OverviewTableContent'
import MidwifeMainFileController from '../mainfile/midwife.mainfile.controller'

export default {
  name: 'MidwifeOverview',

  async created () {
    this.$pageInitController.init(this, this.$moduleInfoController.midwife, 'Hebammen', 'Übersicht')
  },

  components: {
    overviewtablecontent
  },

  props: {
    midwifeList: { type: Array },
    headerList: { type: Array },
    propFilterKey: { type: String },
    propFilterParam: { type: Object }
  },

  methods: {
    async openMidwife (midwife) {
      await MidwifeMainFileController.openMidwifeMainFile(midwife.midwifeId)
    }
  }
}
</script>
