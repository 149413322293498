import ApiService from '../../../common/service/api/api.service'
import VersionController from '../../../module/app/versioning/versioning.controller'

const HandoverService = {
  async loadCurrentHandover () {
    const result = await ApiService.doGet('/enna/office/handover/current/view')
    return result
  },

  async loadHandover (pid) {
    const result = await ApiService.doGet('/enna/office/handover/load/view/' + pid)
    return result
  },

  async saveHandover (dtoHandover) {
    const result = await ApiService.doPost('/enna/office/handover', dtoHandover)
    return result
  },

  async queryGroupByHandover () {
    const latestData = await VersionController.getLatestData('handover')
    if (latestData === null) {
      const result = await ApiService.doGet('/enna/office/handover/querygroupby/view/')
      VersionController.setLatestData('handover', result.data)
      return result.data.data
    } else {
      return latestData.data
    }
  }
}

export default HandoverService
