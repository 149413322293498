var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('mastercontent', {
    attrs: {
      "data-list": _vm.mailTemplateList,
      "headers": _vm.headers,
      "writeRightProp": _vm.writeRight,
      "componentId": "mailTemplate",
      "buttonOneText": "Textbausteine"
    },
    on: {
      "onButtonOne": _vm.openTextModule,
      "openNew": _vm.openNew,
      "open": _vm.open,
      "remove": _vm.remove
    },
    scopedSlots: _vm._u([{
      key: "mdt.priorityTemplate",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('checkitem', {
          attrs: {
            "flag-item": item.priorityTemplate
          }
        })];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }