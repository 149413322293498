var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('formcontent', {
    attrs: {
      "ok-text": "Ausführen",
      "cancel-text": "Abbrechen",
      "write-right": "midMasterdataWrite"
    },
    on: {
      "onOk": _vm.execute,
      "onCancel": _vm.cancel
    }
  }, [_c('div', {
    style: {
      'overflow-y': 'scroll',
      'max-height': _vm.contentheight + 'px'
    }
  }, [_c('div', {
    staticClass: "grid",
    style: {
      'display': 'grid',
      'grid-template-columns': _vm.columnstyle
    }
  }, [_c('span'), _vm._l(_vm.concernColumns, function (column) {
    return _c('span', {
      key: 'concern#' + column,
      staticStyle: {
        "background-color": "#f0f0f0"
      }
    }, [_vm._v(" Daten aus " + _vm._s(column) + ". bestehender Anfrage ")]);
  }), _vm._l(_vm.webformInquiryColumns, function (column) {
    return _c('span', {
      key: 'webforminquiry#' + column,
      staticStyle: {
        "background-color": "#f0f0f0"
      }
    }, [_vm._v(" Daten aus " + _vm._s(column) + ". Webformular ")]);
  }), _c('span', {
    staticStyle: {
      "background-color": "#f0f0f0"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.allConcernList,
      "item-text": "name",
      "item-value": "concernId",
      "label": "Alle anderen Anfragen"
    },
    model: {
      value: _vm.alternativeConcernId,
      callback: function ($$v) {
        _vm.alternativeConcernId = $$v;
      },
      expression: "alternativeConcernId"
    }
  })], 1)], 2), _vm._l(_vm.itemList, function (item) {
    return _c('div', {
      key: item.index,
      staticClass: "grid",
      style: {
        'display': 'grid',
        'grid-template-columns': _vm.columnstyle
      }
    }, [_c('span', {
      staticStyle: {
        "background-color": "#fcfcfc"
      }
    }, [_vm._v(_vm._s(item.caption))]), _vm._l(_vm.concernList, function (concern) {
      return _c('span', {
        key: concern.pid
      }, [_c('doublettype', {
        attrs: {
          "type": item.type,
          "value": {
            data: concern[item.index]
          }
        }
      })], 1);
    }), _vm._l(_vm.webformInquiryList, function (webformInquiry) {
      return _c('span', {
        key: webformInquiry.pid
      }, [_c('doublettype', {
        attrs: {
          "type": item.type,
          "value": {
            data: webformInquiry[item.index]
          }
        }
      })], 1);
    }), _c('span', [_vm._v(" -- ")])], 2);
  }), _c('div', {
    staticClass: "grid",
    style: {
      'display': 'grid',
      'grid-template-columns': _vm.columnstyle
    }
  }, [_c('span', {
    staticClass: "font-weight-black final_theme"
  }, [_c('span', [_vm._v("Aktion auswählen")]), _c('v-select', {
    attrs: {
      "items": _vm.optionList,
      "item-text": "label",
      "item-value": "id"
    },
    on: {
      "change": _vm.optionChanged
    },
    model: {
      value: _vm.choice,
      callback: function ($$v) {
        _vm.choice = $$v;
      },
      expression: "choice"
    }
  })], 1), _vm.choice === 'delete' ? [_vm._l(_vm.concernList, function (concern) {
    return _c('span', {
      key: concern.pid,
      staticClass: "final_action"
    });
  }), _vm._l(_vm.webformInquiryList, function (webformInquiry) {
    return _c('span', {
      key: webformInquiry.pid,
      staticClass: "final_action"
    }, [_c('v-checkbox', {
      key: webformInquiry.pid,
      attrs: {
        "label": "Löschen"
      },
      on: {
        "change": function ($event) {
          return _vm.onDelete($event, webformInquiry.compactInquiryKey);
        }
      }
    })], 1);
  }), _c('span', {
    staticClass: "final_action"
  })] : _vm.choice === 'new' ? [_vm._l(_vm.concernList, function (concern) {
    return _c('span', {
      key: concern.pid,
      staticClass: "final_action"
    });
  }), _vm._l(_vm.webformInquiryList, function (webformInquiry) {
    return _c('span', {
      key: webformInquiry.pid,
      staticClass: "final_action"
    }, [_c('v-checkbox', {
      attrs: {
        "label": "Neu anlegen"
      },
      on: {
        "change": function ($event) {
          return _vm.onNew($event, webformInquiry.compactInquiryKey);
        }
      }
    }), _c('v-checkbox', {
      key: "webformInquiry.pid",
      attrs: {
        "label": "Haupteintrag",
        "value": webformInquiry.compactInquiryKey.webformPid
      },
      model: {
        value: _vm.mainSelected,
        callback: function ($$v) {
          _vm.mainSelected = $$v;
        },
        expression: "mainSelected"
      }
    })], 1);
  }), _c('span', {
    staticClass: "final_action"
  })] : _vm.choice === 'assign' ? [_vm._l(_vm.concernList, function (concern) {
    return _c('span', {
      key: concern.pid,
      staticClass: "final_action"
    }, [_c('v-checkbox', {
      key: "concern.pid",
      attrs: {
        "label": "Anfrage",
        "value": concern.compactInquiryKey.concernId
      },
      model: {
        value: _vm.assignConcern,
        callback: function ($$v) {
          _vm.assignConcern = $$v;
        },
        expression: "assignConcern"
      }
    })], 1);
  }), _vm._l(_vm.webformInquiryList, function (webformInquiry) {
    return _c('span', {
      key: webformInquiry.pid,
      staticClass: "final_action"
    }, [_c('v-checkbox', {
      key: 'c' + webformInquiry.pid,
      attrs: {
        "label": "Zuweisen"
      },
      on: {
        "change": function ($event) {
          return _vm.onAssign($event, webformInquiry.compactInquiryKey);
        }
      }
    })], 1);
  }), _c('span', {
    staticClass: "final_action"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Anfrage"
    },
    model: {
      value: _vm.useAlternativeConcern,
      callback: function ($$v) {
        _vm.useAlternativeConcern = $$v;
      },
      expression: "useAlternativeConcern"
    }
  })], 1)] : _vm._e()], 2)], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }