<template>
  <customcontent prop-scrollbar="visible">
    <template v-slot>
      <div style="display: grid;
    grid-template-columns: 8fr 3fr;" >
        <div v-bind:style="{ 'overflow-y': 'auto', 'max-height': contentheight + 'px' }">
          <div v-for="group in filteredHandover" :key="group.first">
            <v-subheader>
              <dateitem :id="group.first" :date-item="group.first" style="padding-bottom: 15px; "></dateitem>
            </v-subheader>
            <div>
              <div v-for="handover in group.second" :key="handover.pid">
                <div style="padding-left: 30px;">
                  <div :id="handover.pid" style="display: grid; grid-template-columns: 8fr 1fr 1fr 8fr 1fr 1fr;">
                    <label style="font-weight: bold;">
                      {{ handover.user.userName }}
                    </label>
                    <v-icon v-if="handover.todo" light style="padding-left: 20px;">mdi-wrench</v-icon>
                    <div v-else></div>
                    <v-icon v-if="handover.important" light style="padding-left: 20px;">mdi-alert</v-icon>
                    <div v-else></div>
                    <div></div>
                    <v-icon large class="mr-2" @click="open(handover.pid)" color="blue">mdi-pencil</v-icon>
                  </div>
                  <v-divider ></v-divider>
                </div>
                <div style="white-space: pre-wrap; word-wrap: break-word; font-family: inherit; padding-left: 60px; padding-top: 20px; padding-right: 25px; padding-bottom: 40px;}">
                  {{ handover.content }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px;">
          <div style="padding-bottom: 20px;">
            <v-text-field v-model="filterParam.searchText" label="Suchtext"></v-text-field>
          </div>
          <div style="
        display: grid;
        grid-template-columns:1fr 1fr 1fr 1fr;
        padding-bottom: 20px;">
            <v-icon light style="padding-left: 20px;">mdi-wrench</v-icon>
            <v-checkbox v-model="filterParam.searchTodo" label="Todo"></v-checkbox>
            <v-icon light style="padding-left: 20px;">mdi-alert</v-icon>
            <v-checkbox v-model="filterParam.searchImportant" label="Wichtig"></v-checkbox>
          </div>
          <div style="
        display: grid;
        grid-template-columns:8fr 1fr 17fr;
        padding-bottom: 20px;">
            <v-select
              v-model="filterParam.searchDateModeList"
              :items="dateMode"
              item-text="label"
              item-value="id"
              label="Zeitraum"
              solo clearable
            ></v-select>
            <div></div>
            <datepicker :propdate.sync="filterParam.searchDate"/>
          </div>
          <div>
            <v-select
              v-model="filterParam.searchUserList"
              :items="userList"
              item-text="userName"
              item-value="userId"
              label="Hebammen"
              multiple chips
              solo clearable
            ></v-select>
          </div>
        </div>
      </div>
    </template>
  </customcontent>
</template>

<script>
import customcontent from '../../../common/components/content/CustomContent'
import cloneDeep from 'lodash/cloneDeep'
import dateitem from '../../../common/components/datatype/WeekdayDateItem'
import datepicker from '../../../common/components/element/DatePicker'
import HandoverController from './handover.controller'

export default {
  name: HandoverController.handoverOverviewName,

  async created () {
    this.$pageInitController.init(this, this.$moduleInfoController.network, 'Übergaben')
  },

  components: {
    customcontent,
    dateitem,
    datepicker
  },

  watch: {
    filterParam: {
      deep: true,
      handler () {
        HandoverController.saveFilterParam(this.filterParam)
      }
    }
  },

  methods: {
    async open (pid) {
      HandoverController.openHandover(pid)
    },

    containsUser (userId) {
      for (var id of this.filterParam.searchUserList) {
        if (id === userId) {
          return true
        }
      }
      return false
    },

    containsDate (date) {
      if (this.filterParam.searchDateModeList === undefined || this.filterParam.searchDate === null) {
        return true
      }
      if (this.filterParam.searchDateModeList === 1) {
        return date >= this.filterParam.searchDate
      } else { // this.searchDateModeList === 2
        return date < this.filterParam.searchDate
      }
    }
  },

  computed: {
    contentheight: function () {
      return this.$store.state.size.height - 184
    },

    filteredHandover: function () {
      var filteredList = []
      for (var group of this.groupList) {
        var newList = []
        for (var entry of group.second) {
          if (entry.content.match(this.filterParam.searchText) &&
            (this.containsDate(entry.editdate)) &&
            (this.filterParam.searchUserList.length === 0 || this.containsUser(entry.user.userId)) &&
            (this.filterParam.searchTodo === false || entry.todo === true) &&
            (this.filterParam.searchImportant === false || entry.important === true)) {
            newList.push(entry)
          }
        }

        if (newList.length > 0) {
          var dayEntry = {}
          dayEntry.first = group.first
          dayEntry.second = newList
          filteredList.push(dayEntry)
        }
      }
      return filteredList
    }
  },

  props: {
    propFilterParam: { type: Object },
    groupList: { type: Array },
    userList: { type: Array }
  },

  data () {
    return {
      dateMode: [
        {
          id: 1,
          label: 'ab'
        }, {
          id: 2,
          label: 'vor'
        }
      ],
      filterParam: cloneDeep(this.propFilterParam)
    }
  }
}
</script>
