<template>
  <div>
    <formcontent
      ok-text="Speichern" @onOk="save"
      cancel-text="Abbrechen" @onCancel="cancel"
      write-right="concernWrite">

      <div v-if="newMode">
        <singleselection style=" margin-bottom: -6px; max-width: 400px;" :selectedId.sync="activityId" caption="Leistung" idName="activityId" :propItemList="activityList"></singleselection>
      </div>
      <div v-else>
        {{ addInfo.activityName }}
      </div>
      <v-select
        style="max-width: 400px; padding-top:40px;"
        :items="startStateList"
        item-text="caption"
        item-value="keylistValueId"
        v-model="startStateId"
        label="Initiative">
      </v-select>
      <div v-if="addInfo.finishAble">
        <v-select
          style="max-width: 400px;"
          :items="finishStateList"
          item-text="caption"
          item-value="keylistValueId"
          v-model="manualFinishState"
          label="Abschluß"
          clearable>
        </v-select>
      </div>
      <div style="
      display: grid;
      align-items: start;
      grid-template-columns:2fr 3fr 1fr;">
        <div>
          <datepicker style="width: 300px;" title="Start Leistungserbringung ab" :propdate.sync="startDate"/>
          <datepicker style="width: 300px;" title="Start Leistungserbringung bis" :propdate.sync="latestDate"/>
          <v-checkbox v-model="useDeadline" label="ET/GT verwenden"></v-checkbox>
          <v-checkbox v-model="acute" label="Akut"></v-checkbox>
          <v-checkbox v-model="onWaitingList" label="Auf Wartelistse"></v-checkbox>
        </div>
        <div>
          Zur Info:
          <div style="
            display: grid;
            align-items: end;
            grid-template-columns:1fr 1fr;">
                <label>ET:</label>
                <dateitem :date-item="addInfo.edate"></dateitem>
              </div>
          <div style="
            display: grid;
            align-items: baseline;
            grid-template-columns:1fr 1fr;">
            <label>Geburt:</label>
            <dateitem :date-item="addInfo.bdate"></dateitem>
          </div>
          <div v-if="propDtoCauseActivity.mailInfoList.length > 0" style="
            display: grid;
            align-items: baseline;
            grid-template-columns:1fr 1fr;">
            <div>
              Per Rundmail nachgefragt am
            </div>
            <div >
              <div v-for="mail in propDtoCauseActivity.mailInfoList" :key="mail.mailPid">
                - {{ formatDate(mail.sendDate) }}: {{ mail.mailTemplateName }}
                <v-icon class="mr-2" @click="showMailContent(mail.mailPid)" color="blue">mdi-email-open</v-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </formcontent>
    <mailcontentdialog
      :sended-mail="sendedMail"
      @onCancel="onCancel"
      :propShowDialog.sync="showTextDialog" />

  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import dateitem from '#enna/common/components/element/DateItem'
import ConcernCauseActivityController from './concern.causeactivity.controller'
import formcontent from '../../../common/components/content/FormContent'
import singleselection from '../../../common/components/element/SingleSelect'
import datepicker from '../../../common/components/element/DatePicker'
import { formatDate } from '../../../common/utils/date.utils'
import mailcontentdialog from '../case/MailContentDialog'
import ConcernMailController from '@/module/concern/mail/concern.mail.controller'

export default {
  name: 'EditCauseActivity',

  async created () {
    this.$pageInitController.init(this, this.$moduleInfoController.concern, 'Frau', 'Leistung', this.womanName)
  },

  components: {
    mailcontentdialog,
    datepicker,
    dateitem,
    singleselection,
    formcontent
  },

  methods: {
    async showMailContent (pid) {
      this.sendedMail = await ConcernMailController.loadMail(pid)
      this.showTextDialog = true
    },

    formatDate (date) {
      return formatDate(date)
    },

    async save () {
      const dto = cloneDeep(this.propDtoCauseActivity)
      if (this.newMode) {
        dto.activityId = this.activityId
      }
      dto.startState = this.startStateId
      dto.manualFinishState = this.manualFinishState
      dto.start = this.startDate
      dto.latest = this.latestDate
      dto.useDeadline = this.useDeadline
      dto.acute = this.acute
      dto.onWaitingList = this.onWaitingList
      ConcernCauseActivityController.saveCauseActivity(this.concernVersion, this.caseLocator, dto)
    },

    onCancel () {
      this.showTextDialog = false
    },

    async cancel () {
      ConcernCauseActivityController.openConcernCauseActivityOverview(this.caseLocator)
    }
  },

  data () {
    return {
      showTextDialog: false,
      sendedMail: null,
      activityId: null,
      startStateId: this.propDtoCauseActivity.startAction,
      manualFinishState: this.propDtoCauseActivity.manualFinishState,
      startDate: this.propDtoCauseActivity.start,
      latestDate: this.propDtoCauseActivity.latest,
      useDeadline: this.propDtoCauseActivity.useDeadline,
      acute: this.propDtoCauseActivity.acute,
      onWaitingList: this.propDtoCauseActivity.onWaitingList
    }
  },

  props: {
    newMode: { type: Boolean },
    concernVersion: { type: Number },
    caseLocator: { type: Object },
    addInfo: { type: Object },
    propDtoCauseActivity: { type: Object },
    womanName: { type: String },
    activityList: { type: Array },
    startStateList: { type: Array },
    finishStateList: { type: Array }
  }
}
</script>
