import ApiService from '../../../common/service/api/api.service'

const MidwifeContactService = {
  async deleteContact (pid) {
    var result = await ApiService.doDelete('/enna/midwife/contact/' + pid)
    return result
  },

  async newContact (midwifeId) {
    var result = await ApiService.doGet('/enna/midwife/contact/new/view/dto/' + midwifeId)
    return result
  },

  async loadContact (pid) {
    var result = await ApiService.doGet('/enna/midwife/contact/view/dto/' + pid)
    return result
  },

  async saveContact (dtoMidwifeContact) {
    var result = await ApiService.doPost('/enna/midwife/contact', dtoMidwifeContact)
    return result
  },

  async queryViewContact (midwifeId) {
    var result = await ApiService.doGet('/enna/midwife/contact/query/view/' + midwifeId)
    return result
  },

  async queryContact (midwifeId) {
    var result = await ApiService.doGet('/enna/midwife/contact/query/' + midwifeId)
    return result
  }
}

export default MidwifeContactService
