const HelpService = {
  async queryHelp () {
    const helpData = {
      topics: [
        {
          topic: 'Hebamme',
          themes: [
            {
              theme: 'Kontaktdaten',
              contents: [
                'Nur die Nummern, die als \'Frauennummer\' markiert sind, werden in Mails an die Frau verwendet.'
              ]
            }
          ]
        },
        {
          topic: 'Anfrage',
          themes: [
            {
              theme: 'Webformfular',
              contents: [
                'Jede Webformular-Anfragen kann entweder übernommen oder verwerfen (=gelöscht) werden.',
                'Wenn nach dem Abrufen der Webformular-Anfragen Dubletten erkannt werden, können diese gelöscht, neu angelegt oder einer bestehenden Anfrage zugewiesen werden.'
              ]
            }
          ]
        },
        {
          topic: 'Administration',
          themes: [
          ]
        },
        {
          topic: 'Mailvorlagen',
          themes: [
            {
              theme: 'Platzhalter',
              contents: [
                'Detaillierte Beschreibung wird angezeigt, wenn die Maus über dem Platzhalter bewegt wird.'
              ]
            },
            {
              theme: 'Bedingung',
              contents: [
                'Bedingungen werden negiert, indem ein \'!\'-Zeichen vor die Bedingung geschrieben wird. Beispiel: <!heb.praxis>',
                'Platzhalter können auch als Bedingungen genutzt werden. Dafür muß am Ende des Platzhalters ein \'?\'-Zeichen verwendet werden. Die Bedingung ist wahr, wenn für den Platzhalter Text vorhanden ist. Beispiel: <heb.nachname?>',
                'Detaillierte Beschreibung wird angezeigt, wenn die Maus über die Bedingung bewegt wird.'
              ]
            },
            {
              theme: 'Textbaustein',
              contents: [
                'Detaillierte Beschreibung wird angezeigt, wenn die Maus über den Textbaustein bewegt wird.'
              ]
            }
          ]
        }
      ]
    }
    const result = await helpData
    return result
  }
}

export default HelpService
