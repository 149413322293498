import CaseOverviewService from './case.overview.service'
import router from '#enna/common/router'
import PageinfoController from '../../app/pageinfo/pageinfo.controller'
import { stateIconMap, todoIconMap } from './case.util'

const filterKeyVar = 'CaseOverview'

export default {
  saveFilterParam (filterParam) {
    PageinfoController.setPageinfo(filterKeyVar, filterParam)
  },

  async midwifeMediationInfo (midwifeId) {
    const response = await CaseOverviewService.midwifeMediationInfo(midwifeId)
    return response
  },

  async setTextCaseAction (caseLocator, caseAction, text, concernVersion) {
    const actionParam = {
      caseLocator: caseLocator,
      caseAction: caseAction,
      text: text
    }
    await CaseOverviewService.setCaseAction(caseLocator, actionParam, concernVersion)
    await internalOpenCaseFile(caseLocator, Date.now(), null)
  },

  async setCaseAction (caseLocator, caseAction, concernVersion) {
    const actionParam = {
      caseLocator: caseLocator,
      caseAction: caseAction.caseAction,
      midwifeId: caseAction.midwifeId,
      refCaseActionPid: caseAction.refInfo?.actionDataPid
    }
    await CaseOverviewService.setCaseAction(caseLocator, actionParam, concernVersion)
    await internalOpenCaseFile(caseLocator, Date.now(), null)
  },

  async openCaseFile (caseLocator, midwifeId) {
    internalOpenCaseFile(caseLocator, Date.now(), midwifeId)
  },

  async openCaseOverview (caseLocator) {
    const response = await CaseOverviewService.queryCase()
    const caseList = response.dataList
    const headerList = response.headerList

    var filterParam = null
    filterParam = PageinfoController.getPageinfo(filterKeyVar)
    if (filterParam == null) {
      filterParam = {
        sortAttribute: [],
        sortDirection: [],
        search: ''
      }
    }

    let hashId = ''
    if (caseLocator) {
      hashId = getHashId(caseLocator)
    }

    router.push({ name: 'caseoverview', hash: hashId, params: { caseList: caseList, headerList: headerList, propFilterParam: filterParam, propFilterKey: filterKeyVar, iconMapperMap: iconMapperMap } })
  },

  async openFinishedCaseOverview (caseLocator) {
    const response = await CaseOverviewService.queryFinishedCase()
    const caseList = response.dataList
    const headerList = response.headerList

    var filterParam = null
    filterParam = PageinfoController.getPageinfo(filterKeyVar)
    if (filterParam == null) {
      filterParam = {
        sortAttribute: [],
        sortDirection: [],
        search: ''
      }
    }

    let hashId = ''
    if (caseLocator) {
      hashId = getHashId(caseLocator)
    }

    router.push({ name: 'finishedcaseoverview', hash: hashId, params: { caseList: caseList, headerList: headerList, propFilterParam: filterParam, propFilterKey: filterKeyVar, iconMapperMap: iconMapperMap } })
  }
}

function getHashId (caseLocator) {
  return '#' + caseLocator.concernId + '.' + caseLocator.caseId
}

const iconMapperMap = new Map()
iconMapperMap.set('casestate', stateIconMap)
iconMapperMap.set('casetodo', todoIconMap)

async function internalOpenCaseFile (caseLocator, reloadId, midwifeId) {
  const response = await CaseOverviewService.loadCase(caseLocator)
  const concernBaseData = response.concernBaseData
  const concernVersion = response.concernBaseData.concernVersion
  let selectedTabIndex = 4
  const caseIdList = response.concernBaseData.caseIdList
  for (let index = 0; index < caseIdList.length; index++) {
    selectedTabIndex++
    const caseId = caseIdList[index]
    if (caseId === caseLocator.caseId) {
      break
    }
  }
  const caseFileData = response.concernData.caseFileData
  router.push({
    name: 'concerncasefile',
    params: {
      concernVersion: concernVersion,
      caseLocator: caseLocator,
      concernBaseData: concernBaseData,
      womanName: concernBaseData.womanName,
      preselectedTab: selectedTabIndex,
      caseFileData: caseFileData,
      midwifeId: midwifeId
    },
    query: { reload: reloadId }
  })
}
