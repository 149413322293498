import store from '../../../common/store'

export default {
  throwErrorAsString (data, informationList) {
    const message = {
      reason: data,
      informationList: informationList
    }
    store.dispatch('error/setError', message)
  },

  throwError (data) {
    store.dispatch('error/setError', data)
  },

  resetError () {
    store.dispatch('error/reset')
  }
}
