var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "700",
      "persistent": ""
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5 grey lighten-2"
  }, [_vm._v(" Auswahl für Sammelanfrage ")]), _vm.propOpenTodo ? _c('v-card-text', {
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_c('div', {
    staticClass: "text-h6"
  }, [_vm._v("Benachrichtigen")]), _vm._l(_vm.informList, function (item) {
    return _c('div', {
      key: item.todo.caseLocatorPid
    }, [_c('v-checkbox', {
      attrs: {
        "label": item.todo.name
      },
      model: {
        value: item.selected,
        callback: function ($$v) {
          _vm.$set(item, "selected", $$v);
        },
        expression: "item.selected"
      }
    })], 1);
  }), _c('div', [_c('div', {
    staticClass: "text-h6"
  }, [_vm._v("Erinnern")]), _vm._l(_vm.remindList, function (item) {
    return _c('div', {
      key: item.caseLocatorPid
    }, [_c('v-checkbox', {
      attrs: {
        "label": item.todo.name
      },
      model: {
        value: item.selected,
        callback: function ($$v) {
          _vm.$set(item, "selected", $$v);
        },
        expression: "item.selected"
      }
    })], 1);
  })], 2)], 2) : _vm._e(), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" Ok ")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v(" Abbrechen ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }