import store from '../../../store'

export default {
  showReload () {
    return store.state.navigation.reload != null
  },

  showBack () {
    return store.state.navigation.back != null
  },

  executeReload () {
    const page = store.state.navigation.reload
    if (page) {
      page.reload()
    }
  },

  executeBack () {
    const page = store.state.navigation.back
    if (page) {
      page.back()
    }
  }
}
