var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    style: {
      'overflow-y': 'hidden',
      'height': _vm.contentheight + 'px'
    }
  }, [_c('div', {
    staticStyle: {
      "height": "40%"
    }
  }, [_c('label', {
    staticClass: "grey--text font-weight-medium"
  }, [_vm._v("Platzhalter {...}")]), _c('v-divider'), _c('div', {
    staticStyle: {
      "height": "75%",
      "overflow-y": "scroll"
    }
  }, _vm._l(_vm.placeholderList, function (item) {
    return _c('v-list-item', {
      key: item.usage
    }, [_c('v-tooltip', {
      attrs: {
        "right": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on;
          return [_c('v-icon', _vm._g({
            staticClass: "mr-2",
            attrs: {
              "small": "",
              "color": "blue"
            },
            on: {
              "click": function ($event) {
                return _vm.copyToClipboard(item);
              }
            }
          }, on), [_vm._v("mdi-content-copy")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v(_vm._s(item.description))])]), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(item.usage)
      }
    }), _c('v-list-item-subtitle', {
      domProps: {
        "textContent": _vm._s(_vm.placeholderContent(item))
      }
    })], 1)], 1);
  }), 1)], 1), _c('div', {
    staticStyle: {
      "height": "40%"
    }
  }, [_c('label', {
    staticClass: "grey--text font-weight-medium"
  }, [_vm._v("Bedingungen <...></...>")]), _c('v-divider'), _c('div', {
    staticStyle: {
      "height": "75%",
      "overflow-y": "scroll"
    }
  }, _vm._l(_vm.conditionList, function (item) {
    return _c('v-list-item', {
      key: item.usage
    }, [_c('v-tooltip', {
      attrs: {
        "right": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref2) {
          var on = _ref2.on;
          return [_c('v-icon', _vm._g({
            staticClass: "mr-2",
            attrs: {
              "small": "",
              "color": "blue"
            },
            on: {
              "click": function ($event) {
                return _vm.copyToClipboard(item);
              }
            }
          }, on), [_vm._v("mdi-content-copy")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v(_vm._s(item.description))])]), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(item.usage)
      }
    }), _c('v-list-item-subtitle', {
      domProps: {
        "textContent": _vm._s(_vm.conditionContent(item))
      }
    })], 1)], 1);
  }), 1)], 1), _c('div', {
    staticStyle: {
      "height": "20%"
    }
  }, [_c('label', {
    staticClass: "grey--text font-weight-medium"
  }, [_vm._v("Textbausteine [...]")]), _c('v-divider'), _c('div', {
    staticStyle: {
      "height": "80%",
      "overflow-y": "scroll"
    }
  }, _vm._l(_vm.textModuleList, function (item) {
    return _c('v-list-item', {
      key: item.caption
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": "",
        "color": "blue"
      },
      on: {
        "click": function ($event) {
          return _vm.copyToClipboard(item);
        }
      }
    }, [_vm._v("mdi-content-copy")]), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(item.usage)
      }
    }), _c('v-list-item-subtitle', {
      domProps: {
        "textContent": _vm._s(item.description)
      }
    })], 1)], 1);
  }), 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }