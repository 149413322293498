import ApiService from '../../../common/service/api/api.service'

const AuthService = {
  async login (data) {
    const result = await ApiService.doPost('/enna/auth/view/signin', data)
    return result
  }
}

class AuthenticationError extends Error {
  constructor (errorCode, message) {
    super(message)
    this.name = this.constructor.name
    this.message = message
    this.errorCode = errorCode
  }
}

export { AuthService, AuthenticationError }
