<template>
  <div>
    <div v-bind:style="{ 'overflow-y': 'auto', 'max-height': contentheight + 'px' }">
      <v-expansion-panels >
        <v-expansion-panel style="max-width: 100%;"
          v-for="mail in sendedMailList" :key="mail.pid">
          <v-expansion-panel-header>
            <span style="
              display: grid;
              grid-template-columns:1fr 5fr 4fr;">
              <span>{{ mail.sendDate | formattedDate }}</span><span><label class="mailstyle">Vorlage: </label>{{ mail.caption }}<span v-if="mail.templateChanged"> (Vorlage geändert)</span></span>
              <span v-if="type==='woman'">
                <span v-if="mail.midwifeName"><label class="mailstyle">Hebamme: </label> {{ mail.midwifeName }}</span>
              </span>
              <span v-else-if="type==='midwife'">
                <span v-if="mail.womanName"><label class="mailstyle">Anfrage: </label> {{ mail.womanName }}</span>
              </span>
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <span style="
              display: grid;
              gap: 20px;
              grid-template-columns:2fr 1fr;">
              <div>
                <label class="mailstyle">Mailtype: </label>
                <div style="padding-bottom: 10px;">{{ mail.mailtemplateCaption }}</div>
                <div style="background-color: #EDEDED">
                  <label class="mailstyle">Betreff: </label>
                  <div style="padding-bottom: 10px;">{{ mail.subject }}</div>
                  <label class="mailstyle">Inhalt: </label>
                  <div style="white-space: pre-wrap;">{{ mail.content }}</div>
                </div>
              </div>
              <div>
                <label class="mailstyle">Verschickt von: </label>
                <div>{{ mail.userName }}</div>
                <div v-if="type==='woman'">
                  <div style="padding-top: 20px;" v-if="mail.midwifeName">
                    <label class="mailstyle">Verschickt an Hebamme: </label>
                    <div>{{ mail.midwifeName }}</div>
                    <div>{{ mail.midwifeAdressat }}</div>
                  </div>
                  <div style="padding-top: 20px;" v-if="mail.concernAdressat">
                    <label class="mailstyle">Verschickt an Frau: </label>
                    <div>{{ mail.concernAdressat }}</div>
                  </div>
                </div>
                <div v-else-if="type==='midwife'">
                  <div style="padding-top: 20px;" v-if="mail.concernAdressat">
                    <label class="mailstyle">Verschickt an Frau: </label>
                    <div>{{ mail.womanName }}</div>
                    <div>{{ mail.concernAdressat }}</div>
                  </div>
                  <div style="padding-top: 20px;" v-if="mail.midwifeAdressat">
                    <label class="mailstyle">Verschickt an Hebamme: </label>
                    <div>{{ mail.midwifeAdressat }}</div>
                  </div>
                </div>
              </div>
            </span>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    contentheight: function () {
      return this.$store.getters['size/formContent']
    }
  },

  props: {
    type: { type: String },
    sendedMailList: { type: Array }
  }
}
</script>

<style scope>
  .mailstyle {
    color: darkgray;
  }
</style>
