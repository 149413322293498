var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('overviewcontent', {
    attrs: {
      "data-list": _vm.infoList,
      "withNew": true
    },
    on: {
      "onOk": function ($event) {
        return _vm.newItem();
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (infoItem) {
        return [_c('v-system-bar', [_vm._v(" " + _vm._s(_vm.format(infoItem.item.infoDate)) + " - " + _vm._s(infoItem.item.userInfo.userName) + " "), _c('v-spacer'), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: infoItem.item.deleteDate,
            expression: "infoItem.item.deleteDate"
          }]
        }, [_vm._v("Automatisch gelöscht ab " + _vm._s(_vm.format(infoItem.item.deleteDate)))]), _c('v-spacer'), _c('v-icon', {
          attrs: {
            "light": "",
            "color": "blue"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteItem(infoItem.item.pid);
            }
          }
        }, [_vm._v("mdi-delete")]), _c('v-icon', {
          attrs: {
            "light": "",
            "color": "blue"
          },
          on: {
            "click": function ($event) {
              return _vm.updateItem(infoItem.item.pid);
            }
          }
        }, [_vm._v("mdi-pencil")])], 1), _c('div', {
          staticStyle: {
            "display": "grid",
            "grid-template-columns": "1fr 3fr 1fr"
          }
        }, [_c('div', [_c('span', [_c('v-tooltip', {
          key: infoItem.item.pid,
          attrs: {
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var on = _ref.on;
              return [_c('v-icon', _vm._g({
                directives: [{
                  name: "show",
                  rawName: "v-show",
                  value: infoItem.item.forAgency,
                  expression: "infoItem.item.forAgency"
                }],
                staticClass: "mr-2",
                attrs: {
                  "light": "",
                  "size": "30",
                  "color": "blue"
                },
                on: {
                  "click": function ($event) {
                    return _vm.deleteItem(infoItem.item.pid);
                  }
                }
              }, on), [_vm._v("mdi-information-variant")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Relevant in der Übersicht")])])], 1), _c('span', [_c('v-tooltip', {
          key: infoItem.item.pid,
          attrs: {
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var on = _ref2.on;
              return [_c('v-icon', _vm._g({
                directives: [{
                  name: "show",
                  rawName: "v-show",
                  value: infoItem.item.forMediation,
                  expression: "infoItem.item.forMediation"
                }],
                staticClass: "mr-2",
                attrs: {
                  "light": "",
                  "size": "30",
                  "color": "blue"
                },
                on: {
                  "click": function ($event) {
                    return _vm.deleteItem(infoItem.item.pid);
                  }
                }
              }, on), [_vm._v("mdi-binoculars")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Relevant bei der Vermittlung")])])], 1), _c('span', [_c('v-tooltip', {
          key: infoItem.item.pid,
          attrs: {
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var on = _ref3.on;
              return [_c('v-icon', _vm._g({
                directives: [{
                  name: "show",
                  rawName: "v-show",
                  value: infoItem.item.forTodo,
                  expression: "infoItem.item.forTodo"
                }],
                staticClass: "mr-2",
                attrs: {
                  "light": "",
                  "size": "30",
                  "color": "blue"
                },
                on: {
                  "click": function ($event) {
                    return _vm.deleteItem(infoItem.item.pid);
                  }
                }
              }, on), [_vm._v("mdi-wrench")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Zu erledigen")])])], 1)]), _c('div', {
          staticStyle: {
            "padding-top": "10px",
            "padding-bottom": "30px"
          }
        }, [_vm._v(_vm._s(infoItem.item.info))])])];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }