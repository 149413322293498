<template>
    <div>
      <mastercontent
        :data-list="mailTemplateList"
        :headers="headers"
        :writeRightProp="writeRight"
        componentId="mailTemplate"
        buttonOneText="Textbausteine"
        @onButtonOne="openTextModule"
        @openNew="openNew"
        @open="open"
        @remove="remove"
      >
        <template v-slot:mdt.priorityTemplate="{ item }">
          <checkitem :flag-item="item.priorityTemplate"></checkitem>
        </template>
      </mastercontent>
    </div>
</template>

<script>
import checkitem from '#enna/common/components/element/CheckItem'
import cloneDeep from 'lodash/cloneDeep'
import MailtemplateController from './mailtemplate.controller'
import mastercontent from '#enna/common/components/content/MasterContent'

export default {
  name: 'MailTemplateOverview',

  async created () {
    this.$pageInitController.init(this, this.$moduleInfoController.admin, 'Mailvorlagen')
  },

  components: {
    checkitem,
    mastercontent
  },

  methods: {
    openTextModule () {
      MailtemplateController.openTextModuleOverview()
    },

    openNew () {
      MailtemplateController.openNewMailTemplate()
    },

    open (mailTemplate) {
      MailtemplateController.openMailTemplate(mailTemplate.pid)
    },

    async remove (mailTemplate) {
      this.mailTemplateList = await MailtemplateController.deleteMailTemplate(mailTemplate.pid)
    }
  },

  data () {
    return {
      mailTemplateList: cloneDeep(this.propMailTemplateList),
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Addressat', value: 'receiver' },
        { text: 'Mailtyp', value: 'mailType' },
        { text: 'Standard', value: 'priorityTemplate' },
        { text: 'Beschreibung', value: 'description' }
      ]
    }
  },

  props: {
    writeRight: { type: String },
    propMailTemplateList: { type: Array }
  }

}
</script>
