var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('formcontent', {
    attrs: {
      "ok-text": "Speichern",
      "cancel-text": "Abbrechen",
      "write-right": "concernWrite"
    },
    on: {
      "onOk": _vm.save,
      "onCancel": _vm.cancel
    }
  }, [_c('span', {
    staticClass: "font-weight-thin"
  }, [_vm._v("Beratung am ")]), _c('span', {
    staticClass: "font-weight-thick"
  }, [_vm._v(_vm._s(_vm.datetime(_vm.advice.adviceTime)))]), _c('span', {
    staticClass: "font-weight-thin"
  }, [_vm._v(" durch ")]), _c('span', {
    staticClass: "font-weight-thick"
  }, [_vm._v(_vm._s(_vm.advice.userName))]), _c('v-select', {
    attrs: {
      "items": _vm.adviceThemeList,
      "item-text": "name",
      "item-value": "id",
      "menu-props": {
        maxHeight: '400'
      },
      "label": "Beratungsinhalte",
      "multiple": "",
      "chips": "",
      "deletable-chips": "",
      "persistent-hint": ""
    },
    model: {
      value: _vm.advice.themeIdList,
      callback: function ($$v) {
        _vm.$set(_vm.advice, "themeIdList", $$v);
      },
      expression: "advice.themeIdList"
    }
  }), _c('v-textarea', {
    staticStyle: {
      "width": "95%"
    },
    attrs: {
      "clearable": "",
      "clear-icon": "mdi-close-circle",
      "label": "Info"
    },
    model: {
      value: _vm.advice.comment,
      callback: function ($$v) {
        _vm.$set(_vm.advice, "comment", $$v);
      },
      expression: "advice.comment"
    }
  }), _c('v-text-field', {
    staticStyle: {
      "width": "140px"
    },
    attrs: {
      "type": "number",
      "label": "Dauer (Minuten)",
      "clearable": ""
    },
    model: {
      value: _vm.advice.duration,
      callback: function ($$v) {
        _vm.$set(_vm.advice, "duration", $$v);
      },
      expression: "advice.duration"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }