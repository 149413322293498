import store from '../../../common/store'

export default {
  user: {
    category: 'user',
    colour: 'red'
  },
  network: {
    category: 'network',
    colour: 'aqua'
  },
  midwife: {
    category: 'midwife',
    colour: 'green'
  },
  concern: {
    category: 'concern',
    colour: 'blue'
  },
  admin: {
    category: 'admin',
    colour: 'yellow'
  },

  setModuleinfo (category, theme, detail, name) {
    store.dispatch('moduleinfo/setModuleinfo', {
      category: category.category,
      colour: category.colour,
      theme: theme,
      detail: detail,
      name: name
    })
  },

  getModulinfo () {
    return store.state.moduleinfo.moduleinfo
  }
}
