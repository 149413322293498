import ApiService from '#enna/common/service/api/api.service'

const WebformService = {
  async queryDoubletCandidates (pid, possibleDoubletList) {
    const data = {
      pid: pid,
      possibleDoubletList: possibleDoubletList
    }
    const result = await ApiService.doPost('/enna/cause/webform/doubletcandidate', data)
    return result.data
  },

  async queryWebformInquiry () {
    const result = await ApiService.doGet('/enna/cause/webform/view/query')
    return result
  },

  async queryWebformInquiryFromServer () {
    const webformParameter = {}
    const result = await ApiService.doPost('/enna/cause/webform/serverquery', webformParameter)
    return result
  },

  async executeDoubletProcessing (data) {
    await ApiService.doPost('/enna/cause/webform/doubletprocessing', data)
  },

  async takeDeleteWebformInquiry (data) {
    await ApiService.doPost('/enna/cause/webform/takedelete', data)
  }
}

export default WebformService
