import router from '#enna/common/router'
import CaseSearchService from './case.search.service'
import CaseRegistrationService from './case.registration.service'
import CaseOverviewController from '../case/case.overview.controller'
import ConcernCauseactivityController from '../causeactivity/concern.causeactivity.controller'
import WomanController from '../woman/concern.woman.controller'
import cloneDeep from 'lodash/cloneDeep'
import { transformToActivityIdList } from '../common/search.utils'

const SearchStart = {
  New: 'New',
  Case: 'Case',
  CauseActivity: 'CauseActivity'
}

export const DateType = {
  Edate: 'Edate',
  Bdate: 'Bdate'
}

export default {
  async isValidForPropose (caseLocator, concernVersion, flexibleData) {
    const activityIdList = []
    flexibleData.currentSearchActivityList.forEach(item => {
      activityIdList.push(item.activity.activityId)
    })

    const response = await CaseSearchService.isValidForPropose(caseLocator, concernVersion, activityIdList)
    return response
  },

  async checkChange (caseLocator, concernVersion, regionId, edate, bdate, searchActivityList) {
    const response = await CaseSearchService.checkChange(caseLocator, concernVersion, regionId, edate, bdate, searchActivityList)

    let message = ''
    if (response.messageList.length > 0) {
      message = 'Änderung bereits gespeicherter Daten:\n\n'
      response.messageList.forEach(item => {
        message += '- ' + item + '\n'
      })
    }

    const result = {
      hasChanged: response.valid,
      message: message
    }
    return result
  },

  async openCaseToSearch1 (caseLocator) {
    internallyStartSearchMidwife(SearchStart.Case, caseLocator)
  },

  async openCauseActivityToSearch23 (caseLocator) {
    internallyStartSearchMidwife(SearchStart.CauseActivity, caseLocator)
  },

  async openNewToSearch4 () {
    const response = await CaseSearchService.loadEmptySearch()
    const viewSearchInfo = response.viewSearchInfo
    const constantData = {
      originSearchData: null,
      searchKonfig: viewSearchInfo.searchKonfig,
      searchStart: viewSearchInfo.searchStart
    }
    await internallCallSearchPage(viewSearchInfo.configSearchView, constantData, null, {
      selectedOptionList: [],
      edate: null,
      dateType: null,
      region: null,
      currentSearchActivityList: []
    })
  },

  async openSearchResultToSearch8 (configSearchView, constantData, concernData, flexibleData) {
    await internallCallSearchPage(configSearchView, constantData, concernData, flexibleData)
  },

  async openCancelledRegistrationToSearch9 (configSearchView, constantData, concernData, flexibleData) {
    await internallCallSearchPage(configSearchView, constantData, concernData, flexibleData)
  },

  async proposeMidwife (midwifeId, configSearchView, constantData, concernData, flexibleData) {
    const activityList = transformToActivityIdList(flexibleData.currentSearchActivityList)
    const response = await CaseSearchService.proposeMidwife(concernData.caseLocator, concernData.concernVersion, midwifeId, activityList)
    internalBackToCase('BackCase', response)
  },

  async backToCase (backTo, caseLocator) {
    internalBackToCase(backTo, caseLocator)
  },

  async startRegistration (configSearchView, constantData, concernData, flexibleData) {
    let response = null
    if (concernData) {
      response = await CaseRegistrationService.load(concernData.caseLocator)
    } else {
      response = await CaseRegistrationService.new()
    }
    router.push({
      name: 'registration',
      params: {
        configSearchView: configSearchView,
        concernData: concernData,
        constantData: constantData,
        flexibleData: flexibleData,
        propRegistration: response
      }
    })
  },

  async changeToFile (configSearchView, constantData, concernData, flexibleData, registration) {
    const saveRegistrationResult = await internallSaveRegistration(configSearchView, constantData, concernData, flexibleData, registration)
    internalBackToCase('FileWomanData', saveRegistrationResult.concernData.caseLocator)
  },

  async saveRegistration (configSearchView, constantData, concernData, flexibleData, registration) {
    const saveRegistrationResult = await internallSaveRegistration(configSearchView, constantData, concernData, flexibleData, registration)
    internallCallSearchPage(saveRegistrationResult.configSearchView, constantData, saveRegistrationResult.concernData, flexibleData)
  },

  async startSearching (configSearchView, constantData, concernData, flexibleData) {
    let searchOptionList = []
    const searchActivityList = []
    if (flexibleData) {
      if (flexibleData.selectedOptionList) {
        searchOptionList = flexibleData.selectedOptionList
      }
      if (flexibleData.currentSearchActivityList) {
        flexibleData.currentSearchActivityList.forEach(item => {
          const newItem = {
            activityId: item.activity.activityId,
            from: item.from,
            until: item.until,
            useDeadline: item.useDeadline
          }
          searchActivityList.push(newItem)
        })
      }
    }
    const searchingData = {
      caseLocator: concernData?.caseLocator,
      searchActivityList: searchActivityList,
      date: flexibleData?.edate,
      regionId: flexibleData?.region?.regionId,
      searchOptionSet: searchOptionList
    }

    const response = await CaseSearchService.searching(searchingData)
    const searchResult = response

    const hasFoundMidwife = searchResult.dataList.length > 0
    if (hasFoundMidwife) {
      router.push({
        name: 'searchresult',
        params: {
          configSearchView: configSearchView,
          concernData: concernData,
          constantData: constantData,
          flexibleData: flexibleData,
          searchResult: searchResult
        }
      })
      return true
    } else {
      return false
    }
  }
}

async function internallyStartSearchMidwife (searchStart, caseLocator) {
  const response = await CaseSearchService.loadStartSearch(searchStart, caseLocator)
  const viewSearchInfo = response.viewSearchInfo

  const concernData = {
    concernVersion: response.concernVersion,
    caseLocator: caseLocator,
    womanName: response.womanName
  }

  const constantData = {
    originSearchData: viewSearchInfo.originSearchData,
    searchKonfig: viewSearchInfo.searchKonfig,
    searchStart: searchStart
  }

  let edate = null
  let dateType = null
  let region = null

  if (viewSearchInfo.originSearchData) {
    const origin = viewSearchInfo.originSearchData
    if (origin.bdate) {
      edate = origin.bdate
      dateType = DateType.Bdate
    } else if (origin.edate) {
      edate = origin.edate
      dateType = DateType.Edate
    }
    region = origin.region
  }

  const flexibleData = {
    selectedOptionList: [],
    edate: edate,
    dateType: dateType,
    region: cloneDeep(region),
    currentSearchActivityList: cloneDeep(viewSearchInfo.originSearchData.activityList)
  }
  await internallCallSearchPage(viewSearchInfo.configSearchView, constantData, concernData, flexibleData)
}

async function internallCallSearchPage (configSearchView, constantData, concernData, flexibleData) {
  router.push({
    name: 'searchmidwife',
    params: {
      configSearchView: configSearchView,
      concernData: concernData,
      constantData: constantData,
      flexibleData: flexibleData
    },
    query: { reload: Date.now() }
  })
}

async function internallSaveRegistration (configSearchView, constantData, concernData, flexibleData, registration) {
  let edate = null
  let bdate = null
  if (flexibleData.dateType === DateType.Edate) {
    edate = flexibleData.edate
  } else if (flexibleData.dateType === DateType.Bdate) {
    bdate = flexibleData.edate
  }

  const activityList = []
  for (var index = 0; index < flexibleData.currentSearchActivityList.length; index++) {
    const item = flexibleData.currentSearchActivityList[index]
    const newRecord = {
      activityId: item.activity.activityId,
      from: item.from,
      until: item.until,
      acute: item.acute
    }
    activityList.push(newRecord)
  }

  const registrationSupplement = {
    activityList: activityList,
    regionId: flexibleData.region?.regionId,
    edate: edate,
    bdate: bdate
  }

  const saveRegistrationResult = await CaseRegistrationService.save(
    {
      searchStart: constantData.searchStart,
      registration: registration,
      registrationSupplement: registrationSupplement
    }
  )
  return saveRegistrationResult
}

async function internalBackToCase (backTo, caseLocator) {
  if (backTo === 'BackCase') {
    CaseOverviewController.openCaseFile(caseLocator)
  } else if (backTo === 'BackCauseActivity') {
    ConcernCauseactivityController.openConcernCauseActivityOverview(caseLocator)
  } else if (backTo === 'FileCauseActivity') {
    ConcernCauseactivityController.openConcernCauseActivityOverview(caseLocator)
  } else if (backTo === 'FileWomanData') {
    WomanController.openConcernWomanDetail(caseLocator)
  }
}
