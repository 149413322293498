var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-template-columns": "1fr 5fr 11fr 1fr"
    }
  }, [_c('div', {
    style: {
      'display': 'table-cell',
      'width': '56px',
      'height': '56px',
      'background-color': _vm.moduleinfo.colour
    }
  }), _c('div', {
    style: {
      'display': 'table-cell',
      'padding-left': '10px',
      'vertical-align': 'top'
    }
  }, [_c('div', [_c('h3', {
    staticClass: "font-weight-bold title"
  }, [_vm._v(_vm._s(_vm.moduleinfo.theme))]), _c('h4', {
    staticClass: "font-weight-light"
  }, [_vm._v(_vm._s(_vm.moduleinfo.detail))])])]), _c('h2', {
    staticClass: "font-weight-black font-italic",
    style: {
      'padding-left': '66px',
      'padding-top': '10px'
    }
  }, [_vm._v(_vm._s(_vm.moduleinfo.name))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }