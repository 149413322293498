import Axios from 'axios'
import ErrorController from '../../../module/app/error/error.controller'
import getEnv from '@/utils/env'

Axios.defaults.baseURL = getEnv('VUE_APP_SERVICE_URL')

Axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    try {
      let message
      if (error.response.status === 401) {
        const temp = error.response.data.message
        message = JSON.parse(temp)
      } else {
        message = error.response.data
      }
      ErrorController.throwError(message)
    } catch (error) {
      ErrorController.throwErrorAsString('Fehler beim Zugriff auf den Server', ['Möglicherweise ist der Server aktuell nicht erreichbar'])
    }
  })

const ApiService = {

  async doPost (path, data) {
    var response = await Axios.post(path, data)
    return response.data
  },

  async downloadFile (path, data) {
    var response = await Axios.post(path, data)
    const contentDisposition = response.headers['content-disposition']
    const filename = contentDisposition.split('filename=')[1]
    const downloadInfo = {
      fileName: filename,
      content: response.data
    }
    return downloadInfo
  },

  async doGet (path) {
    var response = await Axios.get(path)
    return response.data
  },

  async doDelete (path, data) {
    var response = await Axios.delete(path, data)
    return response.data
  },

  setAuthHeader (token) {
    Axios.defaults.headers.common.Authorization = 'Bearer ' + token
  },

  removeAuthHeader () {
    Axios.defaults.headers.common = {}
  }
}

export default ApiService
