var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('dynpropbasedetail', {
    attrs: {
      "dynPropParam": _vm.dynPropParam
    },
    on: {
      "onSave": _vm.save
    }
  }, [_c('datepicker', {
    staticStyle: {
      "width": "240px"
    },
    attrs: {
      "title": "Wert",
      "propdate": _vm.dateValue
    },
    on: {
      "update:propdate": function ($event) {
        _vm.dateValue = $event;
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }