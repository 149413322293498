var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('formcontent', {
    attrs: {
      "ok-text": "Speichern",
      "cancel-text": "Abbrechen",
      "write-right": "concernWrite"
    },
    on: {
      "onOk": _vm.save,
      "onCancel": _vm.cancel
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-column-gap": "20px",
      "grid-template-columns": "1fr 1fr"
    }
  }, [_c('formcard', {
    attrs: {
      "title": "Frau"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-column-gap": "20px",
      "grid-template-rows": "1fr 1fr",
      "grid-template-columns": "1fr 1fr"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Vorname"
    },
    model: {
      value: _vm.dtoWoman.prename,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman, "prename", $$v);
      },
      expression: "dtoWoman.prename"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Nachname"
    },
    model: {
      value: _vm.dtoWoman.surname,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman, "surname", $$v);
      },
      expression: "dtoWoman.surname"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Neuer Nachname"
    },
    model: {
      value: _vm.dtoWoman.marriageSurname,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman, "marriageSurname", $$v);
      },
      expression: "dtoWoman.marriageSurname"
    }
  }), _c('datepicker', {
    attrs: {
      "title": "Verwenden ab / Heirat",
      "propdate": _vm.dtoWoman.marriageDate
    },
    on: {
      "update:propdate": function ($event) {
        return _vm.$set(_vm.dtoWoman, "marriageDate", $event);
      }
    }
  }), _c('v-text-field', {
    attrs: {
      "counter": "number",
      "label": "Geburtsjahr"
    },
    model: {
      value: _vm.dtoWoman.birthyear,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman, "birthyear", $$v);
      },
      expression: "dtoWoman.birthyear"
    }
  })], 1)]), _c('formcard', {
    attrs: {
      "title": "Schwangerschaft / Geburt"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-column-gap": "20px",
      "grid-template-columns": "1fr 1fr"
    }
  }, [_c('datepicker', {
    attrs: {
      "title": "ET",
      "propdate": _vm.dtoWoman.edate
    },
    on: {
      "update:propdate": function ($event) {
        return _vm.$set(_vm.dtoWoman, "edate", $event);
      }
    }
  }), _c('datepicker', {
    attrs: {
      "title": "GT",
      "propdate": _vm.dtoWoman.bdate
    },
    on: {
      "update:propdate": function ($event) {
        return _vm.$set(_vm.dtoWoman, "bdate", $event);
      }
    }
  }), _c('v-text-field', {
    attrs: {
      "counter": "number",
      "label": "Para"
    },
    model: {
      value: _vm.dtoWoman.para,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman, "para", $$v);
      },
      expression: "dtoWoman.para"
    }
  }), _c('v-text-field', {
    attrs: {
      "counter": "number",
      "label": "Gravida"
    },
    model: {
      value: _vm.dtoWoman.gravida,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman, "gravida", $$v);
      },
      expression: "dtoWoman.gravida"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.multipleBirthList,
      "item-text": "caption",
      "item-value": "keylistValueId",
      "label": "Mehrfachgeburt",
      "clearable": ""
    },
    model: {
      value: _vm.dtoWoman.multipleBirth,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman, "multipleBirth", $$v);
      },
      expression: "dtoWoman.multipleBirth"
    }
  })], 1)]), _c('formcard', {
    attrs: {
      "title": "Kontakt"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-column-gap": "20px",
      "grid-template-rows": "1fr 1fr",
      "grid-template-columns": "1fr 1fr"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Telefon"
    },
    model: {
      value: _vm.dtoWoman.phone1,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman, "phone1", $$v);
      },
      expression: "dtoWoman.phone1"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Weiteres Telefon"
    },
    model: {
      value: _vm.dtoWoman.phone2,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman, "phone2", $$v);
      },
      expression: "dtoWoman.phone2"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "E-Mail"
    },
    model: {
      value: _vm.dtoWoman.mail,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman, "mail", $$v);
      },
      expression: "dtoWoman.mail"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Hinweise zur Erreichbarkeit"
    },
    model: {
      value: _vm.dtoWoman.contactInfo,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman, "contactInfo", $$v);
      },
      expression: "dtoWoman.contactInfo"
    }
  })], 1)]), _c('formcard', {
    attrs: {
      "title": "Anschrift"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-column-gap": "20px",
      "grid-template-rows": "1fr 1fr",
      "grid-template-columns": "1fr 1fr"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Straße"
    },
    model: {
      value: _vm.dtoWoman.adress.street,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman.adress, "street", $$v);
      },
      expression: "dtoWoman.adress.street"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Hausnummer"
    },
    model: {
      value: _vm.dtoWoman.adress.streetNumber,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman.adress, "streetNumber", $$v);
      },
      expression: "dtoWoman.adress.streetNumber"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "PLZ"
    },
    model: {
      value: _vm.dtoWoman.adress.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman.adress, "postcode", $$v);
      },
      expression: "dtoWoman.adress.postcode"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Stadt"
    },
    model: {
      value: _vm.dtoWoman.adress.city,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman.adress, "city", $$v);
      },
      expression: "dtoWoman.adress.city"
    }
  }), _c('singleselection', {
    staticStyle: {
      "margin-bottom": "-11px"
    },
    attrs: {
      "selectedId": _vm.dtoWoman.adress.regionId,
      "prop-selected-item": _vm.dtoWoman.adress.regionId,
      "caption": "Stadtteil / Ortschaft",
      "idName": "regionId",
      "propItemList": _vm.regionList
    },
    on: {
      "update:selectedId": function ($event) {
        return _vm.$set(_vm.dtoWoman.adress, "regionId", $event);
      },
      "update:selected-id": function ($event) {
        return _vm.$set(_vm.dtoWoman.adress, "regionId", $event);
      }
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Hinweise zur Adresse"
    },
    model: {
      value: _vm.dtoWoman.adresschangeinfo,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman, "adresschangeinfo", $$v);
      },
      expression: "dtoWoman.adresschangeinfo"
    }
  })], 1)]), _c('formcard', {
    attrs: {
      "title": "Alternative Anschrift"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-column-gap": "20px",
      "grid-template-rows": "1fr 1fr",
      "grid-template-columns": "1fr 1fr"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Straße"
    },
    model: {
      value: _vm.dtoWoman.futureAdress.street,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman.futureAdress, "street", $$v);
      },
      expression: "dtoWoman.futureAdress.street"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Hausnummer"
    },
    model: {
      value: _vm.dtoWoman.futureAdress.streetNumber,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman.futureAdress, "streetNumber", $$v);
      },
      expression: "dtoWoman.futureAdress.streetNumber"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "PLZ"
    },
    model: {
      value: _vm.dtoWoman.futureAdress.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman.futureAdress, "postcode", $$v);
      },
      expression: "dtoWoman.futureAdress.postcode"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Stadt"
    },
    model: {
      value: _vm.dtoWoman.futureAdress.city,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman.futureAdress, "city", $$v);
      },
      expression: "dtoWoman.futureAdress.city"
    }
  }), _c('singleselection', {
    staticStyle: {
      "margin-bottom": "-11px"
    },
    attrs: {
      "selectedId": _vm.dtoWoman.futureAdress.regionId,
      "prop-selected-item": _vm.dtoWoman.futureAdress.regionId,
      "caption": "Stadtteil / Ortschaft",
      "idName": "regionId",
      "propItemList": _vm.regionList
    },
    on: {
      "update:selectedId": function ($event) {
        return _vm.$set(_vm.dtoWoman.futureAdress, "regionId", $event);
      },
      "update:selected-id": function ($event) {
        return _vm.$set(_vm.dtoWoman.futureAdress, "regionId", $event);
      }
    }
  }), _c('datepicker', {
    attrs: {
      "title": "Adressänderung ab",
      "propdate": _vm.dtoWoman.adresschangedate
    },
    on: {
      "update:propdate": function ($event) {
        return _vm.$set(_vm.dtoWoman, "adresschangedate", $event);
      }
    }
  })], 1)]), _c('formcard', {
    attrs: {
      "title": "Info"
    }
  }, [_c('v-textarea', {
    staticStyle: {
      "width": "95%"
    },
    attrs: {
      "clearable": "",
      "clear-icon": "mdi-close-circle",
      "label": "Original-Info"
    },
    model: {
      value: _vm.dtoWoman.info,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman, "info", $$v);
      },
      expression: "dtoWoman.info"
    }
  }), _c('v-textarea', {
    staticStyle: {
      "width": "95%"
    },
    attrs: {
      "clearable": "",
      "clear-icon": "mdi-close-circle",
      "label": "Überarbeitete Info"
    },
    model: {
      value: _vm.dtoWoman.modifiedInfo,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman, "modifiedInfo", $$v);
      },
      expression: "dtoWoman.modifiedInfo"
    }
  })], 1), _c('formcard', {
    attrs: {
      "title": "Kontakt über"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-column-gap": "20px",
      "grid-template-columns": "1fr 1fr"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Kontakt durch"
    },
    model: {
      value: _vm.dtoWoman.contacter.name,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman.contacter, "name", $$v);
      },
      expression: "dtoWoman.contacter.name"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Telefon"
    },
    model: {
      value: _vm.dtoWoman.contacter.phone,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman.contacter, "phone", $$v);
      },
      expression: "dtoWoman.contacter.phone"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "E-Mail"
    },
    model: {
      value: _vm.dtoWoman.contacter.mail,
      callback: function ($$v) {
        _vm.$set(_vm.dtoWoman.contacter, "mail", $$v);
      },
      expression: "dtoWoman.contacter.mail"
    }
  })], 1)])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }