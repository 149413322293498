import ChangelogService from './changelog.service'
import router from '#enna/common/router'

export default {
  async openChangelogOverview () {
    const response = await ChangelogService.queryChangelog()
    const changelogList = response
    router.push({ name: 'changelog', params: { changelogList: changelogList } })
  }
}
