var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('formcontent', {
    attrs: {
      "ok-text": "Bearbeiten",
      "write-right": "midMasterdataWrite"
    },
    on: {
      "onOk": _vm.edit
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-template-columns": "1fr 1fr"
    }
  }, [_c('themecard', {
    attrs: {
      "title": "Frau"
    }
  }, [_vm._v(" " + _vm._s(_vm.woman.prename) + " " + _vm._s(_vm.woman.surname) + " "), _vm.showBirthyear ? _c('div', [_vm._v("Geburtsjahr " + _vm._s(_vm.woman.birthyear))]) : _vm._e()]), _c('themecard', {
    attrs: {
      "title": "Schwangerschaft / Geburt"
    }
  }, [_vm.woman.edate ? _c('div', [_vm._v(" ET: "), _c('dateitem', {
    staticStyle: {
      "display": "inline"
    },
    attrs: {
      "date-item": _vm.woman.edate
    }
  })], 1) : _vm._e(), _vm.woman.bdate ? _c('div', [_vm._v(" Geburt: "), _c('dateitem', {
    staticStyle: {
      "display": "inline"
    },
    attrs: {
      "date-item": _vm.woman.bdate
    }
  })], 1) : _vm._e(), _vm.woman.gravida ? _c('div', [_vm._v(" Gravida: " + _vm._s(_vm.woman.gravida) + " ")]) : _vm._e(), _vm.woman.para ? _c('div', [_vm._v(" Para: " + _vm._s(_vm.woman.para) + " ")]) : _vm._e(), _vm.woman.multipleBirth ? _c('div', [_vm._v(" " + _vm._s(_vm.woman.multipleBirth.name) + " ")]) : _vm._e()]), _vm.woman.mailInfo ? _c('themecard', {
    attrs: {
      "title": "Info aus dem Webformular"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.woman.mailInfo))])]) : _vm._e(), _c('themecard', {
    attrs: {
      "title": "Kontaktdaten"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.woman.phone1))]), _c('div', [_vm._v(_vm._s(_vm.woman.phone2))]), _c('div', [_vm._v(_vm._s(_vm.woman.mail))]), _c('div', [_vm._v(_vm._s(_vm.woman.contactInfo))])]), _vm.showAdress ? _c('themecard', {
    attrs: {
      "title": "Anschrift"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.woman.adress.street) + " " + _vm._s(_vm.woman.adress.streetNumber))]), _c('div', [_vm._v(_vm._s(_vm.woman.adress.postcode) + " " + _vm._s(_vm.woman.adress.city))]), _vm.woman.adress.region ? _c('div', [_vm._v(_vm._s(_vm.woman.adress.region.name))]) : _vm._e(), _c('div', [_vm._v(_vm._s(_vm.woman.adresschangeinfo))])]) : _vm._e(), _c('themecard', {
    attrs: {
      "title": "Zusatzdaten"
    }
  }, [_c('dynpropoverview', {
    attrs: {
      "withHeader": false,
      "dynPropPropertyList": _vm.dynPropList
    },
    on: {
      "onEditDynPropData": _vm.onEditDynPropData
    }
  })], 1), _vm.showFutureAdress ? _c('themecard', {
    attrs: {
      "title": "Alternative Anschrift"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.woman.futureAdress.street) + " " + _vm._s(_vm.woman.futureAdress.streetNumber))]), _c('div', [_vm._v(_vm._s(_vm.woman.futureAdress.postcode) + " " + _vm._s(_vm.woman.futureAdress.city))]), _vm.woman.futureAdress.region ? _c('div', [_vm._v(_vm._s(_vm.woman.futureAdress.region.name))]) : _vm._e(), _vm.showFutureAdressDate ? _c('div', [_vm._v(" Neue Adresse gültig ab "), _c('dateitem', {
    staticStyle: {
      "display": "inline"
    },
    attrs: {
      "date-item": _vm.woman.adresschangedate
    }
  })], 1) : _vm._e()]) : _vm._e(), _vm.showInfo ? _c('themecard', {
    attrs: {
      "title": "Info"
    }
  }, [_vm.woman.info ? _c('div', [_c('label', [_vm._v("Original-Info:")]), _c('div', [_vm._v(_vm._s(_vm.woman.info))])]) : _vm._e(), _vm.woman.modifiedInfo ? _c('div', [_c('label', [_vm._v("Überarbeitete Info:")]), _c('div', [_vm._v(_vm._s(_vm.woman.modifiedInfo))])]) : _vm._e()]) : _vm._e(), _vm.showContacter ? _c('themecard', {
    attrs: {
      "title": "Kontakt über"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.woman.contacter.name))]), _c('div', [_vm._v(_vm._s(_vm.woman.contacter.phone))]), _c('div', [_vm._v(_vm._s(_vm.woman.contacter.mail))])]) : _vm._e()], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }