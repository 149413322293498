var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('formcontent', {
    attrs: {
      "ok-text": "Bearbeiten",
      "write-right": "midMasterdataWrite"
    },
    on: {
      "onOk": _vm.update
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-template-columns": "5fr 3fr"
    }
  }, [_c('div', [_c('div', {
    staticClass: "font-weight-light",
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_vm._v("Name")]), _c('span', {
    staticClass: "font-weight-medium",
    staticStyle: {
      "padding-left": "15px"
    }
  }, [_vm._v(_vm._s(_vm.midwifeMasterdata.shortname))]), _c('span', {
    staticClass: "font-weight-medium",
    staticStyle: {
      "padding-left": "15px"
    }
  }, [_vm._v(_vm._s(_vm.midwifeMasterdata.surname))]), _c('span', {
    staticClass: "font-weight-medium",
    staticStyle: {
      "padding-left": "15px"
    }
  }, [_vm._v(_vm._s(_vm.midwifeMasterdata.practiseName))]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.birthday,
      expression: "birthday"
    }],
    staticClass: "font-weight-light",
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_vm._v("Geburtsdatum")]), _c('span', {
    staticClass: "font-weight-medium",
    staticStyle: {
      "padding-left": "15px"
    }
  }, [_vm._v(_vm._s(_vm.birthday))]), _c('div', {
    staticClass: "font-weight-light",
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_vm._v("Teilnahme seit")]), _c('span', {
    staticClass: "font-weight-medium",
    staticStyle: {
      "padding-left": "15px"
    }
  }, [_vm._v(_vm._s(_vm.memberSince))]), _c('div', {
    staticClass: "font-weight-light",
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_vm._v("Homepage")]), _c('span', {
    staticClass: "font-weight-medium",
    staticStyle: {
      "padding-left": "15px"
    }
  }, [_vm._v(_vm._s(_vm.midwifeMasterdata.homepage))])]), _c('div', [_c('div', {
    staticClass: "font-weight-light",
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_vm._v("Anschrift")]), _c('span', {
    staticClass: "font-weight-medium",
    staticStyle: {
      "padding-left": "15px"
    }
  }, [_vm._v(_vm._s(_vm.midwifeMasterdata.postcode))]), _c('span', {
    staticClass: "font-weight-medium",
    staticStyle: {
      "padding-left": "15px"
    }
  }, [_vm._v(_vm._s(_vm.midwifeMasterdata.city))]), _c('div', {
    staticClass: "font-weight-medium",
    staticStyle: {
      "padding-left": "15px"
    }
  }, [_vm._v(_vm._s(_vm.midwifeMasterdata.streetnumber))]), _c('div', {
    staticClass: "font-weight-medium",
    staticStyle: {
      "padding-left": "15px"
    }
  }, [_vm._v(_vm._s(_vm.midwifeMasterdata.district))]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.midwifeMasterdata.location,
      expression: "midwifeMasterdata.location"
    }],
    staticClass: "font-weight-light",
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_vm._v("Standort")]), _c('span', {
    staticClass: "font-weight-medium",
    staticStyle: {
      "padding-left": "15px"
    }
  }, [_vm._v(_vm._s(_vm.midwifeMasterdata.location))])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }