<template>
  <v-btn v-on:click="$emit('cmdclick')">
    {{ caption }}
<!--    <v-icon>mdi-content-save-outline</v-icon>-->
  </v-btn>
</template>

<script>
export default {
  props: {
    caption: String
  }
}
</script>

<style scoped>

</style>
