var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('div', [_c('v-app-bar', {
    staticStyle: {
      "padding-top": "50px",
      "height": "170px"
    },
    attrs: {
      "app": "",
      "color": "grey lighten-1",
      "dark": ""
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-template-columns": "2fr 2fr"
    }
  }, [_c('div'), _c('v-toolbar-title', [_c('span', {
    staticClass: "font-weight-thin",
    staticStyle: {
      "font-size": "4em"
    }
  }, [_vm._v("e n ")]), _c('span', {
    staticClass: "font-weight-bold",
    staticStyle: {
      "font-size": "4em"
    }
  }, [_vm._v("n a")]), _c('span', {
    staticClass: "font-weight-bold",
    staticStyle: {
      "color": "aquamarine",
      "font-size": "4em",
      "padding-left": "20px"
    }
  }, [_vm._v(".")]), _c('span', {
    staticStyle: {
      "padding-left": "20px",
      "font-size": "2em"
    }
  }, [_vm._v("network")])])], 1), _c('v-spacer')], 1)], 1), _c('div', {
    staticStyle: {
      "display": "grid",
      "grid-template-areas": "'. . .' '. login .' '. . .'",
      "grid-template-rows": "1fr 1fr 1fr",
      "grid-template-columns": "1fr 1fr 1fr"
    }
  }, [_c('div', {
    staticStyle: {
      "grid-area": "login"
    }
  }, [_vm._t("default")], 2)]), _c('errorpanel')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }