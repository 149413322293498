var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('mastercontent', {
    attrs: {
      "data-list": _vm.textModuleList,
      "headers": _vm.headers,
      "writeRightProp": _vm.writeRight,
      "componentId": "textModule",
      "buttonOneText": "Mailvorlagen"
    },
    on: {
      "onButtonOne": _vm.openMailTemplate,
      "openNew": _vm.openNew,
      "open": _vm.open,
      "remove": _vm.remove
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }