<template>
  <div>
    <v-autocomplete
      v-model="selectedItem"
      :items="itemList"
      filled
      background-color="white"
      chips
      :label="caption"
      @change="onInput"
      item-text="name"
      :item-value="idName"
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="remove(data.item)"
        >
          {{ data.item.name }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <v-list-item-content >
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { transformGroupedData } from '@/common/utils/datagroup.utils'

export default {
  props: {
    idName: String,
    propItemList: { type: Array },
    caption: String,
    propSelectedItem: String
  },

  computed: {
    itemList () {
      const result = transformGroupedData(this.propItemList)
      return result
    }
  },

  data () {
    return {
      selectedItem: this.propSelectedItem
    }
  },
  methods: {
    onInput () {
      this.$emit('update:selectedId', this.selectedItem)
    },
    remove (item) {
      this.selectedItem = null
      this.$emit('update:selectedId', this.selectedItem)
    }
  }
}
</script>
<style>
.v-autocomplete__content .v-select-list .v-subheader {
  background-color: darkgray;
  color: white;

}
</style>
