import ApiService from '#enna/common/service/api/api.service'

const ChangelogService = {
  async queryChangelog () {
    const result = await ApiService.doGet('/enna/office/changelog/query')
    return result
  }
}

export default ChangelogService
