import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from '../../module/app/auth/auth.store'
import { right } from './right/right.store'
import { moduleinfo } from '../../module/app/moduleinfo/moduleinfo.store'
import { menu } from '../../module/app/menu/menu.store'
import { size } from '../../common/components/layout/network/size.store'
import { pageinfo } from '../../module/app/pageinfo/pageinfo.store'
import { error } from '../../module/app/error/error.store'
import { navigation } from '../components/layout/network/navigation.store'
import { versioning } from '../../module/app/versioning/versioning.store'
import { infoDialog } from '../components/element/infodialog/infoDialog.store'
import { carrier } from '../../module/app/auth/carrier.store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    right,
    moduleinfo,
    menu,
    size,
    pageinfo,
    error,
    navigation,
    versioning,
    infoDialog,
    carrier
  },
  state: {
  },
  mutations: {
  },
  actions: {
  }
})
