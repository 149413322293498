var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.flagItem ? _c('v-icon', {
    attrs: {
      "small": "",
      "light": ""
    }
  }, [_vm._v("mdi-check")]) : _c('div')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }