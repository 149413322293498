import ApiService from '../../../common/service/api/api.service'

const DynPropService = {
  async saveDatabaseProperty (path, dtoProperty) {
    const result = await ApiService.doPost('/enna/dynprop/' + path, dtoProperty)
    return result
  },

  async saveConcernProperty (path, dtoProperty) {
    const result = await ApiService.doPost('/enna/cause/woman/dynprop/save/' + path, dtoProperty)
    return result
  }
}

export default DynPropService
