var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tablecontent', {
    attrs: {
      "tablecontentKeyId": "pid",
      "objectIdName": "midwifeId",
      "data-list": _vm.midwifeList,
      "headers": _vm.headers,
      "propFilterKey": _vm.propFilterKey,
      "propFilterParam": _vm.propFilterParam,
      "withRemove": true
    },
    on: {
      "remove": _vm.deleteMidwife
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }