<template>
  <div >
    <div v-bind:style="{ 'overflow-y': 'hidden', 'height': contentheight + 'px' }">
      <div style="height: 40%;">
        <label class="grey--text font-weight-medium">Platzhalter {...}</label>
        <v-divider ></v-divider>
        <div style="height:75%;overflow-y: scroll;">
          <v-list-item v-for="item in placeholderList" :key="item.usage">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small class="mr-2" @click="copyToClipboard(item)" color="blue">mdi-content-copy</v-icon>
              </template>
              <span>{{ item.description }}</span>
            </v-tooltip>
            <v-list-item-content>
              <v-list-item-title v-text="item.usage"></v-list-item-title>
              <v-list-item-subtitle v-text="placeholderContent(item)"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </div>
      <div style="height: 40%;">
        <label class="grey--text font-weight-medium">Bedingungen &lt;...&gt;&lt;/...&gt;</label>
        <v-divider ></v-divider>
        <div style="height:75%;overflow-y: scroll;">
          <v-list-item v-for="item in conditionList" :key="item.usage">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small class="mr-2" @click="copyToClipboard(item)" color="blue">mdi-content-copy</v-icon>
              </template>
              <span>{{ item.description }}</span>
            </v-tooltip>
            <v-list-item-content>
              <v-list-item-title v-text="item.usage"></v-list-item-title>
              <v-list-item-subtitle v-text="conditionContent(item)"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </div>
      <div style="height: 20%;">
        <label class="grey--text font-weight-medium">Textbausteine [...]</label>
        <v-divider ></v-divider>
        <div style="height:80%;overflow-y: scroll;">
          <v-list-item v-for="item in textModuleList" :key="item.caption">
            <v-icon small class="mr-2" @click="copyToClipboard(item)" color="blue">mdi-content-copy</v-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.usage"></v-list-item-title>
              <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    contentheight () {
      const height = this.$store.getters['size/formContent']
      return height
    }
  },

  methods: {
    placeholderContent (item) {
      return item.originCaption + ' -> ' + item.caption
    },

    copyToClipboard (item) {
      navigator.clipboard.writeText(item.usage)
    },

    conditionContent (item) {
      return item.originCaption + ' -> ' + item.caption
    }
  },

  props: {
    conditionList: { type: Array },
    placeholderList: { type: Array },
    textModuleList: { type: Array }
  }
}
</script>
