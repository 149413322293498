<template>
  <div>
    Admin Config Overview
  </div>
</template>

<script>

export default {
  name: 'AdminConfigOverview',

  async created () {
    // this.$pageInitController.initBack(this, this.$moduleInfoController.midwife, 'Hebamme', 'Kontaktdaten', this.midwifeName)
  }
}
</script>
