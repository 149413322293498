export function caseLocator (concernId, casePid) {
  const result = {
    concernId: concernId,
    caseId: casePid
  }
  return result
}

export const stateIconMap = new Map()
stateIconMap.set('START', 'mdi-adjust')
stateIconMap.set('PROGRESS', 'mdi-forward')
stateIconMap.set('FINISHED', 'mdi-lock')
stateIconMap.set('PAUSED', 'mdi-pause')
stateIconMap.set('DELETED', 'mdi-delete')
stateIconMap.set('WAITINGLIST', 'mdi-clock-start')

export const todoIconMap = new Map()
todoIconMap.set('WAITING', 'mdi-clock-start')
todoIconMap.set('CALL', 'mdi-cellphone')
todoIconMap.set('FINISHED_SUCESS', 'mdi-check')
todoIconMap.set('FINISHED_NOT_FOUND', 'mdi-lock')
todoIconMap.set('FINISHED_CANCELLED', 'mdi-lock')
todoIconMap.set('SEARCH', 'mdi-binoculars')
todoIconMap.set('PAUSED', 'mdi-pause')
todoIconMap.set('DELETED', 'mdi-delete')
todoIconMap.set('DOUBLET', 'mdi-content-copy')
todoIconMap.set('CHECK', 'mdi-loupe')
todoIconMap.set('REMIND', 'mdi-timer-off')
