import ApiService from '../../common/service/api/api.service'

const UserService = {
  async userInfo () {
    const result = await ApiService.doGet('/enna/current/user/userinfo')
    return result
  },

  async changePassword (userId, password) {
    const data = {
      userId: userId,
      password: password
    }
    const result = await ApiService.doPost('/enna/current/user/changepassword', data)
    return result
  },

  async releaseFunction () {
    const result = await ApiService.doGet('/enna/current/user/view/releasedfunction')
    return result
  }
}

export { UserService }
