import SendMailService from './sendmail.service'
import CaseController from '../../concern/case/case.overview.controller'
import ConcernCauseactivityController from '@/module/concern/causeactivity/concern.causeactivity.controller'
import MidwifeInquiryController from '../../midwife/inquiry/midwife.inquiry.controller'
import router from '#enna/common/router'

export default {
  async openAccumulativeMail (midwifeId, selectedCaseList) {
    const response = await SendMailService.getAccumulativeMail(midwifeId, selectedCaseList)
    const sendMailData = response.sendMailDataAccumulativeMail
    const midwife = {
      midwifeId: midwifeId,
      selectedCaseList: selectedCaseList,
      midwifeName: response.midwifeName,
      sendMailData: sendMailData
    }
    router.push({
      name: 'sendmail',
      params: {
        woman: null,
        midwife: midwife
      }
    })
  },

  async setCaseActionWithMail (caseLocator, concernVersion, caseAction, executeAction, mailExecDataList) {
    const data = {
      caseLocator: caseLocator,
      concernVersion: concernVersion,
      caseAction: caseAction.id,
      mailContentList: mailExecDataList,
      executeAction: executeAction
    }
    await SendMailService.setCaseActionWithMail(data)
    jumpBack(caseLocator)
  },

  async saveAccumulativeMail (midwife, mailExecDataList) {
    const data = {
      midwifeId: midwife.midwifeId,
      informList: midwife.selectedCaseList.informList,
      remindList: midwife.selectedCaseList.remindList,
      mailContentList: mailExecDataList
    }
    await SendMailService.saveAccumulativeMail(data)
    await MidwifeInquiryController.openMidwifeInquiryOverview(midwife.midwifeId)
  },

  async openMidwife (midwifeId) {
    await MidwifeInquiryController.openMidwifeInquiryOverview(midwifeId)
  },

  async openCaseFile (caseLocator) {
    jumpBack(caseLocator)
  },

  async changeAccumulativeMailTemplate (midwife, mailTypeId, newMailTemplate) {
    const data = {
      midwifeId: midwife.midwifeId,
      informList: midwife.selectedCaseList.informList,
      remindList: midwife.selectedCaseList.remindList,
      mailType: mailTypeId,
      newMailTemplateId: newMailTemplate
    }
    const response = await SendMailService.changeAccumulativeMailTemplate(data)
    return response
  },

  async changeMailTemplate (caseLocator, mailTypeId, newMailTemplate) {
    const data = {
      caseLocator: caseLocator,
      mailType: mailTypeId,
      newMailTemplateId: newMailTemplate
    }
    const response = await SendMailService.changeMailTemplate(data)
    return response
  },

  async startSendMailCauseActivity (concernId) {
    const response = await SendMailService.loadSendMailCauseActivity(concernId)
    const concernBaseData = response.concernBaseData
    const concernVersion = response.concernBaseData.concernVersion
    const sendMailData = response.concernData
    const woman = {
      concernVersion: concernVersion,
      caseLocator: { concernId: concernId },
      womanName: concernBaseData.womanName,
      sendMailData: sendMailData
    }
    router.push({
      name: 'sendmail',
      params: {
        woman: woman,
        midwife: null
      }
    })
  },

  async startSendMailCauseActivityNoMidwifeFound (concernId) {
    const response = await SendMailService.loadSendMailCauseActivityNoMidwifeFound(concernId)
    const concernBaseData = response.concernBaseData
    const concernVersion = response.concernBaseData.concernVersion
    const sendMailData = response.concernData
    const woman = {
      concernVersion: concernVersion,
      caseLocator: { concernId: concernId },
      womanName: concernBaseData.womanName,
      sendMailData: sendMailData
    }
    router.push({
      name: 'sendmail',
      params: {
        woman: woman,
        midwife: null
      }
    })
  },

  async startSendMailCase (caseLocator, caseAction) {
    const response = await SendMailService.loadSendMail(caseLocator, caseAction)
    const concernBaseData = response.concernBaseData
    const concernVersion = response.concernBaseData.concernVersion
    const sendMailData = response.concernData
    const woman = {
      concernVersion: concernVersion,
      caseLocator: caseLocator,
      womanName: concernBaseData.womanName,
      sendMailData: sendMailData
    }
    router.push({
      name: 'sendmail',
      params: {
        woman: woman,
        midwife: null
      }
    })
  }
}
async function jumpBack (caseLocator) {
  if (caseLocator.caseId) {
    await CaseController.openCaseFile(caseLocator)
  } else {
    await ConcernCauseactivityController.openConcernCauseActivityOverview(caseLocator)
  }
}
