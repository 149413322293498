import store from '../../../common/store'
import router from '../../../common/router'

import HandoverController from '../../network/handover/handover.controller'
import ChangelogController from '../../network/changelog/changelog.controller'
import MailtemplateController from '../../superior/mailtemplate/mailtemplate.controller'
import TextModuleController from '../../superior/textmodule/textmodule.controller'
import MidwifeOverviewController from '../../../module/midwife/overview/midwife.overview.controller'
import MidwifeActivityController from '../../midwife/activity/overview/midwife.activity.controller'
import MidwifeContactController from '../../../module/midwife/contact/midwife.contact.controller'
import AdminConfigController from '../../../module/admin/config/admin.config.controller'
import StatisticController from '../../network/statistic/statistic.controller'
import MidwifeInfoController from '../../../module/midwife/info/midwife.info.controller'
import MidwifeInquiryController from '../../../module/midwife/inquiry/midwife.inquiry.controller'
import MidwifeMasterdataController from '../../../module/midwife/masterdata/midwife.masterdata.controller'
import MidwifePropertyController from '../../midwife/property/midwife.property.controller'
import MidwifeSignoffController from '../../../module/midwife/signoff/midwife.signoff.controller'
import MidwifeSubstitutionController from '../../../module/midwife/substitution/midwife.substitution.controller'
import MidwifeMailController from '../../../module/midwife/mail/midwife.mail.controller'

import WebformController from '../../../module/concern/webform/webform.controller'
import ConcernAdminController from '../../../module/concern/concernadmin/concern.concernadmin.controller'
import CaseOverviewController from '../../../module/concern/case/case.overview.controller'
import CaseCircularMailController from '../../../module/concern/circularmail/concern.circularmail.controller'
import ConcernWomanController from '../../../module/concern/woman/concern.woman.controller'
import ConcernDoubletController from '../../../module/concern/doublet/concern.doublet.controller'
import ConcernAdviceController from '../../../module/concern/advice/concern.advice.controller'
import ConcernSummaryController from '../../concern/summary/concern.summary.controller'
import ConcernCauseActivityController from '../../../module/concern/causeactivity/concern.causeactivity.controller'
import ConcernMailController from '@/module/concern/mail/concern.mail.controller'
import SearchController from '@/module/concern/search/case.search.controller'

import UserController from '../../../module/user/user.controller'

export default {
  async createMidwifeFileTabs () {
    store.dispatch('menu/setMidwifeFileTabs', midwifeFileTabs)
  },

  async getMidwifeFileTabs () {
    return midwifeFileTabs
  },

  async getMidwifeFileStartTab (name) {
    for (let index = 0; index < midwifeFileTabs.length; index++) {
      const tab = midwifeFileTabs[index]
      if (tab.tabid === name) {
        return index
      }
    }
    return 0
  },

  async createConcernFileTabs () {
    store.dispatch('menu/setConcernFileTabs', concernFileTabs)
  },

  async getConcernFileTabs () {
    return concernFileTabs
  },

  async getConcernFileStartTab (name) {
    for (let index = 0; index < concernFileTabs.length; index++) {
      const tab = concernFileTabs[index]
      if (tab.tabid === name) {
        return index
      }
    }
    return null
  },

  async createShortlinks (shortlinks) {
    const result = []
    if (shortlinks) {
      shortlinks.forEach((currentLink, index) => {
        const menuItem = getMenuItem(currentLink)
        if (menuItem) {
          result.push(menuItem)
        }
      })
    }
    store.dispatch('menu/setShortlinks', result)
  },

  async createMenu () {
    const result = []
    availableGroups.forEach((currentGroup, index) => {
      var tempMenuitemList = []
      getReleasedMenuitems().forEach((currentMenuitem, index) => {
        if (currentMenuitem.group === currentGroup.id) {
          tempMenuitemList.push(currentMenuitem)
        }
      })
      if (tempMenuitemList.length > 0) {
        currentGroup.children = tempMenuitemList
        result.push(currentGroup)
      }
    })
    store.dispatch('menu/setMenu', result)
  },

  async getShortlinks () {
    return store.state.menu.shortlinks
  },

  async getMenu () {
    return store.state.menu.menu
  },

  async getMenuItemByName (name) {
    return getMenuItem(name)
  }
}

function getMenuItem (name) {
  const menuGroupList = store.state.menu.menu
  for (var i = 0; i < menuGroupList.length; i++) {
    const menuGroup = menuGroupList[i]
    for (var k = 0; k < menuGroup.children.length; k++) {
      const menuItem = menuGroup.children[k]
      if (menuItem.id === name) {
        return menuItem
      }
    }
  }
}

function getReleasedMenuitems () {
  var releasedMenuSet = store.state.right.menuSet
  var result = []
  menuitems.forEach((menuitem, index) => {
    if (releasedMenuSet.has(menuitem.id)) {
      result.push(menuitem)
    }
  })
  return result
}

function callRoute (name) {
  router.push({ name: name })
}

const menuitems = [
  { id: 'changelog', icon: 'mdi-checkbox-multiple-blank-outline', text: 'Programmerweiterung', group: 'application', openRoute () { ChangelogController.openChangelogOverview() } },
  { id: 'handover_overview', icon: 'mdi-archive', text: 'Übergabebuch', group: 'office', openRoute () { HandoverController.openHandoverOverview() } },
  { id: 'current_handover', icon: 'mdi-notebook', text: 'Aktuelle Übergabe', group: 'office', openRoute () { HandoverController.openCurrentHandover() } },
  { id: 'midwife_overview', icon: 'mdi-account-multiple', text: 'Übersicht Hebammen', group: 'midwife', openRoute () { MidwifeOverviewController.openMidwifeOverview() } },
  { id: 'new_midwife', icon: 'mdi-account-plus', text: 'Neue Hebamme', group: 'midwife', openRoute () { MidwifeOverviewController.openNewMidwife() } },
  { id: 'midwife_cancelled', icon: 'mdi-account-remove', text: 'Hebamme scheidet aus', group: 'midwife', openRoute () { MidwifeOverviewController.openCancelMidwife() } },
  { id: 'webforminquiry_overview', icon: 'mdi-email', text: 'Webformular', group: 'concern', openRoute () { WebformController.openWebformOverview() } },
  { id: 'concern_overview', icon: 'mdi-human-pregnant', text: 'Anfrage Übersicht', group: 'concern', openRoute () { CaseOverviewController.openCaseOverview() } },
  { id: 'adminconfig_overview', icon: 'mdi-cog-outline', text: 'Konfiguration', group: 'configuration', openRoute () { AdminConfigController.openAdminConfigOverview() } },
  { id: 'mailtemplate_overview', icon: 'mdi-email-variant', text: 'Mailvorlagen', group: 'configuration', openRoute () { MailtemplateController.openMailTemplateOverview() } },
  { id: 'textmodule_overview', icon: 'mdi-wall', text: 'Textbausteine', group: 'configuration', openRoute () { TextModuleController.openTextModuleOverview() } },
  { id: 'new_inquiry', icon: 'mdi-plus-outline', text: 'Neue Anfrage', group: 'concern', openRoute () { SearchController.openNewToSearch4() } },
  { id: 'finished_overview', icon: 'mdi-clipboard-check', text: 'Abgeschlossene Anfragen', group: 'concern', openRoute () { CaseOverviewController.openFinishedCaseOverview() } },
  { id: 'concernadminoverview', icon: 'mdi-inbox-arrow-down', text: 'Verwaltung von Anfragen', group: 'concern', openRoute () { ConcernAdminController.openConcernAdminOverview() } },

  { id: 'circularmailcase_overview', icon: 'mdi-note-multiple', text: 'Anfrage per Rundmail', group: 'concern', openRoute () { CaseCircularMailController.openCircularMailOverview() } },
  { id: 'searching_in_closed_inquiries', icon: 'mdi-comment-text-multiple-outline', text: 'Freie Kapazitäten', group: 'concern', openRoute () { callRoute(this.route) } },
  { id: 'midwifesubstitution', icon: 'mdi-find-replace', text: 'Vertretung', group: 'midwife', openRoute () { callRoute(this.route) } },
  { id: 'roster', icon: 'mdi-calendar-range', text: 'Dienstplan', group: 'office', openRoute () { callRoute(this.route) } },
  { id: 'statistic_overview', icon: 'mdi-chart-bar', text: 'Statistik', group: 'office', openRoute () { StatisticController.openStatisticOverview() } },
  { id: 'activity_config_overview', icon: 'mdi-medical-bag', text: 'Leistung', group: 'configuration', openRoute () { callRoute(this.route) } },
  { id: 'application_config_overview', icon: 'mdi-cog-outline', text: 'Programmeinstellung', group: 'configuration', openRoute () { callRoute(this.route) } },
  { id: 'keylist_overview', icon: 'mdi-key-variant', text: 'Schlüsselverzeichnisse', group: 'configuration', openRoute () { callRoute(this.route) } },
  { id: 'themegroup_overview', icon: 'mdi-group', text: 'Themengruppe', group: 'configuration', openRoute () { callRoute(this.route) } },
  { id: 'user_account', icon: 'mdi-account-check', text: 'Einstellungen', group: 'user', openRoute () { callRoute(this.route) } },
  { id: 'currentuser_detail', icon: 'mdi-form-textbox-password', text: 'Passwort ändern', group: 'user', openRoute () { UserController.startChangePassword() } },
  { id: 'user_overview', icon: 'mdi-account-cog', text: 'Benutzerverwaltung', group: 'application', openRoute () { callRoute(this.route) } },
  { id: 'admintools', icon: 'mdi-server-network', text: 'Admin-Tools', group: 'application', openRoute () { callRoute(this.route) } }
]

const availableGroups = [
  { id: 'concern', text: 'Anfrage', icon: 'mdi-chevron-up', icon_alt: 'mdi-chevron-down', model: false, children: [] },
  { id: 'midwife', text: 'Hebamme', icon: 'mdi-chevron-up', icon_alt: 'mdi-chevron-down', model: false, children: [] },
  { id: 'office', text: 'Netzwerk', icon: 'mdi-chevron-up', icon_alt: 'mdi-chevron-down', model: false, children: [] },
  { id: 'configuration', text: 'Konfiguration', icon: 'mdi-chevron-up', icon_alt: 'mdi-chevron-down', model: false, children: [] },
  { id: 'user', text: 'Angemeldeter Benutzer', icon: 'mdi-chevron-up', icon_alt: 'mdi-chevron-down', model: false, children: [] },
  { id: 'application', text: 'Programm', icon: 'mdi-chevron-up', icon_alt: 'mdi-chevron-down', model: false, children: [] }
]

const midwifeFileTabs = [
  { name: 'Leistungen', icon: 'mdi-note-multiple', tabid: 'midwifeactivityoverview', openRoute (midwifeIdObject) { const midwifeId = midwifeIdObject.midwifeId; MidwifeActivityController.openMidwifeActivityOverview(midwifeId) } },
  { name: 'Kontakt', icon: 'mdi-cellphone-iphone', tabid: 'midwifecontactoverview', openRoute (midwifeIdObject) { const midwifeId = midwifeIdObject.midwifeId; MidwifeContactController.openMidwifeContactOverview(midwifeId) } },
  // { name: 'Historie', icon: 'mdi-database', tabid: 'midwifehistoryoverview', openRoute (midwifeIdObject) { const midwifeId = midwifeIdObject.midwifeId; MidwifeHistoryController.openMidwifeHistoryOverview(midwifeId) } },
  { name: 'Infos', icon: 'mdi-information-variant', tabid: 'midwifeinfooverview', openRoute (midwifeIdObject) { const midwifeId = midwifeIdObject.midwifeId; MidwifeInfoController.openMidwifeInfoOverview(midwifeId) } },
  { name: 'Anfrage', icon: 'mdi-human-pregnant', tabid: 'midwifeinquiryoverview', openRoute (midwifeIdObject) { const midwifeId = midwifeIdObject.midwifeId; MidwifeInquiryController.openMidwifeInquiryOverview(midwifeId) } },
  { name: 'Stammdaten', icon: 'mdi-developer-board', tabid: 'midwifemasterdataoverview', openRoute (midwifeIdObject) { const midwifeId = midwifeIdObject.midwifeId; MidwifeMasterdataController.openMidwifeMasterdataOverview(midwifeId) } },
  { name: 'Zusatzdaten', icon: 'mdi-account-settings-outline', tabid: 'midwifepropertyoverview', openRoute (midwifeIdObject) { const midwifeId = midwifeIdObject.midwifeId; MidwifePropertyController.openMidwifePropertyOverview(midwifeId) } },
  { name: 'Abmeldung', icon: 'mdi-calendar-range', tabid: 'midwifesignoffoverview', openRoute (midwifeIdObject) { const midwifeId = midwifeIdObject.midwifeId; MidwifeSignoffController.openMidwifeSignOffOverview(midwifeId) } },
  { name: 'Mails', icon: 'mdi-email', tabid: 'midwifemailoverview', openRoute (midwifeIdObject) { const midwifeId = midwifeIdObject.midwifeId; MidwifeMailController.openMidwifeMailOverview(midwifeId) } },
  { name: 'Vertretung', icon: 'mdi-account-convert-outline', tabid: 'midwifesubstitutionoverview', openRoute (midwifeIdObject) { const midwifeId = midwifeIdObject.midwifeId; MidwifeSubstitutionController.openMidwifeSubstitutionOverview(midwifeId) } }
]

const concernFileTabs = [
  { name: 'Übersicht', icon: 'mdi-eye-settings-outline', tabid: 'concernsummary', openRoute (caseLocator) { ConcernSummaryController.openConcernSummary(caseLocator) } },
  { name: 'Frau', icon: 'mdi-human-pregnant', tabid: 'concernwomanoverview', openRoute (caseLocator) { ConcernWomanController.openConcernWomanOverview(caseLocator) } },
  { name: 'Webformular', icon: 'mdi-developer-board', tabid: 'concerndoubletoverview', openRoute (caseLocator) { ConcernDoubletController.openDoubletOverview(caseLocator) } },
  { name: 'Leistungen', icon: 'mdi-plus-circle-multiple-outline', tabid: 'concerncauseactivityoverview', openRoute (caseLocator) { ConcernCauseActivityController.openConcernCauseActivityOverview(caseLocator) } },
  { name: 'Mails', icon: 'mdi-email', tabid: 'concernmailoverview', openRoute (caseLocator) { ConcernMailController.openConcernMailOverview(caseLocator) } },
  { name: 'Beratung', icon: 'mdi-emoticon-outline', tabid: 'concernadviceoverview', openRoute (caseLocator) { ConcernAdviceController.openConcernAdviceOverview(caseLocator) } }
]
