<template>
  <v-dialog
    v-model="showDialog"
    width="500"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Info
      </v-card-title>
      <v-card-text style="white-space: pre-wrap;">
        <v-textarea
          v-model="infoText" ></v-textarea>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="acceptedDialog"
        >
          Ok
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="closeDialog"
        >
          Abbrechen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'InfoTextDialog',

  methods: {
    closeDialog () {
      this.$emit('onCancel')
      this.showDialog = false
    },

    acceptedDialog () {
      this.$emit('onSave', this.infoText)
      this.showDialog = false
    }
  },

  watch: {
    propShowDialog (val) {
      this.showDialog = val
    },
    propInfoText (val) {
      this.infoText = val
    }
  },

  props: {
    propShowDialog: { type: Boolean },
    propInfoText: { type: String }
  },

  data () {
    return {
      showDialog: this.propShowDialog,
      infoText: this.propInfoText
    }
  }
}
</script>
