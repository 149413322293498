var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-navigation-drawer', {
    staticStyle: {
      "width": "500px",
      "background-color": "aliceblue"
    },
    attrs: {
      "clipped": _vm.$vuetify.breakpoint.lgAndUp,
      "app": ""
    },
    on: {
      "input": _vm.onChange
    },
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_vm._l(_vm.menu, function (item) {
    return [item.children ? _c('v-list-group', {
      key: item.text,
      attrs: {
        "prepend-icon": item.model ? item.icon : item.icon_alt,
        "append-icon": "",
        "no-action": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
            staticClass: "font-weight-light"
          }, [_vm._v(_vm._s(item.text))])], 1)], 1)];
        },
        proxy: true
      }], null, true),
      model: {
        value: item.model,
        callback: function ($$v) {
          _vm.$set(item, "model", $$v);
        },
        expression: "item.model"
      }
    }, _vm._l(item.children, function (child, i) {
      return _c('v-list-item', {
        key: i,
        attrs: {
          "link": ""
        },
        on: {
          "click": function ($event) {
            return _vm.onItemClicked(child);
          }
        }
      }, [child.icon ? _c('v-list-item-action', [_c('v-icon', [_vm._v(_vm._s(child.icon))])], 1) : _vm._e(), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(child.text))])], 1)], 1);
    }), 1) : _vm._e()];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }