<template>
  <div v-bind:style="{ 'overflow-y': 'auto', 'max-height': contentheight + 'px' }">
    <ul >
      <v-subheader style="display: grid;grid-template-columns: 6fr 3fr 4fr;">
        <div>Programminterne Informationen</div>
      </v-subheader>
      <v-divider ></v-divider>
      <div style="display: grid; grid-template-columns: 8fr 3fr 2fr;"
           :id="dynPropItem.id"
           v-for="dynPropItem in midwifePropertyList"
           :key="dynPropItem.id">
        <span>{{ dynPropItem.name }}</span>
        <span v-if="dynPropItem.dynPropDataType === 'boolean'">
              <v-icon v-if="dynPropItem.value == true" color="green">mdi-check-bold</v-icon>
              <v-icon v-else-if="dynPropItem.value == false" color="red">mdi-close-thick</v-icon>
        </span>
        <span v-if="dynPropItem.dynPropDataType === 'string'">
          {{ dynPropItem.value }}
        </span>
        <span v-if="dynPropItem.dynPropDataType === 'keylist'">
          <div v-if="dynPropItem.value">{{ dynPropItem.value.caption }}</div>
        </span>
        <v-btn fab small text outlined class="mx-2" router >
          <v-icon small class="mr-2" @click="openMidwifeProp()" color="blue">mdi-pencil</v-icon>
        </v-btn>
      </div>
    </ul>
    <dynpropoverview :withHeader="true" :dynPropPropertyList="dynPropPropertyList" @onEditDynPropData="onEditDynPropData"></dynpropoverview>
  </div>
</template>

<script>
import MidwifePropertyController from './midwife.property.controller'
import MidwifeOverviewController from '../overview/midwife.overview.controller'
import dynpropoverview from '#enna/common/components/element/DynPropOverview'

export default {
  name: 'MidwifePropertyOverview',

  props: {
    midwifeId: { type: String },
    writeRight: { type: String },
    midwifeName: { type: String },
    dynPropPropertyList: { type: Array },
    midwifePropertyList: { type: Array }
  },

  async created () {
    this.$pageInitController.initBack(this, this.$moduleInfoController.midwife, 'Hebamme', 'Zusatzdaten', this.midwifeName)
  },

  components: {
    dynpropoverview
  },

  computed: {
    contentheight () {
      const height = this.$store.getters['size/overviewContent']
      return height
    }
  },

  methods: {
    async back () {
      await MidwifeOverviewController.openMidwifeOverview(this.midwifeId)
    },

    onEditDynPropData (property) {
      MidwifePropertyController.openDynPropDetail(this.midwifeId, property)
    },
    openDynProp (property) {
      MidwifePropertyController.openDynPropDetail(this.midwifeId, property)
    },
    openMidwifeProp () {
      MidwifePropertyController.openMidwifePropertyDetail(this.midwifeId)
    }
  }
}
</script>
