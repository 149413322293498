import ApiService from '../../../common/service/api/api.service'

const ConcernAdviceService = {
  async queryAdvice (caseLocator) {
    const result = await ApiService.doGet('/enna/cause/advice/query/' + caseLocator.concernId)
    return result
  },

  async loadNewAdvice (concernId) {
    const result = await ApiService.doGet('/enna/cause/advice/fornew/' + concernId)
    return result
  },

  async loadAdvice (concernId, pid) {
    const result = await ApiService.doGet('/enna/cause/advice/load/' + concernId + '/' + pid)
    return result
  },

  async saveAdvice (concernId, versionId, advice) {
    const param = {
      concernId: concernId,
      versionId: versionId,
      dtoAdvice: advice
    }
    const result = await ApiService.doPost('/enna/cause/advice/save', param)
    return result
  },

  async deleteAdvice (concernId, versionId, pid) {
    const param = {
      concernId: concernId,
      versionId: versionId,
      pid: pid
    }
    const result = await ApiService.doPost('/enna/cause/advice/delete', param)
    return result
  }

}

export default ConcernAdviceService
