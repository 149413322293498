import router from '#enna/common/router'
import ConcernAdviceService from './concern.advice.service'

export default {
  async openConcernAdviceOverview (caseLocator) {
    await internalOpenAdviceOverview(caseLocator)
  },

  async openConcernNewAdvice (caseLocator) {
    const response = await ConcernAdviceService.loadNewAdvice(caseLocator.concernId)
    await internalLoadAdvice(caseLocator, response, null)
  },

  async loadConcernAdvice (caseLocator, pid, reloadId) {
    if (pid) {
      const response = await ConcernAdviceService.loadAdvice(caseLocator.concernId, pid)
      await internalLoadAdvice(caseLocator, response, reloadId)
    }
  },

  async saveConcernAdvice (caseLocator, concernVersion, dto) {
    await ConcernAdviceService.saveAdvice(caseLocator.concernId, concernVersion, dto)
    await internalOpenAdviceOverview(caseLocator)
  },

  async deleteConcernAdvice (caseLocator, concernVersion, pid, reloadId) {
    await ConcernAdviceService.deleteAdvice(caseLocator.concernId, concernVersion, pid)
    await internalOpenAdviceOverview(caseLocator, reloadId)
  }
}

async function internalOpenAdviceOverview (caseLocator, reloadId) {
  const response = await ConcernAdviceService.queryAdvice(caseLocator)
  const concernVersion = response.concernBaseData.concernVersion
  const concernBaseData = response.concernBaseData
  router.push({
    name: 'concernadviceoverview',
    params: {
      caseLocator: caseLocator,
      concernVersion: concernVersion,
      concernBaseData: concernBaseData,
      womanName: concernBaseData.womanName,
      adviceList: response.concernData
    },
    query: { reload: reloadId }
  })
}

async function internalLoadAdvice (caseLocator, response, reloadId) {
  const concernVersion = response.concernBaseData.concernVersion
  const womanName = response.concernBaseData.womanName
  const advice = response.concernData.advice
  const adviceThemeList = response.concernData.adviceTheme

  router.push({
    name: 'concerneditadvice',
    params: {
      caseLocator: caseLocator,
      womanName: womanName,
      concernVersion: concernVersion,
      adviceThemeList: adviceThemeList,
      propAdvice: advice
    },
    query: { reload: reloadId }
  })
}
