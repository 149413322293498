var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('customcontent', {
    attrs: {
      "prop-scrollbar": "visible"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "display": "grid",
            "grid-template-columns": "8fr 3fr"
          }
        }, [_c('div', {
          style: {
            'overflow-y': 'auto',
            'max-height': _vm.contentheight + 'px'
          }
        }, _vm._l(_vm.filteredHandover, function (group) {
          return _c('div', {
            key: group.first
          }, [_c('v-subheader', [_c('dateitem', {
            staticStyle: {
              "padding-bottom": "15px"
            },
            attrs: {
              "id": group.first,
              "date-item": group.first
            }
          })], 1), _c('div', _vm._l(group.second, function (handover) {
            return _c('div', {
              key: handover.pid
            }, [_c('div', {
              staticStyle: {
                "padding-left": "30px"
              }
            }, [_c('div', {
              staticStyle: {
                "display": "grid",
                "grid-template-columns": "8fr 1fr 1fr 8fr 1fr 1fr"
              },
              attrs: {
                "id": handover.pid
              }
            }, [_c('label', {
              staticStyle: {
                "font-weight": "bold"
              }
            }, [_vm._v(" " + _vm._s(handover.user.userName) + " ")]), handover.todo ? _c('v-icon', {
              staticStyle: {
                "padding-left": "20px"
              },
              attrs: {
                "light": ""
              }
            }, [_vm._v("mdi-wrench")]) : _c('div'), handover.important ? _c('v-icon', {
              staticStyle: {
                "padding-left": "20px"
              },
              attrs: {
                "light": ""
              }
            }, [_vm._v("mdi-alert")]) : _c('div'), _c('div'), _c('v-icon', {
              staticClass: "mr-2",
              attrs: {
                "large": "",
                "color": "blue"
              },
              on: {
                "click": function ($event) {
                  return _vm.open(handover.pid);
                }
              }
            }, [_vm._v("mdi-pencil")])], 1), _c('v-divider')], 1), _c('div', {
              staticStyle: {
                "white-space": "pre-wrap",
                "word-wrap": "break-word",
                "font-family": "inherit",
                "padding-left": "60px",
                "padding-top": "20px",
                "padding-right": "25px",
                "padding-bottom": "40px"
              }
            }, [_vm._v(" " + _vm._s(handover.content) + " ")])]);
          }), 0)], 1);
        }), 0), _c('div', {
          staticStyle: {
            "padding-left": "20px"
          }
        }, [_c('div', {
          staticStyle: {
            "padding-bottom": "20px"
          }
        }, [_c('v-text-field', {
          attrs: {
            "label": "Suchtext"
          },
          model: {
            value: _vm.filterParam.searchText,
            callback: function ($$v) {
              _vm.$set(_vm.filterParam, "searchText", $$v);
            },
            expression: "filterParam.searchText"
          }
        })], 1), _c('div', {
          staticStyle: {
            "display": "grid",
            "grid-template-columns": "1fr 1fr 1fr 1fr",
            "padding-bottom": "20px"
          }
        }, [_c('v-icon', {
          staticStyle: {
            "padding-left": "20px"
          },
          attrs: {
            "light": ""
          }
        }, [_vm._v("mdi-wrench")]), _c('v-checkbox', {
          attrs: {
            "label": "Todo"
          },
          model: {
            value: _vm.filterParam.searchTodo,
            callback: function ($$v) {
              _vm.$set(_vm.filterParam, "searchTodo", $$v);
            },
            expression: "filterParam.searchTodo"
          }
        }), _c('v-icon', {
          staticStyle: {
            "padding-left": "20px"
          },
          attrs: {
            "light": ""
          }
        }, [_vm._v("mdi-alert")]), _c('v-checkbox', {
          attrs: {
            "label": "Wichtig"
          },
          model: {
            value: _vm.filterParam.searchImportant,
            callback: function ($$v) {
              _vm.$set(_vm.filterParam, "searchImportant", $$v);
            },
            expression: "filterParam.searchImportant"
          }
        })], 1), _c('div', {
          staticStyle: {
            "display": "grid",
            "grid-template-columns": "8fr 1fr 17fr",
            "padding-bottom": "20px"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.dateMode,
            "item-text": "label",
            "item-value": "id",
            "label": "Zeitraum",
            "solo": "",
            "clearable": ""
          },
          model: {
            value: _vm.filterParam.searchDateModeList,
            callback: function ($$v) {
              _vm.$set(_vm.filterParam, "searchDateModeList", $$v);
            },
            expression: "filterParam.searchDateModeList"
          }
        }), _c('div'), _c('datepicker', {
          attrs: {
            "propdate": _vm.filterParam.searchDate
          },
          on: {
            "update:propdate": function ($event) {
              return _vm.$set(_vm.filterParam, "searchDate", $event);
            }
          }
        })], 1), _c('div', [_c('v-select', {
          attrs: {
            "items": _vm.userList,
            "item-text": "userName",
            "item-value": "userId",
            "label": "Hebammen",
            "multiple": "",
            "chips": "",
            "solo": "",
            "clearable": ""
          },
          model: {
            value: _vm.filterParam.searchUserList,
            callback: function ($$v) {
              _vm.$set(_vm.filterParam, "searchUserList", $$v);
            },
            expression: "filterParam.searchUserList"
          }
        })], 1)])])];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }