var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-rows": "100px",
      "align-items": "end",
      "grid-template-columns": "3fr 3fr 2fr"
    }
  }, [_c('datepicker', {
    attrs: {
      "title": "Von",
      "propdate": _vm.from
    },
    on: {
      "update:propdate": function ($event) {
        _vm.from = $event;
      }
    }
  }), _c('datepicker', {
    attrs: {
      "title": "Bis",
      "propdate": _vm.until
    },
    on: {
      "update:propdate": function ($event) {
        _vm.until = $event;
      }
    }
  })], 1), _c('div', {
    style: {
      'overflow-y': 'auto',
      'max-height': _vm.contentheight + 'px'
    }
  }, _vm._l(_vm.statisticList, function (statistic) {
    return _c('div', {
      key: statistic.id
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "color": "blue"
      },
      on: {
        "click": function ($event) {
          return _vm.executeStatistic(statistic.pid);
        }
      }
    }, [_vm._v("mdi-play-circle-outline")]), _c('label', {
      staticStyle: {
        "font-weight": "bold"
      }
    }, [_vm._v(" " + _vm._s(statistic.name) + " ")]), _c('div', {
      staticStyle: {
        "padding": "2px 10px 5px 60px"
      }
    }, [_vm._v(" " + _vm._s(statistic.description) + " ")])], 1);
  }), 0), _c('statisticresult', {
    attrs: {
      "statisticId": _vm.statisticId,
      "from": _vm.from,
      "until": _vm.until,
      "propshowdialog": _vm.showStatisticResult
    },
    on: {
      "update:propshowdialog": function ($event) {
        _vm.showStatisticResult = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }