import router from '#enna/common/router'
import MenuController from '../../app/menu/menu.controller'
import MidwifeInfoService from './midwife.info.service'

export default {
  async openMidwifeInfoOverview (midwifeId) {
    callMidwifeInfoOverview(midwifeId)
  },

  async openInfo (midwifeId, pid, reloadId) {
    const response = await MidwifeInfoService.loadInfo(pid)
    callMidwifeInfoDetail(midwifeId, pid, response, reloadId)
  },

  async openNewInfo (midwifeId) {
    const response = await MidwifeInfoService.newInfo(midwifeId)
    callMidwifeInfoDetail(midwifeId, null, response, null)
  },

  async deleteInfo (midwifeId, pid) {
    await MidwifeInfoService.deleteInfo(pid)
    const value = await MidwifeInfoService.queryInfo(midwifeId)
    return value
  },

  async saveMidwifeInfo (dtoMidwifeInfo) {
    await MidwifeInfoService.saveInfo(dtoMidwifeInfo)
    await callMidwifeInfoOverview(dtoMidwifeInfo.midwifeId)
  }
}

async function callMidwifeInfoOverview (midwifeId) {
  const response = await MidwifeInfoService.queryViewInfo(midwifeId)
  const infoList = response.data.infoList
  const midwifeName = response.data.midwifeName
  const selectedTabIndex = await MenuController.getMidwifeFileStartTab('midwifeinfooverview')
  router.push({ name: 'midwifeinfooverview', params: { midwifeId: midwifeId, midwifeName: midwifeName, preselectedTab: selectedTabIndex, propInfoList: infoList } })
}

async function callMidwifeInfoDetail (midwifeId, pid, response, reloadId) {
  const dtoMidwifeInfo = response.data.dtoMidwifeInfo
  const midwifeName = response.data.midwifeName
  router.push({ name: 'midwifeinfodetail', params: { midwifeId: midwifeId, pid: pid, propDtoMidwifeInfo: dtoMidwifeInfo, midwifeName: midwifeName }, query: { reload: reloadId } })
}
