<template>
  <formcontent
    buttonOneText="Alle Übergaben"
    @onButtonOne="openHandoverOverview"
    ok-text="Speichern" @onOk="saveHandover"
    write-right="handoverWrite">
    <template v-slot>
      <div>
        <div style="color: darkgrey; padding-bottom: 15px; font-weight: bold;
          display: grid; grid-template-columns:1fr 1fr 4fr 6fr;">
          <dateitem :date-item="handover.editdate"/>
          <div></div>
        </div>
        <v-divider></v-divider>
        <div style="
        display: grid;
        grid-template-columns:1fr 1fr 2fr 1fr 1fr 6fr;
        padding-bottom: 20px;">
          <v-icon light style="padding-left: 20px;">mdi-wrench</v-icon>
          <v-checkbox v-model="handover.todo" label="Todo"></v-checkbox>
          <div></div>
          <v-icon light style="padding-left: 20px;">mdi-alert</v-icon>
          <v-checkbox v-model="handover.important" label="Wichtig"></v-checkbox>
        </div>
        <v-textarea
          style="width: 95%;"
          clearable
          clear-icon="mdi-close-circle"
          label="Text"
          v-model="handover.content"
        ></v-textarea>
      </div>
    </template>
  </formcontent>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import HandoverController from './handover.controller'
import dateitem from '../../../common/components/datatype/DateItem'
import formcontent from '../../../common/components/content/FormContent'

export default {
  name: 'Handover',

  async created () {
    this.$pageInitController.initReload(this, this.$moduleInfoController.network, 'Übergabe heute', null, this.userName)
  },

  props: {
    userName: String,
    propHandover: { type: Object }
  },

  components: {
    formcontent,
    dateitem
  },

  watch: {
    propHandover (val) {
      this.handover = cloneDeep(this.propHandover)
    }
  },

  data () {
    return {
      handover: cloneDeep(this.propHandover)
    }
  },

  methods: {
    async reload () {
      await HandoverController.openHandover(this.handover.pid, Date.now())
    },

    async saveHandover () {
      this.handover = await HandoverController.saveHandover(this.handover)
    },

    async openHandoverOverview () {
      await HandoverController.openHandoverOverview(this.propHandover.pid)
    }
  }
}
</script>
