<template>
  <div>
    <div v-if="layout==='block'" style="display: grid; grid-template-columns: 2fr 4fr;">
      <div class="font-weight-thin" v-if="hasDeadline()">{{ deadlineCaption() }}</div>
      <div v-if="hasDeadline()">{{ deadline() }}</div>
      <div class="font-weight-thin" v-if="location">Wohnort</div>
      <div v-if="location">{{ location.name }}</div>
      <div class="font-weight-thin" v-if="hasActivity()">Leistungen</div>
      <div>
        <div v-for="searchActivity in activitySearchParamList" :key="searchActivity.activity.activityId">
          <div>
          <span v-if="searchActivity.acute">
            <v-icon color="red">mdi-exclamation</v-icon>
            Akut
            <v-icon color="red">mdi-exclamation</v-icon>
          </span>
            <strong v-bind:style="{ 'color': activityColor }">{{ searchActivity.activity.name }}</strong>
            <div class="font-weight-light" style="padding-left: 20px;">{{ showStart(searchActivity.from, searchActivity.until) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="display: flex; flex-direction: row" >
      <div style="width: 30%">
        <div style="display: grid; grid-template-columns: 2fr 4fr;">
          <div class="font-weight-thin" v-if="hasDeadline()">{{ deadlineCaption() }}</div>
          <div v-if="hasDeadline()">{{ deadline() }}</div>
          <div class="font-weight-thin" v-if="location">Wohnort</div>
          <div v-if="location">{{ location.name }}</div>
        </div>
      </div>
      <div style="width: 10%">
        <div class="font-weight-thin" v-if="hasActivity()">Leistungen</div>
      </div>
      <div style="width: 60%">
        <div>
          <div v-for="searchActivity in activitySearchParamList" :key="searchActivity.activity.activityId">
            <div>
          <span v-if="searchActivity.acute">
            <v-icon color="red">mdi-exclamation</v-icon>
            Akut
            <v-icon color="red">mdi-exclamation</v-icon>
          </span>
              <strong v-bind:style="{ 'color': activityColor }">{{ searchActivity.activity.name }}</strong>
              <span class="font-weight-light" style="padding-left: 20px;">{{ showStart(searchActivity.from, searchActivity.until) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '../../../common/utils/date.utils'
import { startDate, ACTIVITY_COLOR } from '../causeactivity/causeactivity.utils'

export default {
  computed: {
    activityColor () {
      return ACTIVITY_COLOR
    }
  },

  methods: {
    hasActivity () {
      return this.activitySearchParamList.length > 0
    },

    showStart (start, latest) {
      return startDate(start, latest)
    },

    show (date) {
      if (date) {
        return formatDate(date)
      } else {
        return ''
      }
    },

    deadline () {
      return formatDate(this.bdate || this.edate)
    },

    deadlineCaption () {
      if (this.bdate) {
        return 'Geburt'
      } else if (this.edate) {
        return 'ET'
      } else {
        return 'Termin'
      }
    },

    hasDeadline () {
      const result = this.edate || this.bdate
      return result != null
    }
  },

  props: {
    layout: { type: String },
    edate: { type: String },
    bdate: { type: String },
    location: { type: Object },
    activitySearchParamList: { type: Array }
  }
}
</script>

<style scoped>

</style>
