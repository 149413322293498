import router from '#enna/common/router'
import ConcernDoubletService from './concern.doublet.service'
import MenuController from '../../app/menu/menu.controller'

export default {
  async openDoubletOverview (caseLocator) {
    await callOpenDoubletOverview(caseLocator, null)
  },

  async proofed (caseLocator, compactInquiry, concernVersion, event) {
    const webformProofData = {
      pid: compactInquiry.compactInquiryKey.webformPid,
      concernId: caseLocator.concernId,
      concernVersion: concernVersion,
      proofed: event
    }
    await ConcernDoubletService.proofed(webformProofData)
    await callOpenDoubletOverview(caseLocator, Date.now())
  },

  async releaseDoublet (caseLocator, webformReleaseData) {
    await ConcernDoubletService.releaseDoublet(webformReleaseData)
    await callOpenDoubletOverview(caseLocator, Date.now())
  }
}

async function callOpenDoubletOverview (caseLocator, reloadId) {
  const result = await ConcernDoubletService.loadDoublet(caseLocator.concernId)
  const concernBaseData = result.data.concernBaseData
  const refConcern = result.data.concernData.refConcern
  const webformInquiryList = result.data.concernData.webformInquiryList
  const webformVersion = result.data.concernData.webformVersion
  const selectedTabIndex = await MenuController.getConcernFileStartTab('concerndoubletoverview')
  const itemList = ConcernDoubletService.getItemList()
  router.push({
    name: 'concerndoubletoverview',
    params: {
      caseLocator: caseLocator,
      concernBaseData: concernBaseData,
      womanName: concernBaseData.womanName,
      itemList: itemList,
      preselectedTab: selectedTabIndex,
      refConcern: refConcern,
      webformInquiryList: webformInquiryList,
      webformVersion: webformVersion
    },
    query: { reload: reloadId }
  })
}
