<template>
    <div>
      <div>
        <div>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="filterParam.search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                clearable
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table
              :hide-default-footer="true"
              :headers="headers"
              :height="contentheight"
              fixed-header
              :sort-by.sync="filterParam.sortAttribute"
              :sort-desc.sync="filterParam.sortDirection"
              :items-per-page=5000
              :items="dataList"
              :search="filterParam.search"
            >
              <template v-slot:body="{ items }">
                <tbody>
                <tr :id="item[anchorKey]" v-for="item in items" :key="item[tablecontentKeyId]" @click="open(item)">
                  <td v-for="header in headers" :key="header.name" :style="columnStyle(header)">
                    <div v-if="header.type === 'date'" style="white-space:pre;">{{ getDateValue(item, header.value) }}</div>
                    <div v-else-if="header.type === 'boolean'" style="white-space:pre;">{{ getBooleanValue(item, header.value) }}</div>
                    <div v-else-if="header.type === 'icon'" style="white-space:pre;" :set="icon=getIcon(item, header.value, header.iconMapper)">
                      <v-icon small light>{{ icon.icon }}</v-icon> {{ icon.caption }}
                    </div>
                    <div v-else-if="header.type === 'booleanicon'" style="white-space:pre;">
                      <checkitem :flag-item="getBooleanValueForIcon(item, header.value)"></checkitem>
                    </div>
                    <div v-else style="white-space:pre-wrap;">{{ getTextValue(item, header.value) }}</div>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </div>
</template>

<script>
import { formatDate } from '../../utils/date.utils'
import PageinfoController from '../../../module/app/pageinfo/pageinfo.controller'
import cloneDeep from 'lodash/cloneDeep'
import Helper from '../../utils/json.utils'
import checkitem from '#enna/common/components/element/CheckItem'

export default {
  name: 'OverviewTableContent',

  components: {
    checkitem
  },

  computed: {
    contentheight: function () {
      return this.$store.getters['size/tableContent']
    }
  },

  methods: {
    getIcon (item, value, mapper) {
      const iconValue = Helper.getValue(item, value)
      const iconMap = this.iconMapperMap.get(mapper)
      const icon = iconMap.get(iconValue.iconId)
      return {
        icon: icon,
        caption: iconValue.caption
      }
    },

    getBooleanValueForIcon (item, value) {
      return Helper.getValue(item, value)
    },

    columnStyle (header) {
      const result = {
        'vertical-align': 'top'
      }
      if (header.width !== null) {
        result['max-width'] = header.width + 'px'
      }
      return result
    },

    getBooleanValue (item, value) {
      const booleanValue = Helper.getValue(item, value)
      if (booleanValue === true) {
        return 'ja'
      } else if (booleanValue === false) {
        return 'nein'
      } else {
        return null
      }
    },
    getDateValue (item, value) {
      const dateValue = Helper.getValue(item, value)
      if (dateValue) {
        return formatDate(dateValue)
      } else {
        return null
      }
    },
    getTextValue (item, value) {
      return Helper.getValue(item, value)
    },
    open (item) {
      this.$emit('open', item)
    }
  },

  watch: {
    filterParam: {
      deep: true,
      handler () {
        PageinfoController.setPageinfo(this.propFilterKey, this.filterParam)
      }
    }
  },

  data () {
    return {
      filterParam: cloneDeep(this.propFilterParam)
    }
  },

  props: {
    iconMapperMap: { type: Map },
    tablecontentKeyId: { type: String },
    anchorKey: { type: String },
    propFilterKey: { type: String },
    propFilterParam: { type: Object },
    dataList: { type: Array },
    headers: { type: Array }
  }
}
</script>
