export function transformGroupedData (groupedData) {
  var result = []
  for (let i = 0; i < groupedData.length; i++) {
    const group = groupedData[i]
    result.push({ header: group.caption })
    const itemList = group.itemList
    for (let k = 0; k < itemList.length; k++) {
      const item = itemList[k]
      result.push(item)
    }
  }
  return result
}
