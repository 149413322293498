<template>
  <div >
    <v-timeline>
      <div v-for="group in historyList" :key="group.date">
        <v-timeline-item
          fill-dot
          class="black--text font-weight-black"
          color="red"
          small
        >
          <template v-slot:opposite>
            <span>{{ formatDay(group.date) }}</span>
          </template>
        </v-timeline-item>
        <div v-for="action in group.actionList" :key="action.id">
          <v-timeline-item fill-dot hide-dot small>
            <template v-slot:opposite>
              {{ action.user }} - {{ action.time }}
            </template>
            <div class="font-weight-black"> {{ action.action }}</div>
            <div style="padding-left: 10px;" v-if="action.referencedDataAction">
              <div>{{ action.referencedDataAction.caseActionCaption}}</div>
              <div>{{ action.referencedDataAction.info }}</div>
            </div>
            <div style="padding-left: 10px;" v-if="action.midwife">
              <div>{{ action.midwife }}</div>
            </div>
            <div style="padding-left: 10px;" v-if="action.midwifeMailPid">
              <v-icon class="mr-2" @click="showMailContent(action.midwifeMailPid)" color="blue">mdi-email-open</v-icon>
              Hebamme
            </div>
            <div style="padding-left: 10px;" v-if="action.womanMailPid">
              <v-icon class="mr-2" @click="showMailContent(action.womanMailPid)" color="blue">mdi-email-open</v-icon>
              Frau
            </div>
            <div style="padding-left: 20px;">{{ action.info }}</div>
          </v-timeline-item>
        </div>
      </div>
    </v-timeline>
    <mailcontentdialog
      :sended-mail="sendedMail"
      @onCancel="onCancel"
      :propShowDialog.sync="showTextDialog" />
  </div>
</template>

<script>
import { formatWeekdayDate } from '../../../common/utils/date.utils'
import mailcontentdialog from './MailContentDialog'
import ConcernMailController from '../mail/concern.mail.controller'

export default {
  components: {
    mailcontentdialog
  },

  methods: {
    async showMailContent (pid) {
      this.sendedMail = await ConcernMailController.loadMail(pid)
      this.showTextDialog = true
    },

    onCancel () {
      this.showTextDialog = false
    },

    formatDay (date) {
      return formatWeekdayDate(date)
    }
  },

  data () {
    return {
      showTextDialog: false,
      sendedMail: null
    }
  },

  props: {
    historyList: { type: Array }
  }
}
</script>
