var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('dynpropbasedetail', {
    attrs: {
      "dynPropParam": _vm.dynPropParam
    },
    on: {
      "onSave": _vm.save
    }
  }, [_c('v-select', {
    staticStyle: {
      "width": "500px"
    },
    attrs: {
      "items": _vm.dynPropParam.keylistList,
      "item-text": "name",
      "item-value": "id",
      "multiple": "",
      "chips": "",
      "label": "Typ"
    },
    model: {
      value: _vm.keylistValue,
      callback: function ($$v) {
        _vm.keylistValue = $$v;
      },
      expression: "keylistValue"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }