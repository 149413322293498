import store from '../store'

export function containsRight (right) {
  var rightSet = store.state.right.rightSet
  let value = null
  for (value of rightSet) {
    if (value === right) {
      return true
    }
  }
  return false
}
