const actions = {
  async setPageinfo ({ commit }, pageId, pageinfo) {
    commit('setPageinfo', pageId, pageinfo)
  }
}

const state = {
  pageinfoMap: new Map()
}

const getters = {
  pageinfo (state) {
    return pageId => state.pageinfoMap.get(pageId)
  }
}

const mutations = {
  setPageinfo (state, { pageId, pageinfo }) {
    state.pageinfoMap.set(pageId, pageinfo)
  }
}

export const pageinfo = {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}
