var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "500px"
    },
    model: {
      value: _vm.showdialog,
      callback: function ($$v) {
        _vm.showdialog = $$v;
      },
      expression: "showdialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("Vergleich der mobilen Leistungen")])]), _c('v-card-text', [_c('v-carousel', {
    attrs: {
      "light": "",
      "height": "400"
    }
  }, _vm._l(_vm.mobileActivityList, function (mobileActivity, i) {
    return _c('v-carousel-item', {
      key: i
    }, [_c('div', {
      staticClass: "font-weight-black"
    }, [_vm._v(_vm._s(mobileActivity.name) + " ")]), _c('div', {
      staticStyle: {
        "padding-top": "20px",
        "padding-left": "80px",
        "max-height": "81%",
        "overflow": "auto"
      }
    }, _vm._l(mobileActivity.regionList, function (region) {
      return _c('div', {
        key: region.id
      }, [_c('span', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: region.second,
          expression: "region.second"
        }],
        staticClass: "font-weight-medium"
      }, [_vm._v(_vm._s(region.first))]), _c('span', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: !region.second,
          expression: "!region.second"
        }],
        staticClass: "red--text"
      }, [_c('del', [_vm._v(_vm._s(region.first))])])]);
    }), 0)]);
  }), 1)], 1), _c('v-card-actions', [_c('cmdbutton', {
    attrs: {
      "caption": "Schließen"
    },
    on: {
      "cmdclick": _vm.close
    }
  }), _c('v-spacer')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }