const state = {
  show: false,
  caption: null,
  text: null
}

const actions = {
  setInfoDialog ({ commit }, { caption, text }) {
    commit('setInfoDialog', { caption, text })
  },

  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  setInfoDialog (state, { caption, text }) {
    state.show = true
    state.caption = caption
    state.text = text
  },

  clear (state) {
    state.show = false
    state.caption = null
    state.text = null
  }
}

export const infoDialog = {
  namespaced: true,
  actions,
  state,
  mutations
}
