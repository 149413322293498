import HandoverService from './handover.service'
import router from '../../../common/router'
import PageinfoController from '../../app/pageinfo/pageinfo.controller'

const handoverOverviewName = 'HandoverOverview'
export default {

  saveFilterParam (filterParam) {
    PageinfoController.setPageinfo(handoverOverviewName, filterParam)
  },

  async saveHandover (dtoHandover) {
    await HandoverService.saveHandover(dtoHandover)
    var response
    if (dtoHandover.pid) {
      response = await HandoverService.loadHandover(dtoHandover.pid)
    } else {
      response = await HandoverService.loadCurrentHandover()
    }
    const handover = response.data.handover
    return handover
  },

  async openCurrentHandover () {
    const response = await HandoverService.loadCurrentHandover()
    const handover = response.data.handover
    const userName = response.data.user.userName
    router.push({ name: 'currenthandover', params: { propHandover: handover, userName: userName } })
  },

  async openHandover (pid, reloadId) {
    const response = await HandoverService.loadHandover(pid)
    const handover = response.data.handover
    const userName = response.data.user.userName
    router.push({ name: 'currenthandover', params: { propHandover: handover, userName: userName }, query: { reload: reloadId } })
  },

  async openHandoverOverview (pid) {
    const data = await HandoverService.queryGroupByHandover()
    const groupList = data.handoverList
    const userList = data.userList
    var filterParam = null
    filterParam = PageinfoController.getPageinfo(handoverOverviewName)
    if (filterParam == null) {
      filterParam = {
        searchText: '',
        searchDate: null,
        searchDateModeList: null,
        searchUserList: [],
        searchTodo: false,
        searchImportant: false
      }
    }
    let hashId = ''
    if (pid) {
      hashId = '#' + pid
    }
    router.push({ name: 'handoveroverview', hash: hashId, params: { groupList: groupList, userList: userList, propFilterParam: filterParam } })
  }
}
