<template>
  <v-dialog
    v-model="showDialog"
    width="700"
    persistent
  >
    <v-card v-if="sendedMail">
      <v-card-title class="text-h5 grey lighten-2">
        {{ sendedMail.caption }}<span v-if="sendedMail.templateChanged"> (Vorlage geändert)</span>
      </v-card-title>
      <v-card-text style="white-space: pre-wrap;">
        <span style="
              display: grid;
              gap: 20px;
              grid-template-columns:2fr 1fr;">
              <div>
                <label class="mailstyle">Mailtype: </label>
                <div style="padding-bottom: 10px;">{{ sendedMail.mailtemplateCaption }}</div>
                <div style="background-color: #EDEDED">
                  <label class="mailstyle">Betreff: </label>
                  <div style="padding-bottom: 10px;">{{ sendedMail.subject }}</div>
                  <label class="mailstyle">Inhalt: </label>
                  <div style="white-space: pre-wrap;">{{ sendedMail.content }}</div>
                </div>
              </div>
              <div>
                <label class="mailstyle">Verschickt am: </label>
                <div>{{ sendedMail.sendDate | formattedDate }}</div>
                <div style="padding-top: 20px;" >
                <label class="mailstyle">Von: </label>
                <div>{{ sendedMail.userName }}</div>
                </div>
                <div style="padding-top: 20px;" v-if="sendedMail.midwifeName">
                  <label class="mailstyle">Verschickt an Hebamme: </label>
                  <div>{{ sendedMail.midwifeName }}</div>
                  <div>{{ sendedMail.midwifeAdressat }}</div>
                </div>
                <div style="padding-top: 20px;" v-if="sendedMail.concernAdressat">
                  <label class="mailstyle">Verschickt an Frau: </label>
                  <div>{{ sendedMail.concernAdressat }}</div>
                </div>
              </div>
            </span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="closeDialog"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'MailContentDialog',

  methods: {
    closeDialog () {
      this.$emit('onCancel')
      this.showDialog = false
    }
  },

  watch: {
    propShowDialog (val) {
      this.showDialog = val
    }
  },

  props: {
    propShowDialog: { type: Boolean },
    sendedMail: { type: Object }
  },

  data () {
    return {
      showDialog: this.propShowDialog
    }
  }
}
</script>
