<template>
  <div>
    <overviewtablecontent
      tablecontentKeyId="caseLocator.pid"
      anchorKey="pid"
      :data-list="caseList"
      :headers="headerList"
      :propFilterKey="propFilterKey"
      :propFilterParam="propFilterParam"
      :iconMapperMap="iconMapperMap"
      @open="openConcern"
    />
  </div>
</template>

<script>
import overviewtablecontent from '#enna/common/components/content/OverviewTableContent'
import ConcernMainFileController from '../mainfile/concern.mainfile.controller'

export default {
  name: 'CaseOverview',

  async created () {
    this.$pageInitController.init(this, this.$moduleInfoController.concern, 'Anfragen', 'Übersicht')
  },

  components: {
    overviewtablecontent
  },

  props: {
    iconMapperMap: { type: Map },
    caseList: { type: Array },
    headerList: { type: Array },
    propFilterKey: { type: String },
    propFilterParam: { type: Object }
  },

  methods: {
    openConcern (viewCase) {
      ConcernMainFileController.openConcernMainFile(viewCase.caseLocator)
    }
  }
}
</script>
