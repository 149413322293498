var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    style: {
      'overflow-y': 'auto',
      'max-height': _vm.contentheight + 'px'
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('themecard', {
    staticClass: "name",
    attrs: {
      "title": "Frau",
      "set": _vm.woman = this.concernSummary.womanSummary
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.woman.name))]), _vm.woman.telephone ? _c('div', [_vm._v(_vm._s(_vm.woman.telephone))]) : _vm._e(), _vm.woman.mail ? _c('div', [_vm._v(_vm._s(_vm.woman.mail))]) : _vm._e(), _vm.woman.edbd ? _c('div', [_vm._v(" " + _vm._s(_vm.woman.edbdMode) + ": "), _c('dateitem', {
    staticStyle: {
      "display": "inline"
    },
    attrs: {
      "date-item": _vm.woman.edbd
    }
  })], 1) : _vm._e()]), _c('themecard', {
    staticClass: "state",
    attrs: {
      "title": "Status",
      "set": _vm.state = this.concernSummary.stateSummary
    }
  }, [_c('div', [_vm._v(" Anfrage ist in Übersicht "), _c('span', {
    staticStyle: {
      "padding-right": "20px"
    }
  }, [_vm._v("'" + _vm._s(_vm.showListing(_vm.state)) + " Anfragen'")]), _vm.state.possibleStateAction ? _c('cmdbutton', {
    attrs: {
      "caption": _vm.state.possibleStateAction.actionCaption
    },
    on: {
      "cmdclick": _vm.changeState
    }
  }) : _vm._e()], 1)]), _c('themecard', {
    staticClass: "caseInfo",
    attrs: {
      "title": "Info",
      "set": _vm.state = this.concernSummary.stateSummary
    }
  }, [_c('div', [_c('caseinfo', {
    attrs: {
      "concernId": _vm.caseLocator.concernId,
      "versionId": _vm.concernVersion,
      "initialCaseId": null,
      "info-list": _vm.concernSummary.infoList
    },
    on: {
      "reloadParent": _vm.reloadConcernSummary
    }
  })], 1)]), _vm.concernSummary.causeActivityList.length > 0 ? _c('themecard', {
    staticClass: "causeactivity",
    attrs: {
      "title": "Leistungen"
    }
  }, [_c('v-simple-table', {
    attrs: {
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v("Name")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Status")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Todo")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Hebamme")])])]), _c('tbody', _vm._l(_vm.concernSummary.causeActivityList, function (item) {
          var _item$midwife;
          return _c('tr', {
            key: item.activityId
          }, [_c('td', [_vm._v(_vm._s(item.name))]), _c('td', [_vm._v(_vm._s(item.state.caption))]), _c('td', [_vm._v(_vm._s(item.todo.caption))]), _c('td', [_vm._v(_vm._s((_item$midwife = item.midwife) === null || _item$midwife === void 0 ? void 0 : _item$midwife.name))])]);
        }), 0)];
      },
      proxy: true
    }], null, false, 2166489246)
  })], 1) : _vm._e(), _vm.concernSummary.adviceList.length > 0 ? _c('themecard', {
    attrs: {
      "title": "Beratungen"
    }
  }, [_c('v-simple-table', {
    attrs: {
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v("Datum")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Bearbeiterin")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Beratungsinhalt")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Kommentar")])])]), _c('tbody', _vm._l(_vm.concernSummary.adviceList, function (item) {
          return _c('tr', {
            key: item.pid
          }, [_c('td', [_c('dateitem', {
            staticStyle: {
              "display": "inline"
            },
            attrs: {
              "date-item": item.adviceTime
            }
          })], 1), _c('td', [_vm._v(_vm._s(item.userName))]), _c('td', _vm._l(item.adviceThemeList, function (theme) {
            return _c('div', {
              key: theme
            }, [_vm._v("- " + _vm._s(theme))]);
          }), 0), _c('td', [_vm._v(_vm._s(item.info))])]);
        }), 0)];
      },
      proxy: true
    }], null, false, 1420527724)
  })], 1) : _vm._e()], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }