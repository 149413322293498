<template>
  <div>
    <tabcontent :object-id="{ midwifeId: midwifeId } " :tab-list="midwifeFileTabs" :preselectedTab="preselectedTab" contenttype="midwife"></tabcontent>
  </div>
</template>

<script>
import tabcontent from '../../../common/components/content/TabContent'
import MidwifeOverviewController from '../overview/midwife.overview.controller'

export default {
  name: 'MidwifeMainFile',

  components: {
    tabcontent
  },

  methods: {
    async back () {
      await MidwifeOverviewController.openMidwifeOverview(this.midwifeId)
    }
  },

  computed: {
    midwifeFileTabs () {
      return this.$store.state.menu.midwifeFileTabs
    }
  },

  props: {
    preselectedTab: { type: Number },
    midwifeId: { type: String }
  }
}
</script>
