<template>
  <div class="errorpanel">
    <v-bottom-sheet v-model="showError" persistent>
      <v-sheet class="text-center" height="300px">
        <v-btn
          class="mt-6"
          text
          color="error"
          @click="resetError()">
          Schliessen
        </v-btn>
        <div style="overflow-y: auto;height: 220px;">
          <div v-for='message in  error' :key='message.reason' class="py-5">
            <h5>{{ message.reason }}</h5>
            <li v-for='information in message.informationList' :key='information'>
              {{ information }}
            </li>
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import ErrorController from './error.controller'

export default {
  methods: {
    resetError () {
      ErrorController.resetError()
    }
  },

  computed: {
    showError () {
      return this.$store.getters['error/isError']
    },

    error: function () {
      if (this.$store.state.error.error) {
        return this.$store.state.error.error
      } else {
        return {}
      }
    }
  }
}
</script>
