import CaseController from '../case/case.overview.controller'
import CauseActivityController from '../causeactivity/concern.causeactivity.controller'

export default {
  async openConcernMainFile (caseLocator) {
    if (caseLocator.caseId) {
      CaseController.openCaseFile(caseLocator)
    } else {
      CauseActivityController.openConcernCauseActivityOverview(caseLocator)
    }
  }
}
