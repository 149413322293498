var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-template-columns": "200px auto"
    }
  }, [_c('div', [_c('v-tabs', {
    staticClass: "elevation-4",
    attrs: {
      "fixed-tabs": "",
      "vertical": "",
      "background-color": "lighten-5"
    },
    on: {
      "change": _vm.setTabId
    },
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, _vm._l(_vm.tabList, function (tab) {
    return _c('v-tab', {
      key: tab.id,
      staticStyle: {
        "text-align": "right"
      },
      attrs: {
        "light": "",
        "exact": ""
      },
      on: {
        "click": function ($event) {
          tab.openRoute(_vm.getObjectId(tab));
        }
      }
    }, [_c('v-icon', {
      staticStyle: {
        "padding-left": "10px"
      },
      attrs: {
        "left": "",
        "small": ""
      }
    }, [_vm._v(_vm._s(tab.icon))]), _c('div', {
      staticClass: "caption"
    }, [_vm._v(_vm._s(tab.name) + " " + _vm._s(tab.caseId))]), tab.count > 0 ? _c('v-badge', {
      attrs: {
        "color": "red",
        "content": tab.count,
        "right": ""
      }
    }) : _vm._e()], 1);
  }), 1)], 1), _c('div', {
    staticStyle: {
      "padding-left": "20px"
    }
  }, [_c('router-view')], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }