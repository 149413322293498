import router from '#enna/common/router'
import MenuController from '../../app/menu/menu.controller'
import MidwifePropertyService from './midwife.property.service'
import DynPropController from '../../../common/components/dynprop/dynprop.controller'
import ModuleInfoController from '../../app/moduleinfo/moduleinfo.controller'

export default {
  async openMidwifePropertyOverview (midwifeId) {
    callMidwifePropertyOverview(midwifeId)
  },

  async reloadDynPropDetail (param, reloadId) {
    await callDynPropDetail(param.midwifeId, param.property, param.thisObject, reloadId)
  },

  async openMidwifePropertyOverviewFromDynProp (param) {
    callMidwifePropertyOverview(param.midwifeId, param.dynPropDefId)
  },

  async openDynPropDetail (midwifeId, property) {
    callDynPropDetail(midwifeId, property, this)
  },

  async saveMidwifeProperty (midwifeProperty) {
    await MidwifePropertyService.saveMidwifeProperty(midwifeProperty)
    callMidwifePropertyOverview(midwifeProperty.midwifeId)
  },

  async openMidwifePropertyDetail (midwifeId, reloadId) {
    callMidwifePropDetail(midwifeId, reloadId)
  }
}

async function callMidwifePropertyOverview (midwifeId, dynPropDefId) {
  let hashId = ''
  if (dynPropDefId) {
    hashId = '#' + dynPropDefId
  }
  const response = await MidwifePropertyService.queryViewProperty(midwifeId)
  const midwifePropertyList = response.data.midwifePropertyList
  const dynPropertyList = response.data.dynPropList
  const midwifeName = response.data.midwifeName
  const selectedTabIndex = await MenuController.getMidwifeFileStartTab('midwifepropertyoverview')
  router.push({
    name: 'midwifepropertyoverview',
    hash: hashId,
    params: {
      midwifeId: midwifeId,
      midwifeName: midwifeName,
      preselectedTab: selectedTabIndex,
      writeRight: 'midPropertyWrite',
      dynPropPropertyList: dynPropertyList,
      midwifePropertyList: midwifePropertyList
    }
  })
}

async function callMidwifePropDetail (midwifeId, reloadId) {
  const response = await MidwifePropertyService.loadMidwifeProperty(midwifeId)
  const dtoMidwifeProperty = response.data.midwifeProperty
  const promiseProcedureOptionList = response.data.promiseProcedureOptionList
  const contactProcedureOptionList = response.data.contactProcedureOptionList
  const midwifeName = response.data.midwifeName
  router.push({ name: 'midwifepropertydetail', params: { midwifeName: midwifeName, propDtoMidwifeProperty: dtoMidwifeProperty, promiseProcedureOptionList: promiseProcedureOptionList, contactProcedureOptionList: contactProcedureOptionList }, query: { reload: reloadId } })
}

async function callDynPropDetail (midwifeId, property, thisObject, reloadId) {
  const response = await MidwifePropertyService.loadDynPropProperty(midwifeId, property.id, property.pid)
  const moduleInfo = {
    first: 'Hebamme',
    second: 'Zusatzdaten',
    name: response.data.midwifeName
  }

  const param = {
    callFunction: thisObject.openMidwifePropertyOverviewFromDynProp,
    callParam: {
      midwifeId: midwifeId,
      dynPropDefId: response.data.dynPropLoadData.dtoDynPropData.dynPropDefId
    },
    reloadFunction: thisObject.reloadDynPropDetail,
    reloadParam: {
      midwifeId: midwifeId,
      property: property,
      thisObject: thisObject
    },
    objectType: 'midwife',
    objectId: midwifeId,
    name: response.data.dynPropLoadData.name,
    dtoDynPropData: response.data.dynPropLoadData.dtoDynPropData,
    keylistList: response.data.dynPropLoadData.keylistList,
    versionId: response.data.dynPropLoadData.versionId,
    moduleInfo: moduleInfo,
    moduleController: ModuleInfoController.midwife
  }

  DynPropController.openDynPropDetail(param, reloadId)
}
