import MidwifeActivityController from '../overview/midwife.activity.controller'
import MobileActivityService from './mobile.activity.service'
import router from '#enna/common/router'

export default {
  async delete (pid) {
    const value = await MobileActivityService.delete(pid)
    return value
  },

  async load (midwifeId, pid, reloadId) {
    const response = await MobileActivityService.load(pid)
    const propDtoMobileActivity = response.data.dtoMobileActivity
    const midwifeName = response.data.midwifeName
    const activityName = response.data.activityName
    const activityType = response.data.activityType
    const regionList = response.data.regionList
    router.push({
      name: 'mobileactivitydetail',
      params: {
        midwifeId: midwifeId,
        pid: pid,
        midwifeName: midwifeName,
        activityName: activityName,
        activityType: activityType,
        propDtoMobileActivity: propDtoMobileActivity,
        regionList: regionList
      },
      query: { reload: reloadId }
    })
  },

  async save (dtoMobileActivity) {
    await MobileActivityService.save(dtoMobileActivity)
    MidwifeActivityController.openMidwifeActivityOverview(dtoMobileActivity.midwifeId)
  },

  async generateActivity (data) {
    await MobileActivityService.generateActivity(data)
  },

  async compareActivity (midwifeId) {
    const response = await MobileActivityService.compareActivity(midwifeId)
    const result = response.data.mobileActivityComparison
    return result
  },

  async openModifyActivity (midwifeId, reloadId) {
    const response = await MobileActivityService.loadModifyActivityData(midwifeId)
    const regionList = response.data.regionList
    const mobileActivityList = response.data.mobileActivityList
    const midwifeName = response.data.midwifeName

    router.push({
      name: 'mobileactivitymodify',
      params: {
        midwifeId: midwifeId,
        midwifeName: midwifeName,
        regionList: regionList,
        mobileActivityList: mobileActivityList
      },
      query: { reload: reloadId }
    })
  },

  async addRegion (midwifeId, selectedActivityList, selectedRegionList, close) {
    var activityIdList = await getActivityIdList(selectedActivityList)
    var regionIdList = await getRegionIdList(selectedRegionList)
    await MobileActivityService.addRegion({ midwifeId, activityIdList: activityIdList, regionIdList: regionIdList, close: close })
  },

  async removeRegion (midwifeId, selectedActivityList, selectedRegionList, close) {
    var activityIdList = await getActivityIdList(selectedActivityList)
    var regionIdList = await getRegionIdList(selectedRegionList)
    await MobileActivityService.removeRegion({ midwifeId, activityIdList: activityIdList, regionIdList: regionIdList, close: close })
  }
}

async function getRegionIdList (selectedList) {
  var result = []
  for (var index = 0; index < selectedList.length; index++) {
    result.push(selectedList[index].id)
  }
  return result
}

async function getActivityIdList (selectedList, id) {
  var result = []
  for (var index = 0; index < selectedList.length; index++) {
    result.push(selectedList[index].activity.activityId)
  }
  return result
}
