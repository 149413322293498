<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ caption }}
      </v-card-title>
      <v-card-text style="white-space: pre-wrap;">{{ content }}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="closeDialog"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InfoDialogController from './infodialog.controller'
export default {
  name: 'InfoDialog',

  methods: {
    closeDialog () {
      InfoDialogController.clear()
    }
  },

  computed: {
    dialog: function () {
      return this.$store.state.infoDialog.show
    },

    caption: function () {
      return this.$store.state.infoDialog.caption
    },

    content: function () {
      return this.$store.state.infoDialog.text
    }
  }
}
</script>
