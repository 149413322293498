var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('formcontent', {
    attrs: {
      "buttonOneText": "Alle Übergaben",
      "ok-text": "Speichern",
      "write-right": "handoverWrite"
    },
    on: {
      "onButtonOne": _vm.openHandoverOverview,
      "onOk": _vm.saveHandover
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('div', [_c('div', {
          staticStyle: {
            "color": "darkgrey",
            "padding-bottom": "15px",
            "font-weight": "bold",
            "display": "grid",
            "grid-template-columns": "1fr 1fr 4fr 6fr"
          }
        }, [_c('dateitem', {
          attrs: {
            "date-item": _vm.handover.editdate
          }
        }), _c('div')], 1), _c('v-divider'), _c('div', {
          staticStyle: {
            "display": "grid",
            "grid-template-columns": "1fr 1fr 2fr 1fr 1fr 6fr",
            "padding-bottom": "20px"
          }
        }, [_c('v-icon', {
          staticStyle: {
            "padding-left": "20px"
          },
          attrs: {
            "light": ""
          }
        }, [_vm._v("mdi-wrench")]), _c('v-checkbox', {
          attrs: {
            "label": "Todo"
          },
          model: {
            value: _vm.handover.todo,
            callback: function ($$v) {
              _vm.$set(_vm.handover, "todo", $$v);
            },
            expression: "handover.todo"
          }
        }), _c('div'), _c('v-icon', {
          staticStyle: {
            "padding-left": "20px"
          },
          attrs: {
            "light": ""
          }
        }, [_vm._v("mdi-alert")]), _c('v-checkbox', {
          attrs: {
            "label": "Wichtig"
          },
          model: {
            value: _vm.handover.important,
            callback: function ($$v) {
              _vm.$set(_vm.handover, "important", $$v);
            },
            expression: "handover.important"
          }
        })], 1), _c('v-textarea', {
          staticStyle: {
            "width": "95%"
          },
          attrs: {
            "clearable": "",
            "clear-icon": "mdi-close-circle",
            "label": "Text"
          },
          model: {
            value: _vm.handover.content,
            callback: function ($$v) {
              _vm.$set(_vm.handover, "content", $$v);
            },
            expression: "handover.content"
          }
        })], 1)];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }