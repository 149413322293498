const state = {
  moduleinfo: {
    category: '',
    colour: '',
    theme: '',
    detail: '',
    name: ''
  }
}

const getters = {
  moduleinfo: (state) => {
    return state.moduleinfo
  }
}

const actions = {
  setModuleinfo ({ commit }, moduleinfo) {
    commit('setModuleinfo', moduleinfo)
  },

  resetModuleinfo ({ commit }) {
    commit('resetModuleinfo')
  }
}

const mutations = {
  setModuleinfo (state, data) {
    state.moduleinfo = data
  },

  resetModuleinfo (state) {
    state.moduleinfo = {
      category: '',
      colour: '',
      theme: '',
      detail: '',
      name: ''
    }
  }
}

export const moduleinfo = {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}
