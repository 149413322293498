var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('midwifeactivitydetail', {
    attrs: {
      "pid": _vm.pid,
      "activityType": _vm.activityType,
      "activityName": _vm.activityName,
      "midwifeName": _vm.midwifeName,
      "midwifeId": _vm.midwifeId
    },
    on: {
      "onOk": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "display": "grid",
            "grid-gap": "20px",
            "grid-template-rows": "80px",
            "align-items": "end",
            "grid-template-columns": "2fr 3fr 2fr 1fr"
          }
        }, [_c('v-text-field', {
          attrs: {
            "dense": "",
            "label": "Name"
          },
          model: {
            value: _vm.dtoLocationActivity.locationName,
            callback: function ($$v) {
              _vm.$set(_vm.dtoLocationActivity, "locationName", $$v);
            },
            expression: "dtoLocationActivity.locationName"
          }
        }), _c('v-text-field', {
          attrs: {
            "dense": "",
            "label": "Zeitpunkt"
          },
          model: {
            value: _vm.dtoLocationActivity.starttime,
            callback: function ($$v) {
              _vm.$set(_vm.dtoLocationActivity, "starttime", $$v);
            },
            expression: "dtoLocationActivity.starttime"
          }
        }), _c('v-text-field', {
          attrs: {
            "dense": "",
            "label": "Telefon"
          },
          model: {
            value: _vm.dtoLocationActivity.phone,
            callback: function ($$v) {
              _vm.$set(_vm.dtoLocationActivity, "phone", $$v);
            },
            expression: "dtoLocationActivity.phone"
          }
        })], 1), _c('div', {
          staticStyle: {
            "display": "grid",
            "grid-gap": "20px",
            "grid-template-rows": "80px",
            "align-items": "end",
            "grid-template-columns": "1fr 3fr 3fr 1fr"
          }
        }, [_c('v-text-field', {
          attrs: {
            "dense": "",
            "label": "Postleitzahl"
          },
          model: {
            value: _vm.dtoLocationActivity.postcode,
            callback: function ($$v) {
              _vm.$set(_vm.dtoLocationActivity, "postcode", $$v);
            },
            expression: "dtoLocationActivity.postcode"
          }
        }), _c('v-text-field', {
          attrs: {
            "dense": "",
            "label": "Stadt"
          },
          model: {
            value: _vm.dtoLocationActivity.city,
            callback: function ($$v) {
              _vm.$set(_vm.dtoLocationActivity, "city", $$v);
            },
            expression: "dtoLocationActivity.city"
          }
        }), _c('v-text-field', {
          attrs: {
            "dense": "",
            "label": "Straße / Hausnummer"
          },
          model: {
            value: _vm.dtoLocationActivity.streetnumber,
            callback: function ($$v) {
              _vm.$set(_vm.dtoLocationActivity, "streetnumber", $$v);
            },
            expression: "dtoLocationActivity.streetnumber"
          }
        })], 1), _c('div', {
          staticStyle: {
            "display": "grid",
            "grid-gap": "20px",
            "grid-template-rows": "80px",
            "align-items": "end",
            "grid-template-columns": "4fr 3fr 1fr"
          }
        }, [_c('singleselection', {
          staticStyle: {
            "margin-bottom": "-11px"
          },
          attrs: {
            "selectedId": _vm.dtoLocationActivity.regionId,
            "prop-selected-item": _vm.dtoLocationActivity.regionId,
            "caption": "Stadtteil / Ortschaft",
            "idName": "regionId",
            "propItemList": _vm.regionList
          },
          on: {
            "update:selectedId": function ($event) {
              return _vm.$set(_vm.dtoLocationActivity, "regionId", $event);
            },
            "update:selected-id": function ($event) {
              return _vm.$set(_vm.dtoLocationActivity, "regionId", $event);
            }
          }
        }), _c('v-text-field', {
          attrs: {
            "dense": "",
            "label": "Stadtteil"
          },
          model: {
            value: _vm.dtoLocationActivity.district,
            callback: function ($$v) {
              _vm.$set(_vm.dtoLocationActivity, "district", $$v);
            },
            expression: "dtoLocationActivity.district"
          }
        })], 1), _c('div', {
          staticStyle: {
            "display": "grid",
            "grid-gap": "20px",
            "grid-template-rows": "80px",
            "align-items": "end",
            "grid-template-columns": "7fr 1fr"
          }
        }, [_c('v-textarea', {
          attrs: {
            "label": "Info",
            "clearable": "",
            "auto-grow": "",
            "rows": "1"
          },
          model: {
            value: _vm.dtoLocationActivity.info,
            callback: function ($$v) {
              _vm.$set(_vm.dtoLocationActivity, "info", $$v);
            },
            expression: "dtoLocationActivity.info"
          }
        })], 1)];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }