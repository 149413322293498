<template>
    <div>
      {{ datetimeItem | formattedDatetime }}
    </div>
</template>

<script>

export default {
  props: {
    datetimeItem: String
  }
}
</script>
