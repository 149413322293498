import ApiService from '../../../common/service/api/api.service'

const ConcernWomanService = {
  async loadDynPropProperty (caseLocator, dynPropDefId, pid) {
    const result = await ApiService.doGet('/enna/cause/woman/dynprop/view/dto/' + caseLocator.concernId + '/' + dynPropDefId + '/' + pid)
    return result
  },

  async getWoman (concernId) {
    const result = await ApiService.doGet('/enna/cause/woman/overview/' + concernId)
    return result
  },

  async getDtoWoman (pid) {
    const result = await ApiService.doGet('/enna/cause/woman/dto/' + pid)
    return result
  },

  async saveDtoWoman (concernVersion, dto) {
    const data = {
      concernVersion: concernVersion,
      dtoWoman: dto
    }
    const result = await ApiService.doPost('/enna/cause/woman', data)
    return result
  }
}

export default ConcernWomanService
