<template>
    <formcontent
      ok-text="Ausführen" @onOk="execute"
      cancel-text="Abbrechen" @onCancel="cancel"
      write-right="midMasterdataWrite">
      <div v-bind:style="{ 'overflow-y': 'scroll', 'max-height': contentheight + 'px' }">
        <div class="grid" v-bind:style="{ 'display': 'grid', 'grid-template-columns': columnstyle }">
          <span></span>
          <span style="background-color: #f0f0f0;" v-for="column in concernColumns" :key="'concern#' + column">
            Daten aus {{ column }}. bestehender Anfrage
          </span>
          <span style="background-color: #f0f0f0;" v-for="column in webformInquiryColumns" :key="'webforminquiry#' + column">
            Daten aus {{ column }}. Webformular
          </span>
          <span style="background-color: #f0f0f0;">
            <v-select
              :items="allConcernList"
              item-text="name"
              item-value="concernId"
              v-model="alternativeConcernId"
              label="Alle anderen Anfragen">
          </v-select>
          </span>
        </div>
        <div v-bind:style="{ 'display': 'grid', 'grid-template-columns': columnstyle }" class="grid" v-for="item in itemList" :key="item.index">
          <span style="background-color: #fcfcfc">{{ item.caption }}</span>
          <span v-for="concern in concernList" :key="concern.pid">
            <doublettype :type="item.type" :value="{ data: concern[item.index] }"></doublettype>
          </span>
          <span v-for="webformInquiry in webformInquiryList" :key="webformInquiry.pid">
            <doublettype :type="item.type" :value="{ data: webformInquiry[item.index] }"></doublettype>
          </span>
          <span>
            --
          </span>
        </div>
        <div class="grid" v-bind:style="{ 'display': 'grid', 'grid-template-columns': columnstyle }">
        <span class="font-weight-black final_theme">
          <span >Aktion auswählen</span>
          <v-select v-model="choice" @change="optionChanged" :items="optionList" item-text="label" item-value="id">
          </v-select>
        </span>
          <template v-if="choice==='delete'">
          <span v-for="concern in concernList" :key="concern.pid" class="final_action">
          </span>
            <span v-for="webformInquiry in webformInquiryList" :key="webformInquiry.pid" class="final_action">
            <v-checkbox label="Löschen" @change="onDelete($event, webformInquiry.compactInquiryKey)" :key="webformInquiry.pid"></v-checkbox>
          </span>
            <span class="final_action"></span>
          </template>
          <template v-else-if="choice==='new'">
          <span v-for="concern in concernList" :key="concern.pid" class="final_action">
          </span>
            <span v-for="webformInquiry in webformInquiryList" :key="webformInquiry.pid" class="final_action">
            <v-checkbox label="Neu anlegen" @change="onNew($event, webformInquiry.compactInquiryKey)"></v-checkbox>
            <v-checkbox label="Haupteintrag" v-model="mainSelected" :value="webformInquiry.compactInquiryKey.webformPid" key="webformInquiry.pid"></v-checkbox>
          </span>
            <span class="final_action"></span>
          </template>
          <template v-else-if="choice==='assign'">
          <span v-for="concern in concernList" :key="concern.pid" class="final_action">
            <v-checkbox label="Anfrage" v-model="assignConcern" :value="concern.compactInquiryKey.concernId" key="concern.pid"></v-checkbox>
          </span>
            <span v-for="webformInquiry in webformInquiryList" :key="webformInquiry.pid" class="final_action">
            <v-checkbox label="Zuweisen" @change="onAssign($event, webformInquiry.compactInquiryKey)" :key="'c' + webformInquiry.pid"></v-checkbox>
          </span>
            <span class="final_action">
            <v-checkbox label="Anfrage" v-model="useAlternativeConcern"></v-checkbox>
          </span>
          </template>
        </div>
      </div>

    </formcontent>
</template>

<script>
import doublettype from '../doublet/DoubletType'
import formcontent from '../../../common/components/content/FormContent'
import WebformController from './webform.controller'
import InfoDialogController from '../../../common/components/element/infodialog/infodialog.controller'

export default {
  name: 'DoubletProcessingPage',

  async created () {
    this.$pageInitController.init(this, this.$moduleInfoController.concern, 'Potentielle Dublette')
  },

  computed: {
    webformInquiryList () {
      const result = []
      Array.prototype.push.apply(result, this.propWebformInquiryList)
      result.push(this.webformInquiry)
      return result
    },

    webformInquiryColumns () {
      return this.webformInquiryList.length
    },

    concernColumns () {
      return this.concernList.length
    },

    columnstyle () {
      const columnCount = this.webformInquiryList.length + this.concernList.length
      if (columnCount === 0) {
        return '200px'
      } else {
        const newColumnCount = columnCount + 1
        return '200px repeat(' + newColumnCount + ', 200px)'
      }
    },

    contentheight () {
      const height = this.$store.getters['size/overviewContent']
      return height - 86
    }
  },

  methods: {
    cancel () {
      WebformController.openWebformOverview()
    },

    async execute () {
      if (this.plausibilityOk()) {
        let concernId = null
        if (this.useAlternativeConcern) {
          concernId = this.alternativeConcernId
        } else {
          concernId = this.assignConcern
        }
        await WebformController.executeDoubletProcessing(
          this.versionId,
          this.choice,
          this.deleteList,
          this.newList,
          this.mainSelected,
          concernId,
          this.assignWebformInquiryList)
      }
    },

    optionChanged () {
      this.deleteList.clear()
      this.mainSelected = null
      this.newList.clear()
      this.assignConcern = null
      this.assignWebformInquiryList.clear()
    },

    onNew (event, compactInquiryKey) {
      if (event) {
        this.newList.add(compactInquiryKey.webformPid)
      } else {
        this.newList.delete(compactInquiryKey.webformPid)
      }
    },

    onAssign (event, compactInquiryKey) {
      if (event) {
        this.assignWebformInquiryList.add(compactInquiryKey.webformPid)
      } else {
        this.assignWebformInquiryList.delete(compactInquiryKey.webformPid)
      }
    },

    onDelete (event, compactInquiryKey) {
      if (event) {
        this.deleteList.add(compactInquiryKey.webformPid)
      } else {
        this.deleteList.delete(compactInquiryKey.webformPid)
      }
    },

    plausibilityOk () {
      let infoText = null
      if (!this.choice) {
        infoText = 'Wählen Sie eine Aktion aus'
      }

      if (this.choice === 'new') {
        if (this.newList.size === 0) {
          infoText = 'Wählen Sie die Webanfrage(n) aus, die verwendet werden sollen, um eine neue Anfrage zu erstellen.'
        } else if (this.mainSelected === null) {
          infoText = 'Wählen Sie die Webanfrage aus, die beim Anlegen einer neuen Anfrage als Haupteintrag verwendet werden soll.'
        }
      } else if (this.choice === 'delete') {
        if (this.deleteList.size === 0) {
          infoText = 'Wählen Sie die Webanfragen aus, die gelöscht werden sollen.'
        }
      } else if (this.choice === 'assign') {
        if (this.assignConcern === null && !this.useAlternativeConcern) {
          infoText = 'Wählen Sie die Anfrage aus, der die Webanfragen zugeordnet werden sollen.'
        } else if (this.useAlternativeConcern && this.alternativeConcernId === null) {
          infoText = 'Wählen Sie die Anfrage aus "Alle anderen Anfragen", der die Webanfragen zugeordnet werden sollen.'
        } else if (this.assignWebformInquiryList.size === 0) {
          infoText = 'Wählen Sie die Webanfrage(n) aus, die der Anfrage zugeordnet werden sollen.'
        }
      }

      if (infoText) {
        InfoDialogController.showInfo('Fehlende Eingabe', infoText)
      }
      return infoText === null
    }
  },

  components: {
    doublettype,
    formcontent
  },

  data () {
    return {
      useAlternativeConcern: false,
      alternativeConcernId: null,
      assignConcern: null,
      assignWebformInquiryList: new Set(),
      mainSelected: null,
      newList: new Set(),
      deleteList: new Set(),
      choice: '',
      optionList: [
        {
          id: 'new',
          label: 'Neu anlegen',
          explanation: 'Legt eine neue Anfrage an'
        }, {
          id: 'delete',
          label: 'Löschen',
          explanation: 'Löscht die ausgewählten Anfragen'
        }, {
          id: 'assign',
          label: 'Zuweisen',
          explanation: 'Weist die ausgewählten Anfragen einer bestehenden Anfrage zu'
        }
      ]
    }
  },

  props: {
    versionId: Number,
    webformInquiry: { type: Object },
    itemList: { type: Array },
    concernList: { type: Array },
    allConcernList: { type: Array },
    propWebformInquiryList: { type: Array }
  }
}
</script>

<style>
.grid > span {
  padding: 8px 4px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}
.final_theme {
  background-color: #EDEDED;
  border-top: 5px solid black;
}
.final_action {
  background-color: #FAFAFA;
  border-top: 5px solid black;
}
</style>
