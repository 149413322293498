const state = {
  carrierMap: createCarrierMap()
}

const getters = {
  getMandatorList (state) {
    return carrierId => state.carrierMap.get(carrierId)
  }
}

const mutations = {
  setPageinfo (state, { pageId, pageinfo }) {
    state.pageinfoMap.set(pageId, pageinfo)
  }
}

function createCarrierMap () {
  const result = new Map()
  result.set('asbnw',
    [
      { suffix: 'muenster', name: 'Hebammenzentrale Münster' },
      { suffix: 'bielefeld', name: 'Hebammenzentrale Bielefeld' },
      { suffix: 'recklinghausen', name: 'Hebammenzentrale Recklinghausen' },
      { suffix: 'essen', name: 'Hebammenzentrale Essen' },
      { suffix: 'bottrop', name: 'Hebammenzentrale Bottrop' }
    ]
  )
  result.set('demo',
    [
      { suffix: 'aurich', name: 'Hebammenzentrale Aurich' },
      { suffix: 'bergheim', name: 'Hebammenzentrale Bergheim' },
      { suffix: 'bielefeld', name: 'Hebammenzentrale Bielefeld' },
      { suffix: 'erlangen', name: 'Hebammenzentrale Erlangen' },
      { suffix: 'fuerth', name: 'Hebammenzentrale Fürth' },
      { suffix: 'kempten', name: 'Hebammenzentrale Kempten' },
      { suffix: 'mainz', name: 'Hebammenzentrale Mainz' },
      { suffix: 'muenster', name: 'Hebammenzentrale Münster' },
      { suffix: 'recklinghausen', name: 'Hebammenzentrale Recklinghausen' },
      { suffix: 'schweinfurt', name: 'Hebammenzentrale Schweinfurt' },
      { suffix: 'trier', name: 'Hebammenzentrale Trier' },
      { suffix: 'wuerzburg', name: 'Hebammenzentrale Würzburg' }
    ]
  )
  result.set('dev',
    [
      { suffix: 'aurich', name: 'Hebammenzentrale Aurich' },
      { suffix: 'bergheim', name: 'Hebammenzentrale Bergheim' },
      { suffix: 'bielefeld', name: 'Hebammenzentrale Bielefeld' },
      { suffix: 'erlangen', name: 'Hebammenzentrale Erlangen' },
      { suffix: 'fuerth', name: 'Hebammenzentrale Fürth' },
      { suffix: 'kempten', name: 'Hebammenzentrale Kempten' },
      { suffix: 'mainz', name: 'Hebammenzentrale Mainz' },
      { suffix: 'muenster', name: 'Hebammenzentrale Münster' },
      { suffix: 'recklinghausen', name: 'Hebammenzentrale Recklinghausen' },
      { suffix: 'schweinfurt', name: 'Hebammenzentrale Schweinfurt' },
      { suffix: 'trier', name: 'Hebammenzentrale Trier' },
      { suffix: 'wuerzburg', name: 'Hebammenzentrale Würzburg' }
    ]
  )
  return result
}
export const carrier = {
  namespaced: true,
  state,
  getters,
  mutations
}
