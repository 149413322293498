var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "mx-auto",
    staticStyle: {
      "border-top": "100px"
    },
    attrs: {
      "max-width": "800",
      "variant": "outlined"
    }
  }, [_c('v-card-item', [_c('div', [_c('div', {
    staticClass: "text-overline mb-1",
    staticStyle: {
      "padding-bottom": "50px"
    }
  }, [_c('v-toolbar-title', [_c('span', {
    staticClass: "font-weight-thin"
  }, [_vm._v("e n ")]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("n a")])])], 1), _c('div', {
    staticClass: "text-h6 mb-1",
    staticStyle: {
      "padding-bottom": "50px"
    }
  }, [_vm._t("default")], 2)])]), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "variant": "outlined"
    }
  }, [_c('router-link', {
    staticStyle: {
      "font-size": "x-large"
    },
    attrs: {
      "to": "/login"
    }
  }, [_vm._v("Anmelden")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }