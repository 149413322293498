<template>
  <div>
    <overviewcontent @onCancel="loadFromMailserver" cancelText="Anfragen abrufen" @onOk="execute()" objectIdName="pid" :data-list="webformInquiryList" :withSearchString=false :withNew="true" newCaption="Übernehmen / Löschen">
        <template v-slot="webformInquiryItem">
          <v-card class="mx-auto" outlined >
            <div>
              <div style=" display:grid; grid-template-columns: 3fr 3fr 3fr 3fr 1fr;">
                <div style="padding-top:20px; font-weight: bold;">
                  {{ webformInquiryItem.item.compactInquiry.secondname }}, {{ webformInquiryItem.item.compactInquiry.firstname }}
                </div>
                <div style="padding-top:20px;">
                  <span style="font-weight: bold;">{{ getEtBtCaption(webformInquiryItem.item.compactInquiry.edbd, webformInquiryItem.item.compactInquiry.edbdMode) }}</span><dateitem style="display: inline;" :date-item="webformInquiryItem.item.compactInquiry.edbd"></dateitem>
                </div>
                <div style="padding-top:20px;">
                  <v-icon small>{{ 'mdi-email' }}</v-icon> <dateitem style="display: inline;" :date-item="webformInquiryItem.item.compactInquiry.callDate"></dateitem>
                </div>
                <div>
                  <v-combobox v-if="webformInquiryItem.item.possibleDoubletList.length === 0"
                    style="width:160px;"
                    label="Auswählen"
                    :items="optionList"
                    item-text="caption"
                    item-value="id"
                    deletable-chips
                    clearable
                    @change="handleWebformInquiry(webformInquiryItem.item, $event)">
                  </v-combobox>
                  <div v-else style="padding-top: 10px;">
                    <v-btn v-on:click="handleDublette(webformInquiryItem.item)">Dublette?</v-btn>
                  </div>
                </div>
                <div>
                  <v-card-actions>
                    <v-btn color="orange lighten-2" text>Details
                    </v-btn>
                    <v-btn
                      icon
                      @click="switchDetails(webformInquiryItem.item.pid)">
                      <v-icon>{{ showDetails(webformInquiryItem.item.pid) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                  </v-card-actions>
                </div>
              </div>
            </div>
            <v-expand-transition>
              <div v-show="showDetails(webformInquiryItem.item.pid)" style="padding-left: 60px;">
                <webforminquirydetail :inquiry="webformInquiryItem.item.compactInquiry"></webforminquirydetail>
              </div>
            </v-expand-transition>
          </v-card>
        </template>
    </overviewcontent>
  </div>
</template>

<script>
import overviewcontent from '#enna/common/components/content/OverviewContent'
import WebformController from './webform.controller'
import webforminquirydetail from './WebformInquiryDetail'
import cloneDeep from 'lodash/cloneDeep'
import dateitem from '#enna/common/components/datatype/DateItem'
import InfoDialogController from './../../../common/components/element/infodialog/infodialog.controller'

export default {
  name: 'WebformPage',

  async created () {
    this.$pageInitController.initReload(this, this.$moduleInfoController.concern, 'Anfragen per Webformular')
  },

  watch: {
    propVersionId (val) {
      this.versionId = cloneDeep(this.propVersionId)
    },

    propWebformInquiryList (val) {
      this.webformInquiryList = cloneDeep(this.propWebformInquiryList)
    }
  },

  methods: {
    getEtBtCaption (edbd, edbdMode) {
      if (!edbd || !edbdMode) {
        return null
      } else {
        return edbdMode.name + ':'
      }
    },

    showDetails (pid) {
      return this.showDetailsSet.has(pid)
    },

    switchDetails (pid) {
      if (this.showDetailsSet.has(pid)) {
        this.showDetailsSet.delete(pid)
      } else {
        this.showDetailsSet.add(pid)
      }
      this.showDetailsSet = cloneDeep(this.showDetailsSet)
    },

    handleWebformInquiry (item, event) {
      if (event) {
        this.selected.set(item.pid, event.id)
      } else {
        this.selected.delete(item.pid)
      }
    },

    handleDublette (item) {
      WebformController.openDubletteProcessing(item)
    },

    async execute () {
      const result = await WebformController.saveDeleteWebformInquiry(this.selected, this.versionId)
      this.selected.clear()
      this.versionId = result.versionId
      this.webformInquiryList = result.webformInquiryList
    },

    async loadFromMailserver () {
      const readResult = await WebformController.loadFromMailserver()
      const result = await WebformController.reload()
      this.versionId = result.versionId
      const inquiryList = result.webformInquiryList
      const deletePidSet = new Set()
      this.selected.forEach((value, key) => {
        if (!this.containsInquiry(inquiryList, value)) {
          deletePidSet.add(value)
        }
      })
      deletePidSet.forEach(value => {
        this.selected.delete(value)
      })
      this.webformInquiryList = inquiryList
      const counter = readResult.newWebForm
      let counterText
      if (counter === 0) {
        counterText = 'Es wurden keine neuen Anfragen eingelesen'
      } else if (counter === 1) {
        counterText = 'Es wurde 1 neue Anfrage eingelesen'
      } else {
        counterText = 'Es wurden ' + counter + ' neue Anfragen eingelesen'
      }

      if (readResult.errorList.length > 0) {
        counterText += '\n\nEs wurden ' + readResult.errorList.length + ' fehlerhafte Anfrage(n) eingelesen:'
        for (let index = 0; index < readResult.errorList.length; index++) {
          counterText += '\n- '
          counterText += readResult.errorList[index]
        }
      }
      InfoDialogController.showInfo('Anfragen aus dem Webformular', counterText)
    },

    containsInquiry (webformInquiryList, pid) {
      let found = false
      webformInquiryList.forEach((inquiry, index) => {
        if (inquiry.pid === pid) {
          found = true
        }
      })
      return found
    },

    async reload () {
      await WebformController.openWebformOverview(Date.now())
    }
  },

  components: {
    dateitem,
    overviewcontent,
    webforminquirydetail
  },

  data () {
    return {
      versionId: this.propVersionId,
      webformInquiryList: cloneDeep(this.propWebformInquiryList),
      show: false,
      showDetailsSet: new Set(),
      optionList: [
        { id: 1, caption: 'übernehmen' },
        { id: 2, caption: 'löschen' }
      ],
      selected: new Map()
    }
  },

  props: {
    propVersionId: { type: Number },
    propWebformInquiryList: { type: Array }
  }
}
</script>
