var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('formcontent', {
    attrs: {
      "ok-text": "Anlegen"
    },
    on: {
      "onOk": _vm.save
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-rows": "100px",
      "align-items": "end",
      "grid-template-columns": "2fr 2fr 1fr"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Vorname"
    },
    model: {
      value: _vm.prename,
      callback: function ($$v) {
        _vm.prename = $$v;
      },
      expression: "prename"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Nachname"
    },
    model: {
      value: _vm.surname,
      callback: function ($$v) {
        _vm.surname = $$v;
      },
      expression: "surname"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }