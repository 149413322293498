import router from '#enna/common/router'
import ConcernCauseActivityService from './concern.causeactivity.service'
import SearchController from '../search/case.search.controller'
import SendmailController from '@/module/superior/sendmail/sendmail.controller'
import MenuController from '../../app/menu/menu.controller'

export default {
  async openSearchMidwife (caseLocator) {
    SearchController.openCauseActivityToSearch23(caseLocator)
  },

  async saveCauseActivity (concernVersion, caseLocator, dto) {
    await ConcernCauseActivityService.saveCauseActivity(caseLocator.concernId, concernVersion, dto)
    await internalOpenConcernCauseActivityOverview(caseLocator)
  },

  async writeMailNoMidwifeFound (caseLocator) {
    SendmailController.startSendMailCauseActivityNoMidwifeFound(caseLocator.concernId)
  },

  async writeMail (caseLocator) {
    SendmailController.startSendMailCauseActivity(caseLocator.concernId)
  },

  async openConcernNewCauseActivity (caseLocator) {
    const response = await ConcernCauseActivityService.queryNewCauseActivity(caseLocator.concernId)
    const concernVersion = response.concernBaseData.concernVersion
    const addInfo = response.concernData.addInfo
    const dtoCauseActivity = response.concernData.dto
    const activityList = response.concernData.activityList
    const startStateList = response.concernData.startStateList
    const womanName = response.concernBaseData.womanName

    router.push({
      name: 'concerneditcauseactivity',
      params: {
        newMode: true,
        concernVersion: concernVersion,
        caseLocator: caseLocator,
        addInfo: addInfo,
        womanName: womanName,
        propDtoCauseActivity: dtoCauseActivity,
        startStateList: startStateList,
        activityList: activityList
      }
    })
  },

  async openConcernEditCauseActivity (caseLocator, activityId) {
    const response = await ConcernCauseActivityService.loadCauseActivity(caseLocator.concernId, activityId)
    const concernVersion = response.concernBaseData.concernVersion
    const finishStateList = response.concernData.finishStateList
    const startStateList = response.concernData.startStateList
    const dtoCauseActivity = response.concernData.dto
    const addInfo = response.concernData.addInfo
    const womanName = response.concernBaseData.womanName

    router.push({
      name: 'concerneditcauseactivity',
      params: {
        newMode: false,
        concernVersion: concernVersion,
        caseLocator: caseLocator,
        addInfo: addInfo,
        womanName: womanName,
        propDtoCauseActivity: dtoCauseActivity,
        startStateList: startStateList,
        finishStateList: finishStateList
      }
    })
  },

  async openConcernCauseActivityOverview (caseLocator) {
    await internalOpenConcernCauseActivityOverview(caseLocator)
  }
}

async function internalOpenConcernCauseActivityOverview (caseLocator) {
  const response = await ConcernCauseActivityService.queryCauseActivity(caseLocator.concernId)
  const causeActivityList = response.concernData.causeActivityList
  const dateInfo = response.concernData.edbdInfo
  const selectedTabIndex = await MenuController.getConcernFileStartTab('concerncauseactivityoverview')
  const concernBaseData = response.concernBaseData

  router.push({
    name: 'concerncauseactivityoverview',
    params: {
      caseLocator: caseLocator,
      concernBaseData: concernBaseData,
      edbdInfo: dateInfo,
      womanName: concernBaseData.womanName,
      causeActivityList: causeActivityList,
      preselectedTab: selectedTabIndex
    }
  })
}
