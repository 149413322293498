<template>
  <div>
    <formcontent
      ok-text="Abschicken" @onOk="save"
      cancel-text="Abbrechen" @onCancel="cancel">
      <v-tabs >
        <v-tab v-for="item in mailExecDataList" :key="item.pid" vertical>
          <v-icon left>mdi-email</v-icon>{{ item.mailReceiverCaption }}<br/>{{ item.mailType.caption }}
        </v-tab>

        <v-tab-item v-for="item in mailExecDataList" :key="item.pid" >
          <v-card flat>
            <v-card-text>
              <div style="
              padding-top: 30px;
              display: grid;
              grid-gap: 20px;
              grid-template-columns:5fr 2fr;">
                <div>
                  <div style="padding-bottom: 10px;" class="font-weight-light text--primary">Aktuelle Mailvorlage</div>
                  <div>
                    <v-card flat light outlined class="mx-auto" >
                      <v-card-text style="
                        display: grid;
                        grid-gap: 20px;
                        grid-template-columns:1fr 1fr;">
                        <div>
                          <span class="font-weight-black text--primary">
                            {{ item.mailContent.viewMailTemplate.name }}
                          </span>
                          <div v-if="item.mailContent.viewMailTemplate.priorityTemplate">Hauptvorlage</div>
                        </div>
                        <div style="padding-top: 10px;" class="text--primary">
                          {{ item.mailContent.viewMailTemplate.description }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div>
                    <div style="
              padding-top: 30px;
              display: grid;
              grid-gap: 20px;
              grid-template-columns:1fr 1fr;">
                <div>
                  <div>An</div>
                  <div v-if="item.mailSendTo.mailAdress">
                    <div class="font-weight-bold" style="padding-left: 20px;">
                      <div>{{ item.mailSendTo.mailAdress.name }}</div>
                      <div>{{ item.mailSendTo.mailAdress.adress }}</div>
                      <div v-if="item.mailSendTo.mailAdress.info">Hinweis: {{ item.mailSendTo.mailAdress.info }}</div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>BCC</div>
                  <div v-if="item.mailSendTo.bccMailAdress">
                    <div class="font-weight-bold" style="padding-left: 20px;">
                      <div>{{ item.mailSendTo.bccMailAdress.name }}</div>
                      <div>{{ item.mailSendTo.bccMailAdress.adress }}</div>
                      <div v-if="item.mailSendTo.bccMailAdress.info">Hinweis: {{ item.mailSendTo.bccMailAdress.info }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="padding-top: 20px;" >
              <div style="background-color: #EDEDED">
                <v-text-field v-model="item.mailContent.mailSubject" label="Betreff" hint="Betreffzeile der Mail"></v-text-field>
                <v-textarea
                  style="width: 95%;"
                  clearable
                  clear-icon="mdi-close-circle"
                  label="Mailinhalt"
                  hint="Inhalt der Mailvorlage"
                  v-model="item.mailContent.mailContent"></v-textarea>
              </div>
              <cmdbutton @cmdclick="reset(item)" caption="Zurücksetzen"/>
            </div>
                </div>
                <div>
                  <div style="padding-bottom: 10px;" class="font-weight-light text--primary">Alternative Mailvorlagen</div>
                  <div v-bind:style="{ 'overflow-y': 'auto', 'height': contentheight + 'px' }" >
                    <div v-for="mailtemplate in item.alternativeMailTemplateList" :key="mailtemplate.pid">
                      <v-card flat light outlined class="mx-auto" >
                        <v-card-text>
                          <v-icon class="mr-2" @click="changeTemplate(item, mailtemplate)" color="blue">mdi-play-circle-outline</v-icon>
                          <span class="font-weight-black text--primary">
                            {{ mailtemplate.name }}
                          </span>
                          <div v-if="mailtemplate.priorityTemplate">Hauptvorlage</div>
                          <div style="padding-top: 10px;" class="text--primary">
                            {{ mailtemplate.description }}
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </div>
                </div>
              </div>
              </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </formcontent>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import SendMailController from './sendmail.controller'
import formcontent from '#enna/common/components/content/FormContent'
import cmdbutton from '../../../common/components/element/CmdButton'

export default {
  name: 'SendMail',

  async created () {
    if (this.midwifeMode) {
      this.$pageInitController.init(this, this.$moduleInfoController.midwife, 'Hebamme', 'Mail versenden', this.midwife.midwifeName)
    } else {
      this.$pageInitController.init(this, this.$moduleInfoController.concern, 'Frau', 'Mail versenden', this.woman.womanName)
    }

    this.initMailExecList()
    for (var index = 0; index < this.mailExecDataList.length; index++) {
      const entry = this.mailExecDataList[index]
      const mailContentCopy = cloneDeep(entry.mailContent)
      this.defaultMailContentMap.set(entry.mailType.id, mailContentCopy)
    }
  },

  components: {
    cmdbutton,
    formcontent
  },

  methods: {
    initMailExecList () {
      if (this.midwifeMode) {
        this.mailExecDataList = cloneDeep(this.midwife.sendMailData.mailExecDataList)
      } else {
        this.mailExecDataList = cloneDeep(this.woman.sendMailData.mailExecDataList)
      }
    },

    async changeTemplate (item, newMailTemplate) {
      const mailType = item.mailType.id
      let template = null
      if (this.midwifeMode) {
        template = await SendMailController.changeAccumulativeMailTemplate(this.midwife, mailType, newMailTemplate.mailtemplateId)
      } else {
        template = await SendMailController.changeMailTemplate(this.woman.caseLocator, mailType, newMailTemplate.mailtemplateId)
      }
      for (var index = 0; index < this.mailExecDataList.length; index++) {
        const entry = this.mailExecDataList[index]
        if (entry.mailType.id === mailType) {
          entry.alternativeMailTemplateList = template.alternativeMailTemplateList
          entry.mailContent = template.mailContent
          const mailContentCopy = cloneDeep(entry.mailContent)
          this.defaultMailContentMap.set(entry.mailType.id, mailContentCopy)
        }
      }
    },

    reset (item) {
      const mailType = item.mailType.id
      const defaultMailContent = this.defaultMailContentMap.get(mailType)
      for (var index = 0; index < this.mailExecDataList.length; index++) {
        const entry = this.mailExecDataList[index]
        if (entry.mailType.id === mailType) {
          entry.mailContent.mailSubject = defaultMailContent.mailSubject
          entry.mailContent.mailContent = defaultMailContent.mailContent
        }
      }
    },

    async save () {
      if (this.midwifeMode) {
        await SendMailController.saveAccumulativeMail(this.midwife, this.mailExecDataList)
      } else {
        await SendMailController.setCaseActionWithMail(this.woman.caseLocator, this.woman.concernVersion, this.woman.sendMailData.caseAction, this.woman.sendMailData.executeAction, this.mailExecDataList)
      }
    },

    async cancel () {
      if (this.midwifeMode) {
        await SendMailController.openMidwife(this.midwife.midwifeId)
      } else {
        await SendMailController.openCaseFile(this.woman.caseLocator)
      }
    }
  },

  computed: {
    midwifeMode () {
      if (this.midwife) {
        return true
      } else {
        return false
      }
    },

    contentheight () {
      const height = this.$store.getters['size/formContent']
      return height - 100
    }
  },

  data () {
    return {
      defaultMailContentMap: new Map(),
      mailExecDataList: []
    }
  },

  props: {
    woman: { type: Object },
    midwife: { type: Object }
  }
}
</script>
