var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('mastercontent', {
    attrs: {
      "data-list": _vm.activityList,
      "headers": _vm.headers,
      "writeRightProp": _vm.writeRight,
      "componentId": "midwifeActivity",
      "buttonOneText": "Vergleich mobile Leistungen",
      "buttonTwoText": "Bearbeite mobile Leistungen"
    },
    on: {
      "onButtonOne": _vm.mobileCompare,
      "onButtonTwo": _vm.mobileGenerate,
      "openNew": _vm.openNew,
      "open": _vm.open,
      "remove": _vm.remove
    },
    scopedSlots: _vm._u([{
      key: "mdt.activity.activityType",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-icon', {
          attrs: {
            "small": "",
            "light": ""
          }
        }, [_vm._v(_vm._s(item.activityType.icon))])];
      }
    }])
  }), _c('mobileactivitycomparison', {
    attrs: {
      "midwife-name": _vm.midwifeName,
      "midwife-id": _vm.midwifeId,
      "propshowdialog": _vm.mobileActivityComparison
    },
    on: {
      "update:propshowdialog": function ($event) {
        _vm.mobileActivityComparison = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }