import PageinfoController from '../../app/pageinfo/pageinfo.controller'
import MenuController from '../../app/menu/menu.controller'

export default {
  async openMidwifeMainFile (midwifeId) {
    let preselectedTab = PageinfoController.getPageinfo('midwife#tabIndex')
    if (!preselectedTab) {
      preselectedTab = 0
    }

    const midwifeIdObject = { midwifeId: midwifeId }

    const midwifeFileTabs = await MenuController.getMidwifeFileTabs()
    if (midwifeFileTabs.length >= preselectedTab) {
      const tab = midwifeFileTabs[preselectedTab]
      tab.openRoute(midwifeIdObject)
    } else if (midwifeFileTabs.length > 0) {
      const tab = midwifeFileTabs[0]
      tab.openRoute(midwifeIdObject)
    }
  }
}
