import router from '#enna/common/router'
import CircularMailService from './concern.circularmail.service'

export default {
  async openCircularMailOverview () {
    const response = await CircularMailService.queryCaseParam()
    router.push({
      name: 'circularmail',
      params: {
        mode: 'CASE',
        activityGroupList: response.data.activityGroupList,
        caseOptionList: response.data.caseOptionList,
        circularMailData: response.data.defaultMailData
      }
    })
  },

  async changeCaseTemplate (selectedTemplateId, selectedCaseList) {
    const response = await CircularMailService.changeCaseTemplate(selectedTemplateId, selectedCaseList)
    return response.data.circularMailData
  },

  async changeSelectedCases (selectedTemplateId, selectedCaseList) {
    const response = await CircularMailService.changeSelectedCases(selectedTemplateId, selectedCaseList)
    return response.data.mailContent
  },

  async searchCase (caseOption) {
    const response = await CircularMailService.searchCase(caseOption)
    return response.data.caseList
  },

  async sendCaseMail (selectedTemplateId, selectedCaseList, subject, content, hash) {
    await CircularMailService.sendCaseMail(selectedTemplateId, selectedCaseList, subject, content, hash)
  }
}
