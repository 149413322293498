import ApiService from '../../../../common/service/api/api.service'

const MobileActivityService = {

  async delete (pid) {
    await ApiService.doDelete('/enna/midwife/activity/mobile/' + pid)
  },

  async load (pid) {
    var result = await ApiService.doGet('/enna/midwife/activity/mobile/view/dto/' + pid)
    return result
  },

  async save (dtoMidwifeMobileActivity) {
    var result = await ApiService.doPost('/enna/midwife/activity/mobile', dtoMidwifeMobileActivity)
    return result
  },

  async compareActivity (midwifeId) {
    var result = await ApiService.doGet('/enna/midwife/activity/mobile/compare/view/' + midwifeId)
    return result
  },

  async generateActivity (data) {
    var result = await ApiService.doPost('/enna/midwife/activity/mobile/generate', data)
    return result
  },

  async loadModifyActivityData (midwifeId) {
    var result = await ApiService.doGet('/enna/midwife/activity/mobile/modify/view/' + midwifeId)
    return result
  },

  async addRegion (data) {
    var result = await ApiService.doPost('/enna/midwife/activity/mobile/add', data)
    return result
  },

  async removeRegion (data) {
    var result = await ApiService.doPost('/enna/midwife/activity/mobile/remove', data)
    return result
  }

}

export default MobileActivityService
