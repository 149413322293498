<template>
  <v-card>
    <v-list-item two-line>
      <v-list-item-content>
        <div class="text-h5 mb-1">
          {{ title }}
        </div>
        <v-list-item-subtitle style="padding-left: 20px;">
          <slot></slot>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: 'FormCard',

  props: {
    title: String
  }
}
</script>
