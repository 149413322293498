var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('mastercontent', {
    attrs: {
      "data-list": _vm.contactList,
      "headers": _vm.headers,
      "writeRightProp": _vm.writeRight,
      "componentId": "midwifeContact"
    },
    on: {
      "openNew": _vm.openNew,
      "open": _vm.open,
      "remove": _vm.remove
    },
    scopedSlots: _vm._u([{
      key: "mdt.contactMedium.name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-icon', {
          attrs: {
            "small": "",
            "light": ""
          }
        }, [_vm._v(_vm._s(item.contactMedium.icon))])];
      }
    }, {
      key: "mdt.forWoman",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('checkitem', {
          attrs: {
            "flag-item": item.forWoman
          }
        })];
      }
    }, {
      key: "mdt.mainnumber",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('checkitem', {
          attrs: {
            "flag-item": item.mainnumber
          }
        })];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }