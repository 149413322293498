var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "800",
      "persistent": ""
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_vm.midwifeInfo ? _c('v-card', [_c('div', {
    style: {
      'overflow-y': 'auto',
      'max-height': _vm.contentheight + 'px'
    }
  }, [_vm.midwifeInfo.midwifeContactList.length > 0 ? _c('v-card', [_c('v-card-title', {
    staticClass: "text-h5 grey lighten-2"
  }, [_vm._v("Kontaktdaten")]), _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', {
          staticClass: "font-weight-thin"
        }, [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v("Art")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Typ")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Nummer")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Frauennummer")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Hauptnummer")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Info")])])]), _c('tbody', _vm._l(_vm.midwifeInfo.midwifeContactList, function (item) {
          return _c('tr', {
            key: item.number
          }, [_c('td', [_vm._v(_vm._s(item.contactMedium))]), _c('td', [_vm._v(_vm._s(item.contactType))]), _c('td', [_vm._v(_vm._s(item.number))]), _c('td', [_c('checkitem', {
            attrs: {
              "flag-item": item.forWoman
            }
          })], 1), _c('td', [_c('checkitem', {
            attrs: {
              "flag-item": item.mainnumber
            }
          })], 1), _c('td', [_vm._v(_vm._s(item.info))])]);
        }), 0)];
      },
      proxy: true
    }], null, false, 3950526220)
  })], 1) : _vm._e(), _vm.midwifeInfo.midwifeInfoList.length > 0 ? _c('v-card', [_c('v-card-title', {
    staticClass: "text-h5 grey lighten-2"
  }, [_vm._v("Infos")]), _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', {
          staticClass: "font-weight-thin"
        }, [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v("Datum")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Benutzer")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Info")])])]), _c('tbody', _vm._l(_vm.midwifeInfo.midwifeInfoList, function (item) {
          return _c('tr', {
            key: item.number
          }, [_c('td', [_c('dateitem', {
            attrs: {
              "date-item": item.infoDate
            }
          })], 1), _c('td', [_vm._v(_vm._s(item.userInfo.shorthand))]), _c('td', [_vm._v(_vm._s(item.info))])]);
        }), 0)];
      },
      proxy: true
    }], null, false, 1960209933)
  })], 1) : _vm._e(), _vm.midwifeInfo.midwifeSignOffList.length > 0 ? _c('v-card', [_c('v-card-title', {
    staticClass: "text-h5 grey lighten-2"
  }, [_vm._v("Abmeldungen")]), _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v("Typ")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Von")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Bis")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Info")])])]), _c('tbody', _vm._l(_vm.midwifeInfo.midwifeSignOffList, function (item) {
          return _c('tr', {
            key: item.number
          }, [_c('td', [_vm._v(_vm._s(item.signOffType))]), _c('td', [_c('dateitem', {
            attrs: {
              "date-item": item.from
            }
          })], 1), _c('td', [_c('dateitem', {
            attrs: {
              "date-item": item.until
            }
          })], 1), _c('td', [_vm._v(_vm._s(item.info))])]);
        }), 0)];
      },
      proxy: true
    }], null, false, 796013854)
  })], 1) : _vm._e(), _c('v-card', [_c('v-card-title', {
    staticClass: "text-h5 grey lighten-2",
    attrs: {
      "set": _vm.property = _vm.midwifeInfo.midwifeInquiryData
    }
  }, [_vm._v("Präferenzen")]), _c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "padding-left": "10px",
      "padding-right": "10px"
    }
  }, [_vm._v("Teilnahme an Vermittlung")]), _c('td', [_c('checkitem', {
    attrs: {
      "flag-item": _vm.property.participateMediation
    }
  })], 1)]), _c('tr', [_c('td', {
    staticStyle: {
      "padding-left": "10px",
      "padding-right": "10px"
    }
  }, [_vm._v("Verfahren bei Benachrichtigung")]), _c('td', [_vm._v(_vm._s(_vm.property.contactProcedure))])]), _c('tr', [_c('td', {
    staticStyle: {
      "padding-left": "10px",
      "padding-right": "10px"
    }
  }, [_vm._v("Zusatzinfo bei Benachrichtigung")]), _c('td', [_vm._v(_vm._s(_vm.property.contactMailinfo))])]), _c('tr', [_c('td', {
    staticStyle: {
      "padding-left": "10px",
      "padding-right": "10px"
    }
  }, [_vm._v("Verfahren bei Zusage")]), _c('td', [_vm._v(_vm._s(_vm.property.promiseProcedure))])]), _c('tr', [_c('td', {
    staticStyle: {
      "padding-left": "10px",
      "padding-right": "10px"
    }
  }, [_vm._v("Zusatzinfo bei Zusage")]), _c('td', [_vm._v(_vm._s(_vm.property.promiseMailinfo))])])])], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v(" Ok ")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }