var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('formcontent', {
    attrs: {
      "ok-text": "Speichern",
      "cancel-text": "Abbrechen",
      "write-right": "midMasterdataWrite"
    },
    on: {
      "onOk": _vm.save,
      "onCancel": _vm.cancel
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "align-items": "end",
      "grid-gap": "20px",
      "grid-template-rows": "100px",
      "grid-template-columns": "1fr 1fr 1fr"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Vorname"
    },
    model: {
      value: _vm.dtoMidwifeMasterdata.prename,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeMasterdata, "prename", $$v);
      },
      expression: "dtoMidwifeMasterdata.prename"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Zuname"
    },
    model: {
      value: _vm.dtoMidwifeMasterdata.surname,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeMasterdata, "surname", $$v);
      },
      expression: "dtoMidwifeMasterdata.surname"
    }
  }), _c('datepicker', {
    attrs: {
      "title": "Geburtsdatum",
      "propdate": _vm.dtoMidwifeMasterdata.birthday
    },
    on: {
      "update:propdate": function ($event) {
        return _vm.$set(_vm.dtoMidwifeMasterdata, "birthday", $event);
      }
    }
  })], 1), _c('div', {
    staticStyle: {
      "display": "grid",
      "align-items": "end",
      "grid-gap": "20px",
      "grid-template-rows": "100px",
      "grid-template-columns": "150px 1fr"
    }
  }, [_c('v-checkbox', {
    staticStyle: {
      "width": "40px"
    },
    attrs: {
      "label": "Praxis"
    },
    model: {
      value: _vm.dtoMidwifeMasterdata.practise,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeMasterdata, "practise", $$v);
      },
      expression: "dtoMidwifeMasterdata.practise"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Praxisname"
    },
    model: {
      value: _vm.dtoMidwifeMasterdata.practiseName,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeMasterdata, "practiseName", $$v);
      },
      expression: "dtoMidwifeMasterdata.practiseName"
    }
  })], 1), _c('div', {
    staticStyle: {
      "display": "grid",
      "align-items": "end",
      "grid-gap": "20px",
      "grid-template-rows": "100px",
      "grid-template-columns": "100px 1fr 1fr"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Postleitzahl"
    },
    model: {
      value: _vm.dtoMidwifeMasterdata.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeMasterdata, "postcode", $$v);
      },
      expression: "dtoMidwifeMasterdata.postcode"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Stadt"
    },
    model: {
      value: _vm.dtoMidwifeMasterdata.city,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeMasterdata, "city", $$v);
      },
      expression: "dtoMidwifeMasterdata.city"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Straße / Hausnummer"
    },
    model: {
      value: _vm.dtoMidwifeMasterdata.streetnumber,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeMasterdata, "streetnumber", $$v);
      },
      expression: "dtoMidwifeMasterdata.streetnumber"
    }
  })], 1), _c('div', {
    staticStyle: {
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-rows": "100px",
      "align-items": "end",
      "grid-template-columns": "1fr 1fr 1fr"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Stadtteil"
    },
    model: {
      value: _vm.dtoMidwifeMasterdata.district,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeMasterdata, "district", $$v);
      },
      expression: "dtoMidwifeMasterdata.district"
    }
  }), _c('singleselection', {
    staticStyle: {
      "margin-bottom": "-6px"
    },
    attrs: {
      "selectedId": _vm.dtoMidwifeMasterdata.locationId,
      "prop-selected-item": _vm.dtoMidwifeMasterdata.locationId,
      "caption": "Standort",
      "idName": "regionId",
      "propItemList": _vm.regionList
    },
    on: {
      "update:selectedId": function ($event) {
        return _vm.$set(_vm.dtoMidwifeMasterdata, "locationId", $event);
      },
      "update:selected-id": function ($event) {
        return _vm.$set(_vm.dtoMidwifeMasterdata, "locationId", $event);
      }
    }
  })], 1), _c('div', {
    staticStyle: {
      "display": "grid",
      "grid-template-rows": "100px",
      "align-items": "end",
      "grid-gap": "20px",
      "grid-template-columns": "1fr 3fr 1fr"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": true,
      "label": "Teilnahme seit"
    },
    model: {
      value: _vm.memberSince,
      callback: function ($$v) {
        _vm.memberSince = $$v;
      },
      expression: "memberSince"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Homepage"
    },
    model: {
      value: _vm.dtoMidwifeMasterdata.homepage,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeMasterdata, "homepage", $$v);
      },
      expression: "dtoMidwifeMasterdata.homepage"
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }