import MidwifeOverviewService from './midwife.overview.service'
import router from '#enna/common/router'
import PageinfoController from '../../app/pageinfo/pageinfo.controller'
import MidwifeMainFileController from '../mainfile/midwife.mainfile.controller'

const filterKeyVar = 'MidwifeOverview'

export default {
  saveFilterParam (filterParam) {
    PageinfoController.setPageinfo(filterKeyVar, filterParam)
  },

  async openNewMidwife () {
    router.push({ name: 'newmidwife' })
  },

  async openCancelMidwife () {
    const response = await MidwifeOverviewService.queryMidwife()
    const midwifeList = response
    var filterParam = null
    filterParam = PageinfoController.getPageinfo(filterKeyVar)
    if (filterParam == null) {
      filterParam = {
        sortAttribute: [],
        sortDirection: [],
        search: ''
      }
    }

    router.push({ name: 'cancelmidwife', params: { propMidwifeList: midwifeList, propFilterParam: filterParam, propFilterKey: filterKeyVar } })
  },

  async deleteReadMidwife (pid) {
    await MidwifeOverviewService.deleteMidwife(pid)
    const result = await MidwifeOverviewService.queryMidwife()
    return result
  },

  async saveNewMidwife (prename, surname) {
    const midwifeId = await MidwifeOverviewService.saveMidwife(prename, surname)
    MidwifeMainFileController.openMidwifeMainFile(midwifeId)
  },

  async openMidwifeOverview (midwifeId) {
    const response = await MidwifeOverviewService.queryMidwife()
    const midwifeList = response.dataList
    const headerList = response.headerList

    var filterParam = null
    filterParam = PageinfoController.getPageinfo(filterKeyVar)
    if (filterParam == null) {
      filterParam = {
        sortAttribute: [],
        sortDirection: [],
        search: ''
      }
    }

    let hashId = ''
    if (midwifeId) {
      hashId = '#' + midwifeId
    }

    router.push({ name: 'midwifeoverview', hash: hashId, params: { midwifeList: midwifeList, headerList: headerList, propFilterParam: filterParam, propFilterKey: filterKeyVar } })
  }
}
