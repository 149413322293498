<template>
  <div>
    <mastercontent
      :data-list="signOffList"
      :headers="headers"
      :writeRightProp="writeRight"
      componentId="midwifeSignOff"
      @openNew="openNew"
      @open="open"
      @remove="remove"
    >
      <template v-slot:mdt.from="{ item }">
        <dateitem :date-item="item.from"></dateitem>
      </template>
      <template v-slot:mdt.until="{ item }">
        <dateitem :date-item="item.until"></dateitem>
      </template>
    </mastercontent>
  </div>
</template>

<script>
import mastercontent from '#enna/common/components/content/MasterContent'
import dateitem from '#enna/common/components/element/DateItem'
import MidwifeSignOffController from './midwife.signoff.controller'
import cloneDeep from 'lodash/cloneDeep'
import MidwifeOverviewController from '../overview/midwife.overview.controller'

export default {
  name: 'MidwifeSignOffOverview',

  async created () {
    this.$pageInitController.initBack(this, this.$moduleInfoController.midwife, 'Hebamme', 'Abmeldungen', this.midwifeName)
  },

  props: {
    writeRight: { type: String },
    midwifeId: { type: String },
    midwifeName: { type: String },
    propSignOffList: { type: Array }
  },

  components: {
    mastercontent,
    dateitem
  },

  data () {
    return {
      signOffList: cloneDeep(this.propSignOffList),
      headers: [
        { text: 'Typ', value: 'signOffType.name' },
        { text: 'Von', value: 'from' },
        { text: 'Bis', value: 'until' },
        { text: 'Info', value: 'info' }
      ]
    }
  },

  methods: {
    async back () {
      await MidwifeOverviewController.openMidwifeOverview(this.midwifeId)
    },
    openNew () {
      MidwifeSignOffController.openNewSignOff(this.midwifeId)
    },
    open (signOff) {
      MidwifeSignOffController.openSignOffDetail(this.midwifeId, signOff.pid)
    },
    async remove (signOff) {
      this.signOffList = await MidwifeSignOffController.deleteReadSignOff(this.midwifeId, signOff.pid)
    }
  }
}
</script>
