import layoutController from './layout.controller'
import moduleinfoController from '../../../module/app/moduleinfo/moduleinfo.controller'
import store from '../../store'

export default {
  initBack (thisPage, category, theme, detail, name) {
    layoutController.setNetworkLayout(thisPage)
    moduleinfoController.setModuleinfo(category, theme, detail, name)
    store.dispatch('navigation/setBack', thisPage)
    store.dispatch('navigation/resetReload')
  },

  initReload (thisPage, category, theme, detail, name) {
    layoutController.setNetworkLayout(thisPage)
    moduleinfoController.setModuleinfo(category, theme, detail, name)
    store.dispatch('navigation/setReload', thisPage)
    store.dispatch('navigation/resetBack')
  },

  init (thisPage, category, theme, detail, name) {
    layoutController.setNetworkLayout(thisPage)
    moduleinfoController.setModuleinfo(category, theme, detail, name)
    store.dispatch('navigation/resetReload')
    store.dispatch('navigation/resetBack')
  }
}
