var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "padding-top": "100px",
      "padding-left": "0px"
    }
  }, _vm._l(_vm.helpList.topics, function (topic) {
    return _c('div', {
      key: topic,
      staticClass: "text-h4 mb-1"
    }, [_c('div', [_vm._v(_vm._s(topic.topic))]), _vm._l(topic.themes, function (theme) {
      return _c('div', {
        key: theme,
        staticStyle: {
          "padding-left": "40px"
        }
      }, [_c('div', {
        staticClass: "text-h5 mb-2",
        staticStyle: {
          "padding-left": "40px"
        }
      }, [_vm._v(" " + _vm._s(theme.theme) + " "), _c('ul', _vm._l(theme.contents, function (theme) {
        return _c('li', {
          key: theme,
          staticClass: "caption",
          staticStyle: {
            "padding-left": "40px"
          }
        }, [_vm._v(" " + _vm._s(theme) + " ")]);
      }), 0)])]);
    })], 2);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }