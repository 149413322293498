var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.showdialog,
      callback: function ($$v) {
        _vm.showdialog = $$v;
      },
      expression: "showdialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.statisticResult.name))]), _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.downloadStatistic
    }
  }, [_vm._v("mdi-download")])], 1), _c('v-card-text', [_c('div', [_vm._v(_vm._s(_vm.statisticResult.description))]), _c('div', {
    staticStyle: {
      "padding-bottom": "20px"
    }
  }, [_vm._v("Zeitraum: " + _vm._s(_vm.timeSpan))]), _c('div', {
    style: {
      'overflow-y': 'auto',
      'max-height': _vm.contentheight + 'px'
    }
  }, _vm._l(_vm.statisticResult.dataList, function (item) {
    return _c('div', {
      key: item.name
    }, [_c('div', {
      staticClass: "statistic"
    }, [_c('div', {
      staticClass: "item"
    }, [_vm._v(_vm._s(item.name))]), _c('div', {
      staticClass: "count"
    }, [_vm._v(_vm._s(item.count))]), item.childList ? _c('div', _vm._l(item.childList, function (subitem) {
      return _c('div', {
        key: subitem.name
      }, [_c('div', {
        staticClass: "statistic"
      }, [_c('div', {
        staticClass: "subitem",
        staticStyle: {
          "padding-left": "20px"
        }
      }, [_vm._v(_vm._s(subitem.name))]), _c('div', {
        staticClass: "subcount"
      }, [_vm._v(_vm._s(subitem.count))])])]);
    }), 0) : _vm._e()])]);
  }), 0)]), _c('v-card-actions', [_c('cmdbutton', {
    attrs: {
      "caption": "Schließen"
    },
    on: {
      "cmdclick": _vm.close
    }
  }), _c('v-spacer')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }