var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-app-bar', {
    style: {
      'padding-left': _vm.paddingLeft + 'px',
      'padding-right': _vm.paddingRight + 'px',
      'height': _vm.height + 'px'
    },
    attrs: {
      "app": "",
      "color": "grey lighten-1",
      "dark": ""
    }
  }, [_c('v-app-bar-nav-icon', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.drawer = !_vm.drawer;
      }
    }
  }), _c('v-toolbar-title', [_c('span', {
    staticClass: "font-weight-thin"
  }, [_vm._v("e n ")]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("n a")])]), _c('v-spacer'), _c('div', [_c('label', {
    staticClass: "font-weight-medium"
  }, [_vm._v(_vm._s(_vm.userInfo.userName))]), _c('br'), _c('label', {
    staticClass: "font-weight-thin"
  }, [_vm._v(_vm._s(_vm.userInfo.mandatorName))])]), _c('v-btn', {
    staticStyle: {
      "padding-left": "30px"
    },
    attrs: {
      "x-small": "",
      "text": ""
    },
    on: {
      "click": _vm.onLogout
    }
  }, [_c('v-icon', {
    attrs: {
      "right": "",
      "color": "blue"
    }
  }, [_vm._v("mdi-exit-to-app")])], 1), _c('v-btn', {
    staticStyle: {
      "padding-left": "30px"
    },
    attrs: {
      "x-small": "",
      "text": ""
    },
    on: {
      "click": _vm.onHelp
    }
  }, [_c('v-icon', {
    attrs: {
      "right": "",
      "color": "blue"
    }
  }, [_vm._v("mdi-help")])], 1)], 1), _c('menupanel', {
    attrs: {
      "propDrawer": _vm.drawer
    },
    on: {
      "update:propDrawer": function ($event) {
        _vm.drawer = $event;
      },
      "update:prop-drawer": function ($event) {
        _vm.drawer = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }