import ApiService from '../../../../common/service/api/api.service'

const KnowledgeActivityService = {

  async delete (pid) {
    await ApiService.doDelete('/enna/midwife/activity/knowledge/' + pid)
  },

  async load (pid) {
    var result = await ApiService.doGet('/enna/midwife/activity/knowledge/view/dto/' + pid)
    return result
  },

  async save (dtoMidwifeKnowledgeActivity) {
    var result = await ApiService.doPost('/enna/midwife/activity/knowledge', dtoMidwifeKnowledgeActivity)
    return result
  },

  async generateActivity (data) {
    var result = await ApiService.doPost('/enna/midwife/activity/knowledge/generate', data)
    return result
  }
}

export default KnowledgeActivityService
