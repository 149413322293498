<template>
    <div>
        <v-icon small light v-if="flagItem">mdi-check</v-icon>
        <div v-else></div>
    </div>
</template>

<script>

export default {
  props: {
    flagItem: Boolean
  }
}
</script>
