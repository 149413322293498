<template>
  <div>
    <formcontent
      ok-text="Speichern" @onOk="save"
      cancel-text="Abbrechen" @onCancel="cancel">
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:100px;
        align-items: end;
        grid-template-columns:3fr 3fr 2fr;">
        <v-select
          :items="signOffTypeList"
          item-text="name"
          item-value="signofftypeId"
          v-model="dtoMidwifeSignOff.signOffTypeId"
          label="Typ">
        </v-select>
      </div>
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:100px;
        align-items: end;
        grid-template-columns:3fr 3fr 2fr;">
        <datepicker title="von" :propdate.sync="dtoMidwifeSignOff.from"/>
        <datepicker title="bis" :propdate.sync="dtoMidwifeSignOff.until"/>
      </div>
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:100px;
        align-items: end;
        grid-template-columns:6fr 2fr;">
        <v-text-field v-model="dtoMidwifeSignOff.info" label="Info"></v-text-field>
      </div>
    </formcontent>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import formcontent from '#enna/common/components/content/FormContent'
import MidwifeSignOffController from './midwife.signoff.controller'
import datepicker from '../../../common/components/element/DatePicker'
import { addDay, gettDateToIso } from '../../../common/utils/date.utils'

export default {
  name: 'MidwifeSignOffDetail',

  async created () {
    this.$pageInitController.initReload(this, this.$moduleInfoController.midwife, 'Hebamme', 'Abmeldungen', this.midwifeName)
  },

  methods: {
    async reload () {
      await MidwifeSignOffController.openSignOffDetail(this.midwifeId, this.pid, Date.now())
    },
    cancel () {
      MidwifeSignOffController.openMidwifeSignOffOverview(this.midwifeId)
    },

    save () {
      MidwifeSignOffController.saveMidwifeSignOff(this.dtoMidwifeSignOff)
    }
  },

  watch: {
    propDtoMidwifeSignOff (val) {
      this.dtoMidwifeSignOff = cloneDeep(this.propDtoMidwifeSignOff)
    }
  },

  props: {
    signOffTypeList: { type: Array },
    propDtoMidwifeSignOff: { type: Object },
    pid: { type: Number },
    midwifeName: { type: String },
    midwifeId: { type: String }
  },

  data () {
    return {
      changedUntil: false,
      dtoMidwifeSignOff: cloneDeep(this.propDtoMidwifeSignOff)
    }
  },

  async mounted () {
    this.$watch('dtoMidwifeSignOff.until', function (newVal, oldVal) {
      this.changedUntil = true
    })

    this.$watch('dtoMidwifeSignOff.from', function (newVal, oldVal) {
      if (this.changedUntil === false && this.dtoMidwifeSignOff.until == null && newVal != null) {
        this.dtoMidwifeSignOff.until = gettDateToIso(addDay(newVal, 1))
        this.changedUntil = true
      }
    })
  },

  components: {
    datepicker,
    formcontent
  }
}
</script>
