<template>
  <div>
    <dynpropbasedetail :dynPropParam="dynPropParam" @onSave="save" >
      <v-radio-group v-model="selected">
        <v-radio label="ja" value="true" > </v-radio>
        <v-radio label="nein" value="false" ></v-radio>
        <v-radio label="unbekannt" value="null" ></v-radio>
      </v-radio-group>
    </dynpropbasedetail>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import DynPropController from './dynprop.controller'
import dynpropbasedetail from './DynPropBaseDetail'

export default {
  name: 'DynPropUnknownBooleanDetail',

  methods: {
    save () {
      DynPropController.saveBoolean(this.dynPropParam, this.toBoolean(this.selected))
    },
    toString (val) {
      if (val === true) {
        return 'true'
      } else if (val === false) {
        return 'false'
      } else {
        return 'null'
      }
    },
    toBoolean (val) {
      if (val === 'true') {
        return true
      } else if (val === 'false') {
        return false
      } else {
        return null
      }
    }
  },

  watch: {
    dynPropParam (val) {
      this.selected = this.toString(cloneDeep(this.dynPropParam.dtoDynPropData.value))
    }
  },

  props: {
    dynPropParam: { type: Object }
  },

  data () {
    return {
      selected: this.toString(cloneDeep(this.dynPropParam.dtoDynPropData.value))
    }
  },

  components: {
    dynpropbasedetail
  }
}
</script>
