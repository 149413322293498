<template>
  <div>
    <formcontent
      ok-text="Speichern" @onOk="save"
      cancel-text="Abbrechen" @onCancel="cancel">
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:100px;
        align-items: end;
        grid-template-columns:2fr 2fr 1fr;">
        <v-select
          :items="contactMediumList"
          item-text="name"
          item-value="keylistValueId"
          v-model="dtoMidwifeContact.contactMedium"
          label="Kontakttyp">
          <template v-slot:selection="data">
            <v-chip
              :input-value="data.selected"
              :key="JSON.stringify(data.item)"
              class="chip--select-multi"
              @input="data.parent.selectItem(data.item)">
              <v-avatar left>
                <v-icon>{{data.item.icon}}</v-icon>
              </v-avatar>
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template >
              <v-icon>{{data.item.icon}}</v-icon>
              <div >{{data.item.name}}</div>
            </template>
          </template>
        </v-select>
        <v-select
          :items="contactTypeList"
          item-text="name"
          item-value="id"
          v-model="dtoMidwifeContact.contactType"
          label="Kontaktart">
        </v-select>
      </div>
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:100px;
        align-items: end;
        grid-template-columns:2fr 1fr 1fr 1fr;">
        <v-text-field v-model="dtoMidwifeContact.number" label="Nummer"></v-text-field>
        <v-checkbox v-model="dtoMidwifeContact.forWoman" label="Frauennummer"></v-checkbox>
        <v-checkbox v-model="dtoMidwifeContact.mainnumber" label="Primärnummer"></v-checkbox>
      </div>
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:100px;
        align-items: end;
        grid-template-columns:4fr 1fr;">
        <v-text-field  v-model="dtoMidwifeContact.info" label="Info"></v-text-field>
      </div>
    </formcontent>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import formcontent from '#enna/common/components/content/FormContent'
import MidwifeContactController from './midwife.contact.controller'

export default {
  name: 'MidwifeContactDetail',

  async created () {
    this.$pageInitController.initReload(this, this.$moduleInfoController.midwife, 'Hebamme', 'Kontaktdaten', this.midwifeName)
  },

  methods: {
    async reload () {
      await MidwifeContactController.openContactDetail(this.midwifeId, this.pid, Date.now())
    },
    cancel () {
      MidwifeContactController.openMidwifeContactOverview(this.midwifeId)
    },

    async save () {
      await MidwifeContactController.saveMidwifeContact(this.dtoMidwifeContact)
    }
  },

  watch: {
    propDtoMidwifeContact (val) {
      this.dtoMidwifeContact = cloneDeep(this.propDtoMidwifeContact)
    }
  },

  props: {
    contactMediumList: { type: Array },
    contactTypeList: { type: Array },
    propDtoMidwifeContact: { type: Object },
    pid: { type: Number },
    midwifeName: { type: String },
    midwifeId: { type: String }
  },

  data () {
    return {
      dtoMidwifeContact: cloneDeep(this.propDtoMidwifeContact)
    }
  },

  components: {
    formcontent
  }
}
</script>
