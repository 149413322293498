import CaseInfoService from './concern.caseinfo.service'
export default {
  async saveCaseInfo (data) {
    await CaseInfoService.saveCaseInfo(data)
  },

  async deleteCaseInfo (data) {
    await CaseInfoService.deleteCaseInfo(data)
  }
}
