<template>
  <div>
    <sendmailoverview type="woman" :sendedMailList="sendedMailList"></sendmailoverview>
  </div>
</template>

<script>
import sendmailoverview from './SendMailOverview'
import CaseOverviewController from '@/module/concern/case/case.overview.controller'

export default {
  name: 'ConcernMailPage',

  components: {
    sendmailoverview
  },

  methods: {
    async back () {
      await CaseOverviewController.openCaseOverview(this.caseLocator)
    }
  },

  async created () {
    this.$pageInitController.initBack(this, this.$moduleInfoController.concern, 'Frau', 'Mails', this.womanName)
  },

  props: {
    womanName: { type: String },
    caseLocator: { type: Object },
    sendedMailList: { type: Array }
  }
}
</script>
