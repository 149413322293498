import router from '#enna/common/router'
import MenuController from '../../app/menu/menu.controller'
import MidwifeContactService from './midwife.contact.service'

export default {
  async openMidwifeContactOverview (midwifeId) {
    callMidwifeContactOverview(midwifeId)
  },

  async openNewContact (midwifeId) {
    const response = await MidwifeContactService.newContact(midwifeId)
    callMidwifeContactDetail(midwifeId, null, response, null)
  },

  async openContactDetail (midwifeId, pid, reloadId) {
    const response = await MidwifeContactService.loadContact(pid)
    callMidwifeContactDetail(midwifeId, pid, response, reloadId)
  },

  async deleteReadContact (midwifeId, pid) {
    await MidwifeContactService.deleteContact(pid)
    const result = await MidwifeContactService.queryContact(midwifeId)
    return result
  },

  async saveMidwifeContact (dtoMidwifeContact) {
    await MidwifeContactService.saveContact(dtoMidwifeContact)
    await callMidwifeContactOverview(dtoMidwifeContact.midwifeId)
  }
}

async function callMidwifeContactOverview (midwifeId) {
  const response = await MidwifeContactService.queryViewContact(midwifeId)
  const contactList = response.data.contactList
  const midwifeName = response.data.midwifeName
  const selectedTabIndex = await MenuController.getMidwifeFileStartTab('midwifecontactoverview')

  router.push({
    name: 'midwifecontactoverview',
    params: {
      midwifeId: midwifeId,
      midwifeName: midwifeName,
      preselectedTab: selectedTabIndex,
      writeRight: 'midContactWrite',
      propContactList: contactList
    }
  })
}

async function callMidwifeContactDetail (midwifeId, pid, response, reloadId) {
  const dtoMidwifeContact = response.data.dtoMidwifeContact
  const contactMediumList = response.data.contactMediumList
  const contactTypeList = response.data.contactTypeList
  const midwifeName = response.data.midwifeName
  router.push({ name: 'midwifecontactdetail', params: { midwifeId: midwifeId, pid: pid, contactMediumList: contactMediumList, contactTypeList: contactTypeList, propDtoMidwifeContact: dtoMidwifeContact, midwifeName: midwifeName }, query: { reload: reloadId } })
}
