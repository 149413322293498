var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('overviewtablecontent', {
    attrs: {
      "tablecontentKeyId": "caseLocator.pid",
      "anchorKey": "pid",
      "data-list": _vm.caseList,
      "headers": _vm.headerList,
      "propFilterKey": _vm.propFilterKey,
      "propFilterParam": _vm.propFilterParam,
      "iconMapperMap": _vm.iconMapperMap
    },
    on: {
      "open": _vm.openConcern
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }