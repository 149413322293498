import ApiService from '../../../common/service/api/api.service'

const CaseSearchService = {

  async checkChange (caseLocator, concernVersion, regionId, edate, bdate, searchActivityList) {
    const param = {
      caseLocator: caseLocator,
      concernVersion: concernVersion,
      regionId: regionId,
      edate: edate,
      bdate: bdate,
      searchActivityList: searchActivityList
    }
    const result = await ApiService.doPost('/enna/cause/search/case/checkchange', param)
    return result
  },

  async isValidForPropose (caseLocator, concernVersion, activityIdList) {
    const param = {
      caseLocator: caseLocator,
      concernVersion: concernVersion,
      activityIdSet: activityIdList
    }
    const result = await ApiService.doPost('/enna/cause/search/case/isvalidforpropose', param)
    return result
  },

  async proposeMidwife (caseLocator, concernVersion, midwifeId, searchActivityList) {
    const param = {
      caseLocator: caseLocator,
      concernVersion: concernVersion,
      midwifeId: midwifeId,
      searchActivityList: searchActivityList
    }
    const result = await ApiService.doPost('/enna/cause/search/case/proposemidwife', param)
    return result
  },

  async loadStartSearch (searchStart, caseLocator) {
    const param = {
      caseLocator: caseLocator,
      searchStart: searchStart
    }
    const result = await ApiService.doPost('/enna/cause/search/case/start', param)
    return result
  },

  async loadEmptySearch () {
    const result = await ApiService.doGet('/enna/cause/search/new')
    return result
  },

  async searching (searchingData) {
    const result = await ApiService.doPost('/enna/cause/search/searching', searchingData)
    return result
  }
}

export default CaseSearchService
