var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('overviewcontent', {
    attrs: {
      "data-list": _vm.changelogList,
      "withSearchString": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (changelogItem) {
        return [_c('v-subheader', {
          staticStyle: {
            "display": "grid",
            "grid-template-columns": "3fr 3fr 10fr"
          }
        }, [_c('div', [_vm._v(_vm._s(changelogItem.item.semver))]), _c('dateitem', {
          attrs: {
            "id": changelogItem.item.publishdate,
            "date-item": changelogItem.item.publishdate
          }
        })], 1), _c('v-divider'), _vm._l(changelogItem.item.changelogList, function (changelog) {
          return _c('div', {
            key: changelog.changelogId
          }, [_c('div', {
            staticStyle: {
              "padding-left": "20px",
              "padding-top": "10px",
              "padding-bottom": "30px",
              "display": "grid",
              "grid-template-columns": "3fr 3fr 4fr"
            }
          }, [_c('div', [_vm._v("ÄnderungsId")]), _c('div', [_vm._v(_vm._s(changelog.changelogId))]), _c('div'), _c('div', [_vm._v("Thema")]), _c('div', {
            staticStyle: {
              "color": "darkblue",
              "font-weight": "bold",
              "font-size": "larger"
            }
          }, [_vm._v(_vm._s(changelog.theme))]), _c('div'), _c('div', [_vm._v("Ticketnummer")]), _c('div', [_vm._v(_vm._s(changelog.ticketId))]), _c('div'), _c('div', [_vm._v("Kategorie")]), _c('div', [_vm._v(_vm._s(changelog.category))]), _c('div'), _c('div', [_vm._v("Datenbankänderung")]), _c('div', [_vm._v(_vm._s(changelog.dbchange))]), _c('div'), _c('div', [_vm._v("Beschreibung")]), _c('div', [_vm._v(_vm._s(changelog.description))]), _c('div')])]);
        })];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }