<template>
    <div>
      <mastercontent
        :data-list="textModuleList"
        :headers="headers"
        :writeRightProp="writeRight"
        componentId="textModule"
        buttonOneText="Mailvorlagen"
        @onButtonOne="openMailTemplate"
        @openNew="openNew"
        @open="open"
        @remove="remove"
      >
      </mastercontent>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import TextModuleController from './textmodule.controller'
import mastercontent from '#enna/common/components/content/MasterContent'

export default {
  name: 'TextModuleOverview',

  async created () {
    this.$pageInitController.init(this, this.$moduleInfoController.admin, 'Textbausteine')
  },

  components: {
    mastercontent
  },

  methods: {
    openMailTemplate () {
      TextModuleController.openMailTemplateOverview()
    },

    openNew () {
      TextModuleController.openNewTextModule()
    },

    open (textModule) {
      TextModuleController.openTextModule(textModule.pid)
    },

    async remove (textModule) {
      this.textModuleList = await TextModuleController.deleteTextModule(textModule.pid)
    }
  },

  data () {
    return {
      textModuleList: cloneDeep(this.propTextModuleList),
      headers: [
        { text: 'Schlüssel', value: 'textkey' },
        { text: 'Beschreibung', value: 'description' }
      ]
    }
  },

  props: {
    writeRight: { type: String },
    propTextModuleList: { type: Array }
  }

}
</script>
