<template>
  <div>
    <dynpropbasedetail :dynPropParam="dynPropParam" @onSave="save" >
      <v-text-field style="width:540px;"
        v-model="stringValue"
        label="Wert"
        clearable
      ></v-text-field>
    </dynpropbasedetail>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import DynPropController from './dynprop.controller'
import dynpropbasedetail from './DynPropBaseDetail'

export default {
  name: 'DynPropStringDetail',

  methods: {
    save () {
      DynPropController.saveString(this.dynPropParam, this.stringValue)
    }
  },

  watch: {
    dynPropParam (val) {
      this.stringValue = cloneDeep(this.dynPropParam.dtoDynPropData.value)
    }
  },

  props: {
    dynPropParam: { type: Object }
  },

  data () {
    return {
      stringValue: cloneDeep(this.dynPropParam.dtoDynPropData.value)
    }
  },

  components: {
    dynpropbasedetail
  }
}
</script>
