<template>
  <div>
    <formcontent
      ok-text="Speichern" @onOk="save"
      cancel-text="Abbrechen" @onCancel="cancel">
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-columns:7fr 3fr;">
        <div>
          <v-text-field v-model="dtoTextModule.textkey" label="Schlüssel" hint="Eindeutiger Name des Textbaustein"></v-text-field>
          <v-text-field v-model="dtoTextModule.description" label="Beschreibung" hint="Hinweise zum Textbaustein"></v-text-field>
          <v-textarea
            style="width: 95%;"
            clearable
            clear-icon="mdi-close-circle"
            label="Text"
            hint="Inhalt des Textbausteins"
            v-model="dtoTextModule.content"
          ></v-textarea>
          <div style="padding-top: 150px;">
            <label  class="grey--text font-weight-medium">Vorschau</label>
            <v-divider ></v-divider>
            <v-select style="max-width: 50%;"
                :items="caseMidwifeList"
                item-value="caseLocator"
                v-model="caseLocator"
                label="Anfragen / Hebamme">
              <template v-slot:selection="{ item }">
                {{ getCaseMidwifeName(item) }}
              </template>
              <template v-slot:item="{ item }">
                {{ getCaseMidwifeName(item) }}
              </template>
            </v-select>
            <v-select style="max-width: 50%;"
              clearable
              :items="midwifeList"
              item-text="name"
              item-value="midwifeId"
              v-model="midwifeId"
              label="Alternative Hebamme">
            </v-select>
            <cmdbutton @cmdclick="showPreview" caption="Vorschau anzeigen"/>
          </div>
        </div>
      <div v-bind:style="{ 'overflow-y': 'hidden', 'height': contentheight + 'px' }">
        <div style="height: 40%;">
          <mailvariableoverview
            :condition-list="conditionList"
            :placeholder-list="placeholderList"
            :text-module-list="textModuleList"
          ></mailvariableoverview>
          </div>
        </div>
      </div>
    </formcontent>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import formcontent from '#enna/common/components/content/FormContent'
import TextmoduleController from './textmodule.controller'
import InfoDialogController from '../../../common/components/element/infodialog/infodialog.controller'
import mailvariableoverview from '../mailtemplate/MailVariableOverview'

export default {
  name: 'MailTemplateDetail',

  async created () {
    this.$pageInitController.initReload(this, this.$moduleInfoController.admin, 'Textbaustein', null, this.dtoTextModule.textkey)
  },

  computed: {
    contentheight () {
      const height = this.$store.getters['size/formContent']
      return height
    }
  },

  methods: {
    async reload () {
      await TextmoduleController.openTextModule(this.dtoTextModule.pid, Date.now())
    },

    cancel () {
      TextmoduleController.openTextModuleOverview()
    },

    getCaseMidwifeName (item) {
      let result = item.womanName
      const midwifeName = item.midwifeName
      if (midwifeName) {
        result = result + ' (Hebamme: ' + midwifeName + ')'
      }
      return result
    },

    async save () {
      TextmoduleController.save(this.dtoTextModule)
    },

    async showPreview () {
      const previewParam = {
        content: this.dtoTextModule.content,
        alternativMidwifeId: this.midwifeId,
        caseLocator: this.caseLocator
      }
      const preview = await TextmoduleController.preview(previewParam)
      InfoDialogController.showInfo('Vorschau', preview)
    }
  },

  watch: {
    propDtoTextModule (val) {
      this.dtoTextModule = cloneDeep(this.propDtoTextModule)
    }
  },

  props: {
    conditionList: { type: Array },
    placeholderList: { type: Array },
    textModuleList: { type: Array },
    midwifeList: { type: Array },
    caseMidwifeList: { type: Array },
    propDtoTextModule: { type: Object }
  },

  data () {
    return {
      dtoTextModule: cloneDeep(this.propDtoTextModule),
      caseLocator: null,
      midwifeId: null
    }
  },

  components: {
    formcontent,
    mailvariableoverview
  }
}
</script>
