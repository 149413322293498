<template>
  <div style="background-color: lightskyblue">
    <v-toolbar-title >
      <span class="font-weight-thin">e n </span>
      <span class="font-weight-bold">n a</span>
    </v-toolbar-title>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'monitoringlayout'
}
</script>
