import ApiService from '../../../common/service/api/api.service'

const ConcernCaseInfoService = {
  async saveCaseInfo (data) {
    const result = await ApiService.doPost('/enna/cause/info/savecaseinfo', data)
    return result
  },

  async deleteCaseInfo (data) {
    const result = await ApiService.doPost('/enna/cause/info/deleteinfo', data)
    return result
  }
}

export default ConcernCaseInfoService
