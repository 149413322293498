<template>
  <div style="
          display: grid;
          grid-template-columns:4fr 3fr;">
    <div>
      <searchcard caption="Aktueller Status" :set="state=caseFileData.caseFileState">
        <div class="font-weight-thin overline">{{ state.state }}</div>
        <div style="padding-bottom: 10px;" class="font-weight-medium text-body-1">{{ state.stateAction }}</div>
        <v-icon>{{ getTodoIcon(state.todoIcon) }}</v-icon>
        <span class="font-weight-bold ma-3 text-body-2">{{ state.todo }}</span>
        <div v-if="state.midwife != null" style="padding-top: 10px;padding-left: 50px;">
          <v-icon class="mr-2" @click="showMidwifeInfo(state.midwife.midwifeId)" color="blue">mdi-phone-plus</v-icon>
          <span class="font-weight-medium">{{ state.midwife.name }}</span>
        </div>
      </searchcard>
      <searchcard caption="Nächste Aktion">
        <div style="text-align: center;" >
          <span v-for="mainCaseAction in caseFileData.mainCaseList" :key="mainCaseAction.mainCase">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="mr-4" color="blue" large @click="executeAction(mainCaseAction.viewCaseActionOption)">{{ getMainActionIcon(mainCaseAction) }}</v-icon>
              </template>
              <span>{{ mainCaseAction.viewCaseActionOption.caseActionCaption }}</span>
            </v-tooltip>
          </span>
        </div>
        <div style="max-height: 300px; overflow: scroll;">
          <div style="padding-bottom: 20px;" v-for="group in caseFileData.possibleActionList" :key="group.caseActionGroupType">
            <div style="background-color: #f0f0f0;" >
              {{ group.caseActionGroupTypeCaption }}
            </div>
            <div style="display: grid;
              grid-template-columns:1fr 7fr; padding-left: 20px;padding-bottom: 5px;" v-for="option in group.caseActionOptionList" :key="option.tempId">
              <span>
                <v-icon class="mr-2" @click="executeAction(option)" color="blue">mdi-play-circle-outline</v-icon>
              </span>
              <span>
                {{ option.caseActionCaption }}
              <div v-if="option.refInfo" style="padding-left: 50px;">
                <div>{{ option.refInfo.caseActionCaption }}</div>
                <div>{{ option.refInfo.info }}</div>
              </div>
              </span>
            </div>
          </div>
        </div>
      </searchcard>
      <searchcard caption="Info" class="caseinfo">
        <caseinfo
          @reloadParent="reloadCaseFile"
          :concernId="caseLocator.concernId"
          :initialCaseId="caseLocator.caseId"
          :versionId="concernVersion"
          :info-list="caseFileData.infoList"
        />
      </searchcard>
    </div>
    <div>
      <searchcard v-if="caseFileData.originSearchData != null" caption="Daten zur Anfrage">
        <caseparameter
          layout="block"
          :set="origin = this.caseFileData.originSearchData"
         :location="origin.region"
         :bdate="origin.bdate"
         :edate="origin.edate"
         :activitySearchParamList="origin.activityList"
        ></caseparameter>
      </searchcard>
      <searchcard caption="Verlauf">
        <casehistory v-bind:style="{ 'transform': 'scale(1)', 'overflow-y': 'auto', 'max-height': contentheight + 'px' }" :historyList="caseFileData.historyList"/>
      </searchcard>
    </div>
    <tododialog
      :prop-case-action-caption="textCaseActionCaption"
      @onCancelText="onCancelText"
      @onAcceptedText="onAcceptedText"
      :propShowDialog.sync="showTextDialog" />
    <midwifeInfoDialog
      :midwife-info="midwifeInfo"
      @onCancel="onCancelMidwifeInfo"
      :propShowDialog.sync="showMidwifeInfoDialog" />
  </div>
</template>

<script>
import searchcard from '../search/SearchCard'
import casehistory from './CaseHistory'
import caseparameter from '../search/CaseParameter'
import CaseOverviewController from './case.overview.controller'
import SendMailController from '../../superior/sendmail/sendmail.controller'
import SearchController from '../search/case.search.controller'
import tododialog from './TodoDialog'
import midwifeInfoDialog from './MidwifeInfoDialog'
import { todoIconMap } from './case.util'
import caseinfo from '../info/CaseInfo'
import MidwifeMainFileController from '../../midwife/mainfile/midwife.mainfile.controller'

export default {
  name: 'CaseFile',

  computed: {
    contentheight () {
      const height = this.$store.getters['size/overviewContent']
      return height
    }
  },

  components: {
    caseinfo,
    caseparameter,
    searchcard,
    tododialog,
    midwifeInfoDialog,
    casehistory
  },

  async created () {
    this.$pageInitController.initBack(this, this.$moduleInfoController.concern, 'Frau', 'Anfrage', this.womanName)
  },

  methods: {
    getTodoIcon (todoIcon) {
      return todoIconMap.get(todoIcon)
    },

    getMainActionIcon (mainCaseAction) {
      const lookup = {
        phoneinform: 'mdi-cellphone-information',
        search: 'mdi-binoculars',
        promise: 'mdi-thumb-up-outline',
        mailinform: 'mdi-email-edit-outline'
      }
      const result = lookup[mainCaseAction.mainCase]
      return result
    },

    async back () {
      if (this.midwifeId) {
        await MidwifeMainFileController.openMidwifeMainFile(this.midwifeId)
      } else {
        await CaseOverviewController.openCaseOverview(this.caseLocator)
      }
    },

    onCancelText () {
      this.showTextDialog = false
    },

    async showMidwifeInfo (midwifeId) {
      this.midwifeInfo = await CaseOverviewController.midwifeMediationInfo(midwifeId)
      this.showMidwifeInfoDialog = true
    },

    onCancelMidwifeInfo () {
      this.showMidwifeInfoDialog = false
    },

    async onAcceptedText (text) {
      this.showTextDialog = false
      await CaseOverviewController.setTextCaseAction(this.caseLocator, this.textCaseAction, text, this.concernVersion)
    },

    contains (todo, property) {
      for (var i = 0; i < todo.length; i++) {
        var obj = todo[i]
        if (obj === property) {
          return true
        }
      }
      return false
    },

    async reloadCaseFile () {
      await CaseOverviewController.openCaseFile(this.caseLocator)
    },

    async executeAction (item) {
      if (this.contains(item.optionSet, 'withText')) {
        this.textCaseAction = item.caseAction
        this.textCaseActionCaption = item.caseActionCaption
        this.showTextDialog = true
      } else if (this.contains(item.optionSet, 'mail')) {
        await SendMailController.startSendMailCase(this.caseLocator, item, this.concernVersion)
      } else if (this.contains(item.optionSet, 'search')) {
        await SearchController.openCaseToSearch1(this.caseLocator)
      } else {
        await CaseOverviewController.setCaseAction(this.caseLocator, item, this.concernVersion)
      }
    }
  },

  data () {
    return {
      showMidwifeInfoDialog: false,
      midwifeInfo: null,
      showTextDialog: false,
      textCaseAction: '',
      textCaseActionCaption: ''
    }
  },

  props: {
    midwifeId: { type: String },
    currentState: { type: String },
    searchData: { type: Object },
    concernVersion: { type: Number },
    womanName: { type: String },
    caseLocator: { type: Object },
    caseFileData: { type: Object }
  }
}
</script>
