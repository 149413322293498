import router from '#enna/common/router'
import ConcernadminService from './concern.concernadmin.service'
export default {
  async removeConcern (concernId) {
    const response = await ConcernadminService.removeConcern(concernId)
    return response
  },

  async queryAdminConcern () {
    const response = await ConcernadminService.queryAdminConcern()
    return response
  },

  async openConcernAdminOverview () {
    const response = await ConcernadminService.queryAdminConcern()
    const adminConcernList = response
    router.push({ name: 'concernadminoverview', params: { propAdminConcernList: adminConcernList } })
  }
}
