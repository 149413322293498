<template>
  <v-app>
    <component :is="layout">
      <router-view :layout.sync="layout"/>
    </component>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data () {
    return {
      layout: 'div'
    }
  }
}
</script>

<style>
  html, body {
    margin: 0px;
    padding: 0px;
    height: 100vh;
    width: 100vw;
    background-color: white;
    overflow-y: hidden;
  }
  * {
    box-sizing: border-box;
  }
</style>
