<template>
  <div>
    <formcontent
      ok-text="Speichern" @onOk="save"
      cancel-text="Abbrechen" @onCancel="cancel"
      write-right="concernWrite">
      <span class="font-weight-thin">Beratung am </span>
      <span class="font-weight-thick">{{ datetime(advice.adviceTime) }}</span>
      <span class="font-weight-thin"> durch </span>
      <span class="font-weight-thick">{{ advice.userName }}</span>
      <v-select
        v-model="advice.themeIdList"
        :items="adviceThemeList"
        item-text="name"
        item-value="id"
        :menu-props="{ maxHeight: '400' }"
        label="Beratungsinhalte"
        multiple
        chips
        deletable-chips
        persistent-hint>
      </v-select>
      <v-textarea
        style="width: 95%;"
        clearable
        clear-icon="mdi-close-circle"
        label="Info"
        v-model="advice.comment"
      ></v-textarea>
      <v-text-field style="width:140px;"
                    v-model="advice.duration"
                    type="number"
                    label="Dauer (Minuten)"
                    clearable
      ></v-text-field>
    </formcontent>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import formcontent from '#enna/common/components/content/FormContent'
import ConcernAdviceController from './concern.advice.controller'
import { formatDateTime } from '../../../common/utils/date.utils'

export default {
  name: 'EditAdvice',

  async created () {
    this.$pageInitController.initReload(this, this.$moduleInfoController.concern, 'Frau', 'Beratung', this.womanName)
  },

  methods: {
    datetime (value) {
      return formatDateTime(value)
    },

    async reload () {
      await ConcernAdviceController.loadConcernAdvice(this.caseLocator, this.propAdvice.pid, Date.now())
    },
    cancel () {
      ConcernAdviceController.openConcernAdviceOverview(this.caseLocator)
    },

    async save () {
      ConcernAdviceController.saveConcernAdvice(this.caseLocator, this.concernVersion, this.advice)
    }
  },

  watch: {
    propAdvice (val) {
      this.advice = cloneDeep(this.propAdvice)
    }
  },

  props: {
    caseLocator: { type: Object },
    concernVersion: { type: Number },
    womanName: { type: String },
    adviceThemeList: { type: Array },
    propAdvice: { type: Object }
  },

  data () {
    return {
      advice: cloneDeep(this.propAdvice)
    }
  },

  components: {
    formcontent
  }
}
</script>
