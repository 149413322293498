import router from '#enna/common/router'
import MidwifeMasterdataService from './midwife.masterdata.service'
import MenuController from '../../app/menu/menu.controller'

export default {
  async openMidwifeMasterdataOverview (midwifeId) {
    callOpenMidwifeMasterdataOverview(midwifeId)
  },

  async openDtoMidwifeMasterdataOverview (pid, reloadId) {
    const response = await MidwifeMasterdataService.loadMidwifeMasterdata(pid)
    const dtoMidwifeMasterdata = response.data.dtoMidwifeMasterdata
    const regionList = response.data.regionList
    const midwifeName = response.data.midwifeName
    router.push({ name: 'midwifemasterdatadetail', params: { propDtoMidwifeMasterdata: dtoMidwifeMasterdata, midwifeName: midwifeName, regionList: regionList }, query: { reload: reloadId } })
  },

  async saveMidwifeMasterdataOverview (dtoMidwifeMasterdata) {
    await MidwifeMasterdataService.saveHandover(dtoMidwifeMasterdata)
    callOpenMidwifeMasterdataOverview(dtoMidwifeMasterdata.midwifeId)
  }
}

async function callOpenMidwifeMasterdataOverview (midwifeId) {
  const response = await MidwifeMasterdataService.getMidwifeMasterdata(midwifeId)
  const midwifeMasterdata = response.data.midwifeMasterdata
  const midwifeName = response.data.midwifeName
  const selectedTabIndex = await MenuController.getMidwifeFileStartTab('midwifemasterdataoverview')
  router.push({ name: 'midwifemasterdataoverview', params: { midwifeId: midwifeId, midwifeName: midwifeName, preselectedTab: selectedTabIndex, midwifeMasterdata: midwifeMasterdata } })
}
