var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    style: {
      'overflow-y': 'auto',
      'max-height': _vm.contentheight + 'px'
    }
  }, _vm._l(_vm.causeActivityList, function (item) {
    return _c('div', {
      key: item.activityId
    }, [_c('v-card', {
      staticStyle: {
        "padding-bottom": "30px"
      }
    }, [_c('v-list-item-title', {
      staticClass: "text-h5 mb-1"
    }, [_c('v-icon', [_vm._v(_vm._s(_vm.getResultIcon(item)))]), _vm._v(" " + _vm._s(item.name) + " "), _c('v-tooltip', {
      attrs: {
        "right": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on;
          return [_c('v-icon', _vm._g({
            staticClass: "mr-2",
            attrs: {
              "color": "blue"
            },
            on: {
              "click": function ($event) {
                return _vm.onOpen(item);
              }
            }
          }, on), [_vm._v("mdi-arrow-left-bold-outline")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v(_vm._s(_vm.getExecutionCaption(item)))])]), true ? _c('v-tooltip', {
      attrs: {
        "right": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref2) {
          var on = _ref2.on;
          return [_c('v-icon', _vm._g({
            staticClass: "mr-2",
            attrs: {
              "color": "blue"
            },
            on: {
              "click": function ($event) {
                return _vm.onEdit(item);
              }
            }
          }, on), [_vm._v("mdi-pencil")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Bearbeiten")])]) : _vm._e()], 1), _c('div', {
      staticStyle: {
        "padding-left": "30px"
      }
    }, [item.acute ? _c('div', [_c('v-icon', {
      attrs: {
        "color": "red"
      }
    }, [_vm._v("mdi-exclamation")]), _vm._v(" Akut "), _c('v-icon', {
      attrs: {
        "color": "red"
      }
    }, [_vm._v("mdi-exclamation")])], 1) : _vm._e(), _c('div', {
      staticStyle: {
        "padding-left": "30px",
        "padding-top": "10px"
      }
    }, [_c('div', [_vm._v("Status: " + _vm._s(item.state.caption))]), _c('div', [_vm._v("Todo: " + _vm._s(item.todo.caption))]), item.midwife ? _c('div', [_vm._v("Hebamme: " + _vm._s(item.midwife.name))]) : _vm._e(), _c('div', [_vm._v("Kontakt: " + _vm._s(item.startState))]), item.start || item.latest ? _c('div', [_vm._v(" " + _vm._s(_vm.showStart(item.start, item.latest)) + " ")]) : item.useDeadline ? _c('div', [_vm._v(" Termin verwenden "), _vm.edbdInfo != null ? _c('span', [_vm._v(": " + _vm._s(_vm.getEdbdInfo()))]) : _vm._e()]) : _vm._e(), item.onWaitingList ? _c('div', [_vm._v(" Auf Warteliste ")]) : _vm._e(), item.mailInfoList.length > 0 ? _c('div', [_vm._v(" Per Rundmail nachgefragt am "), _vm._l(item.mailInfoList, function (mail) {
      return _c('div', {
        key: mail.mailPid
      }, [_vm._v(" - " + _vm._s(_vm.formatDate(mail.sendDate)) + ": " + _vm._s(mail.mailTemplateName) + " ")]);
    })], 2) : _vm._e()])])], 1)], 1);
  }), 0), _c('formbuttonbar', {
    attrs: {
      "button-one-text": "Suche",
      "button-two-text": "Neue Leistung",
      "button-three-text": "'Keine Hebamme'",
      "button-four-text": "Mail"
    },
    on: {
      "onButtonOne": _vm.onSearch,
      "onButtonTwo": _vm.onNewActivity,
      "onButtonThree": _vm.onWriteMailNoMidwifeFound,
      "onButtonFour": _vm.onWriteMail
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }