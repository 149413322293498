<template>
  <div>
    <formcontent
      ok-text="Speichern" @onOk="save"
      cancel-text="Abbrechen" @onCancel="cancel"
      write-right="concernWrite">
      <div style="
              display: grid;
              grid-column-gap: 20px;
              grid-template-columns: 1fr 1fr;">
        <formcard title="Frau">
            <div style="
                display: grid;
                grid-column-gap: 20px;
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr 1fr;">
              <v-text-field v-model="dtoWoman.prename" label="Vorname"></v-text-field>
              <v-text-field v-model="dtoWoman.surname" label="Nachname"></v-text-field>
              <v-text-field v-model="dtoWoman.marriageSurname" label="Neuer Nachname"></v-text-field>
              <datepicker title="Verwenden ab / Heirat" :propdate.sync="dtoWoman.marriageDate"/>
              <v-text-field v-model="dtoWoman.birthyear" counter="number" label="Geburtsjahr"></v-text-field>
            </div>
        </formcard>
        <formcard title="Schwangerschaft / Geburt">
          <div style="
                display: grid;
                grid-column-gap: 20px;
                grid-template-columns: 1fr 1fr;">
            <datepicker title="ET" :propdate.sync="dtoWoman.edate"/>
            <datepicker title="GT" :propdate.sync="dtoWoman.bdate"/>
            <v-text-field v-model="dtoWoman.para" counter="number" label="Para"></v-text-field>
            <v-text-field v-model="dtoWoman.gravida" counter="number" label="Gravida"></v-text-field>
            <v-select
              :items="multipleBirthList"
              item-text="caption"
              item-value="keylistValueId"
              v-model="dtoWoman.multipleBirth"
              label="Mehrfachgeburt"
              clearable>
            </v-select>
          </div>
        </formcard>
        <formcard title="Kontakt">
          <div style="
                display: grid;
                grid-column-gap: 20px;
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr 1fr;">
            <v-text-field v-model="dtoWoman.phone1" label="Telefon"></v-text-field>
            <v-text-field v-model="dtoWoman.phone2" label="Weiteres Telefon"></v-text-field>
            <v-text-field v-model="dtoWoman.mail" label="E-Mail"></v-text-field>
            <v-text-field v-model="dtoWoman.contactInfo" label="Hinweise zur Erreichbarkeit"></v-text-field>
          </div>
        </formcard>

        <formcard title="Anschrift">
          <div style="
                display: grid;
                grid-column-gap: 20px;
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr 1fr;">
            <v-text-field v-model="dtoWoman.adress.street" label="Straße"></v-text-field>
            <v-text-field v-model="dtoWoman.adress.streetNumber" label="Hausnummer"></v-text-field>
            <v-text-field v-model="dtoWoman.adress.postcode" label="PLZ"></v-text-field>
            <v-text-field v-model="dtoWoman.adress.city" label="Stadt"></v-text-field>
            <singleselection style="margin-bottom: -11px;" :selectedId.sync="dtoWoman.adress.regionId" :prop-selected-item="dtoWoman.adress.regionId"  caption="Stadtteil / Ortschaft" idName="regionId" :propItemList="regionList"></singleselection>
            <v-text-field v-model="dtoWoman.adresschangeinfo" label="Hinweise zur Adresse"></v-text-field>
          </div>
        </formcard>

        <formcard title="Alternative Anschrift">
          <div style="
                display: grid;
                grid-column-gap: 20px;
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr 1fr;">
            <v-text-field v-model="dtoWoman.futureAdress.street" label="Straße"></v-text-field>
            <v-text-field v-model="dtoWoman.futureAdress.streetNumber" label="Hausnummer"></v-text-field>
            <v-text-field v-model="dtoWoman.futureAdress.postcode" label="PLZ"></v-text-field>
            <v-text-field v-model="dtoWoman.futureAdress.city" label="Stadt"></v-text-field>
            <singleselection style="margin-bottom: -11px;" :selectedId.sync="dtoWoman.futureAdress.regionId" :prop-selected-item="dtoWoman.futureAdress.regionId"  caption="Stadtteil / Ortschaft" idName="regionId" :propItemList="regionList"></singleselection>
            <datepicker title="Adressänderung ab" :propdate.sync="dtoWoman.adresschangedate"/>
          </div>
        </formcard>

        <formcard title="Info">
            <v-textarea
              style="width: 95%;"
              clearable
              clear-icon="mdi-close-circle"
              label="Original-Info"
              v-model="dtoWoman.info"
            ></v-textarea>
            <v-textarea
              style="width: 95%;"
              clearable
              clear-icon="mdi-close-circle"
              label="Überarbeitete Info"
              v-model="dtoWoman.modifiedInfo"
            ></v-textarea>
        </formcard>

        <formcard title="Kontakt über">
          <div style="
                display: grid;
                grid-column-gap: 20px;
                grid-template-columns: 1fr 1fr;">
            <v-text-field v-model="dtoWoman.contacter.name" label="Kontakt durch"></v-text-field>
            <v-text-field v-model="dtoWoman.contacter.phone" label="Telefon"></v-text-field>
            <v-text-field v-model="dtoWoman.contacter.mail" label="E-Mail"></v-text-field>
          </div>
        </formcard>

      </div>
    </formcontent>
  </div>
</template>

<script>
import formcontent from '../../../common/components/content/FormContent'
import cloneDeep from 'lodash/cloneDeep'
import ConcernWomanController from './concern.woman.controller'
import datepicker from '../../../common/components/element/DatePicker'
import singleselection from '../../../common/components/element/SingleSelect'
import formcard from '../../../common/components/element/FormCard'

export default {
  name: 'ConcernWomanDetail',

  async created () {
    this.$pageInitController.initReload(this, this.$moduleInfoController.concern, 'Frau', 'Stammdaten', this.womanName)
  },

  components: {
    formcard,
    singleselection,
    datepicker,
    formcontent
  },

  watch: {
    propDtoWoman (val) {
      this.dtoWoman = cloneDeep(this.propDtoWoman)
    }
  },

  methods: {
    async reload () {
      await ConcernWomanController.openConcernWomanDetail(this.caseLocator, Date.now())
    },
    async save () {
      await ConcernWomanController.saveLoadConcernWoman(this.caseLocator, this.concernVersion, this.dtoWoman)
    },
    async cancel () {
      await ConcernWomanController.openConcernWomanOverview(this.caseLocator)
    }
  },

  data () {
    return {
      dtoWoman: cloneDeep(this.propDtoWoman)
    }
  },

  props: {
    concernVersion: { type: Number },
    caseLocator: { type: Object },
    multipleBirthList: { type: Array },
    regionList: { type: Array },
    womanName: { type: String },
    propDtoWoman: { type: Object }
  }
}
</script>
