var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-autocomplete', {
    attrs: {
      "return-object": "",
      "items": _vm.itemList,
      "filled": "",
      "background-color": "white",
      "chips": "",
      "label": _vm.caption,
      "item-text": "name",
      "item-value": _vm.idName
    },
    on: {
      "change": _vm.onInput
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (data) {
        return [_c('v-chip', _vm._b({
          attrs: {
            "input-value": data.selected,
            "close": ""
          },
          on: {
            "click": data.select,
            "click:close": function ($event) {
              return _vm.remove(data.item);
            }
          }
        }, 'v-chip', data.attrs, false), [_vm._v(" " + _vm._s(data.item.name) + " ")])];
      }
    }, {
      key: "item",
      fn: function (data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        })], 1)];
      }
    }]),
    model: {
      value: _vm.selectedItem,
      callback: function ($$v) {
        _vm.selectedItem = $$v;
      },
      expression: "selectedItem"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }