<template>
    <div>
      {{ dateItem | formattedWeekdayDate }}
    </div>
</template>

<script>

export default {
  props: {
    dateItem: String
  }
}
</script>
