import ApiService from '../../../common/service/api/api.service'

const ConcernCauseActivityService = {
  async queryCauseActivity (concernId) {
    const result = await ApiService.doGet('/enna/cause/activity/query/' + concernId)
    return result
  },

  async queryNewCauseActivity (concernId) {
    const result = await ApiService.doGet('/enna/cause/activity/fornew/' + concernId)
    return result
  },

  async loadCauseActivity (concernId, activityId) {
    const result = await ApiService.doGet('/enna/cause/activity/load/' + concernId + '/' + activityId)
    return result
  },

  async saveCauseActivity (concernId, concernVersion, dto) {
    const param = {
      concernId: concernId,
      concernVersion: concernVersion,
      dtoCauseActivity: dto
    }
    const result = await ApiService.doPost('/enna/cause/activity/save', param)
    return result
  }
}

export default ConcernCauseActivityService
