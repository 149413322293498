<template>
  <div style="overflow-x: auto;">
    <div v-if="type==='Number'">
      {{ value.data }}
    </div>
    <div v-else-if="type==='String'" style="white-space: pre-line;">
      {{ value.data }}
    </div>
    <div v-else-if="type==='LocalDate'">
      <dateitem :date-item="value.data"></dateitem>
    </div>
    <div v-else-if="type==='LocalDateTime'">
      <dateitem :date-item="value.data"></dateitem>
    </div>
    <div v-else-if="type==='KeylistValue'">
      <span v-if="value.data">
        {{ value.data.name }}
      </span>
    </div>
    <div v-else-if="type==='ActivityList'">
      <ul>
        <li v-for="activity in value.data" :key="activity.pid">
          {{ activity.caption }} - ({{ activity.activityId }})
        </li>
      </ul>
    </div>
    <div v-else-if="type==='StringMap'">
      <ul>
        <li v-for="error in convertMap(value.data)" :key="error.key">
          {{ error.key }}: {{ error.value }}
        </li>
      </ul>
    </div>
    <div v-else-if="type==='Adress'">
      <div v-if="value.data">
        <div>{{ value.data.street }} {{ value.data.streetnumber }}</div>
        <div>{{ value.data.postcode }} {{ value.data.city }}</div>
        <div v-if="value.data.district">{{ value.data.district.name }}</div>
      </div>
    </div>
    <div v-else-if="type==='Contacter'">
      <div v-if="value.data">
        <div>{{ value.data.name }}</div>
        <div>{{ value.data.phone }}</div>
        <div>{{ value.data.mail }}</div>
      </div>
    </div>
    <div v-else>
      {{ type }}
      {{ value }}
    </div>
  </div>
</template>

<script>
import dateitem from '../../../common/components/element/DateItem'

export default {
  name: 'DoubletType',

  components: {
    dateitem
  },

  methods: {
    convertMap (data) {
      const result = []
      for (var key in data) {
        result.push({
          key: key,
          value: data[key]
        })
      }
      return result
    }
  },

  props: {
    type: String,
    value: { type: Object }
  }
}
</script>

<style>
  .grid > span {
    padding: 8px 4px;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
  }
</style>
