import router from '#enna/common/router'
import MenuController from '../../app/menu/menu.controller'
import MidwifeSignOffService from './midwife.signoff.service'

export default {
  async openMidwifeSignOffOverview (midwifeId) {
    callMidwifeSignOffOverview(midwifeId)
  },

  async openNewSignOff (midwifeId) {
    const response = await MidwifeSignOffService.newSignOff(midwifeId)
    callMidwifeSignOffDetail(midwifeId, null, response, null)
  },

  async openSignOffDetail (midwifeId, pid, reloadId) {
    const response = await MidwifeSignOffService.loadSignOff(pid)
    callMidwifeSignOffDetail(midwifeId, pid, response, reloadId)
  },

  async deleteReadSignOff (midwifeId, pid) {
    await MidwifeSignOffService.deleteSignOff(pid)
    const result = await MidwifeSignOffService.querySignOff(midwifeId)
    return result
  },

  async saveMidwifeSignOff (dtoMidwifeSignOff) {
    await MidwifeSignOffService.saveSignOff(dtoMidwifeSignOff)
    await callMidwifeSignOffOverview(dtoMidwifeSignOff.midwifeId)
  }
}

async function callMidwifeSignOffOverview (midwifeId) {
  const response = await MidwifeSignOffService.queryViewSignOff(midwifeId)
  const midwifeSignOffList = response.data.midwifeSignOffList
  const midwifeName = response.data.midwifeName
  const selectedTabIndex = await MenuController.getMidwifeFileStartTab('midwifesignoffoverview')
  router.push({
    name: 'midwifesignoffoverview',
    params: {
      midwifeId: midwifeId,
      midwifeName: midwifeName,
      preselectedTab: selectedTabIndex,
      writeRight: 'midSignOffWrite',
      propSignOffList: midwifeSignOffList
    }
  })
}

async function callMidwifeSignOffDetail (midwifeId, pid, response, reloadId) {
  const dtoMidwifeSignOff = response.data.dtoMidwifeSignOff
  const signOffTypeList = response.data.signOffTypeList
  const midwifeName = response.data.midwifeName
  router.push({ name: 'midwifesignoffdetail', params: { midwifeId: midwifeId, pid: pid, signOffTypeList: signOffTypeList, propDtoMidwifeSignOff: dtoMidwifeSignOff, midwifeName: midwifeName }, query: { reload: reloadId } })
}
