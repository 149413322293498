import ConcernMailService from './concern.mail.service'
import router from '#enna/common/router'
import MenuController from '../../app/menu/menu.controller'

export default {
  async loadMail (pid) {
    const response = await ConcernMailService.loadMail(pid)
    return response
  },

  async openConcernMailOverview (caseLocator) {
    const response = await ConcernMailService.queryMails(caseLocator)
    const concernBaseData = response.concernBaseData
    const sendedMailList = response.concernData
    const selectedTabIndex = await MenuController.getConcernFileStartTab('concernmailoverview')
    router.push({
      name: 'concernmailoverview',
      params: {
        caseLocator: caseLocator,
        concernBaseData: concernBaseData,
        womanName: concernBaseData.womanName,
        preselectedTab: selectedTabIndex,
        sendedMailList: sendedMailList
      }
    })
  }
}
