import store from '../../../common/store'

export default {
  getPageinfo (pageId) {
    const pageinfo = store.getters['pageinfo/pageinfo'](pageId)
    if (pageinfo) {
      return pageinfo
    }
  },

  setPageinfo (pageId, pageinfo) {
    store.dispatch('pageinfo/setPageinfo', { pageId, pageinfo })
  }
}
