var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('div', [_c('v-card', [_c('v-card-title', [_c('v-text-field', {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Search",
      "single-line": "",
      "clearable": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filterParam.search,
      callback: function ($$v) {
        _vm.$set(_vm.filterParam, "search", $$v);
      },
      expression: "filterParam.search"
    }
  }), _c('v-spacer')], 1), _c('v-data-table', {
    attrs: {
      "hide-default-footer": true,
      "headers": _vm.headers,
      "height": _vm.contentheight,
      "fixed-header": "",
      "sort-by": _vm.filterParam.sortAttribute,
      "sort-desc": _vm.filterParam.sortDirection,
      "items-per-page": 5000,
      "items": _vm.dataList,
      "search": _vm.filterParam.search
    },
    on: {
      "update:sortBy": function ($event) {
        return _vm.$set(_vm.filterParam, "sortAttribute", $event);
      },
      "update:sort-by": function ($event) {
        return _vm.$set(_vm.filterParam, "sortAttribute", $event);
      },
      "update:sortDesc": function ($event) {
        return _vm.$set(_vm.filterParam, "sortDirection", $event);
      },
      "update:sort-desc": function ($event) {
        return _vm.$set(_vm.filterParam, "sortDirection", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var items = _ref.items;
        return [_c('tbody', _vm._l(items, function (item) {
          return _c('tr', {
            key: item[_vm.tablecontentKeyId],
            attrs: {
              "id": item[_vm.tablecontentKeyId]
            }
          }, [_vm._l(_vm.headers, function (header) {
            return _c('td', {
              key: header.name
            }, [_vm._v(" " + _vm._s(_vm.getTextValue(item, header.value)) + " ")]);
          }), _vm.withOpen ? [_c('td', [_c('v-btn', {
            staticClass: "mx-2",
            attrs: {
              "fab": "",
              "small": "",
              "text": "",
              "outlined": "",
              "router": ""
            }
          }, [_c('v-icon', {
            staticClass: "mr-2",
            attrs: {
              "small": "",
              "color": "blue"
            },
            on: {
              "click": function ($event) {
                return _vm.open(item);
              }
            }
          }, [_vm._v("mdi-pencil")])], 1)], 1)] : _vm._e(), _vm.withRemove ? [_c('td', [_c('v-btn', {
            staticClass: "mx-2",
            attrs: {
              "fab": "",
              "small": "",
              "text": "",
              "outlined": "",
              "router": ""
            }
          }, [_c('v-icon', {
            staticClass: "mr-2",
            attrs: {
              "small": "",
              "color": "blue"
            },
            on: {
              "click": function ($event) {
                return _vm.remove(item);
              }
            }
          }, [_vm._v("mdi-delete")])], 1)], 1)] : _vm._e()], 2);
        }), 0)];
      }
    }])
  })], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }