import ApiService from '#enna/common/service/api/api.service'

const ConcernMailService = {
  async queryMails (caseLocator) {
    const result = await ApiService.doPost('/enna/sendedmail/query/concern', caseLocator)
    return result
  },

  async loadMail (pid) {
    const result = await ApiService.doGet('/enna/sendedmail/load/' + pid)
    return result
  }
}

export default ConcernMailService
