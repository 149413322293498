<template>
  <div>
    <div v-bind:style="{ 'overflow-y': 'auto', 'max-height': contentheight + 'px' }">
      <slot/>
    </div>
    <formbuttonbar
      @onOk="onOk" :ok-text="okText"
      @onCancel="onCancel" :cancel-text="cancelText"
      @onButtonOne="onButtonOne" :button-one-text="buttonOneText"
      @onButtonTwo="onButtonTwo" :button-two-text="buttonTwoText"
      @onButtonThree="onButtonThree" :button-three-text="buttonThreeText"
      @onButtonFour="onButtonFour" :button-four-text="buttonFourText">
    </formbuttonbar>
  </div>
</template>

<script>
import formbuttonbar from '../element/FormButtonBar'
import { containsRight } from '../../utils/right.utils'

export default {
  computed: {
    contentheight: function () {
      return this.$store.getters['size/formContent']
    }
  },

  components: {
    formbuttonbar
  },

  methods: {
    writeModus () {
      return containsRight(this.writeRight)
    },
    onOk () {
      this.$emit('onOk')
    },
    onCancel () {
      this.$emit('onCancel')
    },
    onButtonOne () {
      this.$emit('onButtonOne')
    },
    onButtonTwo () {
      this.$emit('onButtonTwo')
    },
    onButtonThree () {
      this.$emit('onButtonThree')
    },
    onButtonFour () {
      this.$emit('onButtonFour')
    }
  },

  props: {
    buttonOneText: String,
    buttonTwoText: String,
    buttonThreeText: String,
    buttonFourText: String,
    okText: String,
    cancelText: String,
    writeRight: String
  }
}
</script>
