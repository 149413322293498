const state = {
  back: null,
  reload: null
}

const actions = {
  setBack ({ commit }, thisPage) {
    commit('setBack', thisPage)
  },
  resetBack ({ commit }) {
    commit('setBack', null)
  },
  setReload ({ commit }, thisPage) {
    commit('setReload', thisPage)
  },
  resetReload ({ commit }) {
    commit('setReload', null)
  }
}

const mutations = {
  setBack (state, data) {
    state.back = data
  },
  setReload (state, data) {
    state.reload = data
  }
}

export const navigation = {
  namespaced: true,
  actions,
  state,
  mutations
}
