<template>
  <div >
    <v-card
      class="mx-auto"
      max-width="800"
      variant="outlined"
      style="border-top: 100px;"
    >
      <v-card-item>
        <div>
          <div class="text-overline mb-1" style="padding-bottom: 50px;">
            <v-toolbar-title >
              <span class="font-weight-thin">e n </span>
              <span class="font-weight-bold">n a</span>
            </v-toolbar-title>
          </div>
          <div class="text-h6 mb-1" style="padding-bottom: 50px;">
            <slot></slot>
          </div>
        </div>
      </v-card-item>

      <v-card-actions>
        <v-btn variant="outlined">
          <router-link style="font-size: x-large" to="/login">Anmelden</router-link>
        </v-btn>
      </v-card-actions>
    </v-card>

  </div>
</template>

<script>
export default {
  name: 'ennalayout'
}
</script>
