import ApiService from '../../../common/service/api/api.service'
import VersionController from '../../../module/app/versioning/versioning.controller'

const MidwifeOverviewService = {

  async queryMidwife () {
    const latestData = await VersionController.getLatestData('midwifeOverview')
    if (latestData === null) {
      const result = await ApiService.doGet('/enna/midwife/overview/view/all')
      VersionController.setLatestData('midwifeOverview', result.data)
      return result.data.data
    } else {
      return latestData.data
    }
  },

  async saveMidwife (prename, surname) {
    const data = { prename, surname }
    const result = await ApiService.doPost('/enna/midwife/overview/new', data)
    return result
  },

  async deleteMidwife (pid) {
    const result = await ApiService.doDelete('/enna/midwife/cancel/' + pid)
    return result
  }
}

export default MidwifeOverviewService
