const state = {
  menu: [],
  midwifeFileTabs: [],
  concernFileTabs: [],
  shortlinks: []
}

const getters = {
}

const actions = {
  setConcernFileTabs ({ commit }, concernFileTabs) {
    commit('setConcernFileTabs', concernFileTabs)
  },

  resetConcernFileTabs ({ commit }) {
    commit('resetConcernFileTabs')
  },

  setMidwifeFileTabs ({ commit }, midwifeFileTabs) {
    commit('setMidwifeFileTabs', midwifeFileTabs)
  },

  resetMidwifeFileTabs ({ commit }) {
    commit('resetMidwifeFileTabs')
  },

  setMenu ({ commit }, menu) {
    commit('setMenu', menu)
  },

  resetMenu ({ commit }) {
    commit('resetMenu')
  },

  setShortlinks ({ commit }, shortlinks) {
    commit('setShortlinks', shortlinks)
  },

  resetShortlinks ({ commit }) {
    commit('resetShortlinks')
  }
}

const mutations = {
  setMidwifeFileTabs (state, midwifeFileTabs) {
    state.midwifeFileTabs = midwifeFileTabs
  },

  resetMidwifeFileTabs (state) {
    state.midwifeFileTabs = []
  },

  setConcernFileTabs (state, concernFileTabs) {
    state.concernFileTabs = concernFileTabs
  },

  resetConcernFileTabs (state) {
    state.concernFileTabs = []
  },

  setMenu (state, data) {
    state.menu = data
  },

  resetMenu (state) {
    state.menu = []
  },

  setShortlinks (state, data) {
    state.shortlinks = data
  },

  resetShortlinks (state) {
    state.shortlinks = []
  }
}

export const menu = {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}
