const actions = {
  async setError ({ commit }, newError) {
    commit('setError', newError)
  },

  async reset ({ commit }) {
    commit('setError', null)
  }
}

const state = {
  error: null
}

const getters = {
  isError: (state) => {
    return state.error !== null
  }
}

const mutations = {
  setError (state, newError) {
    if (newError === null) {
      state.error = null
    } else {
      if (state.error === null) {
        state.error = []
      }
      state.error.push(newError)
    }
  }
}

export const error = {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}
