import { JwtStorage } from './jwt.storage'
import ApiService from '../../../common/service/api/api.service'
import { AuthService, AuthenticationError } from './auth.service'
import router from '../../../common/router'

const state = {
  authenticating: false,
  accessToken: JwtStorage.getToken(),
  authenticationErrorCode: 0,
  authenticationError: ''
}

const getters = {
  loggedIn: (state) => {
    return state.accessToken != null
  },

  authenticationErrorCode: (state) => {
    return state.authenticationErrorCode
  },

  authenticationError: (state) => {
    return state.authenticationError
  },

  authenticating: (state) => {
    return state.authenticating
  }
}
const actions = {
  async login ({ commit }, user) {
    commit('loginRequest')
    try {
      const response = await AuthService.login(user)
      const token = response.data.token
      JwtStorage.saveToken(token)
      ApiService.setAuthHeader(token)
      commit('loginSuccess', token)
    } catch (e) {
      if (e instanceof AuthenticationError) {
        commit('loginError', { errorCode: e.errorCode, errorMessage: e.message })
      }
      return false
    }
  },

  logout ({ commit }) {
    commit('logoutSuccess')
    JwtStorage.removeToken()
    ApiService.removeAuthHeader()
    router.push('/login')
  }
}
const mutations = {
  loginRequest (state) {
    state.authenticating = true
    state.authenticationError = ''
    state.authenticationErrorCode = 0
  },

  loginSuccess (state, accessToken) {
    state.accessToken = accessToken
    state.authenticating = false
  },

  loginError (state, { errorCode, errorMessage }) {
    state.authenticating = false
    state.authenticationErrorCode = errorCode
    state.authenticationError = errorMessage
  },

  logoutSuccess (state) {
    state.accessToken = ''
  }
}

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
