<template>
  <div>
    <div class="grid" v-bind:style="{ 'background-color': '#f0f0f0', 'display': 'grid', 'grid-template-columns': columnstyle }">
      <span></span>
      <span>Aktuelle Anfrage</span>
      <span v-for="column in columns" :key="column">
        Daten aus {{ column }}. Webformular
      </span>
    </div>
    <div v-bind:style="{ 'overflow-y': 'auto', 'max-height': contentheight + 'px' }">
      <div v-bind:style="{ 'display': 'grid', 'grid-template-columns': columnstyle }" class="grid" v-for="item in itemList" :key="item.caption">
        <span style="background-color: #fcfcfc">{{ item.caption }}</span>
        <span style="font-weight: bold">
          <doublettype :type="item.type" :value="{ data: refConcern[item.index] }" ></doublettype>
        </span>
        <span v-for="column in columns" :key="column">
          <doublettype :type="item.type" :value="{ data: webformInquiryList[column - 1][item.index] }"></doublettype>
      </span>
      </div>
    </div>
    <div class="grid" v-bind:style="{ 'background-color': '#f0f0f0', 'display': 'grid', 'grid-template-columns': columnstyle }">
      <span></span>
      <span></span>
      <span v-for="column in columns" :key="column">
      <div class="grid" v-bind:style="{ 'display': 'grid', 'grid-template-columns': '100px 50px' }">
        <v-checkbox label="Geprüft" @change="onProofed($event, webformInquiryList[column - 1])" ></v-checkbox>
        <cmdbutton caption="Lösen" @cmdclick="onRelease(webformInquiryList[column - 1])"></cmdbutton>
      </div>
      </span>
    </div>
  </div>
</template>

<script>
import doublettype from './DoubletType'
import ConcernDoubletController from './concern.doublet.controller'
import CaseOverviewController from '../case/case.overview.controller'

export default {
  name: 'ConcernDoubletOverview',

  components: {
    doublettype
  },

  async created () {
    this.$pageInitController.initBack(this, this.$moduleInfoController.concern, 'Frau', 'Anfragen per Webformular', this.womanName)
  },

  computed: {
    columns () {
      return this.webformInquiryList.length
    },

    columnstyle () {
      if (this.webformInquiryList.length === 0) {
        return '200px 200px'
      } else {
        return '200px 200px repeat(' + this.columns + ', 200px)'
      }
    },

    contentheight () {
      const height = this.$store.getters['size/overviewContent']
      return height - 86
    }
  },

  methods: {
    async back () {
      await CaseOverviewController.openCaseOverview(this.caseLocator)
    },

    onProofed (event, compactInquiry) {
      ConcernDoubletController.proofed(this.caseLocator, compactInquiry, this.refConcern.version, event)
    },

    onRelease (compactInquiry) {
      const webformReleaseData = {
        pid: compactInquiry.compactInquiryKey.webformPid,
        version: compactInquiry.version,
        concernId: this.caseLocator.concernId,
        concernVersion: this.refConcern.version,
        webformVersion: this.webformVersion
      }

      ConcernDoubletController.releaseDoublet(this.caseLocator, webformReleaseData)
    }
  },

  props: {
    webformVersion: Number,
    womanName: String,
    itemList: { type: Array },
    caseLocator: { type: Object },
    refConcern: { type: Object },
    webformInquiryList: { type: Array }
  }
}
</script>

<style>
  .grid > span {
    padding: 8px 4px;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
  }
</style>
