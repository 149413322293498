<template>
  <div style="padding-top: 100px;padding-left: 0px;">
    <div v-for="topic in helpList.topics" :key="topic" class="text-h4 mb-1">
      <div>{{ topic.topic }}</div>
      <div v-for="theme in topic.themes" :key="theme" style="padding-left: 40px;">
        <div style="padding-left: 40px;" class="text-h5 mb-2">
          {{ theme.theme }}
          <ul>
            <li v-for="theme in theme.contents" :key="theme" style="padding-left: 40px;" class="caption">
              {{ theme }}
            </li>
          </ul>
        </div>
      </div>
    </div >
  </div>
</template>

<script>
import HelpController from './help.controller'

export default {
  name: 'HelpPage',

  async created () {
    this.$layoutController.setHelpLayout(this)
    this.helpList = await HelpController.helpData()
  },

  data () {
    return {
      helpList: null
    }
  }
}
</script>
