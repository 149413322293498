var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tabcontent', {
    attrs: {
      "object-id": _vm.caseLocator,
      "tab-list": _vm.concernFileTabs,
      "preselectedTab": _vm.preselectedTab,
      "contenttype": "concern"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }