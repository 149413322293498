<template>
  <div>
    <dynpropbasedetail :dynPropParam="dynPropParam" @onSave="save" >
      <datepicker style="width:240px;" title="Wert" :propdate.sync="dateValue"/>
    </dynpropbasedetail>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import DynPropController from './dynprop.controller'
import dynpropbasedetail from './DynPropBaseDetail'
import datepicker from '../../../common/components/element/DatePicker'

export default {
  name: 'DynPropDateDetail',

  methods: {
    save () {
      DynPropController.saveDate(this.dynPropParam, this.dateValue)
    }
  },

  watch: {
    dynPropParam (val) {
      this.dateValue = cloneDeep(this.dynPropParam.dtoDynPropData.value)
    }
  },

  props: {
    dynPropParam: { type: Object }
  },

  data () {
    return {
      dateValue: cloneDeep(this.dynPropParam.dtoDynPropData.value)
    }
  },

  components: {
    datepicker,
    dynpropbasedetail
  }
}
</script>
