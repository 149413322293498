import ApiService from '../../../common/service/api/api.service'

const SendmailService = {
  async getAccumulativeMail (midwifeId, selectedCaseList) {
    const request = {
      midwifeId: midwifeId,
      informList: selectedCaseList.informList,
      remindList: selectedCaseList.remindList
    }
    var result = await ApiService.doPost('/enna/cause/involvedmidwife/accumulativemail', request)
    return result
  },

  async saveAccumulativeMail (data) {
    const result = await ApiService.doPost('/enna/cause/involvedmidwife/saveaccumulativemail', data)
    return result
  },

  async setCaseActionWithMail (data) {
    const result = await ApiService.doPost('/enna/cause/case/actionwithmail', data)
    return result
  },

  async changeMailTemplate (data) {
    const result = await ApiService.doPost('/enna/cause/case/changemailtemplate', data)
    return result
  },

  async changeAccumulativeMailTemplate (data) {
    const result = await ApiService.doPost('/enna/cause/involvedmidwife/changeaccumulativmailtemplate', data)
    return result
  },

  async loadSendMail (caseLocator, caseAction) {
    const param = {
      caseLocator: caseLocator,
      caseAction: caseAction.caseAction
    }
    const result = await ApiService.doPost('/enna/cause/case/loadsendmail', param)
    return result
  },

  async loadSendMailCauseActivity (concernId) {
    const result = await ApiService.doPost('/enna/cause/case/loadsendmailcauseactivtynomidwifefound/' + concernId)
    return result
  },

  async loadSendMailCauseActivityNoMidwifeFound (concernId) {
    const result = await ApiService.doPost('/enna/cause/case/loadsendmailcauseactivty/' + concernId)
    return result
  }
}

export default SendmailService
