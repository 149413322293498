<template>
    <div>
      <div>
        <div>
          <v-card>
            <v-card-title>
            </v-card-title>
            <v-data-table
              :hide-default-footer="true"
              :headers="headers"
              :height="height"
              fixed-header
              :items-per-page=5000
              :items="dataList"
            >
              <template v-slot:body="{ items }">
                <tbody>
                <tr :id="item[anchorKey]" v-for="item in items" :key="item[tablecontentKeyId]">
                  <td v-for="header in headers" :key="header.name" :style="columnStyle(header)">
                    <div v-if="header.type === 'date'" style="white-space:pre;">{{ getDateValue(item, header.value) }}</div>
                    <div v-else-if="header.type === 'boolean'" style="white-space:pre;">{{ getBooleanValue(item, header.value) }}</div>
                    <div v-else-if="header.type === 'icon'" style="white-space:pre;" :set="icon=getIcon(item, header.value, header.iconMapper)">
                      <v-icon small light>{{ icon.icon }}</v-icon> {{ icon.caption }}
                    </div>
                    <div v-else-if="header.type === 'booleanicon'" style="white-space:pre;">
                      <checkitem :flag-item="getBooleanValueForIcon(item, header.value)"></checkitem>
                    </div>
                    <div v-else-if="header.type === 'propose'" style="white-space:pre;">
                      <cmdbutton @cmdclick="propose(item)" caption="Vorschlagen"/>
                    </div>
                    <div v-else style="white-space:pre-wrap;">{{ getTextValue(item, header.value) }}</div>
                  </td>
                 </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { formatDate } from '../../../common/utils/date.utils'
import Helper from '../../../common/utils/json.utils'
import checkitem from '#enna/common/components/element/CheckItem'

export default {
  name: 'SearchResultTable',

  components: {
    checkitem
  },

  computed: {
    headers: function () {
      const proposeColumn = {
        text: 'Vorschlagen',
        type: 'propose'
      }
      const result = cloneDeep(this.propHeaders)
      result.push(proposeColumn)
      return result
    }
  },

  methods: {
    propose (item) {
      this.$emit('proposeMidwife', item)
    },

    columnStyle (header) {
      const result = {
        'vertical-align': 'top'
      }
      if (header.width !== null) {
        result['max-width'] = header.width + 'px'
      }
      return result
    },

    getIcon (item, value, mapper) {
      const iconValue = Helper.getValue(item, value)
      const iconMap = this.iconMapperMap.get(mapper)
      const icon = iconMap.get(iconValue.iconId)
      return {
        icon: icon,
        caption: iconValue.caption
      }
    },

    getBooleanValueForIcon (item, value) {
      return Helper.getValue(item, value)
    },

    getBooleanValue (item, value) {
      const booleanValue = Helper.getValue(item, value)
      if (booleanValue === true) {
        return 'ja'
      } else if (booleanValue === false) {
        return 'nein'
      } else {
        return null
      }
    },
    getDateValue (item, value) {
      const dateValue = Helper.getValue(item, value)
      if (dateValue) {
        return formatDate(dateValue)
      } else {
        return null
      }
    },
    getTextValue (item, value) {
      return Helper.getValue(item, value)
    }
  },

  props: {
    height: { type: Number },
    iconMapperMap: { type: Map },
    tablecontentKeyId: { type: String },
    anchorKey: { type: String },
    dataList: { type: Array },
    propHeaders: { type: Array }
  },

  data () {
    return {
      selectMidwife: null
    }
  }
}
</script>
