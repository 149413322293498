<template>
  <div>
    <formcontent
      ok-text="Bearbeiten" @onOk="update"
      write-right="midMasterdataWrite">
      <div style="display: grid; grid-template-columns: 5fr 3fr;" >
          <div>
            <div style="padding-top: 20px;" class="font-weight-light">Name</div>
            <span class="font-weight-medium" style="padding-left: 15px;">{{ midwifeMasterdata.shortname }}</span>
            <span class="font-weight-medium" style="padding-left: 15px;">{{ midwifeMasterdata.surname }}</span>
            <span class="font-weight-medium" style="padding-left: 15px;">{{ midwifeMasterdata.practiseName }}</span>
            <div v-show="birthday" style="padding-top: 20px;" class="font-weight-light">Geburtsdatum</div>
            <span class="font-weight-medium" style="padding-left: 15px;">{{ birthday }}</span>
            <div style="padding-top: 20px;" class="font-weight-light">Teilnahme seit</div>
            <span class="font-weight-medium" style="padding-left: 15px;">{{ memberSince }}</span>
            <div style="padding-top: 20px;" class="font-weight-light">Homepage</div>
            <span class="font-weight-medium" style="padding-left: 15px;">{{ midwifeMasterdata.homepage }}</span>
          </div>
          <div>
            <div style="padding-top: 20px;" class="font-weight-light">Anschrift</div>
            <span class="font-weight-medium" style="padding-left: 15px;">{{ midwifeMasterdata.postcode }}</span>
            <span class="font-weight-medium" style="padding-left: 15px;">{{ midwifeMasterdata.city }}</span>
            <div class="font-weight-medium" style="padding-left: 15px;">{{ midwifeMasterdata.streetnumber }}</div>
            <div class="font-weight-medium" style="padding-left: 15px;">{{ midwifeMasterdata.district }}</div>
            <div v-show="midwifeMasterdata.location" style="padding-top: 20px;" class="font-weight-light">Standort</div>
            <span class="font-weight-medium" style="padding-left: 15px;">{{ midwifeMasterdata.location }}</span>
          </div>
      </div>
    </formcontent>
  </div>
</template>

<script>
import formcontent from '../../../common/components/content/FormContent'
import { formatDate } from '../../../common/utils/date.utils'
import MidwifeOverviewController from '../overview/midwife.overview.controller'
import MidwifeMasterdataController from './midwife.masterdata.controller'

export default {
  name: 'MidwifeMasterdataOverview',

  async created () {
    this.$pageInitController.initBack(this, this.$moduleInfoController.midwife, 'Hebamme', 'Stammdaten', this.midwifeName)
  },

  computed: {
    birthday () {
      return formatDate(this.midwifeMasterdata.birthday)
    },
    memberSince () {
      return formatDate(this.midwifeMasterdata.memberSince)
    }
  },

  components: {
    formcontent
  },

  methods: {
    async back () {
      await MidwifeOverviewController.openMidwifeOverview(this.midwifeId)
    },

    async update () {
      await MidwifeMasterdataController.openDtoMidwifeMasterdataOverview(this.midwifeMasterdata.pid)
    }
  },

  props: {
    midwifeId: { type: String },
    midwifeName: { type: String },
    midwifeMasterdata: { type: Object }
  }
}
</script>
