import WebformService from './webform.service'
import ConcernDoubletService from '../doublet/concern.doublet.service'
import router from '#enna/common/router'

export default {
  async openDubletteProcessing (webformInquiry) {
    const response = await WebformService.queryDoubletCandidates(webformInquiry.pid, webformInquiry.possibleDoubletList)
    const orgWebformInquiry = response.data.refData
    const concernList = response.data.concernDoubletList
    const webformInquiryList = response.data.webformInquiryDoubletList
    const allConcernList = response.data.allConcernList
    const versionId = response.version
    const itemList = ConcernDoubletService.getItemList()
    router.push({ name: 'doubletprocessing', params: { versionId: versionId, webformInquiry: orgWebformInquiry, concernList: concernList, propWebformInquiryList: webformInquiryList, itemList: itemList, allConcernList: allConcernList } })
  },

  async executeDoubletProcessing (versionId, choice, deleteList, newList, mainSelected, assignConcern, assignWebformInquiryList) {
    const data = {
      versionId: versionId,
      choice: choice,
      deleteList: Array.from(deleteList),
      newList: Array.from(newList),
      mainSelected: mainSelected,
      assignConcern: assignConcern,
      assignWebformInquiryList: Array.from(assignWebformInquiryList)
    }

    await WebformService.executeDoubletProcessing(data)
    callOpenWebformOverview()
  },

  async openWebformOverview (reloadId) {
    callOpenWebformOverview(reloadId)
  },

  async saveDeleteWebformInquiry (actionMap, versionId) {
    const takeSet = []
    const deleteSet = []
    actionMap.forEach((value, key) => {
      if (value === 1) {
        takeSet.push(key)
      } else if (value === 2) {
        deleteSet.push(key)
      }
    })
    const data = {
      versionId: versionId,
      takePidList: takeSet,
      deletePidList: deleteSet
    }
    await WebformService.takeDeleteWebformInquiry(data)

    const reloadData = await getReloadData()
    return reloadData
  },

  async loadFromMailserver () {
    const result = await WebformService.queryWebformInquiryFromServer()
    return result
  },

  async reload () {
    const reloadData = await getReloadData()
    return reloadData
  }
}

async function getReloadData () {
  const response = await WebformService.queryWebformInquiry()
  const webformInquiryList = response.data.data
  const versionId = response.data.version
  return { webformInquiryList: webformInquiryList, versionId: versionId }
}

async function callOpenWebformOverview (reloadId) {
  const webformParameter = { }
  const response = await WebformService.queryWebformInquiry(webformParameter)
  const webformInquiryList = response.data.data
  const versionId = response.data.version
  router.push({ name: 'webformoverview', params: { propWebformInquiryList: webformInquiryList, propVersionId: versionId }, query: { reload: reloadId } })
}
