var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    style: {
      'overflow-y': 'auto',
      'max-height': 200 + 'px'
    }
  }, _vm._l(_vm.infoList, function (info) {
    return _c('div', {
      key: info.initialDate
    }, [_c('v-subheader', [_c('div', [_vm._v(" " + _vm._s(_vm.getOrigin(info))), _c('br'), _vm._v(" " + _vm._s(info.user.userName) + " - Letzte Änderung: "), _c('datetimeitem', {
      staticStyle: {
        "display": "inline"
      },
      attrs: {
        "datetime-item": info.lastModifiedDate
      }
    }), _c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "color": "blue"
      },
      on: {
        "click": function ($event) {
          return _vm.editInfo(info);
        }
      }
    }, [_vm._v("mdi-pencil")]), _c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "color": "blue"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteInfo(info);
        }
      }
    }, [_vm._v("mdi-delete")]), _c('v-divider')], 1)]), _c('div', {
      staticStyle: {
        "white-space": "pre-wrap",
        "word-wrap": "break-word",
        "color": "black",
        "font-family": "inherit",
        "padding-left": "60px",
        "padding-top": "5px",
        "padding-right": "25px",
        "padding-bottom": "20px"
      }
    }, [_vm._v(" " + _vm._s(info.infoText) + " ")])], 1);
  }), 0), _c('cmdbutton', {
    attrs: {
      "caption": "Neue Info"
    },
    on: {
      "cmdclick": _vm.newInfo
    }
  }), _c('infodialog', {
    attrs: {
      "propInfoText": _vm.currentInfoText,
      "propShowDialog": _vm.showDialog
    },
    on: {
      "update:propInfoText": function ($event) {
        _vm.currentInfoText = $event;
      },
      "update:prop-info-text": function ($event) {
        _vm.currentInfoText = $event;
      },
      "onSave": _vm.onSaveInfoDialog,
      "onCancel": _vm.onCancelInfoDialog,
      "update:propShowDialog": function ($event) {
        _vm.showDialog = $event;
      },
      "update:prop-show-dialog": function ($event) {
        _vm.showDialog = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }