<template>
    <div>
      <div style="display: grid; grid-template-columns: 200px auto;">
        <div>
          <v-tabs class="elevation-4" fixed-tabs vertical background-color="lighten-5" @change="setTabId" v-model="activeTab">
            <v-tab light exact v-for="tab in tabList" :key="tab.id" style="text-align:right;" @click="tab.openRoute(getObjectId(tab))">
              <v-icon style="padding-left: 10px;" left small>{{ tab.icon }}</v-icon>
              <div class="caption">{{ tab.name }} {{ tab.caseId }}</div>
              <v-badge v-if="tab.count>0" color="red" :content=tab.count right > </v-badge>
            </v-tab>
          </v-tabs>
        </div>
        <div style="padding-left: 20px;">
          <router-view></router-view>
        </div>
      </div>
    </div>
</template>

<script>
import PageinfoController from '../../../module/app/pageinfo/pageinfo.controller'

export default {
  name: 'TabContent',

  computed: {
    contentheight: function () {
      return this.$store.getters['size/tabContent']
    }
  },

  methods: {
    getObjectId (tab) {
      if (tab.caseId) {
        const result = {
          concernId: this.objectId.concernId,
          caseId: tab.caseId
        }
        return result
      } else {
        return this.objectId
      }
    },

    setTabId (index) {
      PageinfoController.setPageinfo(this.contenttype + '#tabIndex', index)
    }
  },

  data () {
    return {
      activeTab: this.preselectedTab
    }
  },

  props: {
    tabList: { type: Array },
    objectId: { type: Object },
    preselectedTab: { type: Number },
    contenttype: { type: String }
  }
}
</script>
