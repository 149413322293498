import MailTemplateService from './mailtemplate.service'
import TextModuleController from '../textmodule/textmodule.controller'
import router from '../../../common/router'
import PageinfoController from '../../app/pageinfo/pageinfo.controller'

const mailTemplateOverviewName = 'MailTemplateOverview'
export default {

  saveFilterParam (filterParam) {
    PageinfoController.setPageinfo(mailTemplateOverviewName, filterParam)
  },

  async openNewMailTemplate () {
    const response = await MailTemplateService.new()
    callMailTemplateDetail(response, null)
  },

  async openMailTemplate (pid, reloadId) {
    const response = await MailTemplateService.load(pid)
    callMailTemplateDetail(response, reloadId)
  },

  async preview (previewParam) {
    const response = await MailTemplateService.preview(previewParam)
    return response.data
  },

  async deleteMailTemplate (pid) {
    await MailTemplateService.delete(pid)
    const result = await MailTemplateService.query()
    return result
  },

  async save (dto) {
    await MailTemplateService.save(dto)
    await callMailTemplateOverview()
  },

  async openTextModuleOverview () {
    TextModuleController.openTextModuleOverview()
  },

  async openMailTemplateOverview (pid) {
    await callMailTemplateOverview(pid)
  }
}

async function callMailTemplateOverview (pid) {
  const mailTemplateList = await MailTemplateService.query()
  let hashId = ''
  if (pid) {
    hashId = '#' + pid
  }
  router.push({ name: 'mailtemplateoverview', hash: hashId, params: { propMailTemplateList: mailTemplateList, writeRight: 'mailtemplateWrite' } })
}

async function callMailTemplateDetail (response, reloadId) {
  const conditionList = response.data.textEditSupportData.conditionList
  const placeholderList = response.data.textEditSupportData.placeholderList
  const textModuleList = response.data.textEditSupportData.textModuleList
  const caseMidwifeList = response.data.textEditSupportData.caseMidwifeList
  const midwifeList = response.data.textEditSupportData.midwifeList
  const mailTypeList = response.data.mailTypeList
  const propDtoMailTemplate = response.data.mailTemplate
  router.push({
    name: 'mailtemplatedetail',
    params: {
      conditionList: conditionList,
      placeholderList: placeholderList,
      textModuleList: textModuleList,
      caseMidwifeList: caseMidwifeList,
      midwifeList: midwifeList,
      mailTypeList: mailTypeList,
      propDtoMailTemplate: propDtoMailTemplate
    },
    query: { reload: reloadId }
  })
}
