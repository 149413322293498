var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-timeline', _vm._l(_vm.historyList, function (group) {
    return _c('div', {
      key: group.date
    }, [_c('v-timeline-item', {
      staticClass: "black--text font-weight-black",
      attrs: {
        "fill-dot": "",
        "color": "red",
        "small": ""
      },
      scopedSlots: _vm._u([{
        key: "opposite",
        fn: function () {
          return [_c('span', [_vm._v(_vm._s(_vm.formatDay(group.date)))])];
        },
        proxy: true
      }], null, true)
    }), _vm._l(group.actionList, function (action) {
      return _c('div', {
        key: action.id
      }, [_c('v-timeline-item', {
        attrs: {
          "fill-dot": "",
          "hide-dot": "",
          "small": ""
        },
        scopedSlots: _vm._u([{
          key: "opposite",
          fn: function () {
            return [_vm._v(" " + _vm._s(action.user) + " - " + _vm._s(action.time) + " ")];
          },
          proxy: true
        }], null, true)
      }, [_c('div', {
        staticClass: "font-weight-black"
      }, [_vm._v(" " + _vm._s(action.action))]), action.referencedDataAction ? _c('div', {
        staticStyle: {
          "padding-left": "10px"
        }
      }, [_c('div', [_vm._v(_vm._s(action.referencedDataAction.caseActionCaption))]), _c('div', [_vm._v(_vm._s(action.referencedDataAction.info))])]) : _vm._e(), action.midwife ? _c('div', {
        staticStyle: {
          "padding-left": "10px"
        }
      }, [_c('div', [_vm._v(_vm._s(action.midwife))])]) : _vm._e(), action.midwifeMailPid ? _c('div', {
        staticStyle: {
          "padding-left": "10px"
        }
      }, [_c('v-icon', {
        staticClass: "mr-2",
        attrs: {
          "color": "blue"
        },
        on: {
          "click": function ($event) {
            return _vm.showMailContent(action.midwifeMailPid);
          }
        }
      }, [_vm._v("mdi-email-open")]), _vm._v(" Hebamme ")], 1) : _vm._e(), action.womanMailPid ? _c('div', {
        staticStyle: {
          "padding-left": "10px"
        }
      }, [_c('v-icon', {
        staticClass: "mr-2",
        attrs: {
          "color": "blue"
        },
        on: {
          "click": function ($event) {
            return _vm.showMailContent(action.womanMailPid);
          }
        }
      }, [_vm._v("mdi-email-open")]), _vm._v(" Frau ")], 1) : _vm._e(), _c('div', {
        staticStyle: {
          "padding-left": "20px"
        }
      }, [_vm._v(_vm._s(action.info))])])], 1);
    })], 2);
  }), 0), _c('mailcontentdialog', {
    attrs: {
      "sended-mail": _vm.sendedMail,
      "propShowDialog": _vm.showTextDialog
    },
    on: {
      "onCancel": _vm.onCancel,
      "update:propShowDialog": function ($event) {
        _vm.showTextDialog = $event;
      },
      "update:prop-show-dialog": function ($event) {
        _vm.showTextDialog = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }