var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "overflow-x": "auto"
    }
  }, [_vm.type === 'Number' ? _c('div', [_vm._v(" " + _vm._s(_vm.value.data) + " ")]) : _vm.type === 'String' ? _c('div', {
    staticStyle: {
      "white-space": "pre-line"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.data) + " ")]) : _vm.type === 'LocalDate' ? _c('div', [_c('dateitem', {
    attrs: {
      "date-item": _vm.value.data
    }
  })], 1) : _vm.type === 'LocalDateTime' ? _c('div', [_c('dateitem', {
    attrs: {
      "date-item": _vm.value.data
    }
  })], 1) : _vm.type === 'KeylistValue' ? _c('div', [_vm.value.data ? _c('span', [_vm._v(" " + _vm._s(_vm.value.data.name) + " ")]) : _vm._e()]) : _vm.type === 'ActivityList' ? _c('div', [_c('ul', _vm._l(_vm.value.data, function (activity) {
    return _c('li', {
      key: activity.pid
    }, [_vm._v(" " + _vm._s(activity.caption) + " - (" + _vm._s(activity.activityId) + ") ")]);
  }), 0)]) : _vm.type === 'StringMap' ? _c('div', [_c('ul', _vm._l(_vm.convertMap(_vm.value.data), function (error) {
    return _c('li', {
      key: error.key
    }, [_vm._v(" " + _vm._s(error.key) + ": " + _vm._s(error.value) + " ")]);
  }), 0)]) : _vm.type === 'Adress' ? _c('div', [_vm.value.data ? _c('div', [_c('div', [_vm._v(_vm._s(_vm.value.data.street) + " " + _vm._s(_vm.value.data.streetnumber))]), _c('div', [_vm._v(_vm._s(_vm.value.data.postcode) + " " + _vm._s(_vm.value.data.city))]), _vm.value.data.district ? _c('div', [_vm._v(_vm._s(_vm.value.data.district.name))]) : _vm._e()]) : _vm._e()]) : _vm.type === 'Contacter' ? _c('div', [_vm.value.data ? _c('div', [_c('div', [_vm._v(_vm._s(_vm.value.data.name))]), _c('div', [_vm._v(_vm._s(_vm.value.data.phone))]), _c('div', [_vm._v(_vm._s(_vm.value.data.mail))])]) : _vm._e()]) : _c('div', [_vm._v(" " + _vm._s(_vm.type) + " " + _vm._s(_vm.value) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }