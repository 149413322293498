<template>
  <div>
    <formcontent
      ok-text="Speichern" @onOk="save"
      cancel-text="Abbrechen" @onCancel="cancel">
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-columns:7fr 3fr;">
        <div>
          <v-text-field v-model="dtoMailTemplate.name" label="Kurzbeschreibung" hint="Kurze Zusammenfassung des Inhalts der Mailvorlage"></v-text-field>
          <v-text-field v-model="dtoMailTemplate.description" label="Beschreibung" hint="Hinweise zur Mailvorlage"></v-text-field>
          <div style="
            display: grid;
            grid-gap: 20px;
            grid-template-columns:7fr 3fr;">
            <v-select
              :items="mailTypeList"
              item-text="name"
              item-value="keylistValueId"
              v-model="dtoMailTemplate.mailtemplateKey"
              label="Mailtype">
            </v-select>
            <v-checkbox v-model="dtoMailTemplate.priorityTemplate" label="Voreingestellter Standard"></v-checkbox>
          </div>
          <v-divider ></v-divider>
          <div style="padding-top: 10px; background-color: #EDEDED">
            <v-text-field v-model="dtoMailTemplate.subject" label="Betreff" hint="Betreffzeile der Mail"></v-text-field>
            <v-textarea
              style="width: 95%;"
              clearable
              clear-icon="mdi-close-circle"
              label="Mailinhalt"
              hint="Inhalt der Mailvorlage"
              v-model="dtoMailTemplate.content"
            ></v-textarea>
          </div>
          <div style="padding-top: 150px;">
            <label  class="grey--text font-weight-medium">Vorschau</label>
            <v-divider ></v-divider>
            <v-select style="max-width: 50%;"
                :items="caseMidwifeList"
                item-value="caseLocator"
                v-model="caseLocator"
                label="Anfragen / Hebamme">
              <template v-slot:selection="{ item }">
                {{ getCaseMidwifeName(item) }}
              </template>
              <template v-slot:item="{ item }">
                {{ getCaseMidwifeName(item) }}
              </template>
            </v-select>
            <v-select style="max-width: 50%;"
                      clearable
                      :items="midwifeList"
                      item-text="name"
                      item-value="midwifeId"
                      v-model="midwifeId"
                      label="Alternative Hebamme">
            </v-select>
            <cmdbutton @cmdclick="showPreview" caption="Vorschau anzeigen"/>
          </div>
        </div>
        <div v-bind:style="{ 'overflow-y': 'hidden', 'height': contentheight + 'px' }">
          <div style="height: 40%;">
            <mailvariableoverview
              :condition-list="conditionList"
              :placeholder-list="placeholderList"
              :text-module-list="textModuleList"
            ></mailvariableoverview>
          </div>
        </div>
      </div>
    </formcontent>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import formcontent from '#enna/common/components/content/FormContent'
import MailTemplateController from './mailtemplate.controller'
import InfoDialogController from '../../../common/components/element/infodialog/infodialog.controller'
import mailvariableoverview from './MailVariableOverview'

export default {
  name: 'MailTemplateDetail',

  async created () {
    this.$pageInitController.initReload(this, this.$moduleInfoController.admin, 'Mailvorlage', null, this.dtoMailTemplate.templatekey)
  },

  computed: {
    contentheight () {
      const height = this.$store.getters['size/formContent']
      return height
    }
  },

  methods: {
    async reload () {
      await MailTemplateController.openMailTemplate(this.dtoMailTemplate.pid, Date.now())
    },
    cancel () {
      MailTemplateController.openMailTemplateOverview()
    },

    getCaseMidwifeName (item) {
      let result = item.womanName
      const midwifeName = item.midwifeName
      if (midwifeName) {
        result = result + ' (Hebamme: ' + midwifeName + ')'
      }
      return result
    },

    async save () {
      MailTemplateController.save(this.dtoMailTemplate)
    },

    async showPreview () {
      const previewParam = {
        content: this.dtoMailTemplate.content,
        alternativMidwifeId: this.midwifeId,
        caseLocator: this.caseLocator
      }
      const preview = await MailTemplateController.preview(previewParam)
      InfoDialogController.showInfo('Vorschau', preview)
    }
  },

  watch: {
    propDtoMailTemplate (val) {
      this.dtoMailTemplate = cloneDeep(this.propDtoMailTemplate)
    }
  },

  props: {
    conditionList: { type: Array },
    placeholderList: { type: Array },
    textModuleList: { type: Array },
    midwifeList: { type: Array },
    caseMidwifeList: { type: Array },
    mailTypeList: { type: Array },
    propDtoMailTemplate: { type: Object }
  },

  data () {
    return {
      dtoMailTemplate: cloneDeep(this.propDtoMailTemplate),
      caseLocator: null,
      midwifeId: null
    }
  },

  components: {
    mailvariableoverview,
    formcontent
  }
}
</script>
