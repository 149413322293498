var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    style: {
      'overflow-y': 'auto',
      'max-height': _vm.contentheight + 'px'
    }
  }, [_vm._t("default")], 2), _c('formbuttonbar', {
    attrs: {
      "ok-text": _vm.okText,
      "cancel-text": _vm.cancelText,
      "button-one-text": _vm.buttonOneText,
      "button-two-text": _vm.buttonTwoText,
      "button-three-text": _vm.buttonThreeText,
      "button-four-text": _vm.buttonFourText
    },
    on: {
      "onOk": _vm.onOk,
      "onCancel": _vm.onCancel,
      "onButtonOne": _vm.onButtonOne,
      "onButtonTwo": _vm.onButtonTwo,
      "onButtonThree": _vm.onButtonThree,
      "onButtonFour": _vm.onButtonFour
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }