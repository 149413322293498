var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "700",
      "persistent": ""
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_vm.sendedMail ? _c('v-card', [_c('v-card-title', {
    staticClass: "text-h5 grey lighten-2"
  }, [_vm._v(" " + _vm._s(_vm.sendedMail.caption)), _vm.sendedMail.templateChanged ? _c('span', [_vm._v(" (Vorlage geändert)")]) : _vm._e()]), _c('v-card-text', {
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_c('span', {
    staticStyle: {
      "display": "grid",
      "gap": "20px",
      "grid-template-columns": "2fr 1fr"
    }
  }, [_c('div', [_c('label', {
    staticClass: "mailstyle"
  }, [_vm._v("Mailtype: ")]), _c('div', {
    staticStyle: {
      "padding-bottom": "10px"
    }
  }, [_vm._v(_vm._s(_vm.sendedMail.mailtemplateCaption))]), _c('div', {
    staticStyle: {
      "background-color": "#EDEDED"
    }
  }, [_c('label', {
    staticClass: "mailstyle"
  }, [_vm._v("Betreff: ")]), _c('div', {
    staticStyle: {
      "padding-bottom": "10px"
    }
  }, [_vm._v(_vm._s(_vm.sendedMail.subject))]), _c('label', {
    staticClass: "mailstyle"
  }, [_vm._v("Inhalt: ")]), _c('div', {
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.sendedMail.content))])])]), _c('div', [_c('label', {
    staticClass: "mailstyle"
  }, [_vm._v("Verschickt am: ")]), _c('div', [_vm._v(_vm._s(_vm._f("formattedDate")(_vm.sendedMail.sendDate)))]), _c('div', {
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_c('label', {
    staticClass: "mailstyle"
  }, [_vm._v("Von: ")]), _c('div', [_vm._v(_vm._s(_vm.sendedMail.userName))])]), _vm.sendedMail.midwifeName ? _c('div', {
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_c('label', {
    staticClass: "mailstyle"
  }, [_vm._v("Verschickt an Hebamme: ")]), _c('div', [_vm._v(_vm._s(_vm.sendedMail.midwifeName))]), _c('div', [_vm._v(_vm._s(_vm.sendedMail.midwifeAdressat))])]) : _vm._e(), _vm.sendedMail.concernAdressat ? _c('div', {
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_c('label', {
    staticClass: "mailstyle"
  }, [_vm._v("Verschickt an Frau: ")]), _c('div', [_vm._v(_vm._s(_vm.sendedMail.concernAdressat))])]) : _vm._e()])])]), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v(" Ok ")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }