import ApiService from '../../../common/service/api/api.service'
// import VersionController from '../../../module/app/versioning/versioning.controller'

const MidwifeMasterdataService = {
  async getMidwifeMasterdata (midwifeId) {
    const result = await ApiService.doGet('/enna/midwife/masterdata/view/' + midwifeId)
    return result
  },

  async loadMidwifeMasterdata (pid) {
    const result = await ApiService.doGet('/enna/midwife/masterdata/view/dto/' + pid)
    return result
  },

  async saveHandover (dtoMidwifeMasterdata) {
    const result = await ApiService.doPost('/enna/midwife/masterdata', dtoMidwifeMasterdata)
    return result
  }
}

export default MidwifeMasterdataService
