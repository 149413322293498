import ApiService from '../../../common/service/api/api.service'

const VersioningService = {
  async loadVersion (versionId, objectId) {
    let url = '/enna/version/' + versionId
    if (objectId === null) {
      url = url + '/' + objectId
    }
    const result = await ApiService.doGet(url)
    return result
  }
}

export default VersioningService
