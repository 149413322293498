import ApiService from '../../../common/service/api/api.service'

const MidwifeInquiryService = {
  async queryMidwifeInquiry (midwifeId) {
    var result = await ApiService.doGet('/enna/cause/involvedmidwife/query/case/' + midwifeId)
    return result
  },

  async queryOpenTodo (midwifeId) {
    var result = await ApiService.doGet('/enna/cause/involvedmidwife/query/opentodo/' + midwifeId)
    return result
  }
}

export default MidwifeInquiryService
