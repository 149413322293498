import { UserService } from './user.service'
import store from '../../common/store'
import MenuController from '../app/menu/menu.controller'
import Utils from '../../common/utils/data.utils'
import router from '@/common/router'

export default {

  async startChangePassword () {
    const response = await UserService.userInfo()
    router.push({ name: 'changepassword', params: { username: response.userName, userid: response.userId } })
  },

  async savePassword (userid, password) {
    const response = await UserService.changePassword(userid, password)
    return response
  },

  async userInfo () {
    const response = await UserService.userInfo()
    return response
  },

  async initializeUser () {
    const response = await UserService.releaseFunction()
    store.commit('right/setRightsMenu', response.data)
    const startPage = Utils.getConfig(response, 'startpage')
    const shortlinks = Utils.getConfig(response, 'shortlinks')
    await MenuController.createMenu()
    await MenuController.createShortlinks(shortlinks)
    await MenuController.createMidwifeFileTabs()
    await MenuController.createConcernFileTabs()
    await openStartPage(startPage)
  }
}

async function openStartPage (startPage) {
  const menuItem = await MenuController.getMenuItemByName(startPage)
  if (menuItem) {
    menuItem.openRoute()
  }
}
