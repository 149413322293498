var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('formcontent', {
    attrs: {
      "ok-text": "Speichern",
      "cancel-text": "Abbrechen",
      "write-right": "midPropertyWrite"
    },
    on: {
      "onOk": _vm.save,
      "onCancel": _vm.cancel
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Teilnahme an Vermittlung"
    },
    model: {
      value: _vm.dtoMidwifeProperty.participateMediation,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeProperty, "participateMediation", $$v);
      },
      expression: "dtoMidwifeProperty.participateMediation"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Teilnahme am Vertretungssystem"
    },
    model: {
      value: _vm.dtoMidwifeProperty.participateSubstitution,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeProperty, "participateSubstitution", $$v);
      },
      expression: "dtoMidwifeProperty.participateSubstitution"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Benachrichtung per Mail"
    },
    model: {
      value: _vm.dtoMidwifeProperty.infoPerMail,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeProperty, "infoPerMail", $$v);
      },
      expression: "dtoMidwifeProperty.infoPerMail"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Benachrichtung per Sammelmail"
    },
    model: {
      value: _vm.dtoMidwifeProperty.infoPerCollectiveMail,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeProperty, "infoPerCollectiveMail", $$v);
      },
      expression: "dtoMidwifeProperty.infoPerCollectiveMail"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Sammelmail mit vollständigen Daten der Frau"
    },
    model: {
      value: _vm.dtoMidwifeProperty.accumulativeMailFullData,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeProperty, "accumulativeMailFullData", $$v);
      },
      expression: "dtoMidwifeProperty.accumulativeMailFullData"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Benachrichtigung per SMS"
    },
    model: {
      value: _vm.dtoMidwifeProperty.infoPerSms,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeProperty, "infoPerSms", $$v);
      },
      expression: "dtoMidwifeProperty.infoPerSms"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Priorität: Mail vor SMS"
    },
    model: {
      value: _vm.dtoMidwifeProperty.prioritySmsMail,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeProperty, "prioritySmsMail", $$v);
      },
      expression: "dtoMidwifeProperty.prioritySmsMail"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Anrede 'Sie'"
    },
    model: {
      value: _vm.dtoMidwifeProperty.salutation,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeProperty, "salutation", $$v);
      },
      expression: "dtoMidwifeProperty.salutation"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.contactProcedureOptionList,
      "item-text": "name",
      "item-value": "id",
      "label": "Verfahren bei 'Anfrage'"
    },
    model: {
      value: _vm.dtoMidwifeProperty.contactProcedure,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeProperty, "contactProcedure", $$v);
      },
      expression: "dtoMidwifeProperty.contactProcedure"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Zusatzinfo bei Anfrage'"
    },
    model: {
      value: _vm.dtoMidwifeProperty.contactMailinfo,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeProperty, "contactMailinfo", $$v);
      },
      expression: "dtoMidwifeProperty.contactMailinfo"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.promiseProcedureOptionList,
      "item-text": "name",
      "item-value": "id",
      "label": "Verfahren bei 'Zusage'"
    },
    model: {
      value: _vm.dtoMidwifeProperty.promiseProcedure,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeProperty, "promiseProcedure", $$v);
      },
      expression: "dtoMidwifeProperty.promiseProcedure"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Zusatzinfo bei Zusage'"
    },
    model: {
      value: _vm.dtoMidwifeProperty.promiseMailinfo,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeProperty, "promiseMailinfo", $$v);
      },
      expression: "dtoMidwifeProperty.promiseMailinfo"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }