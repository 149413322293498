<template>
  <div>
    <formcontent
      ok-text="Speichern" @onOk="save"
      cancel-text="Abbrechen" @onCancel="cancel">
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:100px;
        grid-template-columns:4fr 2fr;">
        <v-textarea v-model="dtoMidwifeInfo.info" label="Info" clearable rows="6"></v-textarea>
      </div>
      <div style="
        padding-top: 100px;
        display: grid;
        grid-gap: 20px;
        grid-template-rows:100px;
        grid-template-columns:1fr 1fr 1fr 2fr 1fr;">
        <v-checkbox v-model="dtoMidwifeInfo.forTodo" label="Zu erledigen"></v-checkbox>
        <v-checkbox v-model="dtoMidwifeInfo.forAgency" label="Allgemein"></v-checkbox>
        <v-checkbox v-model="dtoMidwifeInfo.forMediation" label="Vermittlungsrelevant"></v-checkbox>
      </div>
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:100px;
        grid-template-columns:2fr 6fr ;">
        <datepicker title="Automatisch löschen ab" :propdate.sync="dtoMidwifeInfo.deleteDate"/>
      </div>
      <div style="
        display: grid;
        grid-gap: 20px;
        grid-template-rows:100px;
        grid-template-columns:3fr 2fr 1fr;">
        <div></div>
        <v-system-bar>
          {{ format(dtoMidwifeInfo.infoDate) }} - {{ dtoMidwifeInfo.userInfo.userName }}
        </v-system-bar>
      </div>
    </formcontent>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import formcontent from '#enna/common/components/content/FormContent'
import MidwifeInfoController from './midwife.info.controller'
import datepicker from '../../../common/components/element/DatePicker'
import { formatDate } from '../../../common/utils/date.utils'

export default {
  name: 'MidwifeInfoDetail',

  async created () {
    this.$pageInitController.initReload(this, this.$moduleInfoController.midwife, 'Hebamme', 'Info', this.midwifeName)
  },

  methods: {
    async reload () {
      await MidwifeInfoController.openInfo(this.midwifeId, this.pid, Date.now())
    },
    format (value) {
      return formatDate(value)
    },
    cancel () {
      MidwifeInfoController.openMidwifeInfoOverview(this.midwifeId)
    },
    save () {
      MidwifeInfoController.saveMidwifeInfo(this.dtoMidwifeInfo)
    }
  },

  watch: {
    propDtoMidwifeInfo (val) {
      this.dtoMidwifeInfo = cloneDeep(this.propDtoMidwifeInfo)
    }
  },

  props: {
    propDtoMidwifeInfo: { type: Object },
    pid: { type: Number },
    midwifeName: { type: String },
    midwifeId: { type: String }
  },

  data () {
    return {
      dtoMidwifeInfo: cloneDeep(this.propDtoMidwifeInfo)
    }
  },

  components: {
    datepicker,
    formcontent
  }
}
</script>
