var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "grid",
      "grid-template-columns": "3fr 3fr 3fr 1fr"
    }
  }, [_c('div', [_c('v-subheader', {
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_c('div', [_vm._v("Geburt / Kind")])]), _vm.hasEdate ? _c('div', {
    staticStyle: {
      "padding-bottom": "5px"
    }
  }, [_vm._v(" ET: "), _c('dateitem', {
    staticStyle: {
      "display": "inline"
    },
    attrs: {
      "date-item": _vm.inquiry.edate
    }
  })], 1) : _vm._e(), _vm.hasBirthdate ? _c('div', {
    staticStyle: {
      "padding-bottom": "5px"
    }
  }, [_vm._v(" Geburt: "), _c('dateitem', {
    staticStyle: {
      "display": "inline"
    },
    attrs: {
      "date-item": _vm.inquiry.birthdate
    }
  })], 1) : _vm._e(), _vm.hasGravida ? _c('div', {
    staticStyle: {
      "padding-bottom": "5px"
    }
  }, [_vm._v(" Gravida: " + _vm._s(_vm.inquiry.gravida) + " ")]) : _vm._e(), _vm.hasPara ? _c('div', {
    staticStyle: {
      "padding-bottom": "5px"
    }
  }, [_vm._v(" Para: " + _vm._s(_vm.inquiry.para) + " ")]) : _vm._e(), _vm.hasMultipleBirth ? _c('div', {
    staticStyle: {
      "padding-bottom": "5px"
    }
  }, [_vm._v(" " + _vm._s(_vm.inquiry.multipleBirth.name) + " ")]) : _vm._e(), _vm.hasBirthYear ? _c('div', {
    staticStyle: {
      "padding-bottom": "5px"
    }
  }, [_vm._v(" Frau ist " + _vm._s(_vm.womanAge) + " Jahre alt ")]) : _vm._e(), _vm.inquiry.activityList.length > 0 ? _c('div', {
    staticStyle: {
      "padding-bottom": "5px"
    }
  }, [_c('v-subheader', {
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_c('div', [_vm._v("Sucht nach:")])]), _vm._l(_vm.inquiry.activityList, function (activity) {
    return _c('li', {
      key: activity.pid
    }, [_vm._v(" - " + _vm._s(activity.name) + " ")]);
  })], 2) : _vm._e()], 1), _c('div', [_vm.hasContact ? _c('v-subheader', {
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_c('div', [_vm._v("Kontaktdaten")])]) : _vm._e(), _vm.hasMail ? _c('div', {
    staticStyle: {
      "padding-bottom": "5px"
    }
  }, [_vm._v(" " + _vm._s(_vm.inquiry.mail) + " ")]) : _vm._e(), _vm.hasContact1 ? _c('div', {
    staticStyle: {
      "padding-bottom": "5px"
    }
  }, [_vm._v(" " + _vm._s(_vm.inquiry.contact1) + " ")]) : _vm._e(), _vm.hasContact2 ? _c('div', {
    staticStyle: {
      "padding-bottom": "5px"
    }
  }, [_vm._v(" " + _vm._s(_vm.inquiry.contact2) + " ")]) : _vm._e(), _vm.hasContactInfo ? _c('div', {
    staticStyle: {
      "padding-bottom": "5px"
    }
  }, [_vm._v(" " + _vm._s(_vm.inquiry.contactInfo) + " ")]) : _vm._e(), _vm.hasAdress ? _c('div', [_c('v-subheader', {
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_c('div', [_vm._v("Anschrift")])]), _c('div', {
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(" " + _vm._s(_vm.inquiry.currentAdressAsString) + " ")])], 1) : _vm._e(), _vm.hasFutureAdress ? _c('div', [_c('v-subheader', {
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_c('div', [_vm._v("Zukünfige Anschrift")])]), _c('div', {
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(" " + _vm._s(_vm.inquiry.futureAdressAsString) + " ")]), _vm.hasFutureAdressFrom ? _c('div', {
    staticStyle: {
      "padding-bottom": "5px"
    }
  }, [_vm._v(" Gültig ab "), _c('dateitem', {
    staticStyle: {
      "display": "inline"
    },
    attrs: {
      "date-item": _vm.inquiry.futureAdressFrom
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1), _c('div', [_vm.hasInfo ? _c('div', [_c('v-subheader', {
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_c('div', [_vm._v("Zusätzliche Info")])]), _vm._v(" " + _vm._s(_vm.inquiry.info) + " ")], 1) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }