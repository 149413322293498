<template>
  <div>
    <div v-bind:style="{ 'overflow-y': 'auto', 'max-height': contentheight + 'px' }">
      <div class="container">
        <themecard class="name" title="Frau" :set="woman=this.concernSummary.womanSummary">
          <div>{{ woman.name }}</div>
          <div v-if="woman.telephone">{{ woman.telephone }}</div>
          <div v-if="woman.mail">{{ woman.mail }}</div>
          <div v-if="woman.edbd">
            {{ woman.edbdMode }}: <dateitem style="display: inline;" :date-item="woman.edbd"></dateitem>
          </div>
        </themecard>
        <themecard class="state" title="Status" :set="state=this.concernSummary.stateSummary">
          <div>
            Anfrage ist in Übersicht <span style="padding-right: 20px;">'{{ showListing(state) }} Anfragen'</span>
            <cmdbutton v-if="state.possibleStateAction" @cmdclick="changeState" :caption="state.possibleStateAction.actionCaption"/>
          </div>
        </themecard>
        <themecard class="caseInfo" title="Info" :set="state=this.concernSummary.stateSummary">
          <div>
            <caseinfo
              @reloadParent="reloadConcernSummary"
              :concernId="caseLocator.concernId"
              :versionId="concernVersion"
              :initialCaseId=null
              :info-list="concernSummary.infoList"
            />
          </div>
        </themecard>
        <themecard class="causeactivity" v-if="concernSummary.causeActivityList.length > 0" title="Leistungen">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Status</th>
                <th class="text-left">Todo</th>
                <th class="text-left">Hebamme</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in concernSummary.causeActivityList" :key="item.activityId"            >
                <td>{{ item.name }}</td>
                <td>{{ item.state.caption }}</td>
                <td>{{ item.todo.caption }}</td>
                <td>{{ item.midwife?.name }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </themecard>
        <themecard v-if="concernSummary.adviceList.length > 0" title="Beratungen">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">Datum</th>
                <th class="text-left">Bearbeiterin</th>
                <th class="text-left">Beratungsinhalt</th>
                <th class="text-left">Kommentar</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in concernSummary.adviceList" :key="item.pid">
                <td><dateitem style="display: inline;" :date-item="item.adviceTime"></dateitem></td>
                <td>{{ item.userName }}</td>
                <td>
                  <div v-for="theme in item.adviceThemeList" :key="theme">- {{ theme }}</div>
                </td>
                <td>{{ item.info }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </themecard>
      </div>
    </div>
  </div>
</template>

<script>
import themecard from '../../../common/components/element/ThemeCard'
import dateitem from '../../../common/components/element/DateItem'
import caseinfo from '../info/CaseInfo'
import ConcernSummaryController from './concern.summary.controller'

export default {
  name: 'ConcernSummary',

  components: {
    caseinfo,
    dateitem,
    themecard
  },

  async created () {
    this.$pageInitController.init(this, this.$moduleInfoController.concern, 'Frau', 'Zusammenfassung', this.womanName)
  },

  methods: {
    showListing (state) {
      if (state.showMode.listing === 'IN_OPEN') {
        return 'Offene'
      } else if (state.showMode.listing === 'IN_CLOSED') {
        return 'Geschlossene'
      }
    },

    async reloadConcernSummary () {
      await ConcernSummaryController.reloadConcernSummary(this.caseLocator, Date.now())
    },

    async changeState () {
      await ConcernSummaryController.changeConcernState(this.caseLocator, this.concernVersion, this.concernSummary.stateSummary.possibleStateAction.possibleAction, Date.now())
    }
  },

  computed: {
    contentheight: function () {
      return this.$store.getters['size/formContent']
    }
  },

  props: {
    caseLocator: { type: Object },
    womanName: { type: String },
    concernVersion: { type: Number },
    concernSummary: { type: Object }
  }
}
</script>
<style>
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.8fr 1.2fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "name info"
    "state info"
    "causeactivity causeactivity";
}
.caseInfo { grid-area: info; }
.causeactivity { grid-area: causeactivity; }
.name { grid-area: name; }
.state { grid-area: state; }
</style>
