import MidwifeMailService from './midwife.mail.service'
import router from '#enna/common/router'
import MenuController from '../../app/menu/menu.controller'

export default {
  async openMidwifeMailOverview (midwifeId) {
    const response = await MidwifeMailService.queryMails(midwifeId)
    const midwifeName = response.data.midwifeName
    const sendedMailList = response.data.sendedMailList
    const selectedTabIndex = await MenuController.getMidwifeFileStartTab('midwifemailoverview')
    router.push({ name: 'midwifemailoverview', params: { midwifeId: midwifeId, midwifeName: midwifeName, preselectedTab: selectedTabIndex, sendedMailList: sendedMailList } })
  }
}
