<template>
  <div style="">
    <div>
      <v-app-bar app color="grey lighten-1" dark style="padding-top: 50px; height: 170px;">
        <div style="display: grid; grid-template-columns: 2fr 2fr; ">
          <div></div>
          <v-toolbar-title>
            <span class="font-weight-thin" style="font-size:4em;">e n </span>
            <span class="font-weight-bold" style="font-size:4em;">n a</span>
            <span class="font-weight-bold" style="color:aquamarine; font-size:4em;padding-left: 20px;">.</span>
            <span style="padding-left: 20px;font-size:2em;">network</span>
          </v-toolbar-title>
        </div>
        <v-spacer></v-spacer>
      </v-app-bar>
    </div>
    <div style="
      display: grid;
      grid-template-areas:'. . .' '. login .' '. . .';
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;">
      <div style="grid-area: login;">
        <slot></slot>
      </div>
    </div>
    <errorpanel></errorpanel>
  </div>
</template>

<script>
import errorpanel from '../../../module/app/error/ErrorPanel'

export default {
  name: 'loginlayout',

  components: {
    errorpanel
  }
}
</script>
