var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('formcontent', {
    attrs: {
      "ok-text": "Speichern",
      "cancel-text": "Abbrechen"
    },
    on: {
      "onOk": _vm.save,
      "onCancel": _vm.cancel
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-rows": "100px",
      "grid-template-columns": "4fr 2fr"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "Info",
      "clearable": "",
      "rows": "6"
    },
    model: {
      value: _vm.dtoMidwifeInfo.info,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeInfo, "info", $$v);
      },
      expression: "dtoMidwifeInfo.info"
    }
  })], 1), _c('div', {
    staticStyle: {
      "padding-top": "100px",
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-rows": "100px",
      "grid-template-columns": "1fr 1fr 1fr 2fr 1fr"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Zu erledigen"
    },
    model: {
      value: _vm.dtoMidwifeInfo.forTodo,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeInfo, "forTodo", $$v);
      },
      expression: "dtoMidwifeInfo.forTodo"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Allgemein"
    },
    model: {
      value: _vm.dtoMidwifeInfo.forAgency,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeInfo, "forAgency", $$v);
      },
      expression: "dtoMidwifeInfo.forAgency"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Vermittlungsrelevant"
    },
    model: {
      value: _vm.dtoMidwifeInfo.forMediation,
      callback: function ($$v) {
        _vm.$set(_vm.dtoMidwifeInfo, "forMediation", $$v);
      },
      expression: "dtoMidwifeInfo.forMediation"
    }
  })], 1), _c('div', {
    staticStyle: {
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-rows": "100px",
      "grid-template-columns": "2fr 6fr"
    }
  }, [_c('datepicker', {
    attrs: {
      "title": "Automatisch löschen ab",
      "propdate": _vm.dtoMidwifeInfo.deleteDate
    },
    on: {
      "update:propdate": function ($event) {
        return _vm.$set(_vm.dtoMidwifeInfo, "deleteDate", $event);
      }
    }
  })], 1), _c('div', {
    staticStyle: {
      "display": "grid",
      "grid-gap": "20px",
      "grid-template-rows": "100px",
      "grid-template-columns": "3fr 2fr 1fr"
    }
  }, [_c('div'), _c('v-system-bar', [_vm._v(" " + _vm._s(_vm.format(_vm.dtoMidwifeInfo.infoDate)) + " - " + _vm._s(_vm.dtoMidwifeInfo.userInfo.userName) + " ")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }