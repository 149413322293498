import store from '../../../common/store'
import UserController from '../../user/user.controller'
import getEnv from '@/utils/env'

export default {
  async login (data) {
    await store.dispatch('auth/login', data)
    // Todo Wenn Fehler, dann muß Verarbeitung abbrechen
    UserController.initializeUser()
  },

  getMandatorList () {
    const mandatorId = getEnv('VUE_APP_CARRIER_ID')
    if (mandatorId != null) {
      const mandatorList = store.getters['carrier/getMandatorList'](mandatorId)
      return mandatorList
    }
    return []
  },

  logout () {
    store.dispatch('auth/logout')
    store.dispatch('menu/resetMenu')
    store.dispatch('menu/resetMidwifeFileTabs')
    store.dispatch('menu/resetShortlinks')
    store.commit('right/resetRightsMenu')
    store.dispatch('versioning/resetAllVersion')
  }
}
