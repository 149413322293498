import ApiService from '../../../common/service/api/api.service'

const ConcernSummaryService = {
  async loadConcernSummary (concernId) {
    const result = await ApiService.doGet('/enna/cause/concernsummary/load/' + concernId)
    return result
  },

  async changeConcernState (caseLocator, versionId, openState) {
    const param = {
      concernId: caseLocator.concernId,
      versionId: versionId,
      openState: openState
    }
    const result = await ApiService.doPost('/enna/cause/concernsummary/state', param)
    return result
  }
}

export default ConcernSummaryService
