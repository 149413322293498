import ApiService from '../../../common/service/api/api.service'

const ConcernDoubletService = {
  async loadDoublet (concernId) {
    const result = await ApiService.doGet('/enna/cause/webform/compare/' + concernId)
    return result
  },

  async releaseDoublet (webformReleaseData) {
    const result = await ApiService.doPost('/enna/cause/webform/release', webformReleaseData)
    return result
  },

  async proofed (webformProofData) {
    const result = await ApiService.doPost('/enna/cause/webform/proofed', webformProofData)
    return result
  },

  getItemList () {
    const itemList = [
      // { caption: '', index: 'compactInquiryOrigin: CompactInquiryOrigin = CompactInquiryOrigin.concern
      { caption: 'ID', index: 'pid', type: 'Number' },
      { caption: 'Datum', index: 'callDate', type: 'LocalDateTime' },
      { caption: 'Vorname', index: 'firstname', type: 'String' },
      { caption: 'Nachname', index: 'secondname', type: 'String' },
      { caption: 'Zukünft. Nachname', index: 'marriageSurname', type: 'String' },
      { caption: 'Zukünft. Nachname ab', index: 'marriageDate', type: 'LocalDate' },
      { caption: 'Geburtsjahr', index: 'birthYear', type: 'Number' },
      { caption: 'Aktuelle Adresse', index: 'currentAdressAsString', type: 'String' },
      { caption: 'Zukünft. Adresse', index: 'futureAdress', type: 'Adress' },
      { caption: 'Zukünft. Adresse ab', index: 'futureAdressFrom', type: 'LocalDate' },
      { caption: 'Info zur Adresse', index: 'adresschangeinfo', type: 'String' },
      { caption: 'Email', index: 'mail', type: 'String' },
      { caption: 'Telefon 1', index: 'contact1', type: 'String' },
      { caption: 'Telefon 2', index: 'contact2', type: 'String' },
      { caption: 'Info zum Kontakt', index: 'contactInfo', type: 'String' },
      { caption: 'ET', index: 'edate', type: 'LocalDate' },
      { caption: 'Geburt', index: 'birthdate', type: 'LocalDate' },
      { caption: 'Para', index: 'para', type: 'Number' },
      { caption: 'Gravida', index: 'gravida', type: 'Number' },
      { caption: 'Mehrfachgeburt', index: 'multipleBirth', type: 'KeylistValue' },
      { caption: 'Leistungen', index: 'activityList', type: 'ActivityList' },
      { caption: 'Original-Info', index: 'info', type: 'String' },
      { caption: 'Überarbeitete Info', index: 'modifiedInfo', type: 'String' },
      // { caption: 'Sonstige Angaben', index: 'WebformKeylistValue> = emptyList()
      { caption: 'Kontaktkanal', index: 'initialContactChannel', type: 'KeylistValue' },
      { caption: 'Kontakt über', index: 'contacter', type: 'Contacter' },
      { caption: 'Fehler', index: 'errorMap', type: 'StringMap' }
    ]
    return itemList
  }
}

export default ConcernDoubletService
