<template>
  <div>
    <formcontent
      ok-text="Speichern" @onOk="save"
      cancel-text="Abbrechen" @onCancel="cancel">
      <div style="padding-bottom:20px;" class="font-weight-light">{{ dynPropParam.name }}</div>
      <slot/>
    </formcontent>
  </div>
</template>

<script>
import formcontent from '#enna/common/components/content/FormContent'
import DynPropController from './dynprop.controller'

export default {
  name: 'DynPropBaseDetail',

  async created () {
    this.$pageInitController.initReload(this, this.moduleController, this.moduleInfo.first, this.moduleInfo.second, this.moduleInfo.name)
  },

  methods: {
    async reload () {
      DynPropController.reload(this.dynPropParam)
    },
    cancel () {
      DynPropController.cancel(this.dynPropParam)
    },

    save () {
      this.$emit('onSave')
    }
  },

  props: {
    dynPropParam: { type: Object }
  },

  data () {
    return {
      moduleInfo: this.dynPropParam.moduleInfo,
      moduleController: this.dynPropParam.moduleController
    }
  },

  components: {
    formcontent
  }
}
</script>
