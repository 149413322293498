var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.layout === 'block' ? _c('div', {
    staticStyle: {
      "display": "grid",
      "grid-template-columns": "2fr 4fr"
    }
  }, [_vm.hasDeadline() ? _c('div', {
    staticClass: "font-weight-thin"
  }, [_vm._v(_vm._s(_vm.deadlineCaption()))]) : _vm._e(), _vm.hasDeadline() ? _c('div', [_vm._v(_vm._s(_vm.deadline()))]) : _vm._e(), _vm.location ? _c('div', {
    staticClass: "font-weight-thin"
  }, [_vm._v("Wohnort")]) : _vm._e(), _vm.location ? _c('div', [_vm._v(_vm._s(_vm.location.name))]) : _vm._e(), _vm.hasActivity() ? _c('div', {
    staticClass: "font-weight-thin"
  }, [_vm._v("Leistungen")]) : _vm._e(), _c('div', _vm._l(_vm.activitySearchParamList, function (searchActivity) {
    return _c('div', {
      key: searchActivity.activity.activityId
    }, [_c('div', [searchActivity.acute ? _c('span', [_c('v-icon', {
      attrs: {
        "color": "red"
      }
    }, [_vm._v("mdi-exclamation")]), _vm._v(" Akut "), _c('v-icon', {
      attrs: {
        "color": "red"
      }
    }, [_vm._v("mdi-exclamation")])], 1) : _vm._e(), _c('strong', {
      style: {
        'color': _vm.activityColor
      }
    }, [_vm._v(_vm._s(searchActivity.activity.name))]), _c('div', {
      staticClass: "font-weight-light",
      staticStyle: {
        "padding-left": "20px"
      }
    }, [_vm._v(_vm._s(_vm.showStart(searchActivity.from, searchActivity.until)))])])]);
  }), 0)]) : _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "30%"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "grid",
      "grid-template-columns": "2fr 4fr"
    }
  }, [_vm.hasDeadline() ? _c('div', {
    staticClass: "font-weight-thin"
  }, [_vm._v(_vm._s(_vm.deadlineCaption()))]) : _vm._e(), _vm.hasDeadline() ? _c('div', [_vm._v(_vm._s(_vm.deadline()))]) : _vm._e(), _vm.location ? _c('div', {
    staticClass: "font-weight-thin"
  }, [_vm._v("Wohnort")]) : _vm._e(), _vm.location ? _c('div', [_vm._v(_vm._s(_vm.location.name))]) : _vm._e()])]), _c('div', {
    staticStyle: {
      "width": "10%"
    }
  }, [_vm.hasActivity() ? _c('div', {
    staticClass: "font-weight-thin"
  }, [_vm._v("Leistungen")]) : _vm._e()]), _c('div', {
    staticStyle: {
      "width": "60%"
    }
  }, [_c('div', _vm._l(_vm.activitySearchParamList, function (searchActivity) {
    return _c('div', {
      key: searchActivity.activity.activityId
    }, [_c('div', [searchActivity.acute ? _c('span', [_c('v-icon', {
      attrs: {
        "color": "red"
      }
    }, [_vm._v("mdi-exclamation")]), _vm._v(" Akut "), _c('v-icon', {
      attrs: {
        "color": "red"
      }
    }, [_vm._v("mdi-exclamation")])], 1) : _vm._e(), _c('strong', {
      style: {
        'color': _vm.activityColor
      }
    }, [_vm._v(_vm._s(searchActivity.activity.name))]), _c('span', {
      staticClass: "font-weight-light",
      staticStyle: {
        "padding-left": "20px"
      }
    }, [_vm._v(_vm._s(_vm.showStart(searchActivity.from, searchActivity.until)))])])]);
  }), 0)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }