<template>
  <div>
    <div style="height: 70px;padding-top: 20px;" >
      <div>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-row justify="end" align="start">
                <cmdbutton v-if="showOk && writeModus" @cmdclick="onOk" :caption="okText"/>
                <cmdbutton v-if="showCancel" @cmdclick="onCancel" :caption="cancelText"/>
                <v-spacer></v-spacer>
                <cmdbutton v-if="showButtonOne" @cmdclick="onButtonOne" :caption="buttonOneText" />
                <cmdbutton v-if="showButtonTwo" @cmdclick="onButtonTwo" :caption="buttonTwoText" />
                <cmdbutton v-if="showButtonThree" @cmdclick="onButtonThree" :caption="buttonThreeText" />
                <cmdbutton v-if="showButtonFour" @cmdclick="onButtonFour" :caption="buttonFourText" />
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
// import { containsRight } from '../../utils/right.utils'

export default {

  computed: {
    writeModus () {
      return true
      // return containsRight(this.writeRight) || true
    },
    showOk () {
      return this.okText
    },
    showCancel () {
      return this.cancelText
    },
    showButtonOne () {
      return this.buttonOneText
    },
    showButtonTwo () {
      return this.buttonTwoText
    },
    showButtonThree () {
      return this.buttonThreeText
    },
    showButtonFour () {
      return this.buttonFourText
    }
  },

  methods: {
    onOk () {
      this.$emit('onOk')
    },
    onCancel () {
      this.$emit('onCancel')
    },
    onButtonOne () {
      this.$emit('onButtonOne')
    },
    onButtonTwo () {
      this.$emit('onButtonTwo')
    },
    onButtonThree () {
      this.$emit('onButtonThree')
    },
    onButtonFour () {
      this.$emit('onButtonFour')
    }
  },

  props: {
    buttonOneText: String,
    buttonTwoText: String,
    buttonThreeText: String,
    buttonFourText: String,
    okText: String,
    cancelText: String,
    writeRight: String
  }
}
</script>
