import TextModuleService from './textmodule.service'
import MailTemplateController from '../mailtemplate/mailtemplate.controller'
import router from '../../../common/router'
import PageinfoController from '../../app/pageinfo/pageinfo.controller'

const textModuleOverviewName = 'TextModuleOverview'

export default {
  saveFilterParam (filterParam) {
    PageinfoController.setPageinfo(textModuleOverviewName, filterParam)
  },

  async openNewTextModule () {
    const response = await TextModuleService.new()
    callTextModuleDetail(response, null)
  },

  async preview (previewParam) {
    const response = await TextModuleService.preview(previewParam)
    return response.data
  },

  async openTextModule (pid, reloadId) {
    const response = await TextModuleService.load(pid)
    callTextModuleDetail(response, reloadId)
  },

  async deleteTextModule (pid) {
    await TextModuleService.delete(pid)
    const result = await TextModuleService.query()
    return result
  },

  async save (dto) {
    await TextModuleService.save(dto)
    await callTextModuleOverview()
  },

  async openMailTemplateOverview () {
    MailTemplateController.openMailTemplateOverview()
  },

  async openTextModuleOverview (pid) {
    await callTextModuleOverview(pid)
  }
}

async function callTextModuleOverview (pid) {
  const textModuleList = await TextModuleService.query()
  let hashId = ''
  if (pid) {
    hashId = '#' + pid
  }
  router.push({ name: 'textmoduleoverview', hash: hashId, params: { propTextModuleList: textModuleList, writeRight: 'mailtemplateWrite' } })
}

async function callTextModuleDetail (response, reloadId) {
  const conditionList = response.data.textEditSupportData.conditionList
  const placeholderList = response.data.textEditSupportData.placeholderList
  const textModuleList = response.data.textEditSupportData.textModuleList
  const caseMidwifeList = response.data.textEditSupportData.caseMidwifeList
  const midwifeList = response.data.textEditSupportData.midwifeList
  const propDtoTextModule = response.data.textModule
  router.push({
    name: 'textmoduledetail',
    params: {
      conditionList: conditionList,
      placeholderList: placeholderList,
      textModuleList: textModuleList,
      caseMidwifeList: caseMidwifeList,
      midwifeList: midwifeList,
      propDtoTextModule: propDtoTextModule
    },
    query: { reload: reloadId }
  })
}
