var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._v(" " + _vm._s(_vm._f("formattedDate")(_vm.dateItem)) + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }